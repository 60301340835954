import React from "react";
import { Tooltip, alpha, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";

import theme from "./../../theme";

const TailwindButton = ({
  text,
  type = "add",
  onClick,
  color = theme.palette.color.white,
  loadingIndicator,
  backgroundColor = theme.palette.primary.main,
  backgroundColorCancel = theme.palette.color.slate[200],
  disable,
  loading = false,
  icon = null,
  variant = "contained",
  loadingPosition = undefined,
  className = "",
  tooltip = "",
  id,
  sx,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let borderColor = "transparent";
  let _color = theme.palette.color.slate[800];
  let _backgroundColor = backgroundColor;
  let _backgroundColorHover = backgroundColor;

  if (variant === "contained") {
    if (!disable && !loading) {
      if (type === "add") {
        borderColor = backgroundColor;
      } else {
        borderColor = backgroundColorCancel;
      }
    }

    if (type === "add") {
      _backgroundColor = backgroundColor;
      _backgroundColorHover = alpha(backgroundColor, 0.9);
      _color = color;
    } else {
      _backgroundColor = backgroundColorCancel;
      _backgroundColorHover = alpha(backgroundColorCancel, 0.9);
    }
  }
  if (variant === "outlined") {
    if (disable || loading) {
      borderColor = theme.palette.color.slate[200];
      _backgroundColor = theme.palette.color.slate[50];
      _backgroundColorHover = theme.palette.color.slate[50];
      _color = theme.palette.color.slate[500];
    } else {
      borderColor = color;
      _color = color;
      _backgroundColor = alpha(color, 0.05);
      _backgroundColorHover = alpha(color, 0.1);
    }
  }

  return (
    <Tooltip title={t(tooltip)} placement="top">
      <span style={{ width: "fit-content" }}>
        <Button
          id={id}
          className={className}
          type="button"
          disableElevation
          disableFocusRipple
          disableRipple
          variant={variant}
          onClick={onClick}
          loading={loading}
          loadingIndicator={loadingIndicator}
          loadingPosition={loadingPosition}
          sx={{
            width: "fit-content",
            justifyContent: "center",
            borderRadius: theme.borderRadius.main,
            backgroundColor: _backgroundColor,
            px: "0.5rem",
            py: loading ? "0.35rem !important" : "0.5rem",
            fontSize: "0.875rem",
            lineHeight: "0.875rem",
            height: "2.25rem",
            minHeight: "auto",
            fontWeight: theme.typography.fontWeightMediumBold,
            color: _color,
            border: `1px solid ${borderColor}`,
            transition: "background-color 0.3s ease",
            ml: "1rem",
            "&:hover": {
              borderColor,
              backgroundColor: _backgroundColorHover,
            },
            ...sx,
          }}
          disabled={disable}
        >
          {icon}
          {text}
        </Button>
      </span>
    </Tooltip>
  );
};
export default TailwindButton;
