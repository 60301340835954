import { useDispatch, useSelector } from "react-redux";
import { useIsFetching } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import React from "react";

import { setSteps, startTour } from "../store/slices/tour";
import { setJoyRideStatus } from "../store/slices/global";
import useDebounce from "./3-useDebounce/useDebounce";

// Hook
function useJoyRide({ key, allow, timer, path, callback, delay = 1000 }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const isFetching = useIsFetching();

  //redux
  const joyRideStatus = useSelector(
    (state) => state.globalSlice?.joyRideStatus
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );

  const shouldRun =
    Boolean(allow) &&
    isFetching === 0 &&
    isAllHeaderApiFetched &&
    !isFirstLoginOverlayOpen &&
    !isAccountSelectOverlayOpen &&
    joyRideStatus?.filters &&
    !joyRideStatus?.filters?.[key] &&
    location.pathname === path;
    key;

  useDebounce(
    () => {
      let joyRideStatusFilter = joyRideStatus?.filters;
      if (shouldRun && joyRideStatusFilter) {
        let filterObj = {
          ...joyRideStatus,
          data_type: "transaction",
          filters: { ...joyRideStatusFilter, [key]: true },
        };
        setTimeout(() => {
          dispatch(setSteps({ key: key }));
          dispatch(startTour());
          dispatch(setJoyRideStatus(filterObj));
          if (callback) callback();
        }, timer);
      }
    },
    delay,
    [
      allow,
      shouldRun,
      key,
      joyRideStatus?.filters,
      t,
      timer,
      location.pathname,
      callback,
    ]
  );

  return true;
}

export default useJoyRide;
