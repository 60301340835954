const imageCache = new Map();

// Load image dynamically
const loadImage = async (path) => {
  if (imageCache.has(path)) {
    return imageCache.get(path);
  }
  try {
    // Special handling for SVG files
    if (path.endsWith(".svg")) {
      const imagePath = `/assets/${path}`;
      imageCache.set(path, imagePath);
      return imagePath;
    }

    const imagePath = `/assets/${path}`;
    imageCache.set(path, imagePath);
    return imagePath;
  } catch (error) {
    console.error(`Failed to load image: ${path}`, error);
    return null;
  }
};

// Image categories
const ImageCategories = {
  TRANSPARENT: "transparent",
  BANNERS: "banners",
  PLACEHOLDERS: "placeholders",
  LOGOS: "logos",
  ILLUSTRATIONS: "illustrations",
};

// Image registry with metadata
const ImageRegistry = {
  // Transparent logos
  transparent: {
    TinkTransparentLogo3: "europe-banking-provider_small.png",
    TinkTransparentLogo2: "europe-banking-provider.png",
    LexOfficeTransparentLogo: "lexwareoffice.png",
    SevDeskTransparentLogo: "sevdesk.png",
    MocoTransparentLogo: "logo_moco.png",
    ExcelTransparentLogo: "excel.png",
    mBankTransparentLogo: "mbank.png",
    googleSheetsTransparentLogo: "google-sheets.png",
    getMyInvoicesTransparentLogo: "getmyinvoices.png",
    BuchhaltungsButlerTransparentLogo: "BuchhaltungsButler.png",
    billbeeTransparentLogo: "billbee.png",
    easybillTransparentLogo: "easybill.png",
    FastbillTransparentLogo: "fastbill.png",
    BillomatTransparentLogo: "billomat.png",
    StripeTransparentLogo: "stripe.png",
    ShopifyTransparentLogo: "shopify.png",
    mollieTransparentLogo: "molli.png",
    PleoTransparentLogo: "pleo.png",
    PliantTransparentLogo: "pliant.png",
    MossTransparentLogo: "moss.png",
    SpendeskTransparentLogo: "spendesk.png",
    PipedriveTransparentLogo: "pipedrive.png",
    HubSpotTransparentLogo: "hubspot.png",
    AttioTransparentLogo: "attio.png",
    ActiveCampaignTransparentLogo: "activecampaingn.png",
    CloseTransparentLogo: "close.png",
    HighLevelTransparentLogo: "highlevel.png",
    InsightlyTransparentLogo: "insightly.png",
    SalesforceTransparentLogo: "salesforce.png",
    BullhornTransparentLogo: "bullhorn.png",
    OutreachTransparentLogo: "outreach.png",
    ZOHOCRMTransparentLogo: "zohocrm.png",
    AffinityTransparentLogo: "spendesk.png",
    PersonioTransparentLogo: "personio.png",
    ShareTransparentLogo: "personio.png",
    HibobTransparentLogo: "bob.png",
    WorkdayTransparentLogo: "workday.png",
    GoogleDriveTransparentLogo: "googledrive.png",
    ZapierTransparentLogo: "zapier.png",
    XentralTransparentLogo: "xentral.png",
    WeclappTransparentLogo: "weclapp.png",
    WaveTransparentLogo: "wave.png",
    SageTransparentLogo: "sage.png",
    PaperWorkTransparentLogo: "papierkram.png",
    NetsuiteTransparentLogo: "netsuite.png",
    XeroTransparentLogo: "xero.png",
    QuickBooksTransparentLogo: "quickbooks.png",
    ZOHOBookingTransparentLogo: "zohobooking.png",
    ZOHOInvoiceTransparentLogo: "zohoinvoice.png",
    OrgamaxTransparentLogo: "orgamax.png",
    MakeTransparentLogo: "Make.png",
    PaypalTransparentLogo: "paypal.png",
  },

  // Banners and marketing
  mailing_banner: "mailing-banner.png",
  latest_updates_banner: "latest_updates_banner.png",
  banner_empty: "banner_empty.png",

  // Avatars and defaults
  default_avatar: "default_avatar.png",
  onboarding_avatar: "finbot-first-login.svg",
  defaultRobot: "default.png",

  // Auth and onboarding
  AuthPageImage: "AuthPageImage.webp",
  signup: "signup.jpg",
  onboarding: "onboarding.png",
  register_bg: "register_bg.png",
  first_login_page_2_Bg: "first_login_page_2_Bg.png",

  // Charts and insights
  kanban_normal: "kanban_normal.png",
  open_booked_planned_insights: "open_booked_planned_insights.png",
  quick_start_insights: "quick_start_insights.png",
  quick_start_insight_4: "quick_start_insight_4.png",
  quick_start_insight_5: "quick_start_insight_5.png",
  quick_start_insight_6: "quick_start_insight_6.png",
  quick_start_insight_7: "quick_start_insight_7.png",
  quick_start_insight_8: "quick_start_insight_8.png",
  bank_integrations_insights: "bank_integrations_insights.png",
  incomeStatement: "incomeStatement.png",
  income_statement_placeholder: "income_statement_placeholder.png",
  client_portfolio: "client_portfolio.png",
  staff_chart: "staff_chart.png",
  kanban_daily_chart: "kanban_daily_chart.png",
  first_login_page_2_ref: "first_login_page_2_ref.png",

  // Logos
  finban_logo_small: "finban_logo_small.png",
  finban_logo_full: "finban_logo_full.png",
  capterra_logo: "capterra_logo.png",
  logo_finban: "logo_finban.webp",

  // Tips and help
  tips_9: "tips_9.webp",
  tips_10: "tips_10.webp",

  // Other UI elements
  staff: "staff.png",
  dataset_lists_insights: "dataset_lists_insights.png",
  cost_unit_insights: "cost_unit_insights.png",
  liquidity_insight: "liquidity_insight.png",
  forcast_placeholder: "forcast_placeholder.png",
  success_report_placeholder: "success_report_placeholder.png",
  cost_ratio_placeholder: "cost_ratio_placeholder.png",
  setup_offline_organisation: "setup_offline_organisation.png",
  setup_online_bank_account: "setup_online_bank_account.png",
  single_card_explanation: "single_card_explanation.png",
  group_card_explanation: "group_card_explanation.png",
  group_single: "group_single.png",
  chart_and_states: "chart_and_states.png",
  categories_group: "categories_group.png",
  card_insights: "card_insights.png",
  group_state: "group_state.png",
  sheet_to_kanban: "sheet_to_kanban.png",
  Smart_Sequences: "Smart_Sequences.png",
  betriebskapital: "betriebskapital.png",
  "burn-rate-12-month": "burn-rate-12-month.png",
  "profit-12-month": "profit-12-month.png",
  "income-12-month": "income-12-month.png",
  "expenses-12-month": "expenses-12-month.png",
  "cash-development": "cash-development.png",
  "invoice-status-over-time": "invoice-status-over-time.png",
  "lead-status-over-time": "lead-status-over-time.png",
  "offer-status-over-time": "offer-status-over-time.png",
  "over-budget-income": "over-budget-income.png",
  "over-budget-expense": "over-budget-expense.png",
  "latest-bank-transactions": "latest-bank-transactions.png",
  "latest-invoice-transactions": "latest-invoice-transactions.png",
  "latest-lead-transactions": "latest-lead-transactions.png",
  "latest-offer-transactions": "latest-offer-transactions.png",
  "income-pie": "cost.png",
  "expense-pie": "cost.png",
  cost: "cost.png",
  profitability: "profitability.png",
  eur: "eur.png",
  empty_placeholder: "empty_placeholder.png",
  employees_placeholder: "employees_placeholder.png",
  reports_placeholder: "reports_placeholder.png",
  "cash-flow": "cashflow.png",
  report_compare_icon_off: "compare_icon_off.svg",
  report_compare_icon_prev: "compare_icon_prev.svg",
  report_compare_icon_year: "compare_icon_year.svg",
  "cogs-vs-operating": "cogs-vs-operating.png",
  "general-type": "general-type.png",
  software_advice: "software_advice.png",
};

// Get image URL by key
const getImage = async (key) => {
  if (!key) {
    console.error("No image key provided");
    return null;
  }

  const category =
    typeof key === "string" && key.includes(".") ? key.split(".")[0] : null;

  const imageName = category
    ? ImageRegistry[category]?.[key.split(".")[1]] || null
    : ImageRegistry[key] || null;

  if (!imageName) {
    console.error(`Image not found in registry: ${key}`);
    return null;
  }

  return await loadImage(imageName);
};

// Preload commonly used images
const preloadImages = async (keys) => {
  const promises = keys.map((key) => getImage(key));
  return Promise.all(promises);
};

// Export the image utility
const Images = {
  get: getImage,
  preload: preloadImages,
  categories: ImageCategories,
  registry: ImageRegistry,
};

export default Images;
