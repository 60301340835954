import TipsAndUpdatesTwoToneIcon from "@mui/icons-material/TipsAndUpdatesTwoTone";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";

import FinbanInfoBulb from "./FInbanInfo/FinbanInfoBulb";
import Translate from "../hooks/HOC/Translate";
import AsyncImage from "./AsyncImage";
import { Images } from "../Helper";
import theme from "../theme";

//20 tips
const tips = [
  "overlay_tips_01",
  "overlay_tips_02",
  "overlay_tips_03",
  "overlay_tips_04",
  "overlay_tips_05",
  "overlay_tips_06",
  "overlay_tips_07",
  "overlay_tips_08",
  "overlay_tips_09",
  "overlay_tips_10",
  "overlay_tips_11",
  "overlay_tips_12",
  "overlay_tips_13",
  "overlay_tips_14",
  "overlay_tips_15",
  "overlay_tips_16",
  "overlay_tips_17",
  "overlay_tips_18",
  "overlay_tips_19",
  "overlay_tips_20",
];

const slideInAndChangeColor = {
  "@keyframes slideInAndChangeColor": {
    from: {
      transform: "translateY(20px)",
      opacity: 0,
      color: theme.palette.primary.main,
    },
    to: {
      transform: "translateY(0)",
      opacity: 1,
      color: theme.palette.primary[500],
    },
  },
  color: theme.palette.primary.main,
  animation: "slideInAndChangeColor 0.5s ease-out",
};

const TipsWhileLoading = ({ sx }) => {
  const { t } = useTranslation();
  let last_tipIndex = 0; // Default value

  try {
    last_tipIndex = Number(localStorage.getItem("tipIndex")) || 0;
  } catch (error) {
    console.warn("LocalStorage access is restricted:", error);
  }

  const tipsData = useMemo(() => {
    return tips?.filter((item) => t(item) !== "hide");
  }, [t]);

  const tipIndex = tipsData?.[last_tipIndex] ? last_tipIndex : 0;

  React.useEffect(() => {
    try {
      const nextIndex =
        Number(tipIndex) + 1 > tipsData?.length - 1 ? 0 : Number(tipIndex) + 1;
      setTimeout(() => {
        localStorage.setItem("tipIndex", nextIndex);
      }, 4000);
    } catch (error) {
      console.warn("LocalStorage access is restricted:", error);
    }
  }, []);

  if (!tipsData || !tipsData?.[tipIndex] || tipsData?.length === 0) {
    return null;
  }
  const color = "slate";
  return (
    <Box
      variant="span"
      component="span"
      sx={{
        width: "100%",
        mt: "2rem",
        textAlign: "center",
        display: "flex",
        height: "fit-content",
        ...sx,
      }}
    >
      <Stack
        variant="span"
        component="span"
        sx={{
          width: "40rem",
          mx: "auto",
          backgroundColor: theme?.palette?.color?.[color]?.[50],
          borderRadius: theme?.borderRadius?.borderRadiusXL,
          p: "1.5rem",
          pb: "4rem",
          height: "fit-content",
          position: "relative",
        }}
      >
        <Stack
          variant="span"
          component="span"
          key={tipIndex} // need key to re-trigger animation on each tip change
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "start",
            gap: "0.5rem",
            ...slideInAndChangeColor, //  custom slide-in and color change animation
          }}
        >
          <TipsAndUpdatesTwoToneIcon
            sx={{
              fontSize: "1.7rem",
              color: theme?.palette?.color?.["orange"]?.[500],
              cursor: "pointer",
            }}
          />
          <Typography
            variant="span"
            component="span"
            sx={{
              fontSize: "1rem",
              gap: "0.5rem",
              textAlign: "left",
              color: theme?.palette?.color?.[color]?.[700],
            }}
          >
            <Translate i18nkey={tipsData?.[tipIndex] || ""} />
            {[9, 10].includes(tipIndex + 1) &&
            !!Images?.[`tips_${tipIndex + 1}`] ? (
              <div
                style={{
                  borderRadius: theme.borderRadius.main,
                  border: `1px solid ${theme.palette.color.slate[400]}`,
                  marginBlock: "0.5rem",
                }}
              >
                <AsyncImage
                  component="img"
                  imageKey={`tips_${tipIndex + 1}`}
                  alt={"tips_image"}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: theme.borderRadius.main,
                  }}
                ></AsyncImage>
              </div>
            ) : null}
          </Typography>
        </Stack>
        <FinbanInfoBulb
          text={t("all_quick_tips")}
          isDirect
          hideIcon
          url={"https://finban.io/documentation/quick-tipps/"}
          sx={{
            color: theme.palette.color?.slate[600],
            backgroundColor: "transparent",
            borderColor: "transparent",
            textDecoration: "underline",
            zIndex: 1,
            width: "fit-content",
            position: "absolute",
            right: "0.5rem",
            bottom: "0.5rem",
            "&:hover": {
              borderColor: "transparent",
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          }}
        />
      </Stack>
    </Box>
  );
};

export default TipsWhileLoading;
