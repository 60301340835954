import { Box, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import React from "react";

import { Color, Fonts } from "../../Helper";

const DescriptionInput = ({
  value = "",
  onChange,
  error,
  helperText,
  fontSize = "0.85rem",
  name = "",
  disabled = false,
  tooltip = "",
  variant = "outlined",
  placeholder = "",
  label = null,
  likeGoogle = false,
  hideTitle = false,
  borderRadius = 1,
  rows = 4,
  maxRows = 4,
  onBlur = () => {},
  onFocus = () => {},
  sx,
  maxLength = 255,
  showMaxLength = true,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);

  const onFocusInput = (e) => {
    setIsFocus(true);
    onFocus(e);
  };

  const onBlurInput = (e) => {
    setIsFocus(false);
    onBlur(e);
  };

  const handleInputChange = (e) => {
    onChange(e);
  };

  return (
    <Box sx={sx}>
      {!hideTitle && (
        <Tooltip placement="top" title={t(`${tooltip}`)}>
          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            sx={{
              display: "flex",
              width: "70%",
              textAlign: "left",
              mb: "0.25rem",
              fontWeight: theme.typography.fontWeightBold,
              fontSize: fontSize,
            }}
          >
            {`${t("Note")} (${t("optional")})`}
          </Typography>
        </Tooltip>
      )}
      <Box
        sx={{
          height: "fit-content",
          minHeight: "2.5rem",
          ".MuiFormControl-root": {
            width: "100%",
          },
          "& .MuiInputLabel-root": {
            color: theme.palette.color.mainTitle,
            fontWeight: theme.typography.fontWeightMediumBold,
            fontSize: fontSize,
            transform: "translate(12px, 13px) scale(1)",
            "&[data-shrink=true]": {
              transform: "translate(12px, 4px) scale(1)",
            },
          },
          "& input": {
            fontSize: "0.9rem !important",
          },
          ".MuiOutlinedInput-root": {
            width: "100%",
            fontSize: fontSize,
            fontWeight: 600,
            fontFamily: Fonts.Text,
            p: 0,
            "&:hover .MuiOutlinedInput-notchedOutline": {
              backgroundColor: "rgba(0,0,0,0.03)",
            },
          },
          ".MuiOutlinedInput-input": {
            p: "0.5rem",
            backgroundColor: Color.white,
            "&:focus": {
              p: "0.4rem",
              border: `1px solid ${Color.oInputBorderBg}`,
              borderRadius: 1,
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${Color.oInputBorderBg}`,
          },
          ".MuiInputBase-root": {
            borderRadius: borderRadius,
            backgroundColor:
              likeGoogle && isFocus ? Color.inputBG : "transparent",
            "&:before": {
              borderBottom: likeGoogle && "0px !important",
            },
            "&:hover": {
              backgroundColor: likeGoogle && Color.inputBG,
              "&:before": {
                borderBottom: likeGoogle && "0px !important",
              },
            },
            "&:focus": {
              backgroundColor: likeGoogle && Color.inputBG,
            },
            "&.Mui-disabled": {
              backgroundColor: Color.theme.grey[50],
            },
          },
        }}
      >
        <Tooltip placement="top" title={likeGoogle && t(`${tooltip}`)}>
          <TextField
            id="filled-multiline-flexible"
            multiline
            maxRows={maxRows}
            rows={rows}
            size="small"
            name={name}
            value={value || ""}
            onChange={handleInputChange}
            disabled={disabled}
            helperText={
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "0.75rem",
                }}
              >
                <span>{helperText}</span>
                {showMaxLength && (
                  <span>
                    <span
                      style={{
                        color:
                          value.length > maxLength
                            ? theme.palette.color.red[500]
                            : "inherit",
                      }}
                    >
                      {value.length}
                    </span>
                    /{maxLength}
                  </span>
                )}
              </span>
            }
            error={error}
            variant={variant}
            placeholder={placeholder}
            label={label}
            onFocus={onFocusInput}
            onBlur={onBlurInput}
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default DescriptionInput;
