import {
  persistReducer,
  persistStore,
  REHYDRATE,
  REGISTER,
  // FLUSH,
  // PAUSE,
  // PURGE,
  PERSIST,
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import localforage from "localforage";

import pathHeaderSlice from "./slices/pathHeader";
import categorySlice from "./slices/category";
import settingsSlice from "./slices/settings";
import datasetSlice from "./slices/datasets";
import globalSlice from "./slices/global";
import commonSlice from "./slices/common";
import reportSlice from "./slices/report";
import boardSlice from "./slices/board";
import appSlice from "./slices/appmain";
import rulesSlice from "./slices/rules";
import staffSlice from "./slices/staff";
import resetSlice from "./slices/reset";
import authSlice from "./slices/auth";
import tourSlice from "./slices/tour";
import loanSlice from "./slices/loan";

const rootReducer = combineReducers({
  appSlice: appSlice.reducer,
  auth: authSlice.reducer,
  pathHeader: pathHeaderSlice.reducer,
  categorySlice: categorySlice.reducer,
  settingsSlice: settingsSlice.reducer,
  tourSlice: tourSlice.reducer,
  boardSlice: boardSlice.reducer,
  commonSlice: commonSlice.reducer,
  rulesSlice: rulesSlice.reducer,
  staffSlice: staffSlice.reducer,
  globalSlice: globalSlice.reducer,
  datasetSlice: datasetSlice.reducer,
  reportSlice: reportSlice.reducer,
  loanSlice: loanSlice.reducer,
  resetSlice: resetSlice.reducer,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 19,
    storage: localforage,
    stateReconciler: autoMergeLevel2,
    blacklist: [
      "boardSlice",
      "categorySlice",
      "commonSlice",
      "globalSlice",
      "datasetSlice",
      "staffSlice",
      "tourSlice",
      "loanSlice",
      "appSlice",
      "reportSlice",
      "rulesSlice",
      // "settingsSlice", //never add here
    ],
  },
  rootReducer
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: [
          "boardSlice.datasetAccountList",
          "globalSlice.recurring_rules",
          "globalSlice.recurring_rulesById",
          "globalSlice.planningTableData",
          "staffSlice.contractData",
          "staffSlice.staffData",
          "datasetSlice.popupStatus4",
          "datasetSlice.popupStatus3",
          "datasetSlice.popupStatus2",
          "datasetSlice.popupStatus1",
        ],
        ignoredActions: [
          REHYDRATE,
          REGISTER,
          PERSIST,
          "boardSlice/setDataSetAccountList",
          "globalSlice/setRecurringRules",
          "globalSlice/setPlanningTableData",
          "staffSlice/setContractData",
          "staffSlice/setStaffData",
          "datasetSlice/setPopupStatus1",
          "datasetSlice/setPopupStatus2",
          "datasetSlice/setPopupStatus3",
          "datasetSlice/setPopupStatus4",
        ],
      },
    }),
  devTools: import.meta.env.VITE_MODE !== "production",
});
export const persistor = persistStore(store);

export default store;
