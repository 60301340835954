import DialogContentText from "@mui/material/DialogContentText";
import { Box, Fade, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import React from "react";

import { formatAmount } from "../../Helper/data";
import TitleInput from "../Overlay/TitleInput";
import CustomModal from "../Model/CustomModal";
import { Color, Fonts } from "../../Helper";

export default function FormulrForm({ open, handleClose, onSave }) {
  const { t } = useTranslation();

  const currencyFormate = useSelector(
    (state) => state.settingsSlice.currencyFormate
  );

  const [amount, setAmount] = React.useState(null);
  const [finalAmount, setFinalAmount] = React.useState("");
  const [error, setError] = React.useState(null);

  useEffect(() => {
    if (open) {
      setAmount(null);
      setFinalAmount("");
    }
  }, [open]);

  const handleEditChange = (e) => {
    let { value } = e.target;
    if (error) setError(null);

    setAmount(value);
    try {
      if (currencyFormate?.decimalSeparator === ",") {
        value = value
          .replaceAll(",", "#")
          .replaceAll(".", ",")
          .replaceAll("#", ".");
      }
      setFinalAmount(evil(value));
    } catch (e) {
      setFinalAmount("");
    }
  };

  const onClickSave = () => {
    let value = amount;
    try {
      if (currencyFormate?.decimalSeparator === ",") {
        value = amount
          .replaceAll(",", "#")
          .replaceAll(".", ",")
          .replaceAll("#", ".");
      }
      let data = evil(value);
      onSave(data);
    } catch (e) {
      setError("please enter a valid formula");
      return;
    }
  };

  function evil(fn) {
    return new Function("return " + fn)();
  }

  return (
    <div>
      <CustomModal
        textAdd={"save"}
        open={!!open}
        onAdd={onClickSave}
        onClose={handleClose}
        heading={"Formular Amount"}
        restProps={{
          slots: { TransitionComponent: Fade },
        }}
      >
        <Box
          sx={{
            width: "40rem",
            minHeight: "15rem",
            height: "fit-content",
            p: "1.5rem",
            backgroundColor: Color.BodyBG,
          }}
        >
          <DialogContentText>
            You can use simple formulas as <b>hours x rate x tax</b>: 25*80*1,19
          </DialogContentText>
          <Box sx={{ width: "100%", position: "relative", mt: "1rem" }}>
            <TitleInput
              value={amount || ""}
              label={t("Amount")}
              tooltip={t("Amount")}
              onChange={handleEditChange}
              helperText={error}
              error={Boolean(error)}
              hideTitle
              likeGoogle
              variant="filled"
            />
            <Typography
              variant="h6"
              component={"h6"}
              sx={{
                fontWeight: 600,
                fontFamily: Fonts.Text,
                fontSize: "0.8rem",
                position: "absolute",
                bottom: -20,
                left: 12,
              }}
            >
              {finalAmount &&
                formatAmount({
                  amount: finalAmount,
                  count: 2,
                })}
            </Typography>
          </Box>
        </Box>
      </CustomModal>
    </div>
  );
}
