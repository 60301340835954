import { Typography, useTheme, Backdrop, Stack, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as React from "react";

import { setPlanExpiredShow } from "../store/slices/global";
import { GlobalContext } from "../GlobalContextWrapper";
import Translate from "../hooks/HOC/Translate";
import CustomModal from "./Model/CustomModal";
import ActionButton from "./ActionButton";
import AsyncImage from "./AsyncImage";

export default function PlanExpiredAlert() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const style = {
    position: "relative",
    display: "flex",
    height: "17.5rem",
    backgroundColor: "transparent",
  };
  const isPlanExpiredShow = useSelector(
    (state) => state.globalSlice.isPlanExpiredShow
  );

  const handleClose = () => dispatch(setPlanExpiredShow(null));
  const handleCheckPlans = () => {
    handleClose();
    navigate("/settings/subscription");
  };

  const open = Boolean(isPlanExpiredShow);

  return (
    <CustomModal
      open={open}
      onClose={handleClose}
      hideAction
      restProps={{
        slots: { backdrop: Backdrop },
        slotProps: {
          backdrop: {
            timeout: 500,
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(4px)",
            },
          },
        },
      }}
      sx={{
        zIndex: 1321,
      }}
    >
      <Box sx={style}>
        <AsyncImage
          component="img"
          imageKey="banner_empty"
          style={{
            borderRadius: theme.borderRadius.borderRadiusXL,
            width: "100%",
            height: "100%",
          }}
          alt="Banner Empty"
        />
        <Typography
          color="color.white"
          variant="h4"
          fontWeight={"fontWeightMedium"}
          sx={{
            position: "absolute",
            left: "2rem",
            top: "2rem",
          }}
        >
          <Translate i18nkey={isPlanExpiredShow?.title} />
        </Typography>
        <Typography
          color="color.white"
          variant="subTitle2"
          fontWeight={"fontWeightRegular"}
          sx={{
            position: "absolute",
            left: "2rem",
            top: "6rem",
          }}
        >
          <Translate i18nkey={isPlanExpiredShow?.desc} />
        </Typography>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            position: "absolute",
            left: "2rem",
            right: "2rem",
            bottom: "2rem",
          }}
        >
          <ActionButton
            onClick={handleClose}
            label={"trial_end_overlay_ok "}
            sx={{
              ml: 0,
              backgroundColor: theme.palette.color.white,
              "&:hover": {
                backgroundColor: theme.palette.color.slate[100],
              },
            }}
          />
          <Stack direction={"row"} alignItems={"center"}>
            <ChildModal />
            <ActionButton
              onClick={handleCheckPlans}
              label={"Checkout plans"}
              sx={{
                backgroundColor: theme.palette.color.violet[800],
                color: theme.palette.color.white,
                ml: "1rem",
                "&:hover": {
                  backgroundColor: theme.palette.color.violet[900],
                  color: theme.palette.color.white,
                },
              }}
            />
          </Stack>
        </Stack>
      </Box>
    </CustomModal>
  );
}
function ChildModal() {
  const theme = useTheme();
  const globalContext = React.useContext(GlobalContext);
  const calendlyRef = globalContext?.calendlyRef;
  const onClickCalendly = () => {
    calendlyRef?.current?.handleOpen();
  };

  return (
    <React.Fragment>
      <ActionButton
        onClick={onClickCalendly}
        label={"Book Onboarding"}
        sx={{
          backgroundColor: theme.palette.color?.violet[100],
          color: theme.palette.color.violet[800],
          border: `1px solid ${theme.palette.color?.violet[800]}`,
          "&:hover": {
            backgroundColor: theme.palette.color?.violet[50],
            color: theme.palette.color.violet[900],
            border: `1px solid ${theme.palette.color?.violet[900]}`,
          },
        }}
      />
    </React.Fragment>
  );
}
