import { Box, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';

import Images from '../../Helper/Image';

const AsyncImage = ({ 
  imageKey, 
  alt = '', 
  style = {}, 
  className = '',
  component = 'img',
  showLoader = true,
  onLoad,
  onError,
  ...props 
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    let mounted = true;

    const loadImage = async () => {
      try {
        setLoading(true);
        setError(false);
        
        const src = await Images.get(imageKey);
        
        if (mounted) {
          setImageSrc(src);
          setLoading(false);
          onLoad?.();
        }
      } catch (err) {
        if (mounted) {
          console.error(`Failed to load image: ${imageKey}`, err);
          setError(true);
          setLoading(false);
          onError?.();
        }
      }
    };

    loadImage();

    return () => {
      mounted = false;
    };
  }, [imageKey]);

  if (loading && showLoader) {
    return (
      <Box 
        display="flex" 
        alignItems="center" 
        justifyContent="center"
        {...props}
        sx={{
          minHeight: '2rem',
          minWidth: '2rem',
          ...style
        }}
      >
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (error || !imageSrc) {
    return null;
  }

  const Component = component;

  return (
    <Component
      src={imageSrc}
      alt={alt}
      className={className}
      style={style}
      {...(imageSrc?.endsWith('.svg') ? { type: 'image/svg+xml' } : {})}
      {...props}
    />
  );
};

export default AsyncImage; 