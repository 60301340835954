import React from "react";
import { useSelector } from "react-redux";
import { Modal } from "@mui/material";

import FinApiWebForm from "./FinApiWebForm";

const FinApiOverlay = () => {
  const locale = useSelector((state) => state.settingsSlice?.profile?.locale);
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const token = isAccountSelectOverlayOpen?.payload?.token;
  const open =
    isAccountSelectOverlayOpen?.open &&
    isAccountSelectOverlayOpen?.isFinApiWebForm;
  if (!token || !locale || !open) {
    return null;
  }

  return (
    <Modal
      open={open}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <FinApiWebForm
        token={token}
        language={locale === "de_DE" ? "de" : "en"}
      />
    </Modal>
  );
};

export default FinApiOverlay;
