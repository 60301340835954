import {
  ListItemText,
  Typography,
  ListItem,
  useTheme,
  Divider,
  Button,
  alpha,
  Chip,
  Grid,
  List,
  Box,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import format from "date-fns/format";
import { v4 as v4uuid } from "uuid";
import PropTypes from "prop-types";
import React from "react";

import {
  getTransactionByUrl,
  thinScrollbarStyle,
  formatAmount,
} from "../../Helper/data";
import useUpdateEffect from "../../hooks/4-useUpdateEffect/useUpdateEffect";
import { setPopupStatus4 } from "../../store/slices/datasets";
import DescriptionInput from "../Overlay/DescriptionInput";
import Translate from "../../hooks/HOC/Translate";
import ComponentLoader from "../ComponentLoader";
import EndPoints from "../../APICall/EndPoints";
import CustomModal from "../Model/CustomModal";
import TitleInput from "../Overlay/TitleInput";
import { Color, Fonts } from "../../Helper";
import APICall from "../../APICall";

const SplitModal = ({
  open,
  itemToBeSplit,
  handleClose,
  modalType = "add",
}) => {
  const theme = useTheme();
  const rowItem = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  //redux state
  const selectionCategories = useSelector(
    (state) => state.categorySlice?.selectionCategories
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  //state
  const [data, setData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [cardItem, setCardItem] = useState(null);
  const [allocatedAmount, setAllocatedAmount] = useState(0);
  const [update, setUpdate] = useState(false);

  const differenceAmount = useMemo(() => {
    return (
      (cardItem?.gross_value ?? 0) - parseFloat(allocatedAmount)?.toFixed(2)
    );
  }, [allocatedAmount, cardItem]);

  //life cycle method
  useEffect(() => {
    let id = itemToBeSplit.parent ? itemToBeSplit.parent : itemToBeSplit.uuid;
    getTransaction(id);
    if (modalType === "add") {
      getSuggestions();
    }
  }, [itemToBeSplit, modalType]);

  //api
  const addSplitTransactions = async (id, array) => {
    await APICall("post", EndPoints.transactions + `${id}/split/`, array).then(
      (response) => {
        if (response.status === 201 && response.data) {
          enqueueSnackbar(t("Transactions Split Successfully"), {
            variant: "success",
            autoHideDuration: 2000,
          });
          handleClose();
        }
      }
    );
  };

  const deleteSplitTransactions = async (id) => {
    await APICall(
      "Delete",
      EndPoints.transactions + `${id}/delete-split/`
    ).then((response) => {
      if (response.status === 200 && response.data) {
        setUpdate(!update);
        enqueueSnackbar(t("Split Transactions Deleted Successfully"), {
          variant: "success",
          autoHideDuration: 2000,
        });
      }
    });
  };

  const getTransaction = async (id) => {
    await APICall("get", EndPoints.transactions + `${id}/`).then((response) => {
      if (response.status === 200 && response.data) {
        if (modalType === "edit") {
          setData(response.data?.children ?? []);
          setCardItem({
            ...response.data,
            note: response.data?.children?.[0]?.note,
          });
          calculateAllocatedAmount(response.data?.children ?? []);
        } else {
          setCardItem(response.data);
        }
      }
    });
  };

  const getSuggestions = async () => {
    let url = "&has_parent=true&page_size=10";
    if (Number(itemToBeSplit?.gross_value) >= 0) {
      url = url + "&min_gross_value=0";
    } else {
      url = url + "&max_gross_value=-0.001";
    }
    let result = await getTransactionByUrl(dataSetData?.uuid, url);
    setSuggestions(result);
  };

  useUpdateEffect(() => {
    handleClose(null);
  }, [update]);

  //functions
  const onSaveRule = () => {
    if (modalType === "add") {
      let array = [];
      if (data?.length === 0) {
        enqueueSnackbar(t("please enter transaction"), {
          variant: "error",
          autoHideDuration: 4000,
        });
      } else if (differenceAmount !== 0) {
        enqueueSnackbar(
          t("all amount sum must be the same as the original amount"),
          {
            variant: "error",
            autoHideDuration: 4000,
          }
        );
      } else {
        data.forEach((element) => {
          array.push({
            ...cardItem,
            ...element,
            gross_value: parseFloat(element?.gross_value)?.toFixed(2),
            title: element?.title ?? `Splitted ${cardItem?.title}`,
            note: cardItem?.note,
            position: cardItem?.position,
          });
        });
        addSplitTransactions(cardItem?.uuid, array);
      }
    } else {
      let filter = cardItem.children?.filter((o1) => {
        return o1.reconciled;
      });
      if (filter?.length > 0) {
        enqueueSnackbar(
          t("This transaction will reconcile other transactions"),
          {
            variant: "error",
            autoHideDuration: 4000,
          }
        );
      } else {
        deleteSplitTransactions(cardItem?.uuid);
      }
    }
  };

  const handleEditDescriptionChange = (e) => {
    let text = e.target.value;
    setCardItem({ ...cardItem, note: text });
  };

  const handleEditChange = (e, id, field) => {
    let value = e.target.value;
    let dummy = [...data];
    let index = data?.findIndex((o1) => o1.id === id);
    if (field === "gross_value") {
      dummy[index] = { ...data?.[index], [field]: value || 0 };
      setData(dummy);
      calculateAllocatedAmount(dummy);
    } else {
      if (index > -1) {
        dummy[index] = { ...data?.[index], [field]: value };
      }
      setData(dummy);
    }
  };

  const calculateAllocatedAmount = (data) => {
    const total = data.reduce(
      (prevValue, currentValue) =>
        prevValue + parseFloat(currentValue?.gross_value ?? 0),
      0
    );
    setAllocatedAmount(total?.toFixed(2));
  };

  const onClickAdd = () => {
    let dummy = [...data];
    const amount =
      Number(cardItem?.gross_value || 0) - Number(allocatedAmount || 0);
    let obj = {
      id: v4uuid(),
      category: itemToBeSplit?.category,
      title: `${itemToBeSplit?.title} - ${data?.length ? data.length + 1 : 1}`,
      gross_value: parseFloat(amount || 0)?.toFixed(2),
    };
    dummy.push(obj);
    setAllocatedAmount((prev) => Number(prev || 0) + Number(amount || 0));
    setData(dummy);
  };

  const onClickDelete = (id) => {
    let filter = data?.filter((o1) => o1.id !== id);
    setData(filter);
    calculateAllocatedAmount(filter);
  };

  const handleClick = (event, item) => {
    if (modalType === "add") {
      rowItem.current = item;
      dispatch(
        setPopupStatus4({
          open: true,
          anchorEl: event.currentTarget,
          overlay_type: "category",
          payload: {
            type: itemToBeSplit?.income_expense_type,
            defaultType: itemToBeSplit?.income_expense_type,
            onClickCategoryTitle,
          },
        })
      );
    }
  };

  const onClickCategoryTitle = (e, item) => {
    let dummy = [...data];
    let index = data.findIndex((o1) => o1.id === rowItem.current.id);
    if (index > -1) {
      dummy[index] = {
        ...dummy[index],
        category: item?.uuid === `unCategorized_category` ? null : item?.uuid,
      };
    }
    dispatch(setPopupStatus4(null));
    setData(dummy);
  };

  const onClickSuggestion = (item) => {
    let dummy = [...data];
    let obj = {
      id: v4uuid(),
      category: itemToBeSplit.category,
      title: item?.title,
      gross_value: item.gross_value,
      tax: item.tax,
    };
    dummy.push(obj);
    setData(dummy);
    calculateAllocatedAmount(dummy);
  };

  //render functions
  const listView = (item) => {
    const categoryItem = selectionCategories?.find(
      (o1) => o1?.value === item.category
    );

    return (
      <ListItem
        sx={{
          px: 0,
          position: "relative",
          "&:hover": {
            "#trash-icon": { display: "flex" },
          },
        }}
        key={item?.id}
      >
        <Grid item xs={3.5} sx={{ pr: "1rem" }}>
          <TitleInput
            value={item?.title || ""}
            disabled={modalType === "edit"}
            hideTitle
            onChange={(e) => handleEditChange(e, item?.id, "title")}
            mb={0}
            borderRadius={2}
          />
        </Grid>
        <Grid item xs={3.5} sx={{ pb: "0.625rem", textAlign: "center" }}>
          <Chip
            onClick={(e) => handleClick(e, item)}
            label={categoryItem?.label ?? "Set category"}
            sx={{
              fontSize: "0.75rem",
              fontWeight: 600,
              height: "1.5625rem",
              minWidth: "5.625rem",
              borderRadius: 1,
              fontFamily: Fonts.Text,
              border: categoryItem?.label
                ? 0
                : `1px solid ${Color.blueGrey400}`,
              color: categoryItem?.label ? Color.black : Color.red,
              backgroundColor: categoryItem?.color
                ? alpha(categoryItem?.color, 0.3)
                : Color.blueGrey100,
            }}
          />
        </Grid>
        <Grid item xs={3.5} sx={{ pr: "1rem" }}>
          <TitleInput
            type="number"
            value={item?.gross_value || 0}
            hideTitle
            disabled={modalType === "edit"}
            onChange={(e) => handleEditChange(e, item?.id, "gross_value")}
            mb={0}
            borderRadius={2}
          />
        </Grid>
        {modalType === "add" && (
          <DeleteForeverIcon
            id="trash-icon"
            onClick={() => onClickDelete(item.id)}
            sx={{
              display: "none",
              cursor: "pointer",
              color: Color.theme.blueGrey[300],
              fontSize: "2rem",
              right: 8,
              position: "absolute",
            }}
          />
        )}
      </ListItem>
    );
  };

  const InnerView = ({ title, value, color = Color.black }) => {
    return (
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="subTitle1"
          color={"color.mainTitle"}
          fontWeight={"fontWeightBold"}
        >
          {t(title)}:
        </Typography>
        <Typography
          color={"color.slate"}
          fontWeight={"fontWeightBold"}
          sx={{
            fontSize: "0.9rem",
            fontWeight: 800,
            color: color,
          }}
        >
          {formatAmount({
            amount: parseFloat(value ?? 0)?.toFixed(2),
          })}
        </Typography>
      </Box>
    );
  };

  InnerView.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    color: PropTypes.string,
  };

  const AmountView = () => {
    return (
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "space-between",
          height: "8rem",
          py: "1rem",
          px: "2rem",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            height: "100%",
            width: "60%",
            position: "relative",
          }}
        >
          {modalType === "add" && (
            <Button
              variant="contained"
              onClick={onClickAdd}
              disableElevation
              disabled={
                cardItem?.gross_value > 0
                  ? differenceAmount <= 0
                  : differenceAmount >= 0
              }
              sx={{
                backgroundColor: Color.theme.blueGrey[100],
                color: Color.black,
                fontWeight: 600,
                fontSixe: "1rem",
                "&:hover": {
                  backgroundColor: Color.theme.blueGrey[200],
                },
              }}
            >
              {t("ADD NEW LINE")}
            </Button>
          )}
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "80%",
            width: "35%",
          }}
        >
          <InnerView
            title="Amount already allocated"
            value={allocatedAmount}
            color={theme.palette.color.slate[700]}
          />
          <InnerView
            title="Original amount"
            value={cardItem?.gross_value}
            color={theme.palette.color.slate[700]}
          />
          <InnerView
            title="Difference"
            value={parseFloat(differenceAmount).toFixed(2)}
            color={differenceAmount === 0 ? null : theme.palette.color.red[600]}
          />
        </Box>
      </Box>
    );
  };

  return (
    <CustomModal
      open={open}
      hideClose
      onClose={() => handleClose("cancel")}
      onAdd={onSaveRule}
      disableADD={modalType === "add" && differenceAmount !== 0}
      textAdd={modalType === "add" ? "Save" : "Delete"}
      tooltipAdd={
        modalType === "add"
          ? "Saving only possible if the difference is zero"
          : ""
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
          height: "fit-content",
          backgroundColor: Color.BodyBG,
          minWidth: "62.5rem",
          maxHeight: "80vh",
        }}
      >
        <Typography
          variant="subTitle1"
          component={"span"}
          fontWeight={"fontWeightRegular"}
          sx={{
            p: "2rem",
            display: "inline-flex",
            alignItems: "center",
            fontSize: "1.6rem",
          }}
        >
          {t("Split The Transaction")}
        </Typography>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            py: "1rem",
            backgroundColor: Color.BodyBG,
            position: "relative",
            overflow: "auto",
            height: "calc(100% - 4rem)",
            ...theme.thinScrollbarStyle,
          }}
        >
          <Grid
            container
            spacing={2}
            elevation={1}
            sx={{
              px: "2rem",
              ml: 0,
              mt: "1rem",
              display: "inline",
              width: "100%",
            }}
          >
            <Typography
              variant="div"
              sx={{
                fontSize: "1.1rem",
                fontFamily: Fonts.Text,
                "& b": {
                  p: "0.5rem",
                  backgroundColor: theme.palette?.color.slate?.[50],
                  borderRadius: theme.borderRadius.main,
                  mx: "0.25rem",
                },
              }}
            >
              <Translate
                i18nkey={"split_input_title"}
                values={{
                  title: cardItem?.title,
                  gross_value: formatAmount({
                    amount: String(
                      parseFloat(cardItem?.gross_value ?? 0).toFixed(0)
                    ),
                  }),
                  due_date: cardItem?.due_date
                    ? format(new Date(cardItem?.due_date), "dd-MM-yyyy")
                    : "",
                }}
              />
            </Typography>
            <Grid
              sx={{
                ml: 0,
                mt: "3rem",
                display: "inline-flex",
                width: "100%",
              }}
            >
              <Grid item xs={3.5}>
                <ListItemText
                  primary={t("Designation")}
                  sx={{
                    color: theme.palette.color.mainTitle,
                    "& span": {
                      fontWeight: theme.typography.fontWeightBold,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3.5}>
                <ListItemText
                  primary={t("Category")}
                  sx={{
                    color: theme.palette.color.mainTitle,
                    "& span": {
                      textAlign: "center",
                      fontWeight: theme.typography.fontWeightBold,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3.5} sx={{ pr: "1rem" }}>
                <ListItemText
                  primary={t("Amount")}
                  sx={{
                    color: theme.palette.color.mainTitle,
                    "& span": {
                      textAlign: "right",
                      fontWeight: theme.typography.fontWeightBold,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
            {data.length > 0 ? (
              <List sx={{ padding: "0px", height: "12rem", overflowY: "auto" }}>
                {data?.map((item) => {
                  return listView(item);
                })}
              </List>
            ) : (
              <Typography
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  fontSize: "1rem",
                  fontFamily: Fonts.Text,
                  color: Color.grey,
                  width: "100%",
                  textAlign: "center",
                  pl: "45%",
                  height: "12rem",
                }}
              >
                {t("No Data")}
              </Typography>
            )}
          </Grid>
          <AmountView />
          {modalType === "add" && (
            <>
              {" "}
              <Divider sx={{ mx: "2rem", mt: 2, mb: 4 }} />
              {suggestions?.length > 0 && (
                <Typography
                  variant="h6"
                  component={"h6"}
                  sx={{
                    display: "flex",
                    color: Color.blueGrey900,
                    width: "70%",
                    textAlign: "left",
                    mb: "0.5rem",
                    fontWeight: 700,
                    fontFamily: Fonts.Text,
                    fontSize: "0.9rem",
                    px: "2rem",
                  }}
                >
                  {t("Suggestions")}
                </Typography>
              )}
              <List
                sx={{
                  padding: "0px",
                  height: "12rem",
                  // overflowY: "auto",
                  px: "1rem",
                }}
              >
                {suggestions?.length > 0 ? (
                  suggestions?.map((item) => {
                    return (
                      <Box
                        key={item?.uuid}
                        onClick={() => onClickSuggestion(item)}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          height: "2.5rem",
                          width: "100%",
                          cursor: "pointer",
                          borderRadius: 2,
                          px: "1rem",

                          position: "relative",
                          "&:hover": {
                            backgroundColor: theme.palette.primary[50],
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.1rem",
                            fontWeight: 500,
                            fontFamily: Fonts.Text,
                          }}
                        >
                          {item?.title}
                        </Typography>
                        <Typography
                          sx={{
                            position: "absolute",
                            right: 16,
                            fontSize: "1.1rem",
                            fontWeight: 500,
                            fontFamily: Fonts.Text,
                          }}
                        >
                          {formatAmount({
                            amount: String(
                              parseFloat(item?.gross_value ?? 0).toFixed(2)
                            ),
                          })}
                        </Typography>
                      </Box>
                    );
                  })
                ) : (
                  <ComponentLoader
                    height="100%"
                    skeletonCount={4}
                    placeHolderHeight="2.5rem"
                    skeltonSx={{
                      transform: "scale(1,0.9)",
                    }}
                    key1sx={{
                      fontSize: "1.1rem",
                    }}
                    sx={{
                      width: "75%",
                      mx: "auto",
                    }}
                    key2="split_list_no_suggestion_text_01"
                  />
                )}
              </List>
            </>
          )}
          <Box sx={{ mt: "2rem", mb: "1.5rem", px: "2rem" }}>
            <DescriptionInput
              disabled={!cardItem || modalType === "edit"}
              value={cardItem?.note || ""}
              onChange={handleEditDescriptionChange}
            />
          </Box>
        </Box>
      </Box>
    </CustomModal>
  );
};

export default SplitModal;

SplitModal.propTypes = {
  open: PropTypes.bool.isRequired,
  itemToBeSplit: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalType: PropTypes.string,
};

SplitModal.defaultProps = {
  open: false,
  itemToBeSplit: {},
  handleClose: () => {},
  modalType: "add",
};
