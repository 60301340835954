import {
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  ComposedChart,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Line,
  Bar,
} from "recharts";
import { useTheme } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { format } from "date-fns";
import React from "react";

import CustomizedAxisTick from "./CustomizedAxisTick";
import CustomTooltip from "./CustomTooltip";
import useWidth from "../../hooks/useWidth";
import { remToPx } from "../../Helper/data";

const currentMonth = format(new Date(), "MMM-yyyy");
const StackCharts = React.memo(
  ({
    data,
    barsData = [],
    linesData = [],
    absoluteValue,
    formatDate,
    xAxisProps,
    yAxisProps,
  }) => {
    const currentWidth = useWidth();
    const theme = useTheme();
    const RangeType = useSelector((state) => state.reportSlice.RangeType);
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          data={data}
          margin={{
            top: remToPx(currentWidth, 0.3125),
          }}
        >
          <CartesianGrid
            strokeDasharray="3 3"
            vertical={false}
            horizontal={false}
          />
          <XAxis
            dataKey="due_date"
            type="category"
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 1)}
                dx={0}
                highlightValue={currentMonth}
                formatDate={formatDate}
              />
            }
            minTickGap={1}
            tickCount={12}
            interval={"preserveStartEnd"}
            {...xAxisProps}
          />
          <YAxis
            tick={
              <CustomizedAxisTick
                dy={remToPx(currentWidth, 0.2)}
                dx={-remToPx(currentWidth, 1.5)}
                formatted
                absoluteValue={absoluteValue}
              />
            }
            type="number"
            tickCount={8}
            {...yAxisProps}
          />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={
              <CustomTooltip
                RangeType={RangeType}
                barsData={[...(barsData || []), ...(linesData || [])]}
              />
            }
          />
          <Legend wrapperStyle={{ display: "none" }} />

          <ReferenceLine
            x={currentMonth}
            stroke={theme.palette.color.slate[500]}
          />
          {barsData?.map((item) => {
            const { key, ...restProps } = item;
            return <Bar key={key} {...restProps} />;
          })}
          {linesData?.map((item) => {
            const { key, ...restProps } = item;
            return <Line key={key} {...restProps} />;
          })}
          <ReferenceLine y={0} stroke={theme.palette.color.grey[400]} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
  (prev, next) => {
    return (
      prev.data === next.data &&
      prev.data?.length === next.data?.length &&
      prev.barsData === next.barsData &&
      prev.linesData === next.linesData &&
      prev.RangeType === next.RangeType
    );
  }
);
export default StackCharts;
