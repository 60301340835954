import React from "react";
import Link from "@mui/material/Link";
import { Link as NavigateLink } from "react-router-dom";
import { keyframes, Typography, useTheme } from "@mui/material";
const blink = keyframes`
0% {
  opacity: 0.5;
  font-weight:800
}
50% {
  opacity: .5;
  font-weight:600
}
100% {
  opacity: 1;
}
`;
const CustomLink = ({
  to = "#",
  replace = false,
  text = "Link",
  fontSize = "0.9rem",
  style = {},
  href = null,
  showBlink = false,
  ...otherProps
}) => {
  const theme = useTheme();
  if (href) {
    return (
      <Typography
        variant="h6"
        component={"a"}
        href={href}
        target={"_blank"}
        sx={{
          color: theme.palette.color.slate[500],
          fontSize: fontSize,
          fontWeight: 400,
          borderColor: theme.palette.color.slate[500],
          "&:hover": {
            fontWeight: 500,
          },
          ...style,
        }}
        {...otherProps}
      >
        {text}
      </Typography>
    );
  } else {
    return (
      <Link
        component="div"
        variant="a"
        {...otherProps}
        sx={{
          fontSize: fontSize,
          fontWeight: 400,
          animation: showBlink && `${blink} 500ms linear infinite`,
          color: theme.palette.color.slate[500],
          "&:hover": {
            fontWeight: 500,
          },
          ...style,
        }}
      >
        <NavigateLink
          href={href}
          to={to}
          style={{
            color: "inherit",
          }}
          replace={replace}
        >
          {text}
        </NavigateLink>
      </Link>
    );
  }
};

export default CustomLink;
