import React from "react";
import invariant from "tiny-invariant";

import { cloneDeep } from "../../../../Helper/data";
import i18n from "../../../../i18n";

export const tree = {
  remove(data, uuid) {
    return data
      .filter((item) => item.uuid !== uuid)
      .map((item) => {
        if (tree.hasChildren(item)) {
          return {
            ...item,
            children: tree.remove(item.children, uuid),
          };
        }
        return item;
      });
  },
  insertBefore(data, targetId, newItem) {
    return data.flatMap((item) => {
      if (item.uuid === targetId) {
        return [newItem, item];
      }
      if (tree.hasChildren(item)) {
        return {
          ...item,
          children: tree.insertBefore(item.children, targetId, newItem),
        };
      }
      return item;
    });
  },
  insertAfter(data, targetId, newItem) {
    return data.flatMap((item) => {
      if (item.uuid === targetId) {
        return [item, newItem];
      }

      if (tree.hasChildren(item)) {
        return {
          ...item,
          children: tree.insertAfter(item.children, targetId, newItem),
        };
      }

      return item;
    });
  },
  insertChild(data, targetId, newItem) {
    return data.flatMap((item) => {
      if (item.uuid === targetId) {
        // already a parent: add as first child
        return {
          ...item,
          // opening item so you can see where item landed
          expanded: true,
          children: [newItem, ...item.children],
        };
      }

      if (!tree.hasChildren(item)) {
        return item;
      }

      return {
        ...item,
        children: tree.insertChild(item.children, targetId, newItem),
      };
    });
  },
  find(data, itemId) {
    for (const item of data) {
      if (item.uuid === itemId) {
        return item;
      }

      if (tree.hasChildren(item)) {
        const result = tree.find(item.children, itemId);
        if (result) {
          return result;
        }
      }
    }
  },
  getPathToItem({ current, targetId, parentIds = [] }) {
    for (const item of current) {
      if (item.uuid === targetId) {
        return parentIds;
      }
      const nested = tree.getPathToItem({
        current: item.children,
        targetId: targetId,
        parentIds: [...parentIds, item.uuid],
      });
      if (nested) {
        return nested;
      }
    }
  },
  hasChildren(item) {
    return item.children.length > 0;
  },
  flattenTree(data) {
    const flatArray = [];
    const _data = cloneDeep(data);

    const traverse = (item, index, parent, depth) => {
      item.position = index + 1;
      item.parent = parent;
      item.depth = depth;

      flatArray.push(item);
      if (item?.children?.length > 0) {
        item.children.forEach((child, _i) =>
          traverse(child, _i, item?.uuid, depth + 1)
        );
      }
    };

    _data.forEach((rootNode, _i) => {
      traverse(rootNode, _i, null, 1);
    });

    return flatArray;
  },
  searchTree(data, searchTerm) {
    return data
      .map((node) => {
        if (
          i18n
            .t(node?.title)
            ?.toLowerCase()
            ?.includes(searchTerm?.toLowerCase())
        ) {
          return { ...node, expanded: true };
        }
        if (node?.children) {
          const children = tree.searchTree(node?.children, searchTerm);
          if (children?.length > 0) {
            return { ...node, expanded: true, children };
          }
        }
        return null;
      })
      .filter((node) => node !== null);
  },
};

export const dataReducer = (data, action) => {
  const item = tree.find(data, action.itemId);
  if (!item) {
    return data;
  }

  if (action.type === "instruction") {
    const instruction = action.instruction;

    if (instruction.type === "reparent") {
      const path = tree.getPathToItem({
        current: data,
        targetId: action.targetId,
      });
      invariant(path);
      const desiredId = path[instruction.desiredLevel];
      let result = tree.remove(data, action.itemId);
      result = tree.insertAfter(result, desiredId, item);
      return result;
    }

    // the rest of the actions require you to drop on something else
    if (action.itemId === action.targetId) {
      return data;
    }

    if (instruction.type === "reorder-above") {
      let result = tree.remove(data, action.itemId);
      result = tree.insertBefore(result, action.targetId, item);
      return result;
    }

    if (instruction.type === "reorder-below") {
      let result = tree.remove(data, action.itemId);
      result = tree.insertAfter(result, action.targetId, item);
      return result;
    }

    if (instruction.type === "make-child") {
      let result = tree.remove(data, action.itemId);
      result = tree.insertChild(result, action.targetId, item);
      return result;
    }

    console.warn("TODO: action not implemented", instruction);

    return data;
  }

  function toggle(item) {
    if (!tree.hasChildren(item)) {
      return item;
    }

    if (item.uuid === action.itemId) {
      return { ...item, expanded: !item.expanded };
    }

    return { ...item, children: item.children.map(toggle) };
  }

  if (action.type === "toggle") {
    return data.map(toggle);
  }

  if (action.type === "expand") {
    if (tree.hasChildren(item) && !item.expanded) {
      return data.map(toggle);
    }
    return data;
  }

  if (action.type === "collapse") {
    if (tree.hasChildren(item) && item.expanded) {
      return data.map(toggle);
    }
    return data;
  }

  if (action.type === "modal-move") {
    let result = tree.remove(data, item.uuid);

    const siblingItems = getChildItems(result, action.targetId);

    if (siblingItems.length === 0) {
      if (action.targetId === "") {
        /**
         * If the target is the root level, and there are no siblings, then
         * the item is the only thing in the root level.
         */
        result = [item];
      } else {
        /**
         * Otherwise for deeper levels that have no children, we need to
         * use `insertChild` instead of inserting relative to a sibling.
         */
        result = tree.insertChild(result, action.targetId, item);
      }
    } else if (action.index === siblingItems.length) {
      const relativeTo = siblingItems[siblingItems.length - 1];
      /**
       * If the position selected is the end, we insert after the last item.
       */
      result = tree.insertAfter(result, relativeTo.uuid, item);
    } else {
      const relativeTo = siblingItems[action.index];
      /**
       * Otherwise we insert before the existing item in the given position.
       * This results in the new item being in that position.
       */
      result = tree.insertBefore(result, relativeTo.uuid, item);
    }

    return result;
  }

  return data;
};

function getChildItems(data, targetId) {
  /**
   * An empty string is representing the root
   */
  if (targetId === "") {
    return data;
  }

  const targetItem = tree.find(data, targetId);
  invariant(targetItem);

  return targetItem.children;
}
