import React, { Suspense } from "react";

import ThemeSpinLoader from "../components/ThemeSpinLoader";

/**
 * Features:
 * - Standardized suspense handling
 * - Consistent loading indicator
 * - Error handling for failed component loading
 *
 * @param {Object} props
 * @param {React.LazyExoticComponent} props.component - Lazy component to load
 * @param {Object} props.componentProps - Props to pass to the loaded component
 * @returns {JSX.Element}
 */
const LazyRouteWrapper = ({ component: Component, ...componentProps }) => {
  const [errorDetails, setErrorDetails] = React.useState(null);

  // Reset error state when component changes
  React.useEffect(() => {
    setErrorDetails(null);
  }, [Component]);

  const handleError = (error) => {
    if (
      error?.message?.includes("Failed to fetch dynamically imported module")
    ) {
      setErrorDetails({
        hasError: true,
        title: "New Version Available",
        description: "A new version of the application is available!",
      });
    } else {
      setErrorDetails({
        hasError: true,
        title: "Error Loading Page",
        description: "We encountered a problem loading this page.",
      });
    }
  };

  if (errorDetails?.hasError) {
    return (
      <div
        className="route-error-container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <h2>{errorDetails?.title}</h2>
        <p>{errorDetails?.description}</p>
        <button onClick={() => window.location.reload()}>Reload Page</button>
      </div>
    );
  }

  return (
    <Suspense
      fallback={
        <ThemeSpinLoader loading={true} showTips={true} isFallback={true} />
      }
    >
      <ErrorBoundary onError={handleError}>
        <Component {...componentProps} />
      </ErrorBoundary>
    </Suspense>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return null; // The parent component will handle the error UI
    }

    return this.props.children;
  }
}

export default LazyRouteWrapper;
