import {
  ButtonProps as MuiButtonProps,
  useTheme,
  Tooltip,
} from "@mui/material";
import React, { forwardRef, useRef } from "react";
import Button from "@mui/lab/LoadingButton";

import KeyboardSortCutKey from "../KeyboardSortCutKey";

// Only include variant, size, and color
type ButtonBaseProps = Pick<MuiButtonProps, "variant" | "size" | "color">;

// Use all except disableRipple
// type ButtonBaseProps = Omit<MuiButtonProps, "disableRipple">;

interface ButtonProps extends ButtonBaseProps {
  label: string;
  onClick?: () => void;
  tooltipLabel?: string;
  disabled?: boolean;
  width?: string;
  showIcon?: boolean; // Add this line
  loading?: boolean;
  isSecondary?: boolean;
  keyboardShortcuts?: any;
  allowKeysToTrigger?: any;
  hideKey?: boolean;
  icon?: React.ReactNode;
  sx?: any;
  keyWrapperStyle?: any;
  keyStyle?: any;
}
const AddButton = forwardRef((props: ButtonProps, ref) => {
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const {
    label,
    onClick,
    tooltipLabel = "",
    disabled = false,
    width,
    showIcon = true,
    icon,
    sx,
    isSecondary,
    keyboardShortcuts = ["Enter"],
    allowKeysToTrigger,
    hideKey,
    keyStyle,
    ...rest
  } = props;

  return (
    <Tooltip title={tooltipLabel}>
      <Button
        ref={buttonRef}
        onClick={disabled ? undefined : onClick}
        startIcon={showIcon ? (icon ? icon : "+") : undefined}
        sx={{
          textTransform: "initial",
          fontSize: "0.8rem",
          lineHeight: "normal",
          paddingInline: "1rem",
          height: "2.25rem",
          width: width ? width : "auto",
          color: isSecondary
            ? theme.palette.color.slate[600]
            : theme.palette.color.white,
          border: isSecondary
            ? `1px solid ${theme.palette.color.slate[300]}`
            : 0,
          backgroundColor: isSecondary
            ? theme.palette.color.slate[50]
            : theme.palette.primary.main,
          borderRadius: theme.borderRadius.main,
          fontWeight: theme?.typography?.fontWeightBold,
          opacity: disabled ? 0.5 : 1,
          "& .MuiButton-startIcon": {
            fontSize: "1.1rem",
            pb: "1px",
          },
          "&:hover": {
            backgroundColor: isSecondary
              ? theme.palette.color.slate[100]
              : theme.palette.primary.dark,
          },
          ...sx,
        }}
        {...rest}
      >
        {label}

        {hideKey ? null : (
          <KeyboardSortCutKey
            keys={keyboardShortcuts}
            buttonRef={buttonRef}
            theme={theme}
            action={onClick}
            allowKeysToTrigger={allowKeysToTrigger}
            style={{
              marginLeft: "0.5rem",
            }}
            keyStyle={{
              color: theme.palette.color.primary.main,
              border: `1px solid ${theme.palette.color.primary.light}`,
              boxShadow: `${theme.palette.color.primary[400]} 0px 1px 0px 1px`,
              ...keyStyle,
            }}
          />
        )}
      </Button>
    </Tooltip>
  );
});
export default AddButton;
