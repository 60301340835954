import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { format } from "date-fns";

import { removeAllCookies, removeCache } from "../../Helper/data";
import { Color, Constant, Fonts } from "../../Helper";
import { queryClient } from "../../App";

export default function ErrorPage({ error, resetErrorBoundary }) {
  const navigate = useNavigate();
  // const refreshToken = useSelector((state) => state.auth.refreshToken);

  useEffect(() => {
    var req = indexedDB.deleteDatabase("localforage");
    navigate("/error", { replace: true });
  }, []);

  const onClickStart = () => {
    if (!Constant?.isDev) {
      const user = localStorage.getItem("LoggedInUser");

      Sentry.setExtra("ErrorPage  ==> ", {
        message: error?.message,
        response: error?.response,
        status: error?.status || error?.response?.status,
        time: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        user,
      });
      Sentry.captureException(error);
    }
    if (typeof resetErrorBoundary === "function") {
      resetErrorBoundary();
    }

    navigate("/login", { replace: true });
    window.location.reload();
    clearAllState();
  };

  const clearAllState = () => {
    // var req = indexedDB.deleteDatabase('keyvaluepairs');
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("last_visited_dataset");
    localStorage.removeItem("persist:root");
    queryClient.removeQueries();
    removeAllCookies();
    removeCache();
  };

  return (
    <Box
      role="alert"
      sx={{
        height: "100%",
        backgroundImage: `url(/bg-001.jpg)`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="span"
        component="span"
        sx={{
          fontSize: "2rem",
          FontFamily: Fonts.Text,
          color: Color.theme.blueGrey[900],
          textAlign: "center",
          fontWeight: 400,
        }}
      >
        <span style={{ fontWeight: 600 }}>Oops something went wrong! </span>
        <br />
        Please go back to{" "}
        <span>
          {" "}
          <Link variant="span" component="span" color="inherit">
            {/* <NavigateLink
              to={refreshToken ? "/" : "/login"}
              style={{ color: "inherit" }}
              replace
            > */}
            <Box
              variant="span"
              component="span"
              onClick={onClickStart}
              sx={{
                display: "inline-flex",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Start
            </Box>
            {/* </NavigateLink> */}
          </Link>
        </span>{" "}
        or contact our
        <span>
          {" "}
          <Link color="inherit" href="//www.finban.io/support">
            Support.
          </Link>
        </span>
      </Typography>
      {/* <pre>{error.message}</pre> */}
    </Box>
  );
}
