import {
  differenceInCalendarDays,
  eachQuarterOfInterval,
  differenceInQuarters,
  eachMonthOfInterval,
  eachWeekOfInterval,
  differenceInMonths,
  eachDayOfInterval,
  differenceInWeeks,
  differenceInDays,
  startOfQuarter,
  endOfQuarter,
  startOfWeek,
  subMonths,
  endOfWeek,
  subYears,
  isValid,
  setDate,
  addDays,
  getWeek,
  format,
  parse,
} from "date-fns";
import {
  AiOutlineDollarCircle,
  AiOutlineEuroCircle,
  AiOutlinePound,
} from "react-icons/ai";
import {
  TbChartAreaLineFilled,
  TbBuildingBank,
  TbCircles,
} from "react-icons/tb";
import { PiUsersThreeBold, PiMagicWandFill } from "react-icons/pi";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AssistantIcon from "@mui/icons-material/Assistant";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { formatValue } from "react-currency-input-field";
import { endOfMonth, startOfMonth } from "date-fns/esm";
import AddchartIcon from "@mui/icons-material/Addchart";
import CategoryIcon from "@mui/icons-material/Category";
import { MdOutlineCurrencyFranc } from "react-icons/md";
import PercentIcon from "@mui/icons-material/Percent";
import DeleteIcon from "@mui/icons-material/Delete";
import CommitIcon from "@mui/icons-material/Commit";
import { de, enUS } from "date-fns/locale";
import { IoPeople } from "react-icons/io5";
import EventEmitter from "eventemitter3";
import { alpha } from "@mui/material";
import pLimit from "p-limit";
import _ from "underscore";
import React from "react";

import EndPoints from "../APICall/EndPoints";
import APICall from "../../src/APICall";
import Icon from "../components/Icon";
import { Constant } from "./Constant";
import { Color } from "./Color";
import store from "../store";
import i18n from "../i18n";

export const CurrencyPrefixIcon = {
  EUR: <AiOutlineEuroCircle />,
  USD: <AiOutlineDollarCircle />,
  GBP: <AiOutlinePound />,
  CHF: <MdOutlineCurrencyFranc />,
};
let initialData = {
  ResetAllDataOptions: [
    "transactions",
    "List",
    "integrations",
    "invoices",
    "rules",
    "reports",
    "staff",
    "contacts",
  ],

  //acounts
  account_Type: [
    {
      value: "UNDEFINED",
      display_name: "Type undefined",
    },
    {
      value: "CHECKING",
      display_name: "Checking account",
    },
    {
      value: "SAVINGS",
      display_name: "Savings account",
    },
    {
      value: "CREDIT_CARD",
      display_name: "Credit card account",
    },
    {
      value: "INVESTMENT",
      display_name: "Investment",
    },
    {
      value: "CASH",
      display_name: "Cash",
    },
    {
      value: "Security",
      display_name: "Security",
    },
    {
      value: "Loan",
      display_name: "Loan",
    },
    {
      value: "Membership",
      display_name: "Membership",
    },
    {
      value: "Bausparen",
      display_name: "Bausparen",
    },
  ],

  //table

  datasetType: [
    {
      value: "agency",
      display_name: "Agency",
    },
    {
      value: "sass",
      display_name: "SaaS",
    },
    {
      value: "general",
      display_name: "General",
    },
  ],

  //list
  actionListOption: [
    {
      uuid: 4,
      title: "Set State",
      icon: (
        <Icon icon={<TbCircles />} fontSize={"1.25rem"} color="inherit"></Icon>
      ),
    },
    {
      uuid: 8,
      title: "Set Scenario",
      icon: (
        <Icon
          icon={<TbChartAreaLineFilled />}
          fontSize={"1.25rem"}
          color="inherit"
        ></Icon>
      ),
    },
    {
      uuid: 14,
      title: "Set Tax",
      icon: <PercentIcon sx={{ fontSize: "1.25rem" }} />,
    },
    {
      uuid: 15,
      title: "Add Rule",
      icon: (
        <Icon
          icon={<PiMagicWandFill />}
          fontSize={"1.3rem"}
          color="inherit"
        ></Icon>
      ),
    },
    {
      uuid: 3,
      title: "Categorize",
      icon: <LocalOfferIcon sx={{ fontSize: "1.2rem" }} />,
    },
    {
      uuid: 17,
      title: "Department",
      icon: <LocalOfferIcon sx={{ fontSize: "1.2rem" }} />,
    },
    {
      uuid: 5,
      title: "Uncategorize",
      icon: <LocalOfferIcon sx={{ fontSize: "1.2rem" }} />,
    },
    // {
    //   uuid: 6,
    //   title: "Ignored",
    //   icon: <VisibilityOffIcon sx={{ fontSize: "1.25rem" }} />,
    // },
    {
      uuid: 13,
      title: "Booked",
      icon: <VisibilityIcon sx={{ fontSize: "1.25rem" }} />,
    },
    // {
    //   uuid: 7,
    //   title: "Add Cost Unit",
    //   icon: (
    //     <Icon
    //       icon={<RiFolderChartLine />}
    //       fontSize={"1.3rem"}
    //       color="inherit"
    //     ></Icon>
    //   ),
    // },
    {
      uuid: 9,
      title: "Add Due Date",
      icon: <CalendarMonthIcon sx={{ fontSize: "1.25rem" }} />,
    },
    {
      uuid: 16,
      title: "Update Date Range",
      icon: <CalendarMonthIcon sx={{ fontSize: "1.3rem" }} />,
    },
    {
      uuid: 10,
      title: "Add Invoice Date",
      icon: <CalendarMonthIcon sx={{ fontSize: "1.25rem" }} />,
    },
    {
      uuid: 12,
      title: "Apply all suggestions",
      icon: <AssistantIcon sx={{ fontSize: "1.25rem" }} />,
    },
    {
      uuid: 1,
      title: "Delete",
      icon: <DeleteIcon sx={{ fontSize: "1.3rem" }} />,
    },
    {
      uuid: 2,
      title: "Duplicate",
      icon: <ContentCopyIcon sx={{ fontSize: "1.25rem" }} />,
    },
  ],

  //Profile
  euro_countries: [
    {
      locale: "de_AT",
      language: "German (Austria)",
      market: "AT",
      country: "Austria",
    },
    {
      locale: "be_BE",
      language: "Belgian (Dutch)",
      market: "BE",
      country: "Belgium",
    },
    // {
    //   locale: "bg_BG",
    //   language: "Bulgarian",
    //   market: "BG",
    //   country: "Bulgaria",
    // },
    // {
    //   locale: "cs_CZ",
    //   language: "Czech",
    //   market: "CZ",
    //   country: "Czech Republic",
    // },
    {
      locale: "da_DK",
      language: "Danish",
      market: "DK",
      country: "Denmark",
    },
    {
      locale: "de_DE",
      language: "German",
      market: "DE",
      country: "Germany",
    },
    {
      locale: "en_GB",
      language: "English",
      market: "GB",
      country: "United Kingdom",
    },
    {
      locale: "et_EE",
      language: "Estonian",
      market: "EE",
      country: "Estonia",
    },
    {
      locale: "ga_IE",
      language: "Irish",
      market: "IE",
      country: "Ireland",
    },
    // {
    //   locale: "el_GR",
    //   language: "Greek",
    //   market: "GR",
    //   country: "Greece",
    // },
    {
      locale: "es_ES",
      language: "Spanish",
      market: "ES",
      country: "Spain",
    },
    {
      locale: "fr_FR",
      language: "French",
      market: "FR",
      country: "France",
    },
    // {
    //   locale: "hr_HR",
    //   language: "Croatian",
    //   market: "HR",
    //   country: "Croatia",
    // },
    {
      locale: "it_IT",
      language: "Italian",
      market: "IT",
      country: "Italy",
    },
    // {
    //   locale: "el_CY",
    //   language: "Greek (Cyprus)",
    //   market: "CY",
    //   country: "Cyprus",
    // },
    {
      locale: "lv_LV",
      language: "Latvian",
      market: "LV",
      country: "Latvia",
    },
    {
      locale: "lt_LT",
      language: "Lithuanian",
      market: "LT",
      country: "Lithuania",
    },
    // {
    //   locale: "lb_LU",
    //   language: "Luxembourgish",
    //   market: "LU",
    //   country: "Luxembourg",
    // },
    // {
    //   locale: "hu_HU",
    //   language: "Hungarian",
    //   market: "HU",
    //   country: "Hungary",
    // },
    // {
    //   locale: "mt_MT",
    //   language: "Maltese",
    //   market: "MT",
    //   country: "Malta",
    // },
    {
      locale: "nl_NL",
      language: "Dutch",
      market: "NL",
      country: "Netherlands",
    },

    {
      locale: "pl_PL",
      language: "Polish",
      market: "PL",
      country: "Poland",
    },
    {
      locale: "pt_PT",
      language: "Portuguese",
      market: "PT",
      country: "Portugal",
    },
    // {
    //   locale: "ro_RO",
    //   language: "Romanian",
    //   market: "RO",
    //   country: "Romania",
    // },
    // {
    //   locale: "sl_SI",
    //   language: "Slovenian",
    //   market: "SI",
    //   country: "Slovenia",
    // },
    // {
    //   locale: "sk_SK",
    //   language: "Slovak",
    //   market: "SK",
    //   country: "Slovakia",
    // },
    {
      locale: "fi_FI",
      language: "Finnish",
      market: "FI",
      country: "Finland",
    },
    {
      locale: "sv_SE",
      language: "Swedish",
      market: "SE",
      country: "Sweden",
    },
    // {
    //   locale: "de_CH",
    //   language: "German (Switzerland)",
    //   market: "CH",
    //   country: "Switzerland",
    // },
  ],
  cardActionList: [
    { uuid: 1, title: "Delete" },
    { uuid: 2, title: "Duplicate" },
    { uuid: 3, title: "Duplicate to next Month" },
    { uuid: 4, title: "Duplicate to Date" },
    { uuid: 5, title: "Categorize" },
    { uuid: 6, title: "Set Date" },
    { uuid: 7, title: "Set State" },
  ],

  //register
  reviews: [
    {
      profile: "https://finban.io/wp-content/uploads/2022/04/michl.jpeg",
      name: "Michael Barth",
      position: "UX lecturer at various universities",
      linkTitle: "",
      link: "https://www.2av.de/",
      review:
        "Growth is one of the major hurdles for traditional agencies. The order situation and resources should consistently interact. Scenarios can be easily compared and planned with finban. Planning ahead is no longer a guessing game, but backed by numbers.",
    },
    {
      profile:
        "https://finban.io/wp-content/uploads/2022/07/Direktkontakt-Volker-Rantz.jpeg",
      name: "Volker Rantz",
      position: "CEO",
      linkTitle: "Staff One",
      link: "https://www.staffeins.de/",
      review:
        "Keeping track of several companies and a large number of bank accounts was simply not possible without spending a lot of time. The customer portfolio analysis in particular was a real benefit for us.",
    },
    {
      profile:
        "https://finban.io/wp-content/uploads/2022/07/daniel_ittner.jpeg",
      name: "Daniel Ittner",
      position: "CEO",
      linkTitle: "Koehler & Ittner",
      link: "https://www.koehler-ittner.de/en/landingpage/",
      review:
        "We subscribed to some tools, but never really set them up, too complicated. With finban, we had analyzes ready after a few clicks.",
    },
    {
      profile: "https://finban.io/wp-content/uploads/2022/04/steff.jpeg",
      name: "Stephanie Krentz",
      position: "Board of Directors",
      linkTitle: "Humanism Foundation Berlin",
      link: "https://www.humanismus-stiftung.de/",
      review:
        "Analyzing and understanding the different incoming payments in order to draw conclusions for further campaigns is one of the most important aspects of fundraising. It's even fun with finban.",
    },

    {
      profile: "https://finban.io/wp-content/uploads/2022/04/alex.jpeg",
      name: "Alexander Keck",
      position: "Best-selling author",
      linkTitle: "Unternehmergold",
      link: "https://unternehmergold.de/",
      review:
        "Many entrepreneurs concentrate on their core competencies and neglect topics such as financial figures, cash flow, taxes or the legal form of the company. there is a lot of potential here. finban prepares the numbers to make better decisions.",
    },
    {
      profile:
        "https://finban.io/wp-content/uploads/2022/07/tom-schmiedel.1024x1024.jpg",
      name: "Tom Schmiedel",
      position: "Managing Partner",
      linkTitle: "Nordsonne Identity",
      link: "https://www.nordsonne.de/",
      review:
        "The finban customer portfolio analysis surprised us. With a few clicks and a few tweaks, we had some really amazing insights!",
    },
  ],
  path: {
    organization: "organization",
    organizations: "organizations",
    Organization: "Organization",
    Organizations: "Organizations",
  },

  //recur
  recurringTypeTabs: [
    {
      uuid: "single",
      value: "single",
      icon: <CommitIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_single",
      display_name: "label_recur_tab_single",
      title: "label_recur_tab_single",
    },
    {
      uuid: "simple",
      value: "simple",
      icon: <EventRepeatIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_simple",
      display_name: "label_recur_tab_simple",
      title: "label_recur_tab_simple",
    },

    {
      uuid: "loan",
      value: "loan",
      icon: <TbBuildingBank fontSize={"1.2rem"} />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_loan",
      display_name: "label_recur_tab_loan",
      title: "label_recur_tab_loan",
    },

    {
      uuid: "leasing",
      value: "leasing",
      icon: <TbBuildingBank fontSize={"1.2rem"} />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_leasing",
      display_name: "label_recur_tab_leasing",
      title: "label_recur_tab_leasing",
    },
    {
      uuid: "employee",
      value: "employee",
      icon: <IoPeople fontSize={"1.2rem"} />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_employee",
      display_name: "label_recur_tab_employee",
      title: "label_recur_tab_employee",
    },
    {
      uuid: "advanced",
      value: "advanced",
      icon: <AddchartIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_advanced",
      display_name: "label_recur_tab_advanced",
      title: "label_recur_tab_advanced",
    },

    {
      uuid: "subscription",
      value: "subscription",
      icon: <PiUsersThreeBold fontSize={"1.2rem"} />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_subscription",
      display_name: "label_recur_tab_subscription",
      title: "label_recur_tab_subscription",
    },
    {
      uuid: "shop",
      value: "shop",
      icon: <ShoppingCartIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_shop",
      display_name: "label_recur_tab_shop",
      title: "label_recur_tab_shop",
    },
    {
      uuid: "products",
      value: "products",
      icon: <CategoryIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_products",
      display_name: "label_recur_tab_products",
      title: "label_recur_tab_products",
    },
    {
      uuid: "client",
      value: "client",
      icon: <PeopleAltIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_client",
      display_name: "label_recur_tab_client",
      title: "label_recur_tab_client",
    },
    {
      uuid: "time",
      value: "time",
      icon: <HourglassTopIcon />,
      iconPosition: "start",
      tooltip: "tooltip_recur_tab_time",
      display_name: "label_recur_tab_time",
      title: "label_recur_tab_time",
    },
  ],
};
export default initialData;

export const thinScrollbarStyle = {
  scrollbarWidth: "thin",
  "&::-webkit-scrollbar": {
    width: "0.3rem",
  },
  "&::-webkit-scrollbar-track": {
    background: "#f1f1f1",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: alpha(Color.themeColor, 0.1), //'#888',
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: alpha(Color.themeColor, 0.7), //'#555'
  },
};

// reports: {
//       start_date: format(startOfYear(new Date()), "yyyy-MM-dd"),
//       end_date: format(endOfYear(new Date()), "yyyy-MM-dd"),
//       selectedStates: ["Booked"],
//       selectedScenarios: [],
//       client: {
//         formate: {
//           Income: ["top", "new", "trouble"],
//           Expense: ["new", "trouble", "top"],
//         },
//         chartKey: {
//           Income: [],
//           Expense: [],
//         },
//         frequency: {
//           Income: [],
//           Expense: ["irregular", "daily", "weekly", "monthly", "yearly"],
//         },
//         stateValue: {
//           Income: allStates,
//           Expense: allStates,
//         },
//         scenariosValue: {
//           Income: allScenarios,
//           Expense: allScenarios,
//         },
//         selectedCategory: {
//           Income: [],
//           Expense: [],
//         },
//       },
//     },
//     kanban: {
//       precisionValue: 1,
//       chart_keys: {},
//       viewValue: {
//         Inflow: 12,
//         Outflow: 12,
//       },
//       groupValue: {
//         Inflow: 2,
//         Outflow: 2,
//       },
//       stateValue: {
//         Inflow: ["Booked"],
//         Outflow: ["Booked"],
//       },
//       scenariosValue: {
//         Inflow: ["Base"],
//         Outflow: ["Base"],
//       },
//       intervalValue: 1,
//       scenarioValue: 2,

//       selectedStates: allStates,
//       selectedScenarios: allScenarios,
//       groupType: "scenarios",
//       indicatorType: "states",
//       selectedCostUnits: [],
//       selectedCategory: [],
//     },
//     list: {
//       searchText: "",
//       date_type: 1,
//       start_date: null,
//       end_date: null,
//       recurring_type: [],
//       typeName: ["Expense", "Income"],
//       source: Constant.DefaultSource,
//       toggle: ["diffValue"],
//       selectedStates: [],
//       selectedBankStates: [],
//       selectedOpenStates: [],
//       selectedScenarios: [],
//       selectedCategory: [],
//       selectedCostUnits: [],
//       selectedDataSource: [],
//       selectedCustomers: [],
//       selectedSuppliers: [],
//     },
//   };
export const getDefaultFilters = () => {
  const listCommonFilter = {
    searchText: "",
    start_date: "",
    end_date: "",
    date_type: 1,
    typeName: ["Expense", "Income"],
  };
  return {
    planning: {
      daily: {
        start_date: "",
        end_date: "",
      },
      weekly: {
        start_date: "",
        end_date: "",
      },
      monthly: {
        start_date: "",
        end_date: "",
      },
      quarterly: {
        start_date: "",
        end_date: "",
      },
    },
    kanban: {
      chart_keys: {},
    },
    list: {
      allArray: {
        ...listCommonFilter,
        source: Constant.DefaultSource,
        selectedCostUnits: [],
        selectedCategory: [],
        selectedContacts: [],
        selectedStates: [],
        selectedScenarios: [],
        selectedDataSource: [],
      },
      suggestionArray: {
        ...listCommonFilter,
      },
      catArray: {
        ...listCommonFilter,
      },
      bankArray: {
        ...listCommonFilter,
        selectedStates: [],
        selectedCostUnits: [],
        selectedCategory: [],
        selectedContacts: [],
        selectedDataSource: [],
      },
      openArray: {
        ...listCommonFilter,
        selectedStates: [],
        selectedScenarios: [],
        selectedCostUnits: [],
        selectedCategory: [],
        selectedContacts: [],
        selectedDataSource: [],
      },
      plannedArray: {
        ...listCommonFilter,
        selectedCategory: [],
        recurring_type: [],
      },
      lateArray: {
        ...listCommonFilter,
        selectedScenarios: [],
        selectedCostUnits: [],
        selectedCategory: [],
        selectedContacts: [],
      },
      reconcileArray: {
        ...listCommonFilter,
      },
      bulkUnCatArray: {
        ...listCommonFilter,
        toggle: ["diffValue"],
      },
    },
  };
};

export const hasMissingProperties = (defaultObj, targetObj) => {
  if (!targetObj) return true;
  return Object.keys(defaultObj).some((key) => {
    if (typeof defaultObj[key] === "object" && defaultObj[key] !== null) {
      // If the property is a nested object, recursively check
      return (
        !targetObj?.hasOwnProperty(key) ||
        hasMissingProperties(defaultObj[key], targetObj[key])
      );
    }
    // For non-object properties, check if the key is missing
    return !targetObj?.hasOwnProperty(key);
  });
};

export const cloneDeep = (value) => {
  return JSON.parse(JSON.stringify(value));
};

export const getTailwindColor = (colorValue, number = 600) => {
  let color = colorValue || "slate";
  if (color && Color["tailwind"][color] && !color?.includes("#")) {
    if (number === 25) {
      color = alpha(Color["tailwind"][color][50], 0.4);
    } else {
      color = Color["tailwind"][color][number];
    }
  } else if (color && color?.includes("#")) {
    color = alpha(color, number / 1000);
  }

  return color;
};

export const getFormattedDate = (date, formateString = "yyyy-MM-dd") => {
  if (!date) {
    return null;
  }
  const parsed = parse(date, formateString, new Date());
  if (isValid(parsed)) {
    return format(parsed, "yyyy-MM-dd");
  }
  return null;
};

export const formatDateToLocal = (date, formateString = "yyyy-MM-dd") => {
  if (!date) {
    return null;
  }
  const _date = new Date(date);
  const locale = store.getState()?.settingsSlice?.profile?.locale;
  if (isValid(_date)) {
    return format(_date, formateString, {
      locale: locale === "de_DE" ? de : enUS,
    });
  }
  return null;
};

export const formatAmount = ({
  amount = 0,
  hidePrefix,
  dataset,
  count = 0,
  isInMillion = false,
}) => {
  const currencyFormate = store.getState()?.settingsSlice?.currencyFormate;
  const dataSetData = dataset || store.getState()?.boardSlice?.dataSetData;
  const formattedValue = formatValue({
    value: String(
      parseFloat(
        isNaN(amount) || !amount ? 0 : isInMillion ? amount / 1000000 : amount
      )?.toFixed(count)
    ),
    groupSeparator: currencyFormate?.groupSeparator,
    decimalSeparator: currencyFormate?.decimalSeparator,
    prefix: hidePrefix
      ? ""
      : Constant.CurrencyPrefix?.[dataSetData?.currency] ||
        currencyFormate?.prefix,
    suffix: isInMillion ? "M" : "",
  });
  return formattedValue;
};

export const convertFormatAmountToNumber = ({ amount = 0 }) => {
  const currencyFormate = store.getState()?.settingsSlice?.currencyFormate;
  let _amount = amount;
  Object.values(Constant.CurrencyPrefix)?.forEach((prefix) => {
    _amount = _amount?.replace(prefix, "");
  });
  return _amount
    .replaceAll(currencyFormate.groupSeparator, "")
    .replace(currencyFormate.decimalSeparator, ".")
    .replace(/[^0-9.-]+/, "")
    .trim();
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isValidTitle = ({ alreadyAddedTitles, title, count, node }) => {
  if (alreadyAddedTitles?.includes(title)) {
    count = count + 1;
    title = `${node?.title} ${count}`;
    return isValidTitle({ alreadyAddedTitles, title, count, node });
  }
  return title;
};

export const getCategoryParent = ({ id, type }) => {
  const selectionCategoriesByID =
    store.getState().categorySlice?.selectionCategoriesByID;
  const category = selectionCategoriesByID?.[id]?.[0];

  if (!category?.parent) {
    return category?.uuid;
  }

  const parent = getCategoryParent({ id: category?.parent, type });
  return parent;
};

export const getCategoryParentId = () => {
  const selectionCategoriesByID =
    store.getState().categorySlice?.selectionCategoriesByID;
  let categoryParent = {};
  Object.keys(selectionCategoriesByID)?.forEach((key) => {
    categoryParent[key] = getCategoryParent({ id: key });
  });
  return categoryParent;
};

export const getAllSubCategoryIds = ({ array, id }) => {
  const selectionCategoriesByID =
    store.getState().categorySlice?.selectionCategoriesByID;
  const category = selectionCategoriesByID?.[id]?.[0];
  array.push(category?.uuid);
  if (category?.children?.length > 0) {
    category?.children?.forEach((item) => {
      getAllSubCategoryIds({ array, id: item?.uuid });
    });
  }

  return array;
};

export let Emitter = new EventEmitter();

export const makeFunctionCalledOnce = function (fn) {
  let called = false;
  return function (...args) {
    if (!called) {
      called = true;
      fn.call(this, ...args);
    }
  };
};

export const getShare = (Total, amount) => {
  let share = (amount / Total) * 100;
  return share?.toString() === "Infinity"
    ? 100
    : isNaN(share)
    ? 0
    : share?.toFixed(2) ?? 0;
};

export const getPercentageChange = (oldNumber, newNumber, type = "Income") => {
  var decreaseValue = parseFloat(newNumber) - parseFloat(oldNumber);
  let divide = 1;
  if (type === "Income") {
    divide =
      oldNumber === 0
        ? parseFloat(newNumber) < 0
          ? -1
          : 1
        : parseFloat(oldNumber);
  } else {
    divide =
      newNumber === 0.0
        ? parseFloat(oldNumber) < 0
          ? -1
          : 1
        : parseFloat(newNumber);
  }

  return ((decreaseValue / divide) * 100.0).toFixed(1);
};

export const truncate = (source, size = 12) => {
  return source?.length > size
    ? source?.slice(0, size - 1)?.trim("") + ".."
    : source;
};

export const updateLanguage = (value) => {
  let splittedLang = "";
  if (value) {
    splittedLang = value?.split("_");
  } else {
    let userLanguage = navigator.language || navigator.userLanguage;
    splittedLang = userLanguage?.split("-");
  }
  let lang = splittedLang?.[0];
  i18n.changeLanguage(lang === "de" ? lang : "en");
};

export const Highlighted = ({
  text = "",
  highlight = "",
  doNotHighlight = "",
}) => {
  // Escape special regex characters in highlight
  const escapeRegExp = (str) => str.replace(/[-\\/\\^$*+?.()|[\]{}]/g, "\\$&");

  const safeHighlight = highlight ? escapeRegExp(highlight) : "";
  const regex = safeHighlight ? new RegExp(`(${safeHighlight})`, "gi") : null;

  return (
    <span>
      {text?.split(regex)?.map((part, i) => {
        if (safeHighlight && regex.test(part)) {
          return <b key={i}>{part}</b>;
        } else if (doNotHighlight && regex.test(part)) {
          return <span key={i}>{part}</span>;
        } else {
          return <React.Fragment key={i}>{part}</React.Fragment>;
        }
      })}
    </span>
  );
};

export function removeAllCookies() {
  var cookies = document.cookie.split("; ");
  for (var i = 0; i < cookies.length; i++) {
    var cookieName = cookies[i].split("=")[0];
    // Skip Clarity session cookie (replace 'clarity_session_id' with the actual cookie name)
    if (
      !["sessionid", "clarity_session_data", "clarity_session_id"]?.includes(
        cookieName
      )
    ) {
      var cookieBase =
        encodeURIComponent(cookieName) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/";
      document.cookie = cookieBase;
    }
  }
}

export const removeCache = () => {
  if ("caches" in window) {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }
};

export const isPlanExpired = (subscription) => {
  if (new Date() <= new Date(subscription?.expiry_date)) {
    return false;
  } else {
    return true;
  }
};

export const remToPx = (currentWidth, number) => {
  // const keys = [...theme?.breakpoints?.keys]?.reverse();
  // const currentWidth =
  //   keys?.map((output, key) => {
  //     // eslint-disable-next-line react-hooks/rules-of-hooks
  //     const matches = useMediaQuery(theme?.breakpoints?.up(key));
  //     return !output && matches ? key : output;
  //   }, null) || "xs";

  let px = 12;
  if (currentWidth === "s1366") {
    px = 13;
  }
  if (currentWidth === "s1536" || currentWidth === "mid") {
    px = 14;
  }
  if (currentWidth === "s1745") {
    px = 15;
  }
  if (currentWidth === "s1920") {
    px = 16;
  }

  return number * px;
};

const getNumber = (categoriesById, item) => {
  let day = 0;
  if (categoriesById?.[item?.category]) {
    let term = categoriesById?.[item?.category]?.[0]?.payment_term;
    if (term) {
      day = term === "on invoicing" ? 0 : Number(term.replace("D", ""));
    }
  }
  return day;
};

export const isExpire = (item, categoriesById, recurring_rulesById) => {
  let recurring_Obj = null;
  if (item?.recurring_rule) {
    recurring_Obj = recurring_rulesById?.[item?.recurring_rule]?.[0];
  }

  if (
    (item?.state === "Invoice sent" || item?.state === "Invoice open") &&
    (!recurring_Obj ||
      (recurring_Obj &&
        new Date(item?.due_date) <= new Date(recurring_Obj?.start_date))) &&
    ((!item.invoice_date &&
      item.due_date &&
      new Date(item.due_date) < new Date()) ||
      (item.invoice_date &&
        addDays(
          new Date(item.invoice_date),
          getNumber(categoriesById, item) ?? 0
        ) < new Date()))
  ) {
    return true;
  } else {
    return false;
  }
};

function daysInMonth(month, year) {
  // Use 1 for January, 2 for February, etc.
  return new Date(year, month, 0).getDate();
}

export const getRecurDateArray = (id, start_date, end_date) => {
  let result = [];
  const _start_date = new Date(start_date);
  const _end_date = new Date(end_date);

  if (_start_date > _end_date) {
    return result;
  }
  let seqType = "month";
  if (id === 101) {
    seqType = "day";
  } else if (id === 107) {
    seqType = "week";
  }
  let dates = [];
  if (seqType === "day") {
    dates = eachDayOfInterval({
      start: _start_date,
      end: _end_date,
    });
  } else if (seqType === "week") {
    dates = eachWeekOfInterval(
      {
        start: _start_date,
        end: _end_date,
      },
      {
        weekStartsOn: 1,
      }
    );
  } else {
    dates = eachMonthOfInterval({
      start: _start_date,
      end: _end_date,
    });
  }
  let day = _start_date?.getDate();
  let weekDayIndex = _start_date?.getDay(); // Get day of week (0-6, where 0 is Sunday)
  //  Monday is considered first day of week
  if (weekDayIndex === 0) weekDayIndex = 7; // Convert Sunday from 0 to 7 when weekStartsOn is 1
  dates?.forEach((element, index) => {
    if (seqType === "month" && index % id === 0) {
      const monthDay = daysInMonth(
        element?.getMonth() + 1,
        element?.getFullYear()
      );
      if (day > monthDay) {
        day = monthDay;
      }
      result.push(setDate(element, day));
    }

    if (seqType === "week") {
      // Each element is the start of a week (Monday)
      // We need to find the same day of week as the start date
      const targetDate = new Date(element);
      // weekDayIndex is 1-7 (Monday-Sunday)
      // Add days to Monday to get to the same weekday as the start date
      targetDate.setDate(targetDate.getDate() + (weekDayIndex - 1));
      result.push(targetDate);
    }

    if (seqType === "day") {
      result.push(element);
    }
  });
  return result;
};

export function calculatePercentageChange(
  current = 0, //compare
  previous = 0, //planned
  isTable = false
) {
  // Convert inputs to numbers if they're not already
  current = typeof current === "number" ? current : Number(current || 0);
  previous = typeof previous === "number" ? previous : Number(previous || 0);

  // Handle zero cases
  if (isTable && (current === 0 || previous === 0)) {
    return 0;
  }

  // Handle NaN cases after conversion
  if (isNaN(current) || isNaN(previous)) {
    return 0;
  }

  // Handle zero or nearly zero previous value
  if (previous === 0 || Math.abs(previous) < 0.0001) {
    return current === 0 ? 0 : 100;
  }

  // Calculate the actual percentage change
  const diff = isTable ? current : current - previous;
  const percentChange = (diff / Math.abs(previous)) * 100;

  // Handle infinity or very large results
  if (!isFinite(percentChange)) {
    return 100;
  }

  // Return as a number with 2 decimal places
  return parseFloat(
    isTable ? Math.abs(percentChange).toFixed(2) : percentChange.toFixed(2)
  );
}

export function levenshteinDistance(str1, str2) {
  const len1 = str1.length;
  const len2 = str2.length;
  const matrix = [];

  for (let i = 0; i <= len2; i++) {
    matrix[i] = [i];
  }

  for (let i = 0; i <= len1; i++) {
    matrix[0][i] = i;
  }

  for (let i = 1; i <= len2; i++) {
    for (let j = 1; j <= len1; j++) {
      const cost = str1[j - 1] === str2[i - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // Deletion
        matrix[i][j - 1] + 1, // Insertion
        matrix[i - 1][j - 1] + cost // Substitution
      );
    }
  }

  return matrix[len2][len1];
}

export function calculateSimilarity(item1, item2, type = "list") {
  let similarity = 0;
  let title_similarity = 0;
  let value_similarity = 0;
  let date_similarity = 0;
  const distance = levenshteinDistance(item1?.title, item2?.title);

  // Calculate similarity score (1 - normalized distance)
  title_similarity =
    1 - distance / Math.max(item1?.title?.length, item2?.title?.length);

  // Compare "amount" similarity
  const maxAmountDifference = type === "list" ? 1 : 2; // Assume maximum amount difference is 1 or 2
  const amountDifference = Math.abs(item1.gross_value - item2.gross_value);
  value_similarity = 1 - amountDifference / maxAmountDifference;
  similarity += value_similarity;

  // Compare "date" similarity
  const dateDifference = differenceInCalendarDays(
    new Date(item1.due_date),
    new Date(item2.due_date)
  );
  const maxDateDifference = type === "list" ? 60 : 90; // Assume maximum acceptable difference is 2 or 3 months
  date_similarity = 1 - Math.abs(dateDifference) / maxDateDifference;
  similarity += date_similarity;
  return {
    similarity: title_similarity + similarity,
    title_similarity,
    value_similarity,
    date_similarity,
  };
}

export const getFilteredStates = ({
  default_income_expense_type,
  gross_value,
  source,
  sources,
}) => {
  // positive | salesInvoice       | Income <-- yes
  // negative | salescreditnote    | Income <- yes
  // negative | purchaseInvoice    | Expense <-- yes
  // positive | purchasecreditnote | Expense <-- yes

  // negative | salesInvoice       | Income <-- No
  // positive | salescreditnote    | Income <-- No
  // positive | purchaseInvoice    | Expense <-- No
  // negative | purchasecreditnote | Expense <-- No  <-- there is not transaction this kind in finance world
  const state = store?.getState()?.globalSlice?.state;
  const type =
    gross_value && Number(gross_value) !== 0
      ? Number(gross_value) > 0
        ? 1
        : 2
      : default_income_expense_type;

  return state?.filter(
    (item) =>
      (item?.state_number_type
        ? item?.state_number_type?.includes(type)
        : item?.state_type?.includes(type)) &&
      (source ? item?.source?.includes(source) : true) &&
      (sources ? sources?.every((s) => item?.source?.includes(s)) : true)
  );
};

export const isStatesDisabled = ({ state, destination_state, source }) => {
  // const selectionsCategoryByID =
  // store?.getState()?.categorySlice?.selectionCategoriesByID;
  // const stateByTitle = store?.getState()?.globalSlice?.stateByTitle;
  // const category_item = selectionsCategoryByID?.[category]?.[0];
  // const state_type = stateByTitle?.[destination_state]?.[0]?.state_type;
  const plannedState = Constant.plannedState;
  const bookedStates = Constant.bookedStates;
  if (source === 2) {
    if (bookedStates?.includes(state)) {
      return !bookedStates?.includes(destination_state);
    } else if (state === "Ignored") {
      return !["Ignored", "Booked"].includes(destination_state);
    } else {
      return destination_state !== state;
    }
  }
  if (source === 4) {
    if (plannedState?.includes(state)) {
      return destination_state !== state;
    } else {
      return plannedState?.includes(destination_state);
    }
  }
  // if (creditNotes?.includes(destination_state)) {
  //   if (!category_item || !state_type?.includes(category_item?.type)) {
  //     return true;
  //   }
  // }

  // if (
  //   creditNotes?.includes(state) &&
  //   !bookedStates?.includes(destination_state)
  // ) {
  //   return true;
  // }
};

export const getValidStateAfterCategorization = ({
  category,
  state,
  income_expense_type,
  source,
  gross_value,
}) => {
  const selectionsCategoryByID =
    store?.getState()?.categorySlice?.selectionCategoriesByID;
  const stateByTitle = store?.getState()?.globalSlice?.stateByTitle;

  const state_type = stateByTitle?.[state]?.[0]?.state_type;
  const category_item = selectionsCategoryByID?.[category]?.[0];
  const isPositive = Number(gross_value) > 0;
  const isNegative = Number(gross_value) < 0;
  let obj = {};
  if (category_item?.type) {
    obj.income_expense_type = category_item?.type;
  } else if (category_item && (isPositive || isNegative)) {
    obj.income_expense_type = isPositive ? 1 : 2;
  } else {
    obj.income_expense_type = income_expense_type;
  }

  if (
    state_type?.length === 1 &&
    state_type?.[0] !== obj?.income_expense_type
  ) {
    if (source === 2) {
      obj.state = "Booked";
    }
    if (source === 1) {
      obj.state = "Planned";
    }
    if (source === 4) {
      obj.state = "Open";
    }
    if (state?.includes("credit note")) {
      obj.state = "Booked";
    }
  } else {
    if (income_expense_type !== obj?.income_expense_type) {
      if (obj.income_expense_type === 2) {
        obj.state = "Purchase credit note";
      } else {
        obj.state = "Sales credit note";
      }
    }
  }
  return obj;
};

export const getValidCategoryAfterStateUpdate = ({
  category,
  destination_state,
  income_expense_type,
  gross_value,
}) => {
  const stateByTitle = store?.getState()?.globalSlice?.stateByTitle;
  const state_type = stateByTitle?.[destination_state]?.[0]?.state_type;
  const isPositive = Number(gross_value) > 0;
  const isNegative = Number(gross_value) < 0;
  let obj = {};

  const selectionsCategoryByID =
    store?.getState()?.categorySlice?.selectionCategoriesByID;
  const category_item = selectionsCategoryByID?.[category]?.[0];
  if (state_type?.length === 1) {
    obj.income_expense_type = state_type?.[0];
  } else if (isPositive || isNegative) {
    obj.income_expense_type = isPositive ? 1 : 2;
  } else {
    obj.income_expense_type = income_expense_type;
  }
  if (category_item && category_item?.type !== obj?.income_expense_type) {
    obj.category = null;
  }
  if (isBookedPositionsStates({ state: destination_state })) {
    obj.scenario = "Base";
  }

  return obj;
};

export const convertToValidManualState = (state) => {
  return Constant.manualStates?.includes(state) ? state : "Open";
};

export const isBookedStates = ({ state }) => {
  return Constant.bookedPositionsStates
    ?.filter((o1) => !Constant.calculationExcludeStates2.includes(o1))
    ?.includes(state);
};

export const isBookedPositionsStates = ({ state }) => {
  return Constant.bookedPositionsStates?.includes(state);
};

export const getBookedIds = () => {
  const stateByTitle = store?.getState()?.globalSlice?.stateByTitle;
  let ids = [];
  Constant.bookedPositionsStates?.forEach((item) => {
    if (!Constant.calculationExcludeStates2.includes(item)) {
      ids.push(stateByTitle?.[item]?.[0]?.uuid);
    }
  });
  return ids;
};

//api................................................................
const pageBatchNumber = 200;

export const buildUrlFromParams = (params) => {
  const queryParams = Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");

  const url = `?${queryParams}`;
  return url;
};

export const getTransactionByParams = async (params) => {
  let url = "";
  if (!params?.emptyUrl) {
    url = url + `?missing_date=false`;
  }
  if (params?.dataset) {
    url = url + `&dataset=${params?.dataset}`;
  }
  if (params?.search) {
    url = url + `&search=${params?.search}`;
  }
  if (params?.category_dataset) {
    url = url + `&category_dataset=${params?.category_dataset}`;
  }
  if (params?.global_category) {
    url = url + `&global_category=${params?.global_category}`;
  }
  if (params?.title?.length > 0) {
    url = url + `&title=${params?.title}`;
  }
  if (params?.title_or_note) {
    url = url + `&title_or_note=${params?.title_or_note}`;
  }
  //for contacts
  if (params?.title_contains) {
    url = url + `&title_contains=${params?.title_contains}`;
  }
  if (params?.date) {
    let start_date = format(startOfMonth(new Date(params?.date)), "yyyy-MM-dd");
    let end_date = format(endOfMonth(new Date(params?.date)), "yyyy-MM-dd");
    url = url + `&from_date=${start_date}&to_date=${end_date}`;
  }
  if (params?.startDate) {
    let start_date = format(new Date(params?.startDate), "yyyy-MM-dd");
    url = url + `&from_date=${start_date}`;
  }
  if (params?.endDate) {
    let end_date = format(new Date(params?.endDate), "yyyy-MM-dd");
    url = url + `&to_date=${end_date}`;
  }
  if (params?.from_payment_date) {
    let from_payment_date = format(
      new Date(params?.from_payment_date),
      "yyyy-MM-dd"
    );
    url = url + `&from_payment_date=${from_payment_date}`;
  }
  if (params?.to_payment_date) {
    let to_payment_date = format(
      new Date(params?.to_payment_date),
      "yyyy-MM-dd"
    );
    url = url + `&to_payment_date=${to_payment_date}`;
  }
  if (params?.order_by) {
    url = url + `&order_by=${params?.order_by}`;
  }
  if (params?.bank_transaction_type) {
    url = url + `&bank_transaction_type=${params?.bank_transaction_type}`;
  }
  if (params?.source) {
    url = url + `&source=${params?.source}`;
  }
  if (params?.hasOwnProperty("categorized")) {
    url = url + `&categorized=${params?.categorized}`;
  }
  if (params?.hasOwnProperty("is_recurred")) {
    url = url + `&is_recurred=${params?.is_recurred}`;
  }
  if (params?.hasOwnProperty("detailed")) {
    url = url + `&detailed=${params?.detailed}`;
  }
  if (params?.hasOwnProperty("is_reconciled")) {
    url = url + `&is_reconciled=${params?.is_reconciled}`;
  }
  if (params?.hasOwnProperty("reconciled")) {
    url = url + `&reconciled=${params?.reconciled}`;
  }
  if (params?.hasOwnProperty("income_expense_type")) {
    url = url + `&income_expense_type=${params?.income_expense_type}`;
  }
  if (params?.hasOwnProperty("min_gross_value")) {
    url = url + `&min_gross_value=${params?.min_gross_value}`;
  }
  if (params?.hasOwnProperty("max_gross_value")) {
    url = url + `&max_gross_value=${params?.max_gross_value}`;
  }
  if (params?.similar_title) {
    url = url + `&similar_title=${params?.similar_title}`;
  }
  if (params?.gross_value) {
    url = url + `&gross_value=${params?.gross_value}`;
  }
  if (params?.data_source?.length > 0) {
    params?.data_source?.forEach((element) => {
      url = url + "&data_source=" + element;
    });
  }
  if (params?.category?.length > 0) {
    params?.category?.forEach((element) => {
      url = url + "&category=" + element;
    });
  }
  if (params?.state?.length > 0) {
    params?.state?.forEach((element) => {
      url = url + "&state=" + element;
    });
  }
  if (params?.scenario?.length > 0) {
    params?.scenario?.forEach((element) => {
      url = url + "&scenario=" + element;
    });
  }
  if (params?.recurring_rule?.length > 0) {
    params?.recurring_rule?.forEach((element) => {
      url = url + "&recurring_rule=" + element;
    });
  }
  //only for recurring_rules
  if (params?.contact_type) {
    url = url + `&contact_type=${params?.contact_type}`;
  }
  //only for contact
  if (params?.type?.length > 0) {
    params?.type?.forEach((element) => {
      url = url + "&type=" + element;
    });
  }
  if (params?.cost_unit?.length > 0) {
    params?.cost_unit?.forEach((element) => {
      url = url + "&cost_unit=" + element;
    });
  }
  if (params?.contact?.length > 0) {
    params?.contact?.forEach((element) => {
      url = url + "&contact=" + element;
    });
  }
  if (params?.page) {
    url = url + `&page=${params?.page}`;
  }
  if (params?.page_size) {
    url = url + `&page_size=${params?.page_size}`;
  }
  if (params?.url) {
    url = url + params?.url;
  }
  if (params?.emptyState || params?.emptyScenario) {
    return [];
  }
  let data = null;
  await APICall(
    "get",
    params?.endpoint
      ? EndPoints[params?.endpoint] + url
      : EndPoints.transactions + url,
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      data = response.data;
    }
  });
  return data;
};

export const getAllTransactionsByParams = async (params) => {
  let data = [];
  let pageSize = pageBatchNumber;
  let count = 0;
  let page = 1;
  const LIMIT = pLimit(5);

  const getURL = () => {
    let url = "";
    if (!params?.emptyUrl) {
      url = url + `?missing_date=false`;
    }
    if (params?.dataset) {
      url = url + `&dataset=${params?.dataset}`;
    }
    if (params?.search) {
      url = url + `&search=${params?.search}`;
    }
    if (params?.category_dataset) {
      url = url + `&category_dataset=${params?.category_dataset}`;
    }
    if (params?.global_category) {
      url = url + `&global_category=${params?.global_category}`;
    }
    if (params?.title?.length > 0) {
      url = url + `&title=${params?.title}`;
    }
    //for contacts
    if (params?.title_contains) {
      url = url + `&title_contains=${params?.title_contains}`;
    }
    if (params?.date) {
      let start_date = format(
        startOfMonth(new Date(params?.date)),
        "yyyy-MM-dd"
      );
      let end_date = format(endOfMonth(new Date(params?.date)), "yyyy-MM-dd");
      url = url + `&from_date=${start_date}&to_date=${end_date}`;
    }
    if (params?.startDate) {
      let start_date = format(new Date(params?.startDate), "yyyy-MM-dd");
      url = url + `&from_date=${start_date}`;
    }
    if (params?.endDate) {
      let end_date = format(new Date(params?.endDate), "yyyy-MM-dd");
      url = url + `&to_date=${end_date}`;
    }
    if (params?.from_payment_date) {
      let from_payment_date = format(
        new Date(params?.from_payment_date),
        "yyyy-MM-dd"
      );
      url = url + `&from_payment_date=${from_payment_date}`;
    }
    if (params?.to_payment_date) {
      let to_payment_date = format(
        new Date(params?.to_payment_date),
        "yyyy-MM-dd"
      );
      url = url + `&to_payment_date=${to_payment_date}`;
    }
    if (params?.order_by) {
      url = url + `&order_by=${params?.order_by}`;
    }
    if (params?.bank_transaction_type) {
      url = url + `&bank_transaction_type=${params?.bank_transaction_type}`;
    }
    if (params?.hasOwnProperty("categorized")) {
      url = url + `&categorized=${params?.categorized}`;
    }
    if (params?.hasOwnProperty("is_recurred")) {
      url = url + `&is_recurred=${params?.is_recurred}`;
    }
    if (params?.hasOwnProperty("detailed")) {
      url = url + `&detailed=${params?.detailed}`;
    }
    if (params?.hasOwnProperty("is_reconciled")) {
      url = url + `&is_reconciled=${params?.is_reconciled}`;
    }
    if (params?.hasOwnProperty("reconciled")) {
      url = url + `&reconciled=${params?.reconciled}`;
    }
    if (params?.hasOwnProperty("income_expense_type")) {
      url = url + `&income_expense_type=${params?.income_expense_type}`;
    }
    if (params?.hasOwnProperty("min_gross_value")) {
      url = url + `&min_gross_value=${params?.min_gross_value}`;
    }
    if (params?.hasOwnProperty("max_gross_value")) {
      url = url + `&max_gross_value=${params?.max_gross_value}`;
    }
    if (params?.source) {
      url = url + `&source=${params?.source}`;
    }
    if (params?.similar_title) {
      url = url + `&similar_title=${params?.similar_title}`;
    }
    if (params?.gross_value) {
      url = url + `&gross_value=${params?.gross_value}`;
    }
    if (params?.data_source?.length > 0) {
      params?.data_source?.forEach((element) => {
        url = url + "&data_source=" + element;
      });
    }
    if (params?.category?.length > 0) {
      params?.category?.forEach((element) => {
        url = url + "&category=" + element;
      });
    }
    if (params?.state?.length > 0) {
      params?.state?.forEach((element) => {
        url = url + "&state=" + element;
      });
    }
    if (params?.recurring_rule?.length > 0) {
      params?.recurring_rule?.forEach((element) => {
        url = url + "&recurring_rule=" + element;
      });
    }
    //only for recurring_rules
    if (params?.contact_type) {
      url = url + `&contact_type=${params?.contact_type}`;
    }
    //only for contact
    if (params?.type?.length > 0) {
      params?.type?.forEach((element) => {
        url = url + "&type=" + element;
      });
    }
    if (params?.scenario?.length > 0) {
      params?.scenario?.forEach((element) => {
        url = url + "&scenario=" + element;
      });
    }
    if (params?.cost_unit?.length > 0) {
      params?.cost_unit?.forEach((element) => {
        url = url + "&cost_unit=" + element;
      });
    }
    if (params?.contact?.length > 0) {
      params?.contact?.forEach((element) => {
        url = url + "&contact=" + element;
      });
    }
    if (params?.page) {
      page = params?.page;
      url = url + `&page=${params?.page}`;
    } else {
      url = url + `&page=${page}`;
    }
    if (params?.page_size) {
      pageSize = params?.page_size;
      url = url + `&page_size=${params?.page_size}`;
    } else {
      pageSize = pageBatchNumber;
      url = url + `&page_size=${pageBatchNumber}`;
    }
    if (params?.url) {
      url = url + params?.url;
    }
    return url;
  };
  await APICall(
    "get",
    params?.endpoint
      ? EndPoints[params?.endpoint] + getURL()
      : EndPoints.transactions + getURL(),
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      data = response.data.results;
    }
  });
  if (count > data?.length) {
    let number = Math.ceil(count / pageSize) - 1;
    let promises = Array.from(new Array(number)).map(() => {
      page++;

      let url = getURL();
      return LIMIT(() =>
        APICall(
          "get",
          params?.endpoint
            ? EndPoints[params?.endpoint] + url
            : EndPoints.transactions + url,
          null,
          params?.config
        )
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
  }

  return data;
};

export const getAllRules = async (params) => {
  let data = [];
  let pageSize = pageBatchNumber;
  let count = 0;
  let page = 1;
  const LIMIT = pLimit(5);

  const getURL = () => {
    let url = `?page=${page}&page_size=${pageSize}`;
    if (params?.search) {
      url = url + `&search=${params?.search}`;
    }
    return url;
  };
  const url = getURL();

  await APICall(
    "get",
    EndPoints.transactions_rules + url,
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      data = response.data.results;
    }
  });
  if (count > data?.length) {
    const number = Math.ceil(count / pageSize) - 1;
    const promises = Array.from(new Array(number)).map(() => {
      page++;

      const url = getURL();
      return LIMIT(() =>
        APICall("get", EndPoints.transactions_rules + url, null, params?.config)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
  }

  return data;
};

export const getTransactionById = async (uuid) => {
  let data = null;
  await APICall("get", EndPoints.transactions + `${uuid}/`).then((response) => {
    if (response.status === 200 && response.data) {
      data = response.data;
    }
  });
  return data;
};

export const getStatisticsData = async (
  datasetID,
  type = "monthly",
  startDate,
  endDate,
  subMonthNumber = 5,
  filter = "",
  params
) => {
  const LIMIT = pLimit(5);
  let pageSize = pageBatchNumber;
  let count = 0;
  let page = 1;
  let data = [];
  let booked_balances = [];
  let start_date = "";
  let end_date = "";
  let last_modification_date = null;
  // let c0 = performance.now();
  const getURL = () => {
    let url = `?dataset=${datasetID}&page=${page}&page_size=${pageSize}`;

    if (startDate) {
      start_date = format(
        subMonths(new Date(startDate), subMonthNumber),
        "yyyy-MM-dd"
      );
      url = url + `&from_date=${start_date}`;
    }
    if (endDate) {
      end_date = format(new Date(endDate), "yyyy-MM-dd");
      url = url + `&to_date=${end_date}`;
    }
    if (type === "daily") {
      url = EndPoints.transaction_daily_statistic + url + filter;
    }
    if (type === "weekly") {
      url = EndPoints.transaction_weekly_statistic + url + filter;
    }
    if (type === "monthly") {
      url = EndPoints.transaction_monthly_statistic + url + filter;
    }
    if (type === "quarterly") {
      url = EndPoints.transaction_quarterly_statistic + url + filter;
    }

    if (type === "transaction_monthly_chart") {
      url = EndPoints.transaction_monthly_chart + url + filter;
    }
    if (type === "transaction_daily_chart") {
      url = EndPoints.transaction_daily_chart + url + filter;
    }
    return url;
  };
  await APICall("get", getURL(), null, params?.config).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      let resData = response.data.results;
      last_modification_date = response.data.last_modification_date;

      if (response.data?.current) {
        data = [...resData, response.data?.current];
      } else {
        data = resData;
      }

      booked_balances = response.data?.booked_balances;
    }
  });
  if (count > data.length) {
    let number = Math.ceil(count / pageSize) - 1;
    let promises = Array.from(new Array(number)).map(() => {
      page++;
      let url = getURL();
      return LIMIT(() => APICall("get", url, null, params?.config));
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          if (response?.value?.data?.current) {
            data = [...data, ...results, response?.value?.data?.current];
          } else {
            data = [...data, ...results];
          }

          if (response?.value?.data?.booked_balances) {
            booked_balances = [
              ...booked_balances,
              ...response?.value?.data?.booked_balances,
            ];
          }
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
  }
  // let c1 = performance.now();
  // console.log(
  //   `<<--Fetching ${type ? type : "daily"} statistics took --> ${c1 - c0} ms.`
  // );
  return {
    results: data,
    booked_balances: booked_balances,
    count: count,
    last_modification_date: last_modification_date,
  };
};

export const getStatisticsDataWithParams = async (params) => {
  const LIMIT = pLimit(5);
  let pageSize = pageBatchNumber;
  let count = 0;
  let page = 1;
  let data = [];
  let booked_balances = [];
  let start_date = "";
  let end_date = "";
  let error = false;
  let last_modification_date = null;
  // let c0 = performance.now();
  const getURL = () => {
    let url = `?missing_date=false&page=${page}&page_size=${pageSize}`;
    if (params?.category_dataset) {
      url = url + `&category_dataset=${params?.category_dataset}`;
    }
    if (params?.global_category) {
      url = url + `&global_category=${params?.global_category}`;
    }
    if (params?.dataset) {
      url = url + `&dataset=${params?.dataset}`;
    }
    if (params?.group_by) {
      params?.group_by?.forEach((id) => {
        url = url + `&group_by=${id}`;
      });
    }
    if (params?.from_date) {
      start_date = format(
        subMonths(new Date(params?.from_date), params?.subMonthNumber || 0),
        "yyyy-MM-dd"
      );
      url = url + `&from_date=${start_date}`;
    }
    if (params?.to_date) {
      end_date = format(new Date(params?.to_date), "yyyy-MM-dd");
      url = url + `&to_date=${end_date}`;
    }
    if (params?.from_payment_date) {
      start_date = format(
        subMonths(
          new Date(params?.from_payment_date),
          params?.subMonthNumber || 0
        ),
        "yyyy-MM-dd"
      );
      url = url + `&from_payment_date=${start_date}`;
    }
    if (params?.to_payment_date) {
      end_date = format(new Date(params?.to_payment_date), "yyyy-MM-dd");
      url = url + `&to_payment_date=${end_date}`;
    }
    if (params?.multiScenarioIds) {
      params?.multiScenarioIds?.forEach((id) => {
        url = url + `&scenario=${id}`;
      });
    }
    if (params?.multiCategoryIds) {
      const isUncategorized = params?.multiCategoryIds?.includes(
        "unCategorized_category",
        "uncategorizedInflow",
        "uncategorizedOutflow"
      );
      const selectionCategoriesByID =
        store?.getState()?.categorySlice?.selectionCategoriesByID;
      params?.multiCategoryIds?.forEach((id) => {
        const _category = selectionCategoriesByID?.[id]?.[0]?.uuid;
        if (_category) {
          if (isUncategorized) {
            url = url + `&uncategorized_or_with_category=${id}`;
          } else {
            url = url + `&category=${id}`;
          }
        }
      });
    }
    if (params?.multiStatesIds) {
      params?.multiStatesIds?.forEach((id) => {
        url = url + `&state=${id}`;
      });
    }
    if (params?.category_types) {
      params?.category_types?.forEach((id) => {
        url = url + `&category_types=${encodeURIComponent(id)}`;
      });
    }
    if (params?.multiContactsIds) {
      params?.multiContactsIds?.forEach((id) => {
        url = url + `&contact=${id}`;
      });
    }
    if (params?.filter) {
      url = url + params?.filter;
    }
    if (params?.type === "daily") {
      url = EndPoints.transaction_daily_statistic + url;
    }
    if (params?.type === "weekly") {
      url = EndPoints.transaction_weekly_statistic + url;
    }
    if (params?.type === "monthly") {
      url = EndPoints.transaction_monthly_statistic + url;
    }
    if (params?.type === "quarterly") {
      url = EndPoints.transaction_quarterly_statistic + url;
    }
    if (params?.type === "transaction_quarterly_chart") {
      url = EndPoints.transaction_quarterly_chart + url;
    }
    if (params?.type === "transaction_weekly_chart") {
      url = EndPoints.transaction_weekly_chart + url;
    }
    if (
      params?.type === "transaction_monthly_chart" ||
      params?.type === "transaction_monthly_vat_chart"
    ) {
      url = EndPoints.transaction_monthly_chart + url;
    }
    if (params?.type === "transaction_daily_chart") {
      url = EndPoints.transaction_daily_chart + url;
    }
    return url;
  };
  await APICall("get", getURL(), null, params?.config).then((response) => {
    if (response.status === 200 && response.data) {
      error = false;

      count = response.data.count;
      let resData = response.data.results;
      last_modification_date = response.data.last_modification_date;

      if (response.data?.current) {
        data = [...resData, response.data?.current];
      } else {
        data = [...resData];
      }

      booked_balances = [...(response.data?.booked_balances || [])];
    } else {
      error = true;
    }
  });
  if (count > data.length) {
    let number = Math.ceil(count / pageSize) - 1;
    let promises = Array.from(new Array(number)).map(() => {
      page++;
      let url = getURL();
      return LIMIT(() => APICall("get", url, null, params?.config));
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          error = false;

          let results = response?.value?.data?.results;
          if (response?.value?.data?.current) {
            data = [...data, ...results, response?.value?.data?.current];
          } else {
            data = [...data, ...results];
          }

          if (response?.value?.data?.booked_balances) {
            booked_balances = [
              ...booked_balances,
              ...response?.value?.data?.booked_balances,
            ];
          }
        } else {
          error = true;
        }
      }
    } catch (err) {
      error = true;
      console.log(`error:allSettled `, err);
    }
  }
  // let c1 = performance.now();
  // console.log(
  //   `<<--Fetching ${type ? type : "daily"} statistics took --> ${c1 - c0} ms.`
  // );

  if (params?.type === "transaction_daily_chart") {
    const result = eachDayOfInterval({
      start: new Date(start_date),
      end: new Date() > new Date(start_date) ? new Date() : new Date(end_date),
    });
    const groupedByDateBalance = _.groupBy(
      booked_balances,
      (item) => item?.date
    );
    let obj = {};
    let balance = [];
    result?.forEach((date) => {
      const _date = format(date, "yyyy-MM-dd");
      const item = groupedByDateBalance?.[_date]?.[0];
      obj.day = _date;
      if (item) {
        obj.booked_balance = item?.booked_balance;
        obj.ignored_balance = item?.ignored_balance;
      }
      balance.push({ ...obj });
    });
    booked_balances = balance;
    data?.forEach((item) => {
      item.day = item?.date;
    });
  }
  let result = {
    error: error,
    results: data,
    booked_balances: booked_balances,
    count: count,
    last_modification_date: last_modification_date,
  };
  // if (params?.planning_type === "weekly") {
  //   result.weekly_booked_balances = Object.values(groupByWeek(booked_balances));
  // }
  // if (params?.planning_type === "quarterly") {
  //   result.quarterly_booked_balances = Object.values(groupByQuarter(booked_balances));
  // }
  return result;
};

export const getRecurringTransactions = async (
  datasetID = null,
  recurringRules = [],
  startDate = null,
  endDate = null,
  endurl = "",
  params = {}
) => {
  const LIMIT = pLimit(5);
  let pageSize = 1;
  let count = 0;
  let page = 1;
  let data = [];
  const getURL = () => {
    let url = `?missing_date=unknown&is_reconciled=false&page=${page}&page_size=${pageSize}${endurl}`;
    if (datasetID) {
      url = `?dataset=${datasetID}&page=${page}&page_size=${pageSize}&missing_date=unknown&is_reconciled=false&order_by=-due_date${endurl}`; //&source=1
    }

    if (startDate) {
      let start_date = format(new Date(startDate), "yyyy-MM-dd");
      url = url + `&from_date=${start_date}`;
    }
    if (endDate) {
      let end_date = format(new Date(endDate), "yyyy-MM-dd");
      url = url + `&to_date=${end_date}`;
    }
    if (recurringRules?.length > 0) {
      recurringRules?.forEach((element) => {
        url = url + "&recurring_rule=" + element;
      });
    }
    return url;
  };
  await APICall(
    "get",
    EndPoints.transactions + getURL(),
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      let resData = response.data.results;
      if (response.data.next) {
        // page++;
        pageSize = pageBatchNumber;
      } else {
        data = [...data, ...resData];
        // return data;
      }
    }
  });
  if (pageSize === 1) {
    return data;
  }
  if (count > data.length) {
    let number = Math.ceil(count / pageSize);
    let promises = Array.from(new Array(number)).map(() => {
      let url = getURL();
      page++;
      return LIMIT(() =>
        APICall("get", EndPoints.transactions + url, null, params?.config)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const getTransactionByUrl = async (
  datasetID,
  endURL = "",
  params = {}
) => {
  const LIMIT = pLimit(5);
  let pageSize = 1;
  let count = 0;
  let page = 1;
  let data = [];

  const getURL = () => {
    let url;
    if (datasetID) {
      url = `?dataset=${datasetID}&page=${page}&page_size=${pageSize}&is_reconciled=false${endURL}&order_by=-due_date`;
    } else {
      url = `?page=${page}&page_size=${pageSize}&is_reconciled=false${endURL}&order_by=-due_date`;
    }
    return url;
  };
  await APICall(
    "get",
    EndPoints.transactions + getURL(),
    null,
    params?.config
  ).then((response) => {
    if (response.status === 200 && response.data) {
      count = response.data.count;
      let resData = response.data.results;
      if (response.data.next) {
        // page++;
        pageSize = pageBatchNumber;
      } else {
        data = [...data, ...resData];
        // return data;
      }
    }
  });
  if (pageSize === 1) {
    return data;
  }
  if (count > data.length) {
    let number = Math.ceil(count / pageSize);
    let promises = Array.from(new Array(number)).map(() => {
      let url = getURL();
      page++;
      return LIMIT(() =>
        APICall("get", EndPoints.transactions + url, null, params?.config)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data?.results;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const fetchConnectedBookedBalances = async ({
  array,
  type = "monthly",
  params,
}) => {
  const LIMIT = pLimit(5);
  let pageSize = pageBatchNumber;
  let page = 1;
  let count = 0;
  let tcount = 0;
  let data = [];
  let urls = [];
  let last_modification_date = true;

  const getURL = (element) => {
    let url = `${EndPoints.accounts}${element?.accountID}/balances/${
      element?.balanceID
    }/values/${
      type ? type + "/" : ""
    }?page=${page}&page_size=${pageSize}&is_reconciled=false`;

    if (params?.from_date) {
      url = url + `&from_date=${params?.from_date}`;
    }
    if (params?.to_date) {
      url = url + `&to_date=${params?.to_date}`;
    }
    return url;
  };

  while (array?.length > 0) {
    let arrayData = array.splice(0, 1);
    let element = arrayData[0];

    await APICall("get", getURL(element), null, params?.config).then(
      (response) => {
        if (response.status === 200 && response.data) {
          count = Number(response.data.count);
          tcount = tcount + Number(response.data.count);
          last_modification_date = response.data.last_modified_date;
          data = {
            ...data,
            [element.accountID]: response.data.results,
          };
          if (response.data.next) {
            let number = Math.ceil(count / pageSize) - 1;
            Array.from(new Array(number)).forEach(() => {
              page++;
              let url = getURL(element);
              urls.push(LIMIT(() => APICall("get", url, null, params?.config)));
            });
          }
        }
      }
    );
    if (tcount > data[element?.accountID]?.length) {
      try {
        const responses = await Promise.allSettled(urls);
        for (const response of responses) {
          if (response?.status === "fulfilled") {
            let results = response?.value?.data?.results;
            if (results) {
              data = {
                ...data,
                [element?.accountID]: [
                  ...(data[element?.accountID] || []),
                  ...results,
                ],
              };
            }
          }
        }
      } catch (err) {
        console.log(`error:allSettled `, err);
      }
    }
  }
  let mergedArray = [];

  for (const key in data) {
    data[key].forEach((item) => {
      const existingEntry = mergedArray.find(
        (entry) => entry.month === item.month
      );
      if (existingEntry) {
        existingEntry[key] = item.value;
      } else {
        mergedArray.push({ month: item.month, [key]: item.value });
      }
    });
  }

  return { data: mergedArray, last_modification_date };
};

export const fetchConnectedManualBalances = async (
  datasetID,
  array,
  type = "monthly"
) => {
  // let c0 = performance.now();
  let data = [];
  let allow = true;
  let last_modification_date = true;
  if (array?.length > 0) {
    while (allow) {
      let arrayData = array.splice(0, 1);
      let element = arrayData[0];
      await APICall(
        "get",
        EndPoints.accounts +
          `${element?.accountID}/balances/${element?.balanceID}/values${
            type ? "/" + type : ""
          }/`,
        null,
        true
      ).then((response) => {
        if (response.status === 200 && response.data) {
          let results = response.data.results;
          let dummyData = [];
          if (results?.length > 0) {
            if (type === "monthly") {
              let start = new Date(response.data?.results[0]?.month);
              let end = new Date();
              if (start > end) {
                let dummy = start;
                start = end;
                end = dummy;
              }
              const monthResult = eachMonthOfInterval({
                start: start,
                end: end,
              });
              monthResult.forEach((element) => {
                dummyData.push({
                  month: format(element, "yyyy-MM"),
                  value: response.data.results[0]?.value,
                });
              });
            } else {
              let start = new Date(response.data?.results[0]?.date);
              let end = new Date();
              if (start > end) {
                let dummy = start;
                start = end;
                end = dummy;
              }
              const dateResult = eachDayOfInterval({
                start: start,
                end: end,
              });
              dateResult.forEach((element) => {
                dummyData.push({
                  date: format(element, "yyyy-MM-dd"),
                  value: response.data.results[0]?.value,
                });
              });
            }
          }
          data = [...data, ...dummyData];

          last_modification_date = response.data.last_modification_date;
          if (array?.length > 0) {
            allow = true;
          } else {
            allow = false;
          }
        }
      });
    }
  }
  // let c1 = performance.now();
  // console.log(
  //   `<<--Fetching ${type ? type : "daily"} Manual Balances took --> ${
  //     c1 - c0
  //   } ms.`
  // );
  return { data, last_modification_date };
};

export const cloneBatchTransactions = async (array, no = 50) => {
  let data = [];
  const LIMIT = pLimit(5);
  let count = array?.length;
  if (count > data.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number)).map(() => {
      let arrayData = array.splice(0, no);
      return LIMIT(() =>
        APICall("post", EndPoints.transactions + "batch_clone/", arrayData)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const addBatchTransactions = async (array, no = 50) => {
  let data = [];
  const LIMIT = pLimit(5);
  let count = array?.length;
  if (count > data.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number)).map(() => {
      let arrayData = array.splice(0, no);
      return LIMIT(() =>
        APICall("post", EndPoints.transactions + "batch_add/", arrayData)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const deleteBatchTransactions = async (uuids, no = 50) => {
  let isDeleted = false;
  // const LIMIT = pLimit(5);
  // let count = uuids?.length;
  // let number = Math.ceil(count / no);
  // let promises = Array.from(new Array(number)).map(() => {
  //   let arrayData = uuids.splice(0, no);
  //   return LIMIT(() =>
  //     APICall("post", EndPoints.transactions + "batch_delete/", arrayData)
  //   );
  // });
  // try {
  //   const responses = await Promise.allSettled(promises);
  //   for (const response of responses) {
  //     if (response?.status === "fulfilled") {
  //       isDeleted = true;
  //     }
  //   }
  // } catch (err) {
  //   isDeleted = false;
  //   console.log(`error:allSettled `, err);
  // }

  await APICall("post", EndPoints.transactions + "batch_delete/", uuids).then(
    (response) => {
      if (response.status === 204) {
        isDeleted = true;
      } else {
        isDeleted = false;
      }
    }
  );
  return isDeleted;
};

export const updateBatchTransactions = async (_array, no = 50) => {
  const LIMIT = pLimit(5);
  let data = [];
  let array = [..._array];
  let count = array?.length;
  if (count > data?.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number))?.map(() => {
      let arrayData = array?.splice(0, no);
      return LIMIT(() =>
        APICall("put", EndPoints.transactionsBatch, arrayData)
      );
    });

    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }

  // let allow = true;
  // if (type === "all") {
  //   while (allow) {
  //     let arrayData = array.splice(0, number);
  //     await APICall(
  //       "put",
  //       EndPoints.transactionsBatch,
  //       arrayData
  //       // eslint-disable-next-line no-loop-func
  //     ).then((response) => {
  //       if (response.status === 200 && response.data) {
  //         data = [...data, ...response.data];
  //         if (array?.length >= number || array?.length > 0) {
  //           allow = true;
  //         } else {
  //           allow = false;
  //         }
  //       }
  //     });
  //   }
  // } else {
  //   let arrayData = array.splice(0, number);
  //   await APICall(
  //     "put",
  //     EndPoints.transactionsBatch,
  //     arrayData
  //     // eslint-disable-next-line no-loop-func
  //   ).then((response) => {
  //     if (response.status === 200 && response.data) {
  //       data = response.data;
  //     }
  //   });
  // }
};

export const addBatchRecurringRules = async (array, no = 50) => {
  const LIMIT = pLimit(5);
  let data = [];
  let count = array?.length;
  if (count > data.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number)).map(() => {
      let arrayData = array.splice(0, no);
      return LIMIT(() =>
        APICall("post", EndPoints.recurring_rules + "batch_add/", arrayData)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }

  // let allow = true;
  // while (allow) {
  //   let arrayData = array.splice(0, 50);
  //   await APICall(
  //     "post",
  //     EndPoints.recurring_rules + "batch_add/",
  //     arrayData
  //     // eslint-disable-next-line no-loop-func
  //   ).then((response) => {
  //     if (response.status === 201 && response.data) {
  //       data = [...data, ...response.data];
  //       if (array?.length >= 50 || array?.length > 0) {
  //         allow = true;
  //       } else {
  //         allow = false;
  //       }
  //     }
  //   });
  // }
};

export const batchCloneRecurringRules = async (array, no = 50) => {
  const LIMIT = pLimit(5);
  let data = [];
  let count = array?.length;
  if (count > data.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number)).map(() => {
      let arrayData = array.splice(0, no);
      return LIMIT(() =>
        APICall("post", EndPoints.recurring_rules + "batch_clone/", arrayData)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const updateBatchRecurringRules = async (array, no = 50) => {
  const LIMIT = pLimit(5);
  let data = [];
  let count = array?.length;
  if (count > data?.length) {
    let number = Math.ceil(count / no);
    let promises = Array.from(new Array(number)).map(() => {
      let arrayData = array.splice(0, no);
      return LIMIT(() =>
        APICall("put", EndPoints.recurring_rules + "batch_update/", arrayData)
      );
    });
    try {
      const responses = await Promise.allSettled(promises);
      for (const response of responses) {
        if (response?.status === "fulfilled") {
          let results = response?.value?.data;
          data = [...data, ...results];
        }
      }
    } catch (err) {
      console.log(`error:allSettled `, err);
    }
    return data;
  }
};

export const getExternalDataSourceConnection = async (DSID) => {
  let status = null;

  await APICall(
    "get",
    EndPoints.integrations + `${DSID}/external/check-connection/`,
    null,
    { doNotCatchErrorResponse: true }
  ).then((response) => {
    if (response.status === 200 && response.data) {
      if (response.data?.connected) {
        status = "UPDATED";
      } else {
        if (response.data?.error_code === "token_error") {
          status = "token_error";
        } else {
          status = "DISCONNECTED";
        }
      }
    } else {
      status = "UNAUTHORIZED_ACCESS";
    }
  });
  return status;
};

export const getFinApiDataSourceConnection = async (DSID) => {
  let status = null;

  await APICall(
    "get",
    EndPoints.integrations + `${DSID}/finapi/selected_bank_connection/`,
    null,
    { doNotCatchErrorResponse: true }
  ).then((response) => {
    if (response.status === 200 && response.data) {
      const interfaces = response?.data?.interfaces;
      const connection = response?.data;

      const isWEB_SCRAPER = interfaces?.find(
        (o1) => o1.banking_interface === "WEB_SCRAPER"
      );
      const isFINTS_SERVER = interfaces?.find(
        (o1) => o1.banking_interface === "FINTS_SERVER"
      );
      // WEB_SCRAPER:  we can't do auto update in backend with WEB_SCRAPER
      // because web scraper always require manual update

      // FINTS_SERVER: user didn't click on "Save PIN"
      status = response?.data?.status || "DISCONNECTED";
      if (status === "COMPLETED") {
        if (isWEB_SCRAPER && connection?.user_action_required) {
          status = "UPDATED";
        } else if (connection?.is_deprecated) {
          status = "IS_DEPRECATED";
        } else if (connection?.user_action_required) {
          if (isFINTS_SERVER) {
            status = "USER_ACTION_REQUIRED_WITH_ACTION";
          } else {
            status = "USER_ACTION_REQUIRED";
          }
        }
      }
    } else {
      status = "UNAUTHORIZED_ACCESS";
    }
  });

  return status;
};

export const getCellInfo = ({
  data = [],
  total_key = "inflow",
  count_key = "inflow_count",
  isPastMonth,
  isFutureMonth,
  isCurrentMonth,
  highlightedScenarios,
  pastTotal = 0,
  category,
  doNotIncludeCalculationExcludeCategory,
}) => {
  const selectionCategoriesByID =
    store.getState().categorySlice?.selectionCategoriesByID;
  const isExcludedFromCalculation = category?.category_type
    ? Constant?.excludeCategoryTypes?.includes(category?.category_type)
    : false;
  let _data = data?.filter(
    (o1) =>
      o1[count_key] > 0 &&
      (doNotIncludeCalculationExcludeCategory && o1?.category
        ? !Constant?.excludeCategoryTypes?.includes(
            selectionCategoriesByID?.[o1?.category]?.[0]?.category_type
          )
        : true)
  );

  // if (
  //   isPastMonth &&
  //   _data?.length > 0 &&
  //   doNotIncludeCalculationExcludeCategory
  // ) {
  //   console.log("🚀 / _data:", _data);
  // }
  let selected_scenario_data =
    highlightedScenarios?.length > 0
      ? _data?.filter((o1) => highlightedScenarios?.includes(o1.scenario_uuid))
      : [];

  let total = {
    booked: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.bookedPositionsStates.includes(item?.state) &&
            !Constant.calculationExcludeStates2.includes(item?.state)
            ? item[total_key] ?? 0
            : 0
        ),
      0
    ),
    planned: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) ? item[total_key] ?? 0 : 0
        ),
      0
    ),
    open: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.openPositionsStates.includes(item?.state) &&
            !Constant.calculationExcludeStates.includes(item?.state)
            ? item[total_key] ?? 0
            : 0
        ),
      0
    ),
    scenario_planned: selected_scenario_data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) ? item[total_key] ?? 0 : 0
        ),
      0
    ),
  };
  let count = {
    bookedCount: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.bookedPositionsStates.includes(item?.state) &&
            !Constant.calculationExcludeStates2.includes(item?.state)
            ? item[count_key] ?? 0
            : 0
        ),
      0
    ),
    openCount: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.openPositionsStates.includes(item?.state) &&
            !Constant.calculationExcludeStates.includes(item?.state)
            ? item[count_key] ?? 0
            : 0
        ),
      0
    ),
    plannedCount: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) ? item[count_key] ?? 0 : 0
        ),
      0
    ),
    plannedSeqCount: _data?.reduce(
      (total, item) =>
        parseFloat(total) +
        parseFloat(
          Constant.plannedState.includes(item?.state) && item?.planned_seq_count
            ? item["planned_seq_count"] ?? 0
            : 0
        ),
      0
    ),
  };

  const plannedData = highlightedScenarios
    ? selected_scenario_data.filter((o1) => o1.planned_uuid)
    : [];

  const plannedUuid =
    plannedData.length === 1 ? plannedData?.[0]?.planned_uuid : null;

  const scenarios = [
    ...new Set(_data.map((o1) => o1.scenario !== "Base" && o1.scenario_uuid)),
  ];

  const bookedValue = total?.booked ?? 0;
  const openValue = total?.open ?? 0;
  const plannedValue = total?.planned ?? 0;
  const isEmpty =
    total?.booked === 0 &&
    total?.open === 0 &&
    pastTotal === 0 &&
    total?.planned === 0;
  const isEditable = Boolean(
    isEmpty ||
      (count.plannedCount === 0 && !plannedUuid) ||
      (count.plannedCount <= 1 && count?.plannedSeqCount === 0 && plannedUuid)
  );

  const compareValue = isFutureMonth
    ? total.open
    : isCurrentMonth
    ? total.booked + total.open
    : total.booked;
  const compareCount = isFutureMonth
    ? count.openCount
    : isCurrentMonth
    ? count.openCount + count.bookedCount
    : count.bookedCount;
  const compareTotal = isFutureMonth
    ? total.open
    : isCurrentMonth
    ? total.booked + total.open + pastTotal
    : total.booked;
  const isPlanned = isPastMonth
    ? compareTotal === 0 && total?.planned !== 0
    : (compareTotal === 0 && total?.planned !== 0) ||
      (total_key === "inflow"
        ? plannedValue > compareValue
        : plannedValue < compareValue);
  const value = isPlanned
    ? plannedValue + (pastTotal || 0)
    : compareValue + (pastTotal || 0);
  let percentageChange = calculatePercentageChange(
    parseFloat(compareValue || 0),
    parseFloat(plannedValue || 0),
    true
  );

  const is_note_available = _data.some((o1) => o1.is_note_available);
  const is_note_user_edited = _data.some((o1) => o1.is_note_user_edited);
  const is_note_highlighted = _data.some(
    (o1) => o1.is_note_highlighted?.[total_key]
  );
  // total.open = (total?.open || 0) + (pastTotal || 0);
  total.pastTotal = (total?.open || 0) + (pastTotal || 0);
  return {
    pastTotal,
    isExcludedFromCalculation,
    plannedUuid,
    isPlanned,
    total,
    count,
    value,
    bookedValue,
    openValue,
    plannedValue,
    percentageChange,
    isEmpty,
    compareValue,
    compareCount,
    compareTotal,
    scenarios,
    isEditable,
    is_note_available,
    is_note_user_edited,
    is_note_highlighted,
  };
};

export const getVatPayMonths = ({ default_vat_pay_months = 0 }) => {
  const advanceVat = store?.getState()?.globalSlice?.advanceVat;
  let vat_pay_months = default_vat_pay_months;
  if (advanceVat && advanceVat?.enabled) {
    if (
      String(advanceVat?.frequency) === "1" &&
      !advanceVat?.permanent_extension
    ) {
      vat_pay_months = 1;
    }

    if (
      String(advanceVat?.frequency) === "1" &&
      advanceVat?.permanent_extension
    ) {
      vat_pay_months = 2;
    }

    if (
      String(advanceVat?.frequency) === "2" &&
      !advanceVat?.permanent_extension
    ) {
      vat_pay_months = 3;
    }
    if (
      String(advanceVat?.frequency) === "2" &&
      advanceVat?.permanent_extension
    ) {
      vat_pay_months = 4;
    }
  }
  return vat_pay_months;
};

export const getDataSetColumnCount = ({
  planning_type,
  start_date,
  end_date,
}) => {
  if (start_date && end_date) {
    if (planning_type === "quarterly") {
      return differenceInQuarters(new Date(end_date), new Date(start_date));
    } else if (planning_type === "weekly") {
      return differenceInWeeks(new Date(end_date), new Date(start_date));
    } else if (planning_type === "daily") {
      return differenceInDays(new Date(end_date), new Date(start_date));
    } else {
      return differenceInMonths(new Date(end_date), new Date(start_date));
    }
  } else {
    return null;
  }
};

export const formatDateInWeek = (date) => {
  const weekNumber = getWeek(new Date(date), {
    weekStartsOn: 1,
  });
  const formattedDate = formatDateToLocal(date, "MMM yy");
  const _startOfWeek = startOfWeek(new Date(date), { weekStartsOn: 1 });
  const _endOfWeek = endOfWeek(new Date(date), { weekStartsOn: 1 });

  // Check if week spans two months
  const startMonth = formatDateToLocal(_startOfWeek, "MMM");
  const endMonth = formatDateToLocal(_endOfWeek, "MMM");

  const weekLabel =
    startMonth !== endMonth
      ? `${startMonth}/${endMonth} ${formatDateToLocal(date, "yy")}`
      : formattedDate;

  return `${weekLabel} (W${weekNumber})`;
};

export const formatDateInQuarter = (date) => {
  const quarter = formatDateToLocal(new Date(date), "Q");
  const startMonth = formatDateToLocal(startOfQuarter(new Date(date)), "MMM");
  const endMonth = formatDateToLocal(endOfQuarter(new Date(date)), "MMM");
  const year = formatDateToLocal(new Date(date), "yy");
  
  return `Q${quarter}: ${startMonth}-${endMonth} ${year}`;
};

export const getGridColumn = ({
  showColumnStart = true,
  showColumnEnd = true,
  gridWidth = 50,
  stickyColumn = [],
  editable = false,
  start_date,
  end_date,
  planning_type,
}) => {
  let array = [];
  if (!start_date || !end_date) return array;
  const data =
    planning_type === "quarterly"
      ? eachQuarterOfInterval({
          start: new Date(start_date),
          end: new Date(end_date),
        })
      : planning_type === "weekly"
      ? eachWeekOfInterval(
          {
            start: new Date(start_date),
            end: new Date(end_date),
          },
          {
            weekStartsOn: 1,
          }
        )
      : planning_type === "daily"
      ? eachDayOfInterval({
          start: new Date(start_date),
          end: new Date(end_date),
        })
      : eachMonthOfInterval({
          start: new Date(start_date),
          end: new Date(end_date),
        });
  let commonProps = {
    gridWidth,
    editable,
  };
  if (showColumnStart) {
    array.push({
      columnId: "column-start",
      isSticky: stickyColumn.includes("column-start"),
      ...commonProps,
    });
  }
  if (data?.length > 0) {
    data.forEach((element) => {
      const date = format(new Date(element), "yyyy-MM-dd");
      array.push({
        columnId: date,
        item: date,
        isSticky: stickyColumn.includes(date),
        ...commonProps,
      });
    });
  }
  if (showColumnEnd) {
    array.push({
      columnId: "column-end",
      isSticky: stickyColumn.includes("column-end"),
      ...commonProps,
    });
  }
  return array;
};

export const getRowsCell = ({
  cellStart = null,
  cellEnd = null,
  data = [],
}) => {
  let array = [];

  if (cellStart) {
    array.push(cellStart);
  }
  if (data?.length > 0) {
    data.forEach((item) => {
      array.push(item);
    });
  }
  if (cellEnd) {
    array.push(cellEnd);
  }
  return array;
};

export const getIntegrationCard = ({ ds }) => {
  if (ds?.internal_dataset) {
    const card = Constant.integrations.IntegrationsByName?.["ManualDS"]?.[0];
    return card;
  } else if (ds?.note) {
    const card = Constant.integrations.IntegrationsByName?.[ds?.note]?.[0];
    return card;
  } else {
    const card = Constant.integrations.IntegrationsByName?.["ManualDS"]?.[0];
    return card;
  }
};

export const getFinApiQueryKey = ({ data_source }) => {
  const accountByDS = store?.getState()?.globalSlice.accountByDS;
  const account = accountByDS?.[data_source]?.[0];
  return [
    "integrations",
    {
      data_source,
      bank: account?.bank,
      apiType: "data_source_finapi_connections",
    },
  ];
};

export const getUnifiedRedirectUrl = (integration) => {
  let url = `${integration.API_URL}/unified/integration/auth/${
    integration.workspaceId || integration.workspace_id
  }/${integration.type}?redirect=1`;

  if (integration?.state) {
    url += `&state=${encodeURIComponent(integration.state)}`;
  }
  if (integration?.scopes?.length) {
    url += `&scopes=${encodeURIComponent(integration.scopes.join(","))}`;
  }
  if (integration?.environment) {
    url += `&env=${encodeURIComponent(integration.environment)}`;
  }

  url += `&success_redirect=${encodeURIComponent(
    integration.success_redirect || ""
  )}`;
  url += `&failure_redirect=${encodeURIComponent(
    integration.failure_redirect || ""
  )}`;

  return url;
};

export const getStateOptions = ({ optionsType = "all" }) => {
  let array = [];
  const state = store?.getState()?.globalSlice?.state;

  const plannedPositions = state?.filter((o1) =>
    Constant?.plannedState.includes(o1?.title)
  );
  const lead_states = state?.filter((o1) =>
    Constant?.leadState.includes(o1?.title)
  );
  const offer_states = state?.filter((o1) =>
    Constant?.offerState.includes(o1?.title)
  );
  const order_states = state?.filter((o1) =>
    Constant?.orderState.includes(o1?.title)
  );
  const invoice_states = state?.filter((o1) =>
    Constant?.invoiceState.includes(o1?.title)
  );
  const misc_states = state?.filter((o1) =>
    Constant?.miscState.includes(o1?.title)
  );
  const bookedPositions = state?.filter((o1) =>
    Constant?.bookedPositionsStates.includes(o1?.title)
  );
  if (optionsType !== "Bank" && optionsType !== "Open") {
    array.push({
      uuid: "planned_state_separator",
      title: "planned_state_separator_title",
      isSectionHeader: true,
      translate: true,
    });
    array = array.concat(plannedPositions);
  }
  if (optionsType !== "Bank") {
    array.push({
      uuid: "lead_open_state_separator",
      title: "lead_open_state_separator_title",
      isSectionHeader: true,
      translate: true,
    });
    array = array.concat(lead_states);
    array.push({
      uuid: "offer_open_state_separator",
      title: "offer_open_state_separator_title",
      isSectionHeader: true,
      translate: true,
    });
    array = array.concat(offer_states);
    array.push({
      uuid: "order_open_state_separator",
      title: "order_open_state_separator_title",
      isSectionHeader: true,
      translate: true,
    });
    array = array.concat(order_states);
    array.push({
      uuid: "invoice_open_state_separator",
      title: "invoice_open_state_separator_title",
      isSectionHeader: true,
      translate: true,
    });

    array = array.concat(invoice_states);
    array.push({
      uuid: "misc_open_state_separator",
      title: "misc_open_state_separator_title",
      isSectionHeader: true,
      translate: true,
    });
    array = array.concat(misc_states);
  }
  if (optionsType !== "Open") {
    array.push({
      uuid: "booked_state_separator",
      title: "booked_state_separator_title",
      isSectionHeader: true,
      translate: true,
    });
    array = array.concat(bookedPositions);
  }

  return array;
};

export const getStatePresetOptions = ({ optionsType = "all" }) => {
  const stateByTitle = store?.getState()?.globalSlice?.stateByTitle;
  let array = [];
  if (optionsType !== "Bank" && optionsType !== "Open") {
    array.push({
      uuid: "state_filter_preset_planned",
      title: "state_filter_preset_planned",
      value: Constant.plannedState?.map(
        (item) => stateByTitle?.[item]?.[0]?.uuid
      ),
    });
  }
  if (optionsType !== "Bank") {
    array.push({
      uuid: "state_filter_preset_deal",
      title: "state_filter_preset_deal",
      value: Constant.leadState?.map((item) => stateByTitle?.[item]?.[0]?.uuid),
    });

    array.push({
      uuid: "state_filter_preset_offer",
      title: "state_filter_preset_offer",
      value: Constant.offerState?.map(
        (item) => stateByTitle?.[item]?.[0]?.uuid
      ),
    });

    array.push({
      uuid: "state_filter_preset_order",
      title: "state_filter_preset_order",
      value: Constant.orderState?.map(
        (item) => stateByTitle?.[item]?.[0]?.uuid
      ),
    });

    array.push({
      uuid: "state_filter_preset_invoice",
      title: "state_filter_preset_invoice",
      value: Constant.invoiceState?.map(
        (item) => stateByTitle?.[item]?.[0]?.uuid
      ),
    });

    array.push({
      uuid: "state_filter_preset_misc",
      title: "state_filter_preset_misc",
      value: Constant.miscState?.map((item) => stateByTitle?.[item]?.[0]?.uuid),
    });
  }
  if (optionsType !== "Open") {
    array.push({
      uuid: "state_filter_preset_booked",
      title: "state_filter_preset_booked",
      value: Constant.bookedStates?.map(
        (item) => stateByTitle?.[item]?.[0]?.uuid
      ),
    });
  }
  return array;
};

export const getDateRangeForCompareType = (
  start_date,
  end_date,
  compareType,
  isUseCompare = true
) => {
  if (!isUseCompare) {
    return {
      prevStartDate: start_date,
      prevEndDate: end_date,
    };
  }

  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  if (compareType === 2) {
    return {
      prevStartDate: format(subYears(startDate, 1), "yyyy-MM-dd"),
      prevEndDate: format(subYears(endDate, 1), "yyyy-MM-dd"),
    };
  }

  if (compareType === 3) {
    const durationMonths =
      (endDate.getFullYear() - startDate.getFullYear()) * 12 +
      endDate.getMonth() -
      startDate.getMonth() +
      1;
    const prevEndDate = subMonths(startDate, 1);
    const prevStartDate = subMonths(prevEndDate, durationMonths - 1);

    return {
      prevStartDate: format(prevStartDate, "yyyy-MM-dd"),
      prevEndDate: format(endOfMonth(prevEndDate), "yyyy-MM-dd"),
    };
  }

  return {
    prevStartDate: start_date,
    prevEndDate: end_date,
  };
};
