import { Box, Fade, Tooltip, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SlExclamation } from "react-icons/sl";
import { endOfMonth, isValid } from "date-fns";
import { enqueueSnackbar } from "notistack";
import format from "date-fns/format";
import { useEffect } from "react";
import React from "react";

import { getFilteredStates, isStatesDisabled } from "../../../Helper/data";
import DateInputPicker from "../../../components/Overlay/DateInputPicker";
import { setPopupStatus4 } from "../../../store/slices/datasets";
import CustomModal from "../../../components/Model/CustomModal";
import DropDown from "../../../components/Overlay/DropDown";
import { Color, Constant } from "../../../Helper";

export default function SettingForm({
  open,
  onSave,
  type = "",
  isRecurringRule = false,
  actionInfo,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const state = useSelector((state) => state.globalSlice?.state);
  const costunits = useSelector((state) => state.globalSlice.costunits);
  const vat = useSelector((state) => state.globalSlice.vat);

  //state
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [showError, setShowError] = React.useState(false);
  let title = "";
  if (type === "state") {
    title = "Select State";
  }
  if (type === "scenario") {
    title = "Select Scenario";
  }
  if (type === "due_date" || type === "clone_at_date") {
    title = "Select Due Date";
  }
  if (type === "invoice_date") {
    title = "Select Invoice Date";
  }
  if (type === "cost_unit") {
    title = "Select Cost Unit";
  }
  if (type === "date_range") {
    title = "Select Date Range";
  }
  if (type === "tax") {
    title = "Select Tax";
  }

  const optionsState = React.useMemo(() => {
    if (type === "state") {
      if (isRecurringRule) {
        return state
          .filter((o1) => Constant.staffState.includes(o1?.title))
          .map((o1) => {
            return {
              ...o1,
              _title: o1?.title,
              title: `Employee-${o1?.title}`,
            };
          });
      } else {
        let sources = [];
        // isAllManuals,
        // isAllIntegrations,
        // isAllBanks,
        // isManualFound,
        // isIntegrationFound,
        // isBankFound,
        // isInflow,
        // isOutflow,
        if (actionInfo?.isManualFound) {
          sources.push(1);
        }
        if (actionInfo?.isBankFound) {
          sources.push(2);
        }
        if (actionInfo?.isIntegrationFound) {
          sources.push(4);
        }
        let filteredStates = getFilteredStates({
          default_income_expense_type: actionInfo?.default_income_expense_type,
          sources,
          income_expense_type: actionInfo?.income_expense_type,
          gross_value: actionInfo?.gross_value,
        });
        filteredStates = filteredStates.filter(
          (o1) =>
            !isStatesDisabled({
              state: actionInfo?.state,
              destination_state: o1?.title,
              source: actionInfo?.source,
              category: actionInfo?.category,
            })
        );
        return filteredStates;
      }
    }
    return [];
  }, [actionInfo, isRecurringRule, state, type]);

  useEffect(() => {
    if (open) {
      if (type === "date_range") {
        setSelectedValue({
          start_date: null,
          end_date: null,
        });
      } else {
        setSelectedValue(null);
      }
    }
  }, [open, type]);

  const onChangeDropDown = async (event, value) => {
    setSelectedValue({
      ...value,
      title: type === "state" ? t(value?.title) : value?.title,
      _title: isRecurringRule ? value?._title : value?.title,
    });
  };

  const onChangeDate = (value) => {
    setSelectedValue({
      [type === "invoice_date" ? type : "due_date"]: isValid(new Date(value))
        ? format(new Date(value), "yyyy-MM-dd")
        : null,
    });
  };

  const onChangeRangeDate = (value, name) => {
    if (isValid(new Date(value))) {
      setShowError(false);
      setSelectedValue({
        ...selectedValue,
        [name]: value
          ? format(
              name === "end_date" ? endOfMonth(new Date(value)) : value,
              "yyyy-MM-dd"
            )
          : null,
      });
    }
  };

  const onClickSave = () => {
    if (type === "date_range") {
      if (
        (selectedValue?.start_date || selectedValue?.end_date) &&
        isValid(new Date(selectedValue?.start_date)) &&
        isValid(new Date(selectedValue?.end_date))
      ) {
        if (
          selectedValue?.start_date && selectedValue?.end_date
            ? new Date(selectedValue?.start_date) <=
              new Date(selectedValue?.end_date)
            : true
        ) {
          onSave(selectedValue);
        } else {
          enqueueSnackbar(t("Start date should be less than end date"), {
            variant: "error",
            autoHideDuration: 2000,
            preventDuplicate: true,
          });
        }
      } else {
        setShowError(true);
      }
    } else {
      onSave(
        type === "state"
          ? { ...selectedValue, title: selectedValue?._title }
          : selectedValue
      );
    }
  };

  const onClose = () => {
    dispatch(
      setPopupStatus4({
        open: false,
        anchorEl: false,
      })
    );
  };

  return (
    <CustomModal
      open={!!open}
      onAdd={onClickSave}
      onClose={onClose}
      textAdd={"save"}
      heading={title}
      restProps={{
        slots: { TransitionComponent: Fade },
      }}
    >
      <Box
        sx={{
          width: "40rem",
          minHeight: "15rem",
          height: "fit-content",
          p: "1.5rem",
          backgroundColor: Color.BodyBG,
        }}
      >
        <Box sx={{ width: "100%", position: "relative" }}>
          {type === "tax" ? (
            <DropDown
              mb={0}
              hideTitle
              value={selectedValue || null}
              onChange={onChangeDropDown}
              options={vat}
              renderOption={(option) => `${option?.title}` ?? ""}
              getOptionLabel={(option) => `${option?.title}` ?? ""}
              placeholder={t("tax")}
            />
          ) : null}
          {type === "state" ? (
            <DropDown
              mb={0}
              hideTitle
              value={selectedValue || null}
              onChange={onChangeDropDown}
              options={optionsState}
              placeholder={t("State")}
              getOptionLabel={(option) => t(option?.title) ?? ""}
              renderOption={(option) => t(option?.title) ?? ""}
            />
          ) : null}

          {type === "scenario" ? (
            <DropDown
              mb={0}
              hideTitle
              value={
                selectedValue
                  ? { ...selectedValue, title: t(selectedValue?.title) }
                  : null
              }
              onChange={onChangeDropDown}
              options={scenario}
              placeholder={t("Scenario")}
              getOptionLabel={(item) =>
                (item?.title === "Base" ? t(item?.title) : item?.title) ?? ""
              }
              renderOption={(item) =>
                (item?.title === "Base" ? t(item?.title) : item?.title) ?? ""
              }
            />
          ) : null}

          {type === "cost_unit" ? (
            <DropDown
              mb={0}
              hideTitle
              value={selectedValue || null}
              onChange={onChangeDropDown}
              options={costunits}
              placeholder={t("Cost_Unit")}
            />
          ) : null}

          {type === "invoice_date" ||
          type === "due_date" ||
          type === "clone_at_date" ? (
            <DateInputPicker
              name={type === "invoice_date" ? type : "due_date"}
              label={
                type === "invoice_date" ? t("Invoice Date") : t("Due Date")
              }
              showFullDate
              error={
                showError &&
                !Boolean(
                  selectedValue[type === "invoice_date" ? type : "due_date"]
                )
              }
              value={
                selectedValue &&
                selectedValue[type === "invoice_date" ? type : "due_date"]
                  ? new Date(
                      selectedValue[type === "invoice_date" ? type : "due_date"]
                    )
                  : undefined
              }
              onChange={onChangeDate}
              variant="filled"
              likeGoogle
              hideTitle
            />
          ) : null}

          {type === "date_range" ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <DateInputPicker
                  name="start_date"
                  tooltip=""
                  label={
                    <>
                      {t("label_start_date")}
                      <Tooltip
                        title={t(
                          "staff_bulk_action_update_date_range_start_date_info"
                        )}
                        placement="top"
                      >
                        <span>
                          <SlExclamation
                            style={{
                              fontSize: "1.1em",
                              cursor: "pointer",
                              marginLeft: "0.25rem",
                              paddingTop: "0.25rem",
                            }}
                          />
                        </span>
                      </Tooltip>
                    </>
                  }
                  showFullDate
                  error={showError && !Boolean(selectedValue?.start_date)}
                  value={selectedValue?.start_date}
                  onChange={onChangeRangeDate}
                  variant="filled"
                  likeGoogle
                  hideTitle
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  mt: "0.5rem",
                  display: "flex",
                }}
              >
                <DateInputPicker
                  name="end_date"
                  tooltip=""
                  label={
                    <>
                      {t("label_end_date")}
                      <Tooltip
                        title={t(
                          "staff_bulk_action_update_date_range_end_date_info"
                        )}
                        placement="top"
                      >
                        <span>
                          <SlExclamation
                            style={{
                              fontSize: "1.1em",
                              cursor: "pointer",
                              marginLeft: "0.25rem",
                              paddingTop: "0.25rem",
                            }}
                          />
                        </span>
                      </Tooltip>
                    </>
                  }
                  value={selectedValue?.end_date}
                  error={showError && !Boolean(selectedValue?.end_date)}
                  onChange={onChangeRangeDate}
                  variant="filled"
                  likeGoogle
                  hideTitle
                />
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    </CustomModal>
  );
}
