import {
  isSameQuarter,
  startOfMonth,
  endOfQuarter,
  subQuarters,
  isSameMonth,
  endOfMonth,
  isSameWeek,
  subMonths,
  endOfWeek,
  isSameDay,
  subWeeks,
  isBefore,
  subDays,
  format,
} from "date-fns";

import { getVatPayMonths } from "./data";
import { Constant } from "./Constant";
import store from "../store";

export const getReportPagesQueryKey = (data) => {
  return ["ReportPages", { dataset: data?.dataset }];
};

export const getContactQueryKey = (data) => {
  return [
    "contacts",
    {
      dataset: data?.dataset,
      type: [1, 2],
    },
  ];
};

export const getContactByIdQueryKey = (data) => {
  return [
    "contacts",
    {
      contact: data?.contact,
      contact_name: data?.contact_name,
      dataset: data?.dataset,
    },
  ];
};

export const getPlanningChartQueryKey = (data) => {
  let _last_month_date = startOfMonth(subMonths(new Date(), 5));

  let from_payment_date = data?.from_date
    ? format(new Date(data?.from_date), "yyyy-MM-dd")
    : null;
  let to_payment_date = data?.to_date
    ? format(new Date(data?.to_date), "yyyy-MM-dd")
    : null;

  if (new Date(from_payment_date) > _last_month_date) {
    from_payment_date = format(_last_month_date, "yyyy-MM-dd");
  }
  const vat_pay_months = getVatPayMonths({
    default_vat_pay_months: data?.default_vat_pay_months,
  });
  return [
    "transactions",
    {
      dataset: data?.dataset,
      type: `transaction_${data?.planning_type}_chart`,
      from_date: from_payment_date,
      to_date: to_payment_date,
      subMonthNumber: vat_pay_months,
    },
  ];
};

export const pastTableQueryKey = (data) => {
  const state = store?.getState()?.globalSlice?.state;
  const from_payment_date = startOfMonth(
    subMonths(new Date(), Constant.Past_Month)
  );

  let to_payment_date = null;
  let allowMakeApiCall = true;
  if (data?.planning_type === "quarterly") {
    to_payment_date = endOfQuarter(subQuarters(new Date(), 1));
  } else if (data?.planning_type === "weekly") {
    to_payment_date = endOfWeek(subWeeks(new Date(), 1), {
      weekStartsOn: 1,
    });
  } else if (data?.planning_type === "daily") {
    to_payment_date = subDays(new Date(), 1);
  } else {
    to_payment_date = endOfMonth(subMonths(new Date(), 1));
  }

  if (
    from_payment_date < new Date(data?.from_date) &&
    new Date(data?.from_date) < to_payment_date
  ) {
    if (data?.planning_type === "quarterly") {
      to_payment_date = endOfQuarter(subQuarters(new Date(data?.from_date), 1));
    } else if (data?.planning_type === "weekly") {
      to_payment_date = endOfWeek(subWeeks(new Date(data?.from_date), 1), {
        weekStartsOn: 1,
      });
    } else if (data?.planning_type === "daily") {
      to_payment_date = subDays(new Date(data?.from_date), 1);
    } else {
      to_payment_date = endOfMonth(subMonths(new Date(data?.from_date), 1));
    }
  } else {
    let isSame = false;
    let isPast = false;

    isPast = isBefore(new Date(data?.from_date), from_payment_date);
    if (data?.planning_type === "quarterly") {
      isSame = isSameQuarter(from_payment_date, new Date(data?.from_date));
    } else if (data?.planning_type === "weekly") {
      isSame = isSameWeek(from_payment_date, new Date(data?.from_date), {
        weekStartsOn: 1,
      });
    } else if (data?.planning_type === "daily") {
      isSame = isSameDay(from_payment_date, new Date(data?.from_date));
    } else {
      isSame = isSameMonth(from_payment_date, new Date(data?.from_date));
    }

    allowMakeApiCall = !isPast && !isSame;
  }

  const multiStatesIds = state
    ?.filter((o1) => !Constant.calculationExcludeStates2.includes(o1?.title))
    ?.map((o1) => o1?.uuid);
  return [
    "transactions",
    {
      type: data?.planning_type, //monthly
      dataset: data?.dataset,
      from_date: format(from_payment_date, "yyyy-MM-dd"),
      to_date: format(to_payment_date, "yyyy-MM-dd"),
      multiStatesIds,
      allowMakeApiCall,
    },
  ];
};

export const tableQueryKey = (data) => {
  return [
    "transactions",
    {
      type: data?.planning_type, //monthly
      multiScenarioIds: data?.multiScenarioIds,
      dataset: data?.dataset,
      from_date: data?.from_date,
      to_date: data?.to_date,
    },
  ];
};

export const getStaffQueryKey = (data) => {
  return ["staff"];
};

export const getAllEmployeesQueryKey = (data) => {
  return [
    "staff",
    {
      contact_type: 3,
    },
  ];
};
