import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";

import useUpdateEffect from "../../../hooks/4-useUpdateEffect/useUpdateEffect";
import SearchFilterView from "../ThemeFilter/Component/SearchFilterView";
import { useDeferredTimeout } from "../../../hooks/useDeferredTimeout";
import DropdownOverlayItem from "./DropdownOverlayItem";
import ComponentLoader from "../../ComponentLoader";
import EndPoints from "../../../APICall/EndPoints";
import { queryClient } from "../../../App";
import AddButton from "../../AddButton";
import APICall from "../../../APICall";
import MenuView from "../MenuView";

const DropdownOverlay = ({
  popup_state,
  color,
  defaultOptions,
  menuOptions,
  searchType = "client",
  hideRadioButtons,
  create_new_text,
  display_key,
  end_point,
  selectedItem,
  setSelectedItem,
  payload,
  onChange,
  onClickMenuItem,
  primaryItemView,
  secondaryItemView,
  customOpenAddForm,
  query_options_payload,
  titleCount,
  key1 = "dropdown_selection_no_data_text_01",
  key2 = "dropdown_selection_no_data_text_02",
}) => {
  const { t } = useTranslation();
  const itemRef = useRef({});
  const observer = useRef(null);

  const theme = useTheme();

  const refreshData = useSelector((state) => state.appSlice?.refreshData);

  //state
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [searchText, setSearchText] = useState("");
  const deferredSearchText = useDeferredTimeout({
    value: searchText,
    timeoutMs: 1000,
  });

  //Api
  const getOptions = async (params) => {
    let data = null;
    let url = "";

    if (params?.pageParam) {
      url = url + params?.pageParam;
    } else {
      url = url + EndPoints?.[end_point];

      if (params?.page) {
        url = url + `?page=${params?.page}`;
      }
      if (params?.page_size) {
        url = url + `&page_size=${params?.page_size}`;
      }
      if (params?.dataset) {
        url = url + `&dataset=${params?.dataset}`;
      }
      if (params?.title_contains) {
        url = url + `&title_contains=${params?.title_contains}`;
      }
      if (params?.type?.length > 0) {
        params?.type?.forEach((item) => {
          url = url + `&type=${item}`;
        });
      }
    }
    await APICall("get", url, null, params?.config).then((response) => {
      if (response.status === 200 && response) {
        data = response?.data;
      }
    });
    return data;
  };

  //query
  const query_options = useInfiniteQuery({
    queryKey: [
      "options",
      { end_point, deferredSearchText, ...query_options_payload },
    ],
    queryFn: ({ pageParam = null, signal }) => {
      const params = {
        config: {
          signal,
        },
        pageParam,
        page: 1,
        page_size: 50,
        title_contains: deferredSearchText,
        ...query_options_payload,
      };
      const result = getOptions(params);
      if (result) {
        return result;
      }
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.next ?? undefined;
    },
    select: (data) => {
      const flatData = data?.pages?.map((o1) => o1?.results)?.flat();
      return flatData;
    },
    refetchOnMount: true,
    keepPreviousData: true,
    enabled: !!end_point,
  });

  //function
  const onClickOverlayItem = (e, item) => {
    setSelectedItem(item);
    if (onChange) {
      onChange({ selectedItem: item, payload });
    }
    popup_state?.close();
  };

  const openAddForm = (e) => {
    itemRef.current = {
      modalType: "add",
      payload: { query_options_payload },
    };
    if (customOpenAddForm) {
      customOpenAddForm(e, itemRef.current);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClickMore = (e, item) => {
    e?.stopPropagation();
    itemRef.current = {
      modalType: "edit",
      payload: { ...item, setAnchorElMenu },
    };
    setAnchorElMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const onClickClearSearch = () => {
    setSearchText("");
  };

  const onChangeSearch = (e) => {
    setSearchText(e.target?.value);
  };

  const observerElem = useCallback(
    (node) => {
      if (query_options?.isFetching || searchType !== "server") return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && query_options?.hasNextPage) {
          query_options?.fetchNextPage();
        }
      });
      if (node) observer.current?.observe(node);
    },
    [query_options?.hasNextPage, query_options?.isFetching, searchType]
  );

  const options =
    searchType === "client" ? defaultOptions : query_options?.data;

  useEffect(() => {
    if (!popup_state?.isOpen) {
      queryClient.removeQueries(["options"]);
    }
  }, [popup_state?.isOpen]);

  useUpdateEffect(() => {
    queryClient.removeQueries(["options"]);
    query_options?.refetch();
  }, [refreshData]);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: "40rem",
        pt: "1rem",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.color.white,
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{
          px: "2.1rem",
          my: "1rem",
          width: "100%",
        }}
      >
        <SearchFilterView
          active={false}
          value={searchText}
          color={color}
          onClickClear={onClickClearSearch}
          onChange={onChangeSearch}
          sx={{ width: "fit-content", height: "2.25rem" }}
        />
        {create_new_text ? (
          <AddButton
            onClick={openAddForm}
            label={t(create_new_text)}
            isSecondary
            hideKey
            sx={{
              width: "fit-content",
            }}
          />
        ) : null}
      </Stack>

      <Stack
        sx={{
          width: "100%",
          height: "21rem",
          overflow: "auto",
          mb: "3rem",
          ...theme.thinScrollBar,
        }}
      >
        {!query_options?.isFetchingNextPage &&
        (query_options?.isFetching || options?.length === 0) ? (
          <ComponentLoader
            loading={query_options?.isFetching}
            key1={key1}
            key2={key2}
            sx={{ width: "82%", mx: "auto", height: "100%" }}
          />
        ) : (
          options?.map((item) => {
            if (
              searchType === "client" &&
              searchText &&
              !item?.[display_key]
                ?.toLowerCase()
                ?.includes(searchText?.toLowerCase())
            ) {
              return null;
            }

            return (
              <DropdownOverlayItem
                key={item?.uuid}
                item={item}
                titleCount={titleCount}
                theme={theme}
                color={color}
                display_key={display_key}
                isActive={item?.uuid === selectedItem?.uuid}
                hideRadioButtons={hideRadioButtons}
                onClick={onClickOverlayItem}
                handleClickMore={menuOptions ? handleClickMore : undefined}
                primaryItemView={primaryItemView}
                secondaryItemView={secondaryItemView}
              />
            );
          })
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "2rem",
          }}
        >
          {searchType === "server" &&
          !query_options?.isFetching &&
          query_options?.hasNextPage ? (
            <LoadingButton
              ref={observerElem}
              loading={query_options?.isFetchingNextPage}
              onClick={() => query_options?.fetchNextPage()}
              disabled={query_options?.isFetching}
              sx={{
                width: "fit-content",
                mt: "1rem",
                minHeight: "3rem",
                color: theme.palette.color?.[color]?.[600],
              }}
            >
              {t("Load More")}
            </LoadingButton>
          ) : null}
        </div>
      </Stack>

      {/* <InnerOverlay
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        modalType={itemRef.current?.modalType}
        payload={itemRef.current?.payload}
      /> */}
      {Boolean(anchorElMenu) ? (
        <MenuView
          anchorEl={anchorElMenu}
          open={Boolean(anchorElMenu)}
          options={menuOptions}
          item={itemRef?.current?.payload}
          handleClose={handleCloseMenu}
          onClickItem={onClickMenuItem}
        />
      ) : null}
    </Grid>
  );
};

export default DropdownOverlay;
