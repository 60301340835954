import React from "react";
import { createContext } from "react";

import {
  attachInstruction,
  extractInstruction,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/tree-item";
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/tree-item';

export const TreeContext = createContext({
  dispatch: () => {},
  uniqueContextId: Symbol("uniqueId"),
  getPathToItem: () => [],
  getMoveTargets: () => [],
  getChildrenOfItem: () => [],
  registerTreeItem: () => {},
});

export const DependencyContext = createContext({
  DropIndicator: DropIndicator,
  attachInstruction: attachInstruction,
  extractInstruction: extractInstruction,
});
