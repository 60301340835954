import { WebFormLanguage, load } from "@finapi/web-form";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback } from "react";
import { Box } from "@mui/material";

import { setIsAccountSelectOverlayOpen } from "../../../store/slices/global";
import { setIsAllHeaderApiFetched } from "../../../store/slices/common";
import { GlobalContext } from "../../../GlobalContextWrapper";
import { setAddFrom } from "../../../store/slices/settings";

function FinApiWebForm({
  token,
  language,
}: {
  token: string;
  language: string;
}) {
  const dispatch = useDispatch();
  const globalContext = React.useContext(GlobalContext) as { dsRef: any };
  const dsRef = globalContext?.dsRef;

  const addFrom = useSelector((state: any) => state.settingsSlice.addFrom);

  const createWebForm = useCallback(async (target: HTMLElement) => {
    load(
      target,
      {
        token,
        targetUrl: (import.meta as any)?.env?.VITE_FINAPI_TARGET_URL,
        layoutConfig: "xs",
        customerSupportUrl: "https://finapi.io",
        language: language as WebFormLanguage | undefined,
      },
      {
        onLoaded: function () {},
        onComplete: function () {
          if (addFrom?.type === "Consents") {
            dsRef?.current?.afterUpdateFinApiConsents(addFrom?.integration_id);
          } else {
            setTimeout(() => {
              dispatch(
                setIsAccountSelectOverlayOpen({
                  open: true,
                  isBankAccountView: true,
                  is_recently_tried_import_new_accounts:
                    addFrom?.type === "import_new_accounts",
                })
              );
            }, 1000);
          }
        },
        onFail: function () {
          dispatch(setIsAccountSelectOverlayOpen(null));
          dispatch(setAddFrom(null));
          dispatch(setIsAllHeaderApiFetched(true));
          console.log("onFail:");
          // handle "onFail" event
        },
        onAbort: function () {
          dispatch(setAddFrom(null));
          dispatch(setIsAccountSelectOverlayOpen(null));
          dispatch(setIsAllHeaderApiFetched(true));
          console.log("onAbort:");

          // handle "onAbort" event
        },
        onLoadError: function () {
          dispatch(setIsAccountSelectOverlayOpen(null));
          dispatch(setAddFrom(null));
          dispatch(setIsAllHeaderApiFetched(true));
          console.log("onLoadError:");
          // handle "onLoadError" event
        },
      }
    );
  }, []);

  const containerRef = (container: HTMLDivElement) => {
    if (container) {
      createWebForm(container);
    }
  };
  if (!(import.meta as any)?.env?.VITE_FINAPI_TARGET_URL) {
    return null;
  }
  return <Box ref={containerRef} sx={{ width: "50%" }}></Box>;
}

export default FinApiWebForm;
