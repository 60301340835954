import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import {
  calculateLoanHistory,
  calculateLoanTenure,
} from "../../../Helper/functions";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import { formatDateToLocal } from "./../../../Helper/data";
import { formatAmount } from "../../../Helper/data";

const Preview = ({
  expectedList,
  totalPreviewCount,
  tab,
  recurring_Obj,
  VariableRatesItemList,
  sx,
}) => {
  const { t } = useTranslation();
  const [interestPaid, setInterestPaid] = useState(null);

  useDebounce(
    () => {
      if (
        (tab === "loan" || tab === "leasing") &&
        recurring_Obj?.start_date &&
        (VariableRatesItemList?.length > 0
          ? true
          : recurring_Obj?.interest_rate &&
            recurring_Obj?.interest_rate_type) &&
        recurring_Obj?.value
      ) {
        if (recurring_Obj?.loan_calculation_method === 2 || tab === "leasing") {
          const { totalInterestPaid } = calculateLoanHistory({
            principal: recurring_Obj?.value,
            downPayment: recurring_Obj?.deposit_value,
            interest_rate_type: recurring_Obj?.interest_rate_type,
            interest_rate: recurring_Obj?.interest_rate,
            loanTenureInMonths: recurring_Obj?.payment_default,
            VariableRatesItemList,
            start_date: recurring_Obj?.start_date,
          });
          setInterestPaid(totalInterestPaid || 0);
        } else {
          const LoanTenure = calculateLoanTenure({
            principal: recurring_Obj?.value,
            downPayment: recurring_Obj?.deposit_value,
            interest_rate_type: recurring_Obj?.interest_rate_type,
            interest_rate: recurring_Obj?.interest_rate,
            emi: Math.abs(recurring_Obj?.average_value || 0),
            VariableRatesItemList,
            start_date: recurring_Obj?.start_date,
          });

          const { totalInterestPaid } = calculateLoanHistory({
            principal: recurring_Obj?.value,
            downPayment: recurring_Obj?.deposit_value,
            interest_rate_type: recurring_Obj?.interest_rate_type,
            interest_rate: recurring_Obj?.interest_rate,
            loanTenureInMonths: LoanTenure,
            VariableRatesItemList,
            start_date: recurring_Obj?.start_date,
          });
          setInterestPaid(totalInterestPaid || 0);
        }
      } else {
        setInterestPaid(null);
      }
    },
    500,
    [
      tab,
      expectedList,
      recurring_Obj?.start_date,
      recurring_Obj?.interest_rate,
      recurring_Obj?.interest_rate_type,
      recurring_Obj?.value,
      recurring_Obj?.payment_default,
    ],
    true
  );
  return (
    <Box
      sx={{
        width: "100%",
        mt:
          tab === "loan"
            ? "-8.25rem"
            : tab === "leasing"
              ? "-6.25rem"
              : "0.25rem",
        ...sx,
      }}
    >
      {expectedList?.length > 0 ? (
        <Typography
          variant="subtitle1"
          fontWeight={"fontWeightMediumBold"}
          color="color.mainTitle"
          sx={{
            fontSize: "0.8rem",
          }}
        >
          {t("Preview_heading", {
            total: totalPreviewCount.current,
            count: expectedList?.length > 9 ? 10 : expectedList?.length,
          })}
        </Typography>
      ) : null}

      {expectedList?.map((item, index) => {
        if (!item) return null;
        if (index > 9 && index < expectedList?.length - 1) return null;
        if (index === 9 && index < expectedList?.length - 1) {
          return (
            <Typography key={item?.due_date} color="color.slate.400">
              ...
            </Typography>
          );
        } else {
          return (
            <div
              key={item?.due_date}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                color="color.slate.400"
                fontWeight={"fontWeightMedium"}
                sx={{
                  minWidth: "4rem",
                  width: "60%",
                  fontSize: "0.75rem",
                }}
              >
                {formatDateToLocal(item?.due_date, "dd MMMM yyyy")}
              </Typography>
              <Typography
                color="color.slate.700"
                fontWeight={"fontWeightMediumBold"}
                sx={{
                  fontSize: "0.85rem",
                  width: "40%",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {item &&
                  formatAmount({
                    amount: String(item?.gross_value),
                  })}
              </Typography>
            </div>
          );
        }
      })}

      {interestPaid ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <Typography
            fontWeight={"fontWeightMediumBold"}
            color="color.mainTitle"
            sx={{
              minWidth: "4rem",
              width: "60%",
              fontSize: "0.75rem",
            }}
          >
            {t("interest_amount")}
          </Typography>
          <Typography
            color="color.slate.700"
            fontWeight={"fontWeightMediumBold"}
            sx={{
              fontSize: "0.85rem",
              width: "40%",
              pl: "0.45rem",
            }}
          >
            {formatAmount({
              amount: String(interestPaid),
            })}
          </Typography>
        </div>
      ) : null}
    </Box>
  );
};

export default Preview;
