import React from "react";
import { Typography, TextField, useTheme, Tooltip, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { Color, Fonts } from "../../Helper";

const TitleInput = ({
  title,
  value = "",
  onChange,
  error,
  helperText,
  fontSize = "0.9rem",
  name = "",
  type = "text",
  disabled = false,
  star = false,
  hideTitle = false,
  mb = "1rem",
  borderRadius = 1,
  tooltip = "",
  i18nkey,
  variant = "outlined",
  placeholder = "",
  label = null,
  likeGoogle = false,
  defaultValue = undefined,
  onBlur = () => {},
  sx,
  ...rest
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);

  const onFocus = () => {
    setIsFocus(true);
  };

  const onBlurInput = (e) => {
    setIsFocus(false);
    onBlur(e);
  };

  return (
    <Tooltip placement="top" title={tooltip}>
      <Box sx={sx} width={"100%"}>
        {!hideTitle && (
          <Typography
            variant="h6"
            component={"h6"}
            color="color.mainTitle"
            fontWeight={"fontWeightMediumBold"}
            sx={{
              display: "flex",
              width: "100%",
              textAlign: "left",
              mb: "0.25rem",
              fontSize: fontSize,
              cursor: "default",
            }}
          >
            {" "}
            {title ? title : t("Title")} {star ? "*" : ""}
          </Typography>
        )}
        <Box
          sx={{
            mb: mb,
            width: "100%",
            ".MuiFormControl-root": {
              width: "100%",
            },
            "& .MuiInputLabel-root": {
              color: error
                ? theme.palette.error
                : theme.palette.color.mainTitle,
              fontWeight: theme.typography.fontWeightMediumBold,
              fontSize: fontSize,
              transform: "translate(12px, 13px) scale(1)",
              "&[data-shrink=true]": {
                transform: "translate(12px, 4px) scale(1)",
              },
            },
            ".MuiOutlinedInput-root": {
              width: "100%",
              fontSize: fontSize,
              fontWeight: 400,
              fontFamily: Fonts.Text,
              "& input": {
                height: "1.7em",
              },
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  backgroundColor: "rgba(0,0,0,0.03)",
                },
              },
            },
            ".MuiOutlinedInput-input": {
              p: "0.5rem",
              backgroundColor: Color.white,
              "&:focus": {
                p: "0.4rem",
                border: `1px solid ${Color.oInputBorderBg}`,
                borderRadius: borderRadius,
              },
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${Color.oInputBorderBg}`,
              // borderStyle: value === "" ? "solid" : "none",
            },
            ".MuiInputBase-root": {
              // p: likeGoogle && "0.4rem",
              borderRadius: borderRadius,
              backgroundColor:
                likeGoogle && isFocus ? Color.inputBG : "transparent",
              "&:before": {
                borderBottom: likeGoogle && "0px !important",
              },
              "&:hover": {
                backgroundColor: likeGoogle && Color.inputBG,
                "&:before": {
                  borderBottom: likeGoogle && "0px !important",
                },
              },
              "&:focus": {
                backgroundColor: likeGoogle && Color.inputBG,
              },
              "&.Mui-disabled": {
                backgroundColor: Color.theme.grey[50],
              },
            },
            "& input": {
              fontSize: "0.9rem !important",
            },
          }}
        >
          <TextField
            id="outlined-name"
            size="small"
            type={type}
            name={name}
            value={value || ""}
            disabled={disabled}
            onChange={onChange}
            helperText={helperText}
            error={error}
            variant={variant}
            placeholder={placeholder}
            label={label}
            defaultValue={defaultValue}
            onFocus={onFocus}
            onBlur={onBlurInput}
            {...rest}
          />
        </Box>
      </Box>
    </Tooltip>
  );
};
export default TitleInput;
