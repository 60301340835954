import {
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  format,
} from "date-fns";
import { useEffect, useMemo, forwardRef, useDeferredValue } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import React from "react";

import {
  setMonthlyChartData,
  setTransactionData,
  setDataSetData,
  setDataSetList,
} from "../../../store/slices/board";
import {
  hasMissingProperties,
  buildUrlFromParams,
  getDefaultFilters,
} from "../../../Helper/data";
import { setAppliedFilterslist } from "../../../store/slices/global";
import { setCurrencyFormate } from "../../../store/slices/settings";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import { setPopupStatus } from "../../../store/slices/datasets";
import FirstLoginOverlay from "../../Overlay/FirstLoginOverlay";
import { setReportFilter } from "../../../store/slices/report";
import UpdatesOverlay from "../../Overlay/UpdatesOverlay";
import EndPoints from "../../../APICall/EndPoints";
import useJoyRide from "../../../hooks/useJoyRide";
import initialData from "../../../Helper/data";
import { Constant } from "../../../Helper";
import APICall from "../../../APICall";

const LogicFunctions = forwardRef(() => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let abortController = new AbortController();
  let aborted = abortController.signal.aborted;

  //redux
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isUpdatesOverlayOpen = useSelector(
    (state) => state.globalSlice.isUpdatesOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );

  // const refreshData = useSelector((state) => state.appSlice?.refreshData);
  // const refreshColumnData = useSelector(
  //   (state) => state.appSlice?.refreshColumnData
  // );

  const is_screen_inside_tab = useMemo(() => {
    return (
      location?.pathname.includes(`/${initialData?.path?.organization}/`) &&
      !location?.pathname.includes("/settings/")
    );
  }, [location?.pathname]);

  const allowCheck =
    isAllHeaderApiFetched &&
    !isAccountSelectOverlayOpen &&
    !isFirstLoginOverlayOpen &&
    !location.pathname?.includes("/callback") &&
    !location.pathname?.includes("client_id") &&
    !location.pathname?.includes("error") &&
    !location.pathname?.includes("code") &&
    location.pathname !== "/";

  //api
  const updateDataSetByID = async (id, obj) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data && aborted === false) {
          let data = [...dataSetList];
          const index = data?.findIndex((o1) => o1.uuid === id);
          if (index > -1) {
            data[index] = response.data;
            dispatch(setDataSetList(data));
            dispatch(setDataSetData(response.data));
            dispatch(setAppliedFilterslist(response?.data?.filters));
          }
        }
      }
    );
  };

  const getContacts = async (params) => {
    let result = null;
    await APICall("get", EndPoints.contacts + buildUrlFromParams(params)).then(
      (response) => {
        if (response.status === 200 && response.data) {
          result = response.data;
        }
      }
    );
    return result;
  };

  const Staff = useQuery({
    queryKey: ["staff"],
    queryFn: () => {
      const result = getContacts({
        type: 3,
      });
      if (result) {
        return result;
      }
    },
    cacheTime: 600000,
    enabled:
      isAllHeaderApiFetched &&
      !isFirstLoginOverlayOpen &&
      !!is_screen_inside_tab &&
      !!dataSetData?.uuid &&
      !!dataSource,
  });

  //lifecycle method
  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (dataSetData?.uuid && allowCheck) {
      let array = location.pathname?.split("/");
      array.splice(0, 3);
      if (array.length !== 0) {
        localStorage.setItem("last_visited_dataset", dataSetData?.uuid);
        let url = `/${initialData?.path?.organization}/${dataSetData?.uuid}`;
        array.forEach((path) => {
          url = url + "/" + path;
        });
        if (location?.search) {
          url = url + location?.search;
        }

        navigate(url);

        const data = dataSetList?.find((o1) => {
          return o1.uuid === dataSetData?.uuid;
        });
        window.global.allowFetch = { Inflow: [], Outflow: [] };
        dispatch(setAppliedFilterslist({ ...data?.filters }));
        dispatch(
          setCurrencyFormate(
            Constant.CurrencyFormatter?.[dataSetData?.currency || "EUR"]
          )
        );
        dispatch(setMonthlyChartData({}));
        dispatch(setTransactionData([]));
        dispatch(
          setPopupStatus({
            open: false,
            anchorEl: null,
          })
        );
      }
    }
  }, [allowCheck, dataSetData?.uuid]);

  useEffect(() => {
    if (
      !location?.pathname?.includes(`/${initialData?.path?.organization}`) &&
      dataSetData
    ) {
      dispatch(setDataSetData(null));
      dispatch(
        setReportFilter({
          key: "Page",
          value: null,
        })
      );
    }
  }, [dataSetData, location.pathname]);

  useDebounce(
    () => {
      if (
        location?.pathname.includes(`/${initialData?.path?.organization}/`) &&
        allowCheck &&
        dataSetList?.length > 0
      ) {
        const myArray = location?.pathname.split("/");
        const datasetId = myArray[2];

        if (
          !dataSetData ||
          (dataSetData && datasetId && dataSetData?.uuid !== datasetId)
        ) {
          const data = dataSetList?.find((o1) => o1.uuid === datasetId);
          if (data) {
            localStorage.setItem("last_visited_dataset", data?.uuid);
            const default_filters = getDefaultFilters();
            const isAnyFilterMissing = hasMissingProperties(
              default_filters,
              data?.filters
            );

            if (
              Boolean(JSON.stringify(data?.filters) === "{}") ||
              !data?.start_date ||
              !data?.end_date ||
              isAnyFilterMissing
            ) {
              let obj = { ...data, filters: { ...default_filters } };

              //to check default org have missing start_date and end_date
              if (!data?.start_date || !data?.end_date) {
                const startDate = startOfMonth(subMonths(new Date(), 1));
                const endDate = endOfMonth(
                  addMonths(startDate, Constant.default_column_count)
                );
                const start_date = format(new Date(startDate), "yyyy-MM-dd");
                const end_date = format(new Date(endDate), "yyyy-MM-dd");
                obj.planning_type = "monthly";
                obj.start_date = start_date;
                obj.end_date = end_date;
              }
              dispatch(
                setReportFilter({
                  key: "Page",
                  value: null,
                })
              );
              dispatch(setAppliedFilterslist({ ...obj?.filters }));
              dispatch(setDataSetData(obj));
              updateDataSetByID(data?.uuid, obj);
            } else {
              let obj = { ...data };
              if (!obj?.planning_type) {
                obj.planning_type = "monthly";
              }
              dispatch(
                setReportFilter({
                  key: "Page",
                  value: null,
                })
              );
              dispatch(setAppliedFilterslist({ ...obj?.filters }));
              dispatch(setDataSetData(obj));
            }
          } else {
            if (
              location.pathname?.includes(
                `/${initialData?.path?.organization}/`
              )
            ) {
              navigate("/dashboard");
            }
          }
        }
      }
    },
    500,
    [location?.pathname, allowCheck, dataSetList],
    true
  );

  // useUpdateEffect(() => {
  //   if (refreshData) {
  //     refreshQuery();
  //   }
  // }, [refreshData]);

  // useUpdateEffect(() => {
  //   if (refreshColumnData) {
  //     refreshColumnQuery();
  //   }
  // }, [refreshColumnData]);

  const _allowJoyRide = useDeferredValue(dataSetData?.uuid && allowCheck);

  useJoyRide({
    key: "joyride_planview",
    path: `/${initialData?.path?.organization}/${dataSetData?.uuid}/table`,
    allow: !!_allowJoyRide,
    delay: 3000,
  });

  useJoyRide({
    key: "reports",
    path: "/reports/overview",
    allow: !!_allowJoyRide,
    delay: 3000,
  });

  //function
  // const refreshQuery = async () => {
  //   let options = {
  //     predicate: (query) =>
  //       query.queryKey[0] === "transactions" &&
  //       query.queryKey[1]?.dataset === dataSetData?.uuid &&
  //       refreshData?.key?.includes(query.queryKey[1]?.apiType),
  //     type: "active",
  //     ...refreshData?.filters,
  //   };
  //   refresh(options);
  // };

  // const refreshColumnQuery = async () => {
  //   if (!refreshColumnData?.date) {
  //     let options = {
  //       predicate: (query) =>
  //         query.queryKey[0] === "transactions" &&
  //         query.queryKey[1]?.apiType === "normal_monthly_transactions" &&
  //         query.queryKey[1]?.dataset === dataSetData?.uuid &&
  //         query.queryKey[1]?.start_date === dataSetData?.start_date &&
  //         (refreshColumnData?.boardType
  //           ? refreshColumnData?.boardType?.includes(
  //               query.queryKey[1]?.boardType
  //             )
  //           : true),
  //       ...refreshColumnData?.filters,
  //     };

  //     refresh(options);
  //   } else {
  //     let dates = [...new Set(refreshColumnData?.date)]?.map((o1) =>
  //       format(startOfMonth(new Date(o1)), "yyyy-MM-dd")
  //     );
  //     let boardType = [refreshColumnData?.boardType];
  //     if (
  //       refreshColumnData?.cardItem &&
  //       Number(refreshColumnData?.cardItem?.gross) === 0 &&
  //       !boardType.includes("Inflow")
  //     ) {
  //       boardType.push("Inflow");
  //     }

  //     boardType.forEach((element) => {
  //       global.allowFetch = {
  //         [element]: dates,
  //       };
  //     });

  //     let options = {
  //       predicate: (query) =>
  //         query.queryKey[0] === "transactions" &&
  //         query.queryKey[1]?.apiType === "Kanban_Transactions" &&
  //         query.queryKey[1]?.dataset === dataSetData?.uuid &&
  //         (boardType
  //           ? boardType?.includes(query.queryKey[1]?.boardType)
  //           : true) &&
  //         (dates ? dates?.includes(query.queryKey[1]?.date) : true),
  //       // type: "active",
  //       ...refreshColumnData?.filters,
  //     };
  //     refresh(options);
  //   }

  //   setTimeout(() => {
  //     global.allowFetch = {
  //       Inflow: [],
  //       Outflow: [],
  //     };
  //   }, 6000);
  // };

  // const refresh = (options) => {
  //   // queryClient.refetchQueries(options);
  //   queryClient.resetQueries(options);
  //   queryClient.invalidateQueries(options, {
  //     cancelRefetch: true,
  //   });
  //   dispatch(setRefreshColumnData(null));
  // };

  return (
    <>
      {isFirstLoginOverlayOpen && <FirstLoginOverlay />}
      {isUpdatesOverlayOpen && <UpdatesOverlay />}
    </>
  );
});
export default LogicFunctions;
