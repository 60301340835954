import React from "react";
import { useEffect } from "react";
import useTimeout from "../2-useTimeout/useTimeout";

export default function useDebounce(
  callback,
  delay,
  dependencies,
  firstFetch = false
) {
  const { reset, clear } = useTimeout(callback, delay, firstFetch);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
}
