import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Intercom } from "@intercom/messenger-js-sdk";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import * as Sentry from "@sentry/react";
import _ from "underscore";
import React from "react";

import {
  setIsFirstLoginOverlayOpen,
  setIsUpdatesOverlayOpen,
  setTransactionSystem,
  setIsDsUuidLoading,
  setRecurring_rules,
  setAppliedFilters,
  setOnBoardingList,
  setJoyRideStatus,
  setDataSource,
  setAdvanceVat,
  setCostUnits,
  setScenario,
  setAccounts,
  setFilters,
  setStates,
  setVats,
} from "../store/slices/global";
import DrawerModalContactEditForm from "../Pages/Datasets/Employees/Clients/Component/DrawerModalContactEditForm";
import ReconcileListViewModal from "../Pages/Datasets/Transactions/Components/ReconcileListViewModal";
import AccountsSelectOverlay from "../Pages/Settings/DataSource/AccountsSelectOverlay";
import AddTransactionModal from "../Pages/Datasets/Kanban/Overlay/AddTransactionModal";
import GroupSettingsModal from "../Pages/Datasets/Kanban/Overlay/GroupSettingsModal";
import DrawerModalEditForm from "../components/TableEditModal/DrawerModalEditForm";
import { setHighlightedScenarios, setDataSetList } from "../store/slices/board";
import { getAllTransactionsByParams, updateLanguage } from "../Helper/data";
import { setBankCategories, setCategories } from "../store/slices/category";
import CategoryTreeSelect from "../components/Overlay/CategoryTreeSelect";
import RecurOverlay from "../Pages/Datasets/Kanban/Overlay/RecurOverlay";
import useUpdateEffect from "../hooks/4-useUpdateEffect/useUpdateEffect";
import FinApiOverlay from "../Pages/Settings/DataSource/FinApiOverlay";
import ConfirmationModal from "../components/Model/ConfirmationModal";
import DrawerModal from "../components/TableEditModal/DrawerModal";
import { setIsAllHeaderApiFetched } from "../store/slices/common";
import { setAddFrom, setProfile } from "../store/slices/settings";
import SettingForm from "../Pages/Datasets/Kanban/SettingForm";
import PlanExpiredAlert from "../components/PlanExpiredAlert";
import useDebounce from "../hooks/3-useDebounce/useDebounce";
import ThemeSpinLoader from "../components/ThemeSpinLoader";
import { setFirstLoading } from "../store/slices/appmain";
import GlobalDSFetchSystem from "./GlobalDSFetchSystem";
import StageLoader from "../components/StageLoader";
import EndPoints from "../APICall/EndPoints";
import authSlice from "../store/slices/auth";
import packageJson from "../../package.json";
import Loader from "../components/Loader";
import { queryClient } from "../App";
import APICall from "../APICall";

const defaultJoyrideOptions = [
  {
    data_type: "transaction",
    title: "joyride",
    filters: {
      header: false,
      dataSetList: false,
      dataSource: false,
      dashboard_overview: false,
      list: false,
      kanban: false,
    },
  },
];

const GlobalViews = () => {
  return (
    <>
      <GlobalFunctions />
      <GlobalDSFetchSystem />
      <StageLoader />
      <ChatWidget />
      <GlobalSpinner />
      <PlanExpiredAlert />
      <AccountsSelectOverlay />
      <FinApiOverlay />
      <PopupOverlay />
      <DrawerModalListView />
      <DrawerModalEditFormView />
      <DrawerModalContactEditFormView />
    </>
  );
};
export default GlobalViews;

//transactions form
const DrawerModalListView = () => {
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);

  return (
    <>
      {popupStatus2?.open && popupStatus2?.overlay_type === "drawer_modal" && (
        <DrawerModal />
      )}
    </>
  );
};

const DrawerModalEditFormView = () => {
  const transactionsOverlayStatus = useSelector(
    (state) => state.datasetSlice?.transactionsOverlayStatus
  );

  if (
    transactionsOverlayStatus?.open &&
    transactionsOverlayStatus?.overlay_type === "drawer_modal_edit_form"
  ) {
    return <DrawerModalEditForm />;
  }
  return null;
};

const DrawerModalContactEditFormView = () => {
  const contactOverlayStatus = useSelector(
    (state) => state.datasetSlice?.contactOverlayStatus
  );

  if (
    contactOverlayStatus?.open &&
    contactOverlayStatus?.overlay_type === "drawer_modal_contact_edit_form"
  ) {
    return <DrawerModalContactEditForm />;
  }
  return null;
};

//global finban support chat widget
const ChatWidget = React.memo(() => {
  const location = useLocation();
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const intercomInitialized = useRef(false);
  const showHeader =
    profile?.email &&
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register") &&
    !location.pathname.includes("/registration/") &&
    !location.pathname.includes("/auth/");

  useEffect(() => {
    try {
      if (
        import.meta.env.VITE_INTERCOM_APP_ID &&
        showHeader &&
        !intercomInitialized.current
      ) {
        Intercom({
          appId: import.meta.env.VITE_INTERCOM_APP_ID,
          user_id: profile?.email,
          name: profile?.first_name + " " + profile?.last_name,
          email: profile?.email,
          created_at: Math.floor(
            new Date(profile?.subscription?.start_date).getTime() / 1000
          ),
        });
        intercomInitialized.current = true;
      }
    } catch (error) {
      console.error("Intercom initialization error:", error);
      Sentry.captureException(error);
    }
  }, [profile, location.pathname, showHeader]);

  return null;
});

//global spinner
const GlobalSpinner = () => {
  const loading = useSelector((state) => state.appSlice.loading);
  const isFirstLoading = useSelector((state) => state.appSlice?.isFirstLoading);
  const token = useSelector((state) => state.auth?.token);

  return (
    <>
      <ThemeSpinLoader
        loading={loading || (isFirstLoading && token)}
        showTips={isFirstLoading && token}
        isFirstLoading={isFirstLoading && token}
      />

      <Loader loading={loading} />
    </>
  );
};

//popup overlay
const PopupOverlay = () => {
  //redux
  const popupStatus = useSelector((state) => state.datasetSlice?.popupStatus);
  const popupStatus2 = useSelector((state) => state.datasetSlice?.popupStatus2);
  const popupStatus3 = useSelector((state) => state.datasetSlice?.popupStatus3);
  const popupStatus4 = useSelector((state) => state.datasetSlice?.popupStatus4);

  return (
    <>
      {popupStatus?.from === "column_add_Transaction" && popupStatus?.open && (
        <AddTransactionModal />
      )}
      {popupStatus?.from === "column_add_recurrence" && popupStatus?.open && (
        <RecurOverlay />
      )}
      {popupStatus?.from === "column_reconcile" && popupStatus?.open && (
        <ReconcileListViewModal />
      )}
      {/* <ReconcileModal /> */}
      {popupStatus2?.from === "card_setting" && <GroupSettingsModal />}
      {/*for delete, warning ,for ask confirmation */}
      {popupStatus3?.open && <ConfirmationModal />}{" "}
      {/*for category selection ,form for state,scenario,cost unit, due date ,invoice date, date ,recurring date range*/}
      {popupStatus4?.open && popupStatus4?.overlay_type === "category" && (
        <CategoryTreeSelect
          anchorEl={popupStatus4?.anchorEl}
          onClickCategoryTitle={popupStatus4?.payload?.onClickCategoryTitle}
          handleClosePopOver={popupStatus4?.payload?.handleClosePopOver}
          allowParentCategory={popupStatus4?.payload?.allowParentCategory}
          hiddenCategory={popupStatus4?.payload?.hiddenCategory}
          ruleTitle={popupStatus4?.payload?.ruleTitle}
          type={popupStatus4?.payload?.type}
          defaultType={popupStatus4?.payload?.defaultType}
          isOverlay={popupStatus4?.payload?.isOverlay}
          hideUncategorize={popupStatus4?.payload?.hideUncategorize}
          anchorReference={popupStatus4?.payload?.anchorReference}
          anchorPosition={popupStatus4?.payload?.anchorPosition}
        />
      )}
      {popupStatus4?.open && popupStatus4?.overlay_type === "form" && (
        <SettingForm
          open={popupStatus4.open}
          onSave={popupStatus4?.payload?.onSave}
          type={popupStatus4?.payload?.type}
          isRecurringRule={popupStatus4?.payload?.isRecurringRule}
          actionInfo={popupStatus4?.payload?.actionInfo}
        />
      )}
    </>
  );
};

//global functions
const GlobalFunctions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const alreadyStoredFilters = useRef(null);
  const isFirstFetch = useRef({
    joyride: true,
    dataset: true,
    filter: true,
  });

  //redux
  const auth = useSelector((state) => state.auth);
  const refreshToken = useSelector((state) => state.auth?.refreshToken);
  const addFrom = useSelector((state) => state.settingsSlice.addFrom);
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const filters = useSelector((state) => state.globalSlice?.filters);
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const appliedFilter = useSelector((state) => state.globalSlice.appliedFilter);
  const refreshBalance = useSelector((state) => state.appSlice?.refreshBalance);

  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const joyRideStatus = useSelector(
    (state) => state.globalSlice?.joyRideStatus
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isFirstLoginOverlayOpen = useSelector(
    (state) => state.globalSlice.isFirstLoginOverlayOpen
  );
  const isAccountSelectOverlayOpen = useSelector(
    (state) => state.globalSlice.isAccountSelectOverlayOpen
  );

  //const
  let showHeader =
    auth?.token &&
    auth?.refreshToken &&
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register") &&
    !location.pathname.includes("/registration/") &&
    !location.pathname.includes("/auth/");

  //api
  const updateApiKey = async (integration_id, obj) => {
    await APICall(
      "patch",
      EndPoints.integrations + `${integration_id}/external/config/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response) {
        setTimeout(() => {
          dispatch(setIsDsUuidLoading(integration_id));
          dispatch(setAddFrom(null));
          queryClient.resetQueries({
            queryKey: [
              "integrations",
              {
                data_source: integration_id,
                apiType: "data_source_external_connections",
              },
            ],
          });
        }, 500);
      }
    });
  };

  const updateFiltersApi = async (id, obj) => {
    await APICall("patch", EndPoints.filters + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          let data = [...filters];
          let index = filters?.findIndex(
            (o1) => o1?.uuid === response.data.uuid
          );
          data[index] = response.data;
          dispatch(setFilters(data));
        }
      }
    );
  };

  const updateToken = async () => {
    let obj = {
      refresh: refreshToken,
    };
    await APICall("post", EndPoints.refreshToken, obj).then((response) => {
      if (response.status === 200 && response.data) {
        let accessToken = response.data.access;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("refreshToken", refreshToken);

        dispatch(
          authSlice.actions.setAuthTokens({
            token: accessToken,
            refreshToken: refreshToken,
          })
        );
      }
    });
  };

  const updateDataSetByID = async (id, obj, array) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
          alreadyStoredFilters.current = response.data?.filters;
          let data = [...array];
          let index = array.findIndex((o1) => o1.uuid === id);
          if (index > -1) {
            data[index] = response.data;
          }
          data.sort((a, b) => (a.position > b.position ? 1 : -1));
          dispatch(setDataSetList(data));
        }
      }
    );
  };

  const getRecurringRules = useCallback(
    async (params) => {
      const results = await getAllTransactionsByParams(params);
      if (results) {
        dispatch(setRecurring_rules([...results]));
      }
    },
    [dispatch]
  );

  const getFilters = async () => {
    await APICall("get", EndPoints.filters).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        if (data.length === 0) {
          defaultJoyrideOptions.forEach((element, i) => {
            addFilters(element, i === defaultJoyrideOptions.length - 1);
          });
        } else {
          let filterData = data?.find((o1) => {
            return o1?.title === "joyride";
          });
          if (filterData) {
            dispatch(
              setJoyRideStatus({ ...filterData, data_type: "transaction" })
            );
          }
          let filterListData = data?.find((o1) => {
            return o1?.title === "list";
          });
          if (filterListData) {
            dispatch(
              setAppliedFilters({ ...filterListData, data_type: "transaction" })
            );
          }
          dispatch(setFilters(data));
        }
      }
    });
  };

  const addFilters = async (obj, isAllow = false) => {
    await APICall("post", EndPoints.filters, obj).then((response) => {
      if (response.status === 201 && response.data) {
        if (isAllow) {
          getFilters();
        }
      }
    });
  };

  const getMasterData = async () => {
    await APICall("get", EndPoints.master_data).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data;
        Sentry?.setUser({
          email: data?.profile?.email,
          first_name: data?.profile?.first_name,
          last_name: data?.profile?.last_name,
          locale: data?.profile?.locale,
          is_owner: data?.profile?.is_owner,
        });
        if (!data?.profile?.first_login) {
          updateLanguage(data?.profile?.locale);
        }
        const base_id = data?.scenarios?.results?.find(
          (o1) => o1.title === "Base"
        )?.uuid;
        dispatch(setHighlightedScenarios([base_id]));
        dispatch(
          setBankCategories({
            data: data?.bank_categories?.results,
            type: "all",
          })
        );
        dispatch(setStates(data?.states?.results));
        dispatch(setScenario(data?.scenarios?.results));
        dispatch(setProfile(data?.profile));
        dispatch(setCostUnits(data?.costunits?.results));
        dispatch(
          setCategories({ data: data?.categories?.results, type: "all" })
        );
        dispatch(setVats(data?.taxes?.results));
        dispatch(setAdvanceVat(data?.tax_configuration));
        dispatch(setAccounts(data?.accounts?.results));
        dispatch(setDataSource(data?.data_sources?.results));
        dispatch(
          setOnBoardingList(
            data?.todos?.results.filter(
              (o1) => o1.category?.toLowerCase() === "onboarding"
            )
          )
        );
        dispatch(
          setDataSetList(
            data?.datasets?.results.sort((a, b) =>
              a.position > b.position ? 1 : -1
            )
          )
        );
      }
    });
  };

  const getTransactionSystemUuid = async () => {
    await APICall("get", EndPoints.transaction_systems).then((response) => {
      if (response.status === 200 && response.data) {
        dispatch(setTransactionSystem(response.data.results));
      }
    });
  };

  const getAccountsApi = async () => {
    await APICall("get", EndPoints.accounts).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setAccounts(data));
      }
    });
  };

  const updateProfile = useCallback(async (obj) => {
    await APICall("patch", EndPoints.profile, obj).then((response) => {
      if (response.status === 200 && response.data) {
      }
    });
  }, []);

  //functions
  const checkUserLanguage = () => {
    const userLanguage = navigator.language || navigator.userLanguage;
    updateLanguage();
    let splittedLang = userLanguage?.split("-");
    const updatedLang = splittedLang === "de" ? "de_DE" : "en_GB";
    dispatch(
      setProfile({
        ...profile,
        locale: updatedLang,
      })
    );
    const obj = {
      locale: updatedLang,
    };
    updateProfile(obj);
  };

  const checkNavigation = async () => {
    let pathname = window.location.href;
    let defaultPathname = "/settings/Integrations";

    //lexoffice
    if (pathname.includes("client_id") && pathname.includes("state")) {
      if (pathname.includes("error")) {
        if (addFrom?.targetUrl) {
          navigate(addFrom?.targetUrl);
        } else {
          navigate(defaultPathname);
        }
        enqueueSnackbar(t("lexoffice_error_msg"), {
          variant: "error",
          autoHideDuration: 5000,
        });
        dispatch(setAddFrom(null));
        return;
      }
      if (addFrom) {
        navigate(addFrom?.targetUrl || defaultPathname);
      } else {
        navigate(defaultPathname);
      }
    }

    //integrations redirect
    if (pathname.includes("code") && pathname.includes("state")) {
      if (addFrom) {
        let url = new URL(pathname);
        let searchParams = url.searchParams;
        let authorization_code = searchParams.get("code");
        if (addFrom?.integration_id) {
          updateApiKey(addFrom?.integration_id, { authorization_code });
        }
        navigate(addFrom?.targetUrl || defaultPathname);
      } else {
        navigate(defaultPathname);
      }
    }

    //Unified
    if (
      pathname.includes("id") &&
      pathname.includes("state") &&
      pathname.includes("type")
    ) {
      if (addFrom) {
        const { searchParams } = new URL(pathname);
        const state = searchParams.get("state"),
          type = searchParams.get("type"),
          connection_id = searchParams.get("id");

        if (
          addFrom?.integration_id === state &&
          addFrom?.type === type &&
          connection_id
        ) {
          updateApiKey(addFrom?.integration_id, {
            connection_id: connection_id,
            type: addFrom?.type,
          });
          navigate(addFrom?.targetUrl || defaultPathname);
        } else {
          navigate(defaultPathname);
        }
      } else {
        navigate(defaultPathname);
      }
    }
    if (pathname.includes("isUnifiedError")) {
      if (addFrom?.targetUrl) {
        navigate(addFrom?.targetUrl);
      } else {
        navigate(defaultPathname);
      }
      enqueueSnackbar(t("unified_integration_error"), {
        variant: "error",
        autoHideDuration: 5000,
      });
      dispatch(setAddFrom(null));
      return;
    }
  };

  const checkIsUserValid = () => {
    if (showHeader) {
      const EMAIL = localStorage.getItem("LoggedInUser");
      if (!profile?.email) {
        window.location.href = "/login";
        return;
      }
      if (!EMAIL) {
        localStorage.setItem("LoggedInUser", profile?.email);
        return;
      }
      if (EMAIL && EMAIL !== profile?.email) {
        localStorage.setItem("LoggedInUser", profile?.email);
        window.location.href = "/dashboard";
        emptyCacheStorage(true, 0);
      }
    }
  };

  useEffect(() => {
    if (dataSetData?.uuid) {
      getRecurringRules({
        endpoint: "recurring_rules",
        dataset: dataSetData?.uuid,
      });
    }
  }, [dataSetData?.uuid, getRecurringRules]);

  //first load
  useEffect(() => {
    const tokenExpire = setInterval(() => {
      updateToken();
    }, 29 * 60 * 1000);
    window.global.allowFetch = { Inflow: [], Outflow: [] };
    window.global.isFirstFetchUuid = [];

    setTimeout(async () => {
      if (refreshToken) {
        queryClient.clear();
        await updateToken();
        await getMasterData();
        await getTransactionSystemUuid();
        await getFilters();
        setTimeout(() => {
          dispatch(setIsAllHeaderApiFetched(true));
          dispatch(setFirstLoading(false));
        }, 0);
      } else {
        navigate("/login");
      }
    }, 0);

    return () => {
      clearInterval(tokenExpire);
    };
  }, []);

  //check is user is valid
  useEffect(() => {
    if (showHeader) {
      checkIsUserValid();
    } else {
      updateLanguage();
    }
  }, [showHeader]);

  //check is user is valid
  useDebounce(
    () => {
      window.addEventListener("focus", checkIsUserValid);

      checkIsUserValid();

      return () => {
        window.removeEventListener("focus", checkIsUserValid);
      };
    },
    500,
    [showHeader, location.pathname],
    true
  );

  //check navigation/callback for integrations
  useEffect(() => {
    if (isAllHeaderApiFetched) {
      setTimeout(() => {
        checkNavigation();
      }, 1000);
    }
  }, [isAllHeaderApiFetched]);

  //update joyride filters
  useDebounce(
    () => {
      if (
        joyRideStatus?.uuid &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        isAllHeaderApiFetched
      ) {
        if (!isFirstFetch.current?.joyride) {
          updateFiltersApi(joyRideStatus?.uuid, joyRideStatus);
        } else {
          isFirstFetch.current.joyride = false;
        }
      }
    },
    2000,
    [joyRideStatus]
  );

  //update filters
  useDebounce(
    () => {
      if (
        appliedFilter?.uuid &&
        !isFirstLoginOverlayOpen &&
        !isAccountSelectOverlayOpen &&
        isAllHeaderApiFetched
      ) {
        if (!isFirstFetch.current?.filter) {
          updateFiltersApi(appliedFilter?.uuid, appliedFilter);
        } else {
          isFirstFetch.current.filter = false;
        }
      }
    },
    2000,
    [appliedFilter]
  );

  //update dataset filters
  useDebounce(
    () => {
      if (
        dataSetData?.uuid &&
        isAllHeaderApiFetched &&
        !isAccountSelectOverlayOpen &&
        !isFirstLoginOverlayOpen &&
        appliedFilterlist &&
        (!alreadyStoredFilters.current ||
          (alreadyStoredFilters.current &&
            !_.isEqual(alreadyStoredFilters.current, appliedFilterlist)))
      ) {
        if (!isFirstFetch.current?.dataset) {
          updateDataSetByID(
            dataSetData?.uuid,
            { filters: appliedFilterlist },
            dataSetList
          );
        } else {
          isFirstFetch.current.dataset = false;
        }
      }
    },
    2000,
    [appliedFilterlist]
  );

  //refresh balance
  useUpdateEffect(() => {
    getAccountsApi();
  }, [refreshBalance]);

  //check finban version update
  useEffect(() => {
    if (isAllHeaderApiFetched) {
      const version_key = `FINBAN_APP_VERSION_${profile?.email}`;
      let FINBAN_APP_VERSION = localStorage.getItem(version_key);
      if (profile?.first_login) {
        localStorage.setItem(version_key, packageJson?.version);
        checkUserLanguage();
        dispatch(setIsFirstLoginOverlayOpen(true));
      } else {
        const UserVersionArray = FINBAN_APP_VERSION?.split(".");
        const UserMajorVersion1 = UserVersionArray?.[0];
        const UserMajorVersion2 = UserVersionArray?.[1];
        const UserMajorVersion3 = UserVersionArray?.[2];

        const FinbanVersionArray = packageJson?.version?.split(".");
        const FinbanMajorVersion1 = FinbanVersionArray?.[0];
        const FinbanMajorVersion2 = FinbanVersionArray?.[1];
        const FinbanMajorVersion3 = FinbanVersionArray?.[2];
        let isMajorRelease = false;

        if (
          !FINBAN_APP_VERSION ||
          UserMajorVersion1 !== FinbanMajorVersion1 ||
          UserMajorVersion2 !== FinbanMajorVersion2 ||
          UserMajorVersion3 !== FinbanMajorVersion3
        ) {
          isMajorRelease = true;
        }
        if (isMajorRelease) {
          requestAnimationFrame(() => {
            localStorage.setItem(version_key, packageJson?.version);
            dispatch(setIsUpdatesOverlayOpen(true));
          });
        }
      }
    }
  }, [profile?.first_login, isAllHeaderApiFetched, profile?.email]);

  const emptyCacheStorage = async (update = false, count = 2000) => {
    if ("caches" in window) {
      const cacheKeys = await window.caches.keys();
      await Promise.all(
        cacheKeys.map((key) => {
          window.caches.delete(key);
        })
      );
      queryClient.clear();
      if (update) {
        setTimeout(() => {
          window.location.replace(window.location.href);
        }, count);
      }
    }
  };
};
