import { Box, CircularProgress, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import React from "react";

import useDebounce from "../hooks/3-useDebounce/useDebounce";
import TipsWhileLoading from "./TipsWhileLoading";
import AsyncImage from "./AsyncImage";

const ThemeSpinLoader = ({ loading, showTips, children, isFallback }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const token = localStorage.getItem("token");
  React.useEffect(() => {
    if (loading) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [loading]);

  if (!open) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)", // Centering horizontally and vertically
        zIndex: 9999,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "max-content",
      }}
    >
      <ThemeSpinner loading={loading} theme={theme} children={children} />
      {showTips && !isFallback && token ? <TipsWhileLoading /> : null}
    </Box>
  );
};

export default ThemeSpinLoader;

export const ThemeSpinner = ({ children, theme, loading, style }) => {
  const [showLogo, setShowLogo] = React.useState(false);
  useDebounce(
    () => {
      if (loading) {
        setShowLogo(true);
        setTimeout(() => {
          setShowLogo(false);
        }, 1000);
      }
    },
    500,
    [loading],
    true
  );
  return (
    <div
      style={{
        position: "relative",
        ...style,
      }}
    >
      {children || (
        <div
          style={{
            backgroundColor: theme?.palette?.primary?.main,
            borderRadius: theme?.borderRadius?.main,
            color: theme?.palette?.color?.white,
            width: "2.4rem",
            height: "2.4rem",
            boxShadow: theme?.boxShadow,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {showLogo ? (
            <AsyncImage
              imageKey="logo_finban"
              alt="finban_logo"
              style={{
                width: "2.4rem",
                height: "2.4rem",
                objectFit: "cover",
              }}
            />
          ) : null}

          {!showLogo ? (
            <CircularProgress
              size="1.6rem"
              sx={{
                zIndex: 1,
                m: "auto",
                position: "absolute",
                color: `${theme?.palette?.color?.white} !important`,
              }}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};
