export const tourStep = {
  empty: [],
  joyride_planview: [
    {
      target: ".joyride_planview_step_0",
      content: "joyride_planview_step_0",
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".joyride_planview_step_1",
      content: "joyride_planview_step_1",
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".joyride_planview_step_2",
      content: "joyride_planview_step_2",
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".joyride_planview_step_3",
      content: "joyride_planview_step_3",
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".joyride_planview_step_4",
      content: "joyride_planview_step_4",
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".joyride_planview_step_5",
      content: "joyride_planview_step_5",
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".joyride_planview_step_6",
      content: "joyride_planview_step_6",
      placement: "auto",
      disableBeacon: true,
    },
    {
      target: ".joyride_planview_step_7",
      content: "joyride_planview_step_7",
      placement: "auto",
      disableBeacon: true,
    },
  ],
  reports: [
    {
      target: ".reports_step_0",
      content: "reports_step_0",
      placement: "auto",
      disableBeacon: true,
    },
  ],
};

// dataSetList: [
//   {
//     target: ".dataSetList-1-step",
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         You can add new dataset by pressing this button .
//         <br />
//       </div>
//     ),
//     disableBeacon: true,
//   },
//   {
//     target: ".dataSetList-2-step",
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         by pressing this tab you can go to dataset dashboard
//         <br />
//       </div>
//     ),
//   },
// ],
// header: [
//   {
//     target: ".header-1-step",
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         {t("header_1_step")}
//         <br />
//       </div>
//     ),
//     disableBeacon: true,
//     // styles: {
//     //   options: {
//     //     beaconSize: 0,
//     //     spotlightShadow: "0 0 0 rgba(0, 0, 0, 0)",
//     //     overlayColor: "rgba(79, 26, 0, 0)",
//     //     arrowColor: "transparent",
//     //   },
//     // },
//   },
//   {
//     target: ".header-2-step",
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span>{t("header_2_step")}</span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".header-3-step",
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         {t("header_3_step")}
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".header-4-step",
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         {t("header_4_step")}

//         <br />
//       </div>
//     ),
//   },
// ],
// dataSource: [
//   {
//     target: ".dataSource-1-step",
//     disableBeacon: true,
//     offset: 20,
//     action: (node) => {
//       // Scroll to the target element using a smooth scroll animation
//       const targetElement = document.querySelector(".dataSource-1-step");
//       window.scrollTo({
//         top: targetElement.offsetTop,
//         behavior: "smooth",
//       });

//       // Focus the target element after scrolling
//       targetElement.focus();
//     },
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             Hi 👋🏼
//           </span>
//           <span>
//             <Trans
//               i18nkey="dataSource_1_step"
//               values={{ title: "finban!" }}
//               // components={components}
//             >
//               {/* Welcome to <strong>finban!</strong> I'm Finbot I'll help you
//               to understand the system and set it up correctly. Shall we get
//               started? Then click [Next]. */}
//               {t("dataSource_1_step")}
//             </Trans>
//           </span>
//         </div>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dataSource-1-step",
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />{" "}
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span>{t("dataSource_2_step_1")}</span>
//           <b style={{ marginBlock: "1rem" }}>{t("dataSource_2_step_2")}</b>
//           <span>{t("dataSource_2_step_3")} </span>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {t("dataSource_2_step_4")}
//           </span>
//         </div>
//         <br />
//       </div>
//     ),
//   },
// ],
// dashboard_overview: [
//   {
//     target: ".dashboard_overview_1_step",
//     disableBeacon: true,
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />

//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {" "}
//           {t("dashboard_overview_1_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_1_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {t("dashboard_overview_2_step_1")}
//           </span>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {t("dashboard_overview_2_step_2")}
//           </span>
//         </div>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_2_1_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_2_1_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_3_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_3_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_3_step_after",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_3_step_after")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_4_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_4_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_5_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_5_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_6_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: 650,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {t("dashboard_overview_6_step_1")}
//           </span>
//           <span style={{ fontSize: "1rem", marginBlock: "0.25rem" }}>
//             <b>{t("Dashboard")}: </b>
//             {t("dashboard_overview_6_step_2")}
//           </span>
//           <span style={{ fontSize: "1rem", marginBlock: "0.25rem" }}>
//             <b>{t("Kanban")}: </b>
//             {t("dashboard_overview_6_step_3")}
//           </span>
//           <span style={{ fontSize: "1rem", marginBlock: "0.25rem" }}>
//             <b>{t("Table")}: </b>
//             {t("dashboard_overview_6_step_4")}
//           </span>
//           <span style={{ fontSize: "1rem", marginBlock: "0.25rem" }}>
//             <b>{t("List")}: </b>
//             {t("dashboard_overview_6_step_5")}
//           </span>
//         </div>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_7_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_7_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_8_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_8_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_9_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_9_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_10_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_10_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".dashboard_overview_11_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {t("dashboard_overview_11_step")}
//         </span>
//         <br />
//       </div>
//     ),
//   },
// ],
// list: [
//   {
//     target: ".list_1_step",
//     disableBeacon: true,
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />

//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {" "}
//           <Trans
//             i18nkey="list_1_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_1_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".list_2_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {" "}
//             <Trans
//               i18nkey="list_2_step"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("list_2_step")}
//             </Trans>
//           </span>
//         </div>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".list_3_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {" "}
//           <Trans
//             i18nkey="list_3_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_3_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".list_4_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="list_4_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_4_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".list_5_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="list_5_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_5_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".list_6_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="list_6_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_6_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".list_7_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="list_7_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_7_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".list_8_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="list_8_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_8_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".list_9_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="list_9_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_9_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },

//   {
//     target: ".list_10_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="list_10_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_10_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".list_11_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: width,
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="list_11_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("list_11_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
// ],
// kanban: [
//   {
//     target: ".kanban_1_step",
//     placement: "center",
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     disableBeacon: true,
//     content: (
//       <div
//         style={{ display: "flex", flexDirection: "column", width: "40vw" }}
//       >
//         <div style={{ display: "flex", flexDirection: "row" }}>
//           <AsyncImage
//             imageKey="onboarding_avatar"
//             alt="Finbot"
//             style={{
//               width: robotWidth,
//               height: robotHeight,
//               marginRight: "1rem",
//             }}
//           />
//           <div style={{ display: "flex", flexDirection: "column" }}>
//             <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//               <Trans
//                 i18nkey="kanban_1_step"
//                 // values={{ title: "finban!" }}
//                 components={components}
//               >
//                 {t("kanban_1_step")}
//               </Trans>
//             </span>
//             <div style={{ width: "40rem", marginLeft: "4rem" }}>
//               <AsyncImage
//                 imageKey="sheet_to_kanban"
//                 alt="Sheet to Kanban conversion"
//                 style={{ width: "100%", height: "100%" }}
//               />
//             </div>
//             <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//               {" "}
//               <Trans
//                 i18nkey="kanban_1_step_2"
//                 values={{ space: <> {"      "}</> }}
//                 components={components}
//               >
//                 {t("kanban_1_step_2")}
//               </Trans>
//             </span>
//           </div>
//         </div>

//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_3_step_before",
//     disableBeacon: true,
//     placement: "right",
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "row",
//           width: "30vw",
//         }}
//       >
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {" "}
//           <Trans
//             i18nkey="kanban_3_step_before"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_3_step_before")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_2_step",
//     disableBeacon: true,
//     placement: "right",
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {" "}
//             <Trans
//               i18nkey="kanban_2_step"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_2_step")}
//             </Trans>
//           </span>
//         </div>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_2_step_grouped",
//     disableBeacon: true,
//     placement: "right",
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             <Trans
//               i18nkey="kanban_2_step_grouped_1"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_2_step_grouped_1")}
//             </Trans>
//           </span>
//           <div style={{ width: "40rem", marginLeft: "4rem" }}>
//             <AsyncImage
//               imageKey="group_card_explanation"
//               alt="Group card explanation"
//               style={{ width: "100%", height: "100%" }}
//             />
//           </div>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             <Trans
//               i18nkey="kanban_2_step_grouped_2"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_2_step_grouped_2")}
//             </Trans>
//           </span>
//         </div>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_2_step_single",
//     disableBeacon: true,
//     placement: "right",
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginTop: "1rem" }}>
//             {" "}
//             <Trans
//               i18nkey="kanban_2_step_single_1"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_2_step_single_1")}
//             </Trans>
//           </span>
//           <div style={{ width: "40rem", marginLeft: "4rem" }}>
//             <AsyncImage
//               imageKey="single_card_explanation"
//               alt="Single card explanation"
//               style={{ width: "100%", height: "100%" }}
//             />
//           </div>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {" "}
//             <Trans
//               i18nkey="kanban_2_step_single_2"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_2_step_single_2")}
//             </Trans>
//           </span>
//         </div>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_3_step",
//     disableBeacon: true,
//     placement: "right",
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           {" "}
//           <Trans
//             i18nkey="kanban_3_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_3_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_4_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="kanban_4_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_4_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_9_step_before",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="kanban_9_step_before"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_9_step_before")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_9_step_after",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="kanban_9_step_after"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_9_step_after")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_9_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="kanban_9_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_9_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_10_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="kanban_10_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_10_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_5_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="kanban_5_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_5_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_6_step_2",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             <Trans
//               i18nkey="kanban_6_step_2"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_6_step_2")}
//             </Trans>
//           </span>
//           <div style={{ width: "40rem", marginLeft: "4rem" }}>
//             <AsyncImage
//               imageKey="group_state"
//               alt="Group state visualization"
//               style={{ width: "100%", height: "100%" }}
//             />
//           </div>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {" "}
//             <Trans
//               i18nkey="kanban_6_step_2_2"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_6_step_2_2")}
//             </Trans>
//           </span>
//         </div>

//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_6_step_1",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             <Trans
//               i18nkey="kanban_6_step_1"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_6_step_1")}
//             </Trans>
//           </span>
//           <div style={{ width: "40rem", marginLeft: "4rem" }}>
//             <AsyncImage
//               imageKey="categories_group"
//               alt="Categories group visualization"
//               style={{ width: "100%", height: "100%" }}
//             />
//           </div>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {" "}
//             <Trans
//               i18nkey="kanban_6_step_1_2"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_6_step_1_2")}
//             </Trans>
//           </span>
//         </div>

//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_6_step_3",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="kanban_6_step_3"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_6_step_3")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_7_step",
//     disableBeacon: true,
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="kanban_7_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_7_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_8_step",
//     disableBeacon: true,
//     placement: "right-start",
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "40vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />

//         <div style={{ display: "flex", flexDirection: "column" }}>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             <Trans
//               i18nkey="kanban_8_step"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_8_step")}
//             </Trans>
//           </span>
//           <div style={{ width: "40rem", marginLeft: "4rem" }}>
//             <AsyncImage
//               imageKey="chart_and_states"
//               alt="Chart and States visualization"
//               style={{ width: "100%", height: "100%" }}
//             />
//           </div>
//           <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//             {" "}
//             <Trans
//               i18nkey="kanban_8_step_2"
//               // values={{ title: "finban!" }}
//               components={components}
//             >
//               {t("kanban_8_step_2")}
//             </Trans>
//           </span>
//         </div>

//         <br />
//       </div>
//     ),
//   },
//   {
//     target: ".kanban_11_step",
//     disableBeacon: true,
//     placement: "left",
//     styles: {
//       options: {
//         width: "fit-content",
//       },
//     },
//     content: (
//       <div style={{ display: "flex", flexDirection: "row", width: "35vw" }}>
//         <AsyncImage
//           imageKey="onboarding_avatar"
//           alt="Finbot"
//           style={{
//             width: robotWidth,
//             height: robotHeight,
//             marginRight: "1rem",
//           }}
//         />
//         <span style={{ fontSize: "1rem", marginBlock: "1rem" }}>
//           <Trans
//             i18nkey="kanban_11_step"
//             // values={{ title: "finban!" }}
//             components={components}
//           >
//             {t("kanban_11_step")}
//           </Trans>
//         </span>
//         <br />
//       </div>
//     ),
//   },
// ],
