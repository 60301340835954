import React from "react";
import {
  circularProgressClasses,
  CircularProgress,
  Typography,
  Skeleton,
  Stack,
  alpha,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { addMonths, format } from "date-fns";
import { useContext } from "react";

import { setTransactionsOverlayStatus } from "../store/slices/datasets";
import { GlobalContext } from "../GlobalContextWrapper";
import Translate from "../hooks/HOC/Translate";
import ActionButton from "./ActionButton";
import AsyncImage from "./AsyncImage";
import { Color } from "../Helper";

export default function ComponentLoader({
  height = "20rem",
  placeHolderWidth = "30%",
  placeHolderHeight = "6rem",
  hideNoDataPlaceholder = false,
  loading = false,
  isSkeleton = false,
  showActionButton = false,
  showBankButton = false,
  showOpenButton = false,
  showBudgetButton = false,
  actionTitle = "Reset Filters",
  onClickAction = () => {},
  backgroundColor = Color.tailwind.slate[50],
  skeletonCount = 6,
  skeltonSx = {},
  placeHolderSx = {},
  key1,
  key2,
  size = 24,
  sx,
  key1sx,
  key2sx,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const dsRef = globalContext?.dsRef;

  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);

  let _showActionButton = showActionButton;
  let _showBankButton = showBankButton;
  let _showOpenButton = showOpenButton;
  let _showBudgetButton = showBudgetButton;

  //api
  const onClickAddBank = () => {
    dsRef?.current?.openIntegrationOverlay("add");
  };

  const onClickAddOpen = () => {
    const item = {
      title: "",
      note: "",
      source: 1,
      invoice_date: null,
      category: null,
      tax: null,
      recurring_rule: null,
      scenario: "Base",
      state: "Open",
      dataset: dataSetData.uuid,
      due_date: format(new Date(), "yyyy-MM-dd"),
      data_source: dataSetData?.internal_data_source,
      gross_value: "0.00",
      contact: null,
      income_expense_type: 1,
    };
    dispatch(
      setTransactionsOverlayStatus({
        open: true,
        overlay_type: "drawer_modal_edit_form",
        anchor: "right",
        payload: {
          modalType: "add",
          item,
        },
      })
    );
  };

  const onClickAddBudget = () => {
    const item = {
      title: "",
      note: "",
      source: 1,
      invoice_date: null,
      category: null,
      tax: null,
      recurring_rule: null,
      scenario: "Base",
      state: "Planned",
      dataset: dataSetData.uuid,
      due_date: format(new Date(), "yyyy-MM-dd"),
      data_source: dataSetData?.internal_data_source,
      gross_value: "0.00",
      contact: null,
      income_expense_type: 1,
    };

    const recurringItem = {
      name: "",
      recurring_type: "simple",
      period: 12,
      repetition: 1,
      start_date: format(new Date(), "yyyy-MM-dd"),
      end_date: format(addMonths(new Date(), 12), "yyyy-MM-dd"),
      tax: null,
      value: "0.00",
    };
    dispatch(
      setTransactionsOverlayStatus({
        open: true,
        overlay_type: "drawer_modal_edit_form",
        anchor: "right",
        payload: {
          modalType: "add",
          item,
          recurringItem,
        },
      })
    );
  };

  return (
    <Box
      sx={{
        height,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
    >
      {loading ? (
        isSkeleton ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            {Array.from({ length: skeletonCount }).map((_, index) => (
              <Skeleton
                key={index}
                width={"100%"}
                height={placeHolderHeight}
                sx={skeltonSx}
              />
            ))}
          </div>
        ) : (
          <SpinLoader size={size} theme={theme} />
        )
      ) : null}
      {!loading && !hideNoDataPlaceholder ? (
        <div
          style={{
            display: "flex",
            alignItems: "start",
            padding: "1.5rem",
            backgroundColor: alpha(
              backgroundColor || theme.palette.color.slate[50],
              0.5
            ),
            ...placeHolderSx,
          }}
        >
          <AsyncImage
            component="img"
            imageKey="empty_placeholder"
            style={{
              width: placeHolderWidth,
              objectFit: "contain",
            }}
            alt="Empty Placeholder"
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginInline: "1rem",
            }}
          >
            {key1 ? (
              <Typography
                variant="div"
                color="color.mainTitle"
                sx={{
                  fontSize: "1.4rem",
                  textAlign: "left",
                  mt: "0.5rem",
                  overflow: "hidden",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  ...key1sx,
                }}
              >
                <Translate i18nkey={key1} />
              </Typography>
            ) : null}
            {key2 ? (
              <Typography
                variant="div"
                color="color.description"
                sx={{
                  mt: key1 ? "1rem" : 0,
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  textAlign: "left",
                  wordBreak: "break-word",
                  whiteSpace: "normal",
                  ...key2sx,
                }}
              >
                <Translate i18nkey={key2} />
              </Typography>
            ) : null}

            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ width: "100%", mt: "2rem", ml: "-0.5rem" }}
            >
              {_showActionButton ? (
                <ActionButton
                  buttontype="manage2"
                  onClick={onClickAction}
                  label={t(actionTitle)}
                />
              ) : null}
              {_showBankButton ? (
                <ActionButton
                  buttontype="manage2"
                  onClick={onClickAddBank}
                  label={t("Add new Integration")}
                  sx={{ ml: "0.5rem" }}
                />
              ) : null}
              {_showOpenButton ? (
                <ActionButton
                  buttontype="manage2"
                  onClick={onClickAddOpen}
                  label={t("Add Open position")}
                  sx={{ ml: "0.5rem" }}
                />
              ) : null}
              {_showBudgetButton ? (
                <ActionButton
                  buttontype="manage2"
                  onClick={onClickAddBudget}
                  label={t("Add Budget")}
                  sx={{ ml: "0.5rem" }}
                />
              ) : null}
            </Stack>
          </div>
        </div>
      ) : null}
    </Box>
  );
}

export const SpinLoader = ({ size = 24, theme, sx }) => {
  return (
    <>
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme.palette.color.slate[200],
        }}
        size={size}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: theme.palette.primary.main,
          animationDuration: "550ms",
          position: "absolute",
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          ...sx,
        }}
        size={size}
        thickness={4}
      />
    </>
  );
};
