import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { Box, Tooltip, useTheme } from "@mui/material";
import Popover from "@mui/material/Popover";
import * as React from "react";

const CustomPopover = React.forwardRef((props, ref) => {
  let {
    children,
    showRight = false,
    anchorEl = null,
    disabled,
    onClose,
    onClick,
    onDoubleClick,
    tooltip = "",
    type = "default",
    height = "fit-content",
    width = "fit-content",
    sx = {},
    popupProps = {},
    PaperPropsSx = {},
    BaseButtonSx = {},
    BaseButton = <span></span>,
  } = props;
  const theme = useTheme();
  const payload = React.useRef({
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    transformOrigin: { vertical: "top", horizontal: "right" },
  });
  const overlayHeight = React.useRef(0);

  const triggerRef = React.useRef(null);
  const overlayRef = React.useRef(null);

  const [refresh, setRefresh] = React.useState(false);

  const calculatePosition = React.useCallback(
    (e, popupState) => {
      if (triggerRef.current && !disabled) {
        const rect = triggerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        // Safety margin (pixels from edge of screen)
        const safetyMargin = 8;

        // Calculate available space in all directions
        const spaceBelow = windowHeight - rect.bottom - safetyMargin;
        const spaceAbove = rect.top - safetyMargin;
        const spaceRight = windowWidth - rect.right - safetyMargin;
        const spaceLeft = rect.left - safetyMargin;

        // Get overlay height if available
        let currentOverlayHeight = 0;
        if (overlayRef?.current) {
          currentOverlayHeight =
            overlayRef?.current?.getBoundingClientRect().height;
        }

        // Determine vertical position - prefer direction with most space
        // Consider the actual height of the overlay when deciding
        const vertical =
          spaceBelow >= Math.max(currentOverlayHeight, spaceAbove)
            ? "bottom"
            : "top";

        // Determine horizontal position - prefer direction with most space
        const horizontal =
          spaceRight >= spaceLeft || showRight ? "right" : "left";

        payload.current = {
          anchorOrigin: {
            vertical,
            horizontal: horizontal === "left" ? "right" : "left",
          },
          transformOrigin: {
            vertical: vertical === "top" ? "bottom" : "top",
            horizontal: horizontal === "left" ? "right" : "left",
          },
        };

        if (currentOverlayHeight !== overlayHeight?.current) {
          overlayHeight.current = currentOverlayHeight;
          if (popupState) {
            popupState?.open(e.currentTarget);
          } else {
            setRefresh((prev) => !prev);
          }
        }
      }
    },
    [disabled, showRight]
  );

  const onClosePopup = React.useCallback((e) => {
    overlayHeight.current = 0;

    if (onClose) {
      onClose(e);
    }
  }, []);

  if (type === "anchor") {
    return (
      <span ref={triggerRef}>
        <Tooltip title={tooltip} disableFocusListener placement="top">
          <Box
            // ref={triggerRef}
            ref={ref}
            disabled={disabled}
            onClick={!disabled && onClick ? onClick : undefined}
            onDoubleClick={onDoubleClick}
            sx={BaseButtonSx}
          >
            {BaseButton}
          </Box>
        </Tooltip>

        <Popover
          id={anchorEl ? "simple-popover" : undefined}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClosePopup}
          anchorOrigin={payload.current.anchorOrigin}
          transformOrigin={payload.current.transformOrigin}
          container={document.body}
          role="dialog"
          aria-hidden="false"
          aria-label="Popover Dialog"
          slotProps={{
            paper: {
              sx: {
                mt: "0.5rem",
                p: "0.3rem",
                mx: 0,
                boxShadow: theme.boxShadow,
                borderRadius: theme.borderRadius.borderRadiusXL2,
                backgroundColor: theme.palette.color.overlayBorderShade,
                backdropFilter: "blur(2px)",
                overflow: "visible",
                ...PaperPropsSx,
              },
            },
          }}
          {...popupProps}
          sx={sx}
          onMouseMove={calculatePosition}
        >
          <div
            ref={overlayRef}
            style={{
              position: "relative",
              borderRadius: theme.borderRadius.borderRadiusXL,
              outline: `2px solid ${theme.palette.color.grey[300]}`,
              height,
              width,
              overflow: "visible",
            }}
          >
            {children}
          </div>
        </Popover>
      </span>
    );
  } else {
    return (
      <PopupState
        variant="popper"
        ref={ref}
        id="demo-mutiple-checkbox"
        popupId="demo-popup-popover"
      >
        {(popupState) => {
          return (
            <span ref={ref}>
              <Box
                ref={triggerRef}
                {...bindTrigger(popupState)}
                onClick={
                  disabled ? undefined : (e) => calculatePosition(e, popupState)
                }
                onDoubleClick={onDoubleClick}
                sx={BaseButtonSx}
              >
                {BaseButton}
              </Box>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={payload.current.anchorOrigin}
                transformOrigin={payload.current.transformOrigin}
                container={document.body}
                role="dialog"
                aria-hidden="false"
                aria-label="Popover Dialog"
                slotProps={{
                  paper: {
                    sx: {
                      mt: "0.5rem",
                      p: "0.3rem",
                      mx: 0,
                      boxShadow: theme.boxShadow,
                      borderRadius: theme.borderRadius.borderRadiusXL2,
                      backgroundColor: theme.palette.color.overlayBorderShade,
                      overflow: "visible",
                      backdropFilter: "blur(2px)",
                      ...PaperPropsSx,
                    },
                  },
                }}
                {...popupProps}
                sx={sx}
                onMouseMove={calculatePosition}
              >
                <div
                  ref={overlayRef}
                  style={{
                    position: "relative",
                    borderRadius: theme.borderRadius.borderRadiusXL,
                    outline: `2px solid ${theme.palette.color.grey[300]}`,
                    height,
                    width,
                    overflow: "visible",
                  }}
                >
                  {children}
                </div>
              </Popover>
            </span>
          );
        }}
      </PopupState>
    );
  }
});
export default CustomPopover;
