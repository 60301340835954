import React from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { Box, Collapse, Tooltip, Typography } from "@mui/material";
import { TbChartAreaLineFilled } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useTheme } from '@mui/material/styles';
import { useState } from "react";

import ScenarioChip from "../ScenarioChip";
import ToggleButton from "./ToggleButton";
import { Constant } from "../../Helper";
import Icon from "../Icon";

const ScenarioView = ({
  cardItem,
  onClick,
  width = "36.35rem",
  collapsedSize = "2.3rem",
  chipWidth = "11rem",
  chipHeight = "2.2rem",
}) => {
  const theme = useTheme();
  const scenario = useSelector((state) => state.globalSlice?.scenario);
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClickBtn = () => {
    setIsExpanded((prev) => !prev);
  };

  const onClickScenario = (item) => {
    setIsExpanded(false);
    if (onClick) onClick(item);
  };

  const handleClickInfo = () => {
    var win = window.open(
      "https://finban.io/documentation/planung-mit-szenarien/",
      "_blank"
    );
    win.focus();
  };

  const onClickItem = () => {
    setIsExpanded(true);
  };
  
  return (
    <>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          pr: "1.5rem",
          mt: "1rem",
        }}
      >
        <Icon
          icon={<TbChartAreaLineFilled />}
          fontSize={"1.3rem"}
          color={theme.palette.color.blueGrey[700]}
          style={{
            marginRight: "1.7rem",
            marginLeft: "0.2rem",
          }}
        ></Icon>

        <Typography
          variant="h6"
          component={"h6"}
          fontSize={"0.9rem"}
          color="color.mainTitle"
          fontWeight={"fontWeightMediumBold"}
        >
          {t("Scenario")}
        </Typography>
        <Tooltip
          title={t("Click here to access our guide")}
          placement="top"
           
        >
          <HelpOutlineOutlinedIcon
            onClick={handleClickInfo}
            sx={{
              fontSize: "1rem",
              cursor: "pointer",
              ml: "0.5rem",
              color: theme.palette.primary.main,
            }}
          />
        </Tooltip>
      </Box>

      <Box
        sx={{
          ml: "2.1rem",
          display: "flex",
          height: "max-content",
          flexDirection: "column",
          backgroundColor: isExpanded
            ? theme.palette.color.slate[50]
            : "transparent",
          borderRadius: theme.borderRadius.main,
          border: `2px dashed ${isExpanded ? theme.palette.color.slate[200] : "transparent"}`,
          p: "1rem",
          width,
        }}
      >
        <Collapse
          orientation="vertical"
          in={isExpanded}
          collapsedSize={collapsedSize}
        >
          <Box
            sx={{
              gap: "0.5rem",
              display: "flex",
              flexWrap: "wrap",
              height: "max-content",
            }}
          >
            {!isExpanded ? (
              <ScenarioChip
                title={cardItem?.scenario}
                width={chipWidth}
                height={chipHeight}
                isSelected
                onClick={onClickItem}
              />
            ) : (
              scenario?.map((item) => {
                return (
                  <ScenarioChip
                    key={item?.uuid}
                    title={item?.title}
                    width={chipWidth}
                    height={chipHeight}
                    isSelected={cardItem?.scenario === item?.title}
                    onClick={onClickScenario}
                    disabled={
                      Constant?.bookedPositionsStates.includes(cardItem.state) &&
                      item?.title !== "Base"
                    }
                  />
                );
              })
            )}
          </Box>
        </Collapse>
        <ToggleButton
          isExpanded={isExpanded}
          onClick={handleClickBtn}
          style={{
            mt: "0.5rem",
          }}
        />
      </Box>
    </>
  );
};

export default ScenarioView;
