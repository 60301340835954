import {
  eachQuarterOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachYearOfInterval,
  eachDayOfInterval,
  getDaysInMonth,
  startOfQuarter,
  isSameQuarter,
  startOfMonth,
  endOfQuarter,
  startOfYear,
  startOfWeek,
  addQuarters,
  isSameMonth,
  endOfMonth,
  startOfDay,
  isSameWeek,
  isSameYear,
  endOfYear,
  subMonths,
  endOfWeek,
  addMonths,
  subYears,
  endOfDay,
  subWeeks,
  addWeeks,
  addYears,
  getYear,
  subDays,
  isValid,
  addDays,
  format,
  set,
} from "date-fns";
import {
  ButtonGroup,
  Typography,
  useTheme,
  Divider,
  Stack,
  Box,
} from "@mui/material";
import { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { MdOutlineDateRange } from "react-icons/md";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { useTranslation } from "react-i18next";
import Button from "@mui/lab/LoadingButton";
import { enqueueSnackbar } from "notistack";
import { keyframes } from "@emotion/react";
import React from "react";

import {
  formatDateInQuarter,
  formatDateToLocal,
  formatDateInWeek,
} from "../../../../Helper/data";
import useDebounce from "../../../../hooks/3-useDebounce/useDebounce";
import FilterBaseView from "../FilterBaseView";
import CustomPopover from "../../../PopOver";

const daysHeaderOptions = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const PRESETS = [
  "Today",
  "Yesterday",
  "This Week",
  "Last Week",
  "This Month",
  "Last Month",
  "Last 3 Months",
  "Last 6 Months",
  "Last 12 Months",
  "This Quarter",
  "Last Quarter",
  "This Year",
  "Last Year",
];
const formatString = "dd MMM yy";

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideAnimation = keyframes`
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const DateRangePicker = ({
  start_date = "",
  end_date = "",
  Placeholder = "Select Date Range",
  RangeType = ["Monthly", "Daily"],
  options = {
    maxRange: null,
    minRange: null,
    type: null,
  },
  showPreset,
  onChangeDate,
  onClickReset,
  onOk,
  autoClose = true,
  showClear,
  hideHeading,
  defaultStart = startOfYear(new Date()),
  defaultEnd = endOfYear(new Date()),
  headerView,
  baseSx,
  tooltip = "tooltip_Date_Range",
  hideIcon,
}) => {
  const [anchorElDate, setAnchorElDate] = useState(null);
  const onCloseDatePopOver = (e) => {
    e?.stopPropagation();
    setAnchorElDate(null);
  };

  const onClickDatePopOver = (e) => {
    e?.stopPropagation();
    setAnchorElDate(e.currentTarget);
  };

  const _startDate = isValid(new Date(start_date))
    ? RangeType?.includes("Weekly")
      ? formatDateInWeek(new Date(start_date))
      : RangeType?.includes("Quarterly")
      ? formatDateInQuarter(new Date(start_date))
      : formatDateToLocal(new Date(start_date), formatString)
    : "";
  const _endDate = isValid(new Date(end_date))
    ? RangeType?.includes("Weekly")
      ? formatDateInWeek(new Date(end_date))
      : RangeType?.includes("Quarterly")
      ? formatDateInQuarter(new Date(end_date))
      : formatDateToLocal(new Date(end_date), formatString)
    : "";

  return (
    <CustomPopover
      type="anchor"
      anchorEl={anchorElDate}
      onClose={onCloseDatePopOver}
      showRight
      BaseButton={
        <FilterBaseView
          icon={
            hideIcon ? null : (
              <MdOutlineDateRange
                style={{ fontSize: "1.15rem", marginTop: "1px" }}
              />
            )
          }
          heading={hideHeading ? null : "Date_Range"}
          tooltip={tooltip}
          Placeholder={Placeholder}
          maxWidth="fit-content"
          // isActive={isActive}
          isActive={false}
          value={_startDate || _endDate ? `${_startDate} ~ ${_endDate}` : null}
          onClick={onClickDatePopOver}
          onClear={onClickReset}
          baseSx={baseSx}
        />
      }
    >
      <DateView
        start_date={start_date || defaultStart}
        end_date={end_date || defaultEnd}
        onChangeDate={onChangeDate}
        RangeType={RangeType}
        onOk={onOk}
        autoClose={autoClose}
        showClear={showClear}
        headerView={headerView}
        showPreset={showPreset}
        options={options}
        onCloseDatePopOver={onCloseDatePopOver}
      />
    </CustomPopover>
  );
};

export default DateRangePicker;

const DateView = ({
  onCloseDatePopOver,
  start_date,
  end_date,
  onChangeDate,
  onOk,
  RangeType,
  showPreset,
  autoClose,
  showClear,
  headerView,
  options,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [viewDate, setViewDate] = useState({
    start_date: start_date,
    end_date: end_date,
  });
  const [selectedDate, setSelectedDate] = useState({
    start_date: start_date,
    end_date: end_date,
  });

  const _selectedDate = {
    start_date: selectedDate?.start_date
      ? format(new Date(selectedDate.start_date), "yyyy-MM-dd")
      : null,
    end_date: selectedDate?.end_date
      ? format(new Date(selectedDate.end_date), "yyyy-MM-dd")
      : null,
  };

  useDebounce(
    () => {
      if (onChangeDate) {
        onChangeDate(_selectedDate);
      }
    },
    500,
    [selectedDate]
  );

  const onClickOk = (e) => {
    e?.stopPropagation();
    if (onOk) {
      setLoading(true);
      if (_selectedDate.start_date > _selectedDate.end_date) {
        enqueueSnackbar(
          t("date_range_selector_error_end_date_before_start_date"),
          {
            variant: "error",
            preventDuplicate: true,
          }
        );
        setLoading(false);
        return;
      }
      onOk(_selectedDate, onCloseDatePopOver);
      if (autoClose) {
        onCloseDatePopOver();
      }
    }
  };

  const onClickReset = (e) => {
    e?.stopPropagation();
    if (onOk) {
      const obj = {
        start_date: null,
        end_date: null,
      };
      setSelectedDate(obj);
      onOk(obj, onCloseDatePopOver);
      if (autoClose) {
        onCloseDatePopOver();
      }
    }
  };

  return (
    <Box
      onClick={(e) => e?.stopPropagation()}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.color.white,
        animation: `${slideAnimation} 0.3s ease`,
        // padding: "0.5rem",
      }}
    >
      <div style={{ display: "flex" }}>
        {showPreset ? (
          <PresetView
            setSelectedDate={setSelectedDate}
            setViewDate={setViewDate}
          />
        ) : null}
        <Stack spacing={1}>
          {RangeType?.includes("Monthly") && (
            <OptionsWrapperView
              t={t}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              viewDate={viewDate}
              setViewDate={setViewDate}
              theme={theme}
              defaultViewType={"Monthly"}
              commonProps={{ options }}
            />
          )}
          {RangeType?.includes("Quarterly") && (
            <OptionsWrapperView
              t={t}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              viewDate={viewDate}
              setViewDate={setViewDate}
              theme={theme}
              defaultViewType={"Quarterly"}
              commonProps={{ options }}
            />
          )}
          {RangeType?.includes("Weekly") && (
            <OptionsWrapperView
              t={t}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              viewDate={viewDate}
              setViewDate={setViewDate}
              theme={theme}
              defaultViewType={"Weekly"}
              commonProps={{ hideYear: true, options }}
            />
          )}
          {RangeType?.includes("Daily") && (
            <OptionsWrapperView
              t={t}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              viewDate={viewDate}
              setViewDate={setViewDate}
              theme={theme}
              defaultViewType={"Daily"}
              commonProps={{ hideYear: true, options }}
            />
          )}
        </Stack>
      </div>

      {selectedDate?.start_date && selectedDate?.end_date && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0.75rem",
            m: "1rem",
            mb: "0.5rem",
            backgroundColor: theme.palette.color.primary[50],
            borderRadius: "8px",
            border: `1px solid ${theme.palette.color.primary[200]}`,
            animation: `${fadeInAnimation} 0.3s ease`,
            boxShadow: `0 2px 8px ${theme.palette.color.primary[100]}`,
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.9rem",
              fontWeight: 700,
              color: theme.palette.color.primary[700],
            }}
          >
            {isValid(new Date(selectedDate.start_date)) &&
            isValid(new Date(selectedDate.end_date))
              ? `${formatDateToLocal(
                  new Date(selectedDate.start_date),
                  formatString
                )} ~ ${formatDateToLocal(
                  new Date(selectedDate.end_date),
                  formatString
                )}`
              : ""}
          </Typography>
        </Box>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "auto",
        }}
      >
        {headerView || <span></span>}
        {showClear ? (
          <Button
            onClick={onClickReset}
            sx={{
              width: "fit-content",
              height: "2rem",
              my: "0.8rem",
              mr: "1rem",
              color: theme.palette.color.slate[700],
              backgroundColor: theme.palette.color.slate[100],
              fontSize: "1rem",
              fontWeight: 600,
              lineHeight: "normal",
              padding: "0.5rem 1rem",
              textTransform: "initial",
              transition: "all 0.2s ease",
              "&:hover": {
                backgroundColor: theme.palette.color.slate[200],
                transform: "translateY(-2px)",
              },
            }}
          >
            {t("Reset Filter")}
          </Button>
        ) : null}
        <Button
          loading={loading}
          onClick={onClickOk}
          sx={{
            width: "fit-content",
            my: "1rem",
            mr: "1rem",
            color: theme.palette.color.white,
            backgroundColor: theme.palette.color.slate[700],
            fontSize: "0.9rem",
            fontWeight: 600,
            lineHeight: "normal",
            padding: "0.5rem 1rem",
            textTransform: "initial",
            transition: "all 0.2s ease",
            minHeight: "2rem",
            "& .MuiLoadingButton-loadingIndicator": {
              color: theme.palette.color.white,
            },
            "&:hover": {
              backgroundColor: theme.palette.color.slate[800],
              transform: "translateY(-2px)",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            },
          }}
        >
          {loading ? "" : t("Apply")}
        </Button>
      </div>
    </Box>
  );
};

const PresetView = ({ setSelectedDate, setViewDate }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activePreset, setActivePreset] = useState(null);

  const handleRangeSelection = (range) => {
    const today = new Date();
    let startDate, endDate;

    if (range === "Today") {
      startDate = format(startOfDay(today), "yyyy-MM-dd");
      endDate = format(endOfDay(today), "yyyy-MM-dd");
    } else if (range === "Yesterday") {
      startDate = format(subDays(today, 1), "yyyy-MM-dd");
      endDate = format(subDays(today, 1), "yyyy-MM-dd");
    } else if (range === "This Week") {
      startDate = format(
        startOfWeek(today, {
          weekStartsOn: 1,
        }),
        "yyyy-MM-dd"
      );
      endDate = format(
        endOfWeek(today, {
          weekStartsOn: 1,
        }),
        "yyyy-MM-dd"
      );
    } else if (range === "Last Week") {
      startDate = format(
        startOfWeek(subWeeks(today, 1), {
          weekStartsOn: 1,
        }),
        "yyyy-MM-dd"
      );
      endDate = format(
        endOfWeek(subWeeks(today, 1), {
          weekStartsOn: 1,
        }),
        "yyyy-MM-dd"
      );
    } else if (range === "This Month") {
      startDate = format(startOfMonth(today), "yyyy-MM-dd");
      endDate = format(endOfMonth(today), "yyyy-MM-dd");
    } else if (range === "Last Month") {
      startDate = format(startOfMonth(subMonths(today, 1)), "yyyy-MM-dd");
      endDate = format(endOfMonth(subMonths(today, 1)), "yyyy-MM-dd");
    } else if (range === "Last 3 Months") {
      startDate = format(startOfMonth(subMonths(today, 2)), "yyyy-MM-dd");
      endDate = format(endOfMonth(today), "yyyy-MM-dd");
    } else if (range === "Last 6 Months") {
      startDate = format(startOfMonth(subMonths(today, 5)), "yyyy-MM-dd");
      endDate = format(endOfMonth(today), "yyyy-MM-dd");
    } else if (range === "Last 12 Months") {
      startDate = format(startOfMonth(subMonths(today, 11)), "yyyy-MM-dd");
      endDate = format(endOfMonth(today), "yyyy-MM-dd");
    } else if (range === "This Quarter") {
      startDate = format(startOfQuarter(today), "yyyy-MM-dd");
      endDate = format(endOfQuarter(today), "yyyy-MM-dd");
    } else if (range === "Last Quarter") {
      startDate = format(startOfQuarter(subMonths(today, 3)), "yyyy-MM-dd");
      endDate = format(endOfQuarter(subMonths(today, 3)), "yyyy-MM-dd");
    } else if (range === "Last Year") {
      const lastYearDate = subYears(today, 1);
      startDate = format(startOfYear(lastYearDate), "yyyy-MM-dd");
      endDate = format(endOfYear(lastYearDate), "yyyy-MM-dd");
    } else if (range === "This Year") {
      startDate = format(startOfYear(today), "yyyy-MM-dd");
      endDate = format(endOfYear(today), "yyyy-MM-dd");
    }

    // Update selected date
    setSelectedDate({
      start_date: startDate,
      end_date: endDate,
    });

    // Update view date to ensure calendar shows the correct month/year
    setViewDate({
      start_date: startDate,
      end_date: endDate,
    });

    // Set active preset for visual feedback
    // setActivePreset(range);
  };

  return (
    <ButtonGroup
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: "1.5rem",
        ml: "0.8rem",
        animation: `${fadeInAnimation} 0.3s ease`,
        "& .MuiButton-root": {
          borderRadius: "8px !important",
          color: theme.palette.color.slate[700],
          fontWeight: 600,
          border: "1px solid transparent",
          transition: "all 0.2s ease",
          position: "relative",
          overflow: "hidden",
          "&:hover": {
            backgroundColor: theme.palette.primary[50],
            borderColor: theme.palette.primary[500],
            transform: "translateX(4px)",
            animation: `${pulseAnimation} 1s infinite`,
            boxShadow: `0 2px 4px ${theme.palette.primary[100]}`,
          },
          "&::before": {
            content: '""',
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "0",
            backgroundColor: theme.palette.primary[100],
            transition: "width 0.2s ease",
            zIndex: 0,
          },
          "&:hover::before": {
            width: "100%",
          },
          "& span": {
            position: "relative",
            zIndex: 1,
          },
        },
      }}
    >
      {PRESETS?.map((item) => (
        <Button
          key={item}
          onClick={() => handleRangeSelection(item)}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            backgroundColor:
              activePreset === item
                ? theme.palette.primary[100]
                : "transparent",
            borderLeft:
              activePreset === item
                ? `3px solid ${theme.palette.primary[600]}`
                : "1px solid transparent",
            fontWeight: activePreset === item ? 700 : 600,
          }}
        >
          <span>{t(item)}</span>
        </Button>
      ))}
    </ButtonGroup>
  );
};

const OptionsWrapperView = ({
  selectedDate,
  setSelectedDate,
  viewDate,
  setViewDate,
  theme,
  defaultViewType,
  commonProps,
  t,
}) => {
  return (
    <div
      style={{
        display: "flex",
        height: "fit-content",
        animation: `${fadeInAnimation} 0.3s ease`,
      }}
    >
      <OptionView
        t={t}
        date={viewDate?.start_date}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setViewDate={setViewDate}
        defaultViewType={defaultViewType}
        type="start_date"
        {...commonProps}
      />
      <Divider
        flexItem
        orientation="vertical"
        sx={{
          color: theme.palette.color.slate[700],
        }}
      />
      <OptionView
        t={t}
        date={viewDate?.end_date}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setViewDate={setViewDate}
        defaultViewType={defaultViewType}
        type="end_date"
        {...commonProps}
      />
    </div>
  );
};

const OptionView = ({
  date = new Date(),
  selectedDate,
  setSelectedDate,
  setViewDate,
  type,
  defaultViewType,
  hideYear,
  options,
  t,
}) => {
  const theme = useTheme();
  const yearListRef = useRef(null);

  //state
  const [viewType, setViewType] = useState(defaultViewType);

  //data
  const selectedYear = useMemo(() => {
    return getYear(new Date(date));
  }, [date]);

  // Scroll to selected year when view changes to Annually
  useEffect(() => {
    if (viewType === "Annually" && yearListRef.current) {
      const selectedYearElement = yearListRef.current.querySelector(
        `[data-year="${selectedYear}"]`
      );
      if (selectedYearElement) {
        yearListRef.current.scrollTop =
          selectedYearElement.offsetTop - yearListRef.current.offsetTop - 50;
      }
    }
  }, [viewType, selectedYear]);

  // Check if start and end dates are in the same period (month, week, etc.)
  const isSamePeriod = useMemo(() => {
    if (!selectedDate?.start_date || !selectedDate?.end_date) return false;

    const startDate = new Date(selectedDate.start_date);
    const endDate = new Date(selectedDate.end_date);

    if (viewType === "Daily") {
      return format(startDate, "yyyy-MM-dd") === format(endDate, "yyyy-MM-dd");
    } else if (viewType === "Weekly") {
      return isSameWeek(startDate, endDate, { weekStartsOn: 1 });
    } else if (viewType === "Monthly") {
      return isSameMonth(startDate, endDate);
    } else if (viewType === "Quarterly") {
      return isSameQuarter(startDate, endDate);
    } else if (viewType === "Annually") {
      return isSameYear(startDate, endDate);
    }
    return false;
  }, [selectedDate, viewType]);

  const previousMonthDays = useMemo(() => {
    if (viewType !== "Daily") return [];
    const _date = new Date(date);
    if (!isValid(_date)) return [];

    const month = _date.getMonth();
    const year = _date.getFullYear();
    // Get the first day of the current month
    const firstDayOfMonth = new Date(year, month, 1);
    const firstDayWeekday = firstDayOfMonth.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

    const adjustedWeekday = (firstDayWeekday + 7) % 7;

    // Get the previous month
    const previousMonth = month === 0 ? 11 : month - 1;
    const previousYear = month === 0 ? year - 1 : year;

    // Get the number of days in the previous month
    const daysInPreviousMonth = getDaysInMonth(
      new Date(previousYear, previousMonth)
    );

    // Calculate the dates we need from the previous month
    const previousMonthDays = Array.from(
      { length: adjustedWeekday },
      (_, i) => {
        return daysInPreviousMonth - adjustedWeekday + i + 1;
      }
    );

    return previousMonthDays;
  }, [date, viewType]);

  const nextMonthDays = useMemo(() => {
    if (viewType !== "Daily") return [];
    const _date = new Date(date);
    if (!isValid(_date)) return [];
    const month = _date.getMonth();
    const year = _date.getFullYear();
    // Get the last day of the current month
    const lastDayOfMonth = new Date(year, month + 1, 0); // Last day of the current month
    const lastDayWeekday = lastDayOfMonth.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6

    const adjustedWeekday = (lastDayWeekday + 7) % 7;

    // Number of days needed to fill the week (next month's days)
    const daysToAdd = 6 - adjustedWeekday;

    // Calculate the days for the next month (starting from 1)
    const nextMonthDays = Array.from({ length: daysToAdd }, (_, i) => i + 1);

    return nextMonthDays;
  }, [date, viewType]);

  const ListData = useMemo(() => {
    if (viewType === "Daily") {
      const daysInMonth = eachDayOfInterval({
        start: startOfMonth(new Date(date)),
        end: endOfMonth(new Date(date)),
      });

      return daysInMonth;
    }
    if (viewType === "Monthly") {
      const monthsInYear = eachMonthOfInterval({
        start: startOfYear(new Date(date)),
        end: endOfYear(new Date(date)),
      });

      return monthsInYear;
    }
    if (viewType === "Weekly") {
      const weeksInMonths = eachWeekOfInterval(
        {
          start: startOfMonth(new Date(date)),
          end: endOfMonth(new Date(date)),
        },
        {
          weekStartsOn: 1,
        }
      );

      return weeksInMonths;
    }
    if (viewType === "Quarterly") {
      const quartersInYear = eachQuarterOfInterval({
        start: startOfYear(new Date(date)),
        end: endOfYear(new Date(date)),
      });

      return quartersInYear;
    }
    if (viewType === "Annually") {
      const yearsInCentury = eachYearOfInterval({
        start: startOfYear(set(new Date(date), { year: 2001 })),
        end: endOfYear(set(new Date(date), { year: 2050 })),
      });
      return yearsInCentury;
    }
  }, [date, viewType]);

  //functions
  const getMaxRangeEndDate = useCallback(
    (_start_date) => {
      let maxRangeEndDate = null;
      const __selected_start_date = new Date(
        _start_date || selectedDate?.start_date
      );
      if (options?.type === "daily") {
        maxRangeEndDate = addDays(__selected_start_date, options?.maxRange);
      } else if (options?.type === "weekly") {
        maxRangeEndDate = endOfWeek(
          addWeeks(__selected_start_date, options?.maxRange),
          {
            weekStartsOn: 1,
          }
        );
      } else if (options?.type === "monthly") {
        maxRangeEndDate = endOfMonth(
          addMonths(__selected_start_date, options?.maxRange)
        );
      } else if (options?.type === "quarterly") {
        maxRangeEndDate = endOfQuarter(
          addQuarters(__selected_start_date, options?.maxRange)
        );
      } else if (options?.type === "yearly") {
        maxRangeEndDate = addYears(__selected_start_date, options?.maxRange);
      } else {
        maxRangeEndDate = new Date(selectedDate?.end_date);
      }

      return maxRangeEndDate;
    },
    [selectedDate, options]
  );

  const getViewDate = useCallback(
    (type, _viewDate) => {
      let updatedDate = null;
      const _date = new Date(_viewDate);
      if (type === "start_date") {
        if (viewType === "Daily") {
          updatedDate = _date;
        }
        if (viewType === "Weekly") {
          updatedDate = startOfWeek(_date, { weekStartsOn: 1 });
        }
        if (viewType === "Monthly") {
          updatedDate = startOfMonth(_date);
        }
        if (viewType === "Quarterly") {
          updatedDate = startOfQuarter(_date);
        }
        if (viewType === "Annually") {
          updatedDate = startOfYear(_date);
        }
      }

      if (type === "end_date") {
        if (viewType === "Daily") {
          updatedDate = endOfDay(_date);
        }
        if (viewType === "Weekly") {
          updatedDate = endOfWeek(_date, { weekStartsOn: 1 });
        }
        if (viewType === "Monthly") {
          updatedDate = endOfMonth(_date);
        }
        if (viewType === "Quarterly") {
          updatedDate = endOfQuarter(_date);
        }
        if (viewType === "Annually") {
          updatedDate = endOfYear(_date);
        }
      }
      return updatedDate;
    },
    [selectedDate, options, viewType]
  );

  const setRealDate = (type, date) => {
    let finalDate = null;
    let obj = {};
    if (type === "start_date") {
      if (options?.maxStartDate) {
        if (date > new Date(options?.maxStartDate)) {
          finalDate = new Date(options?.maxStartDate);
        } else {
          finalDate = date;
        }
      }
      if (options?.minStartDate) {
        if (date < new Date(options?.minStartDate)) {
          finalDate = new Date(options?.minStartDate);
        }
      }
      if (!options?.maxStartDate && !options?.minStartDate) {
        finalDate = date;
      }
      if (options?.maxRange) {
        const maxRangeEndDate = getMaxRangeEndDate(finalDate);
        if (new Date(finalDate) > new Date(selectedDate?.end_date)) {
          obj.end_date = format(maxRangeEndDate, "yyyy-MM-dd");
        }
        if (new Date(selectedDate?.end_date) > maxRangeEndDate) {
          obj.end_date = format(maxRangeEndDate, "yyyy-MM-dd");
        }
      }
    }
    if (type === "end_date") {
      if (options?.maxEndDate) {
        if (date > new Date(options?.maxEndDate)) {
          finalDate = new Date(options?.maxEndDate);
        } else {
          finalDate = date;
        }
      }
      if (options?.minEndDate) {
        if (date < new Date(options?.minEndDate)) {
          finalDate = new Date(options?.minEndDate);
        }
      }
      if (options?.maxRange) {
        const maxRangeEndDate = getMaxRangeEndDate();

        if (date > maxRangeEndDate) {
          finalDate = maxRangeEndDate;
        } else {
          finalDate = date;
        }
      }
      if (!options?.maxEndDate && !options?.minEndDate && !options?.maxRange) {
        finalDate = date;
      }
    }

    obj[type] = format(finalDate, "yyyy-MM-dd");
    //if auto adjust end date, show in  view date also
    if (type === "start_date" && obj.end_date) {
      setViewDate((prev) => ({
        ...prev,
        end_date: format(getViewDate("end_date", obj.end_date), "yyyy-MM-dd"),
      }));
    }
    setSelectedDate((prev) => ({
      ...prev,
      ...obj,
    }));
  };

  const setDate = (option, updateViewOnly = false) => {
    const updatedDate = getViewDate(type, option?.date);

    setViewDate((prev) => ({
      ...prev,
      [type]: format(updatedDate, "yyyy-MM-dd"),
    }));

    if (!updateViewOnly) {
      setRealDate(type, updatedDate);
    }
  };

  const handleMonthClick = (e, item) => {
    e?.stopPropagation();
    setDate({ date: item });
    if (viewType === "Annually") {
      setViewType(defaultViewType);
    }
  };

  const clickRight = () => {
    setDate({ date: set(new Date(date), { year: selectedYear + 1 }) });
  };

  const clickLeft = () => {
    setDate({ date: set(new Date(date), { year: selectedYear - 1 }) });
  };

  const onClickYear = () => {
    setViewType((viewType) =>
      viewType === "Annually" ? defaultViewType : "Annually"
    );
  };

  // Function to check if a date is in the selected range
  const isInRange = useCallback(
    (itemDate) => {
      if (!selectedDate?.start_date || !selectedDate?.end_date) return false;

      const startDateObj = new Date(selectedDate.start_date);
      const endDateObj = new Date(selectedDate.end_date);
      const itemDateObj = new Date(itemDate);

      return itemDateObj >= startDateObj && itemDateObj <= endDateObj;
    },
    [selectedDate]
  );

  // Function to check if a date is the start of the range
  const isRangeStart = useCallback(
    (itemDate) => {
      if (!selectedDate?.start_date) return false;

      const startDateObj = new Date(selectedDate.start_date);
      const itemDateObj = new Date(itemDate);

      if (viewType === "Daily") {
        return (
          format(itemDateObj, "yyyy-MM-dd") ===
          format(startDateObj, "yyyy-MM-dd")
        );
      } else if (viewType === "Weekly") {
        return isSameWeek(itemDateObj, startDateObj, { weekStartsOn: 1 });
      } else if (viewType === "Monthly") {
        return isSameMonth(itemDateObj, startDateObj);
      } else if (viewType === "Quarterly") {
        return isSameQuarter(itemDateObj, startDateObj);
      } else if (viewType === "Annually") {
        return isSameYear(itemDateObj, startDateObj);
      }
      return false;
    },
    [selectedDate, viewType]
  );

  // Function to check if a date is the end of the range
  const isRangeEnd = useCallback(
    (itemDate) => {
      if (!selectedDate?.end_date) return false;

      const endDateObj = new Date(selectedDate.end_date);
      const itemDateObj = new Date(itemDate);

      if (viewType === "Daily") {
        return (
          format(itemDateObj, "yyyy-MM-dd") === format(endDateObj, "yyyy-MM-dd")
        );
      } else if (viewType === "Weekly") {
        return isSameWeek(itemDateObj, endDateObj, { weekStartsOn: 1 });
      } else if (viewType === "Monthly") {
        return isSameMonth(itemDateObj, endDateObj);
      } else if (viewType === "Quarterly") {
        return isSameQuarter(itemDateObj, endDateObj);
      } else if (viewType === "Annually") {
        return isSameYear(itemDateObj, endDateObj);
      }
      return false;
    },
    [selectedDate, viewType]
  );

  return (
    <Box
      sx={{
        p: "0.5rem",
        mb: "1rem",
      }}
    >
      {hideYear ? null : (
        <YearHeader
          selectedYear={selectedYear}
          onClickYear={onClickYear}
          clickLeft={clickLeft}
          clickRight={clickRight}
        />
      )}
      {viewType !== "Daily" ? null : <DaysHeader theme={theme} t={t} />}

      <Box
        ref={viewType === "Annually" ? yearListRef : null}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "20rem",
          maxHeight: viewType === "Daily" ? "unset" : "8rem",
          overflowY: viewType === "Annually" ? "auto" : "hidden",
          justifyContent: "center",
          gap: viewType === "Daily" ? "0.5rem" : "0.5rem",
          transition: "all 0.3s ease",
          animation: `${fadeInAnimation} 0.3s ease`,
          ...theme.thinScrollBar,
          "&:hover": {
            overflowY: viewType === "Annually" ? "auto" : "hidden",
          },
        }}
      >
        {viewType === "Daily" ? (
          <ExtraDaysView
            t={t}
            options={previousMonthDays}
            viewType={viewType}
            theme={theme}
          />
        ) : null}
        {ListData?.map((item) => {
          const _selected_date = selectedDate?.[type];
          if (!isValid(new Date(_selected_date))) {
            return null;
          }

          let visible_item = item;
          let ItemDate = item;
          if (viewType === "Weekly") {
            visible_item = formatDateInWeek(item);
          } else if (viewType === "Quarterly") {
            visible_item = formatDateInQuarter(item);
          } else if (viewType === "Monthly") {
            visible_item = formatDateToLocal(item, "MMM");
          } else if (viewType === "Daily") {
            visible_item = formatDateToLocal(item, "d");
          } else if (viewType === "Annually") {
            visible_item = formatDateToLocal(item, "yyyy");
          }
          if (!isValid(ItemDate)) {
            return null;
          }
          const FormattedItemDate = format(ItemDate, "yyyy-MM-dd");
          const FormattedYear =
            viewType === "Annually" ? format(ItemDate, "yyyy") : null;

          let isSelected = false;
          let disabled = false;
          let isStartDateExceedsMax = false;
          let isStartDateExceedsMin = false;
          let isEndDateExceedsMax = false;
          let isEndDateExceedsMin = false;
          let isEndDateExceedsMaxRange = false;
          let isEndDateInPast = false;

          if (viewType === "Daily") {
            isSelected = FormattedItemDate === _selected_date;
          } else if (viewType === "Weekly") {
            isSelected = isSameWeek(
              new Date(FormattedItemDate),
              new Date(_selected_date),
              {
                weekStartsOn: 1,
              }
            );
          } else if (viewType === "Monthly") {
            isSelected = isSameMonth(
              new Date(FormattedItemDate),
              new Date(_selected_date)
            );
          } else if (viewType === "Quarterly") {
            isSelected = isSameQuarter(
              new Date(FormattedItemDate),
              new Date(_selected_date)
            );
          } else if (viewType === "Annually") {
            isSelected = isSameYear(
              new Date(FormattedItemDate),
              new Date(_selected_date)
            );
          }

          //disable date
          if (type === "end_date") {
            let isCurrentSpan = false;
            if (viewType === "Monthly") {
              isCurrentSpan = isSameMonth(
                ItemDate,
                new Date(selectedDate?.start_date)
              );
            }
            if (viewType === "Annually") {
              isCurrentSpan = isSameYear(
                ItemDate,
                new Date(selectedDate?.start_date)
              );
            }
            if (viewType === "Quarterly") {
              isCurrentSpan = isSameQuarter(
                ItemDate,
                new Date(selectedDate?.start_date)
              );
            }
            if (viewType === "Weekly") {
              isCurrentSpan = isSameWeek(
                ItemDate,
                new Date(selectedDate?.start_date),
                { weekStartsOn: 1 }
              );
            }
            isEndDateInPast =
              !isCurrentSpan &&
              ItemDate <
                set(new Date(selectedDate?.start_date), {
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                  milliseconds: 0,
                });
          }

          if (type === "end_date" && options?.maxRange) {
            const maxRangeEndDate = getMaxRangeEndDate();
            isEndDateExceedsMaxRange = ItemDate > maxRangeEndDate;
          }

          if (type === "start_date" && options?.maxStartDate) {
            isStartDateExceedsMax = ItemDate > new Date(options?.maxStartDate);
          }

          if (type === "start_date" && options?.minStartDate) {
            isStartDateExceedsMin = ItemDate < new Date(options?.minStartDate);
          }

          if (type === "end_date" && options?.maxEndDate) {
            isEndDateExceedsMax =
              new Date(ItemDate) > new Date(options?.maxEndDate);
          }

          if (type === "end_date" && options?.minEndDate) {
            isEndDateExceedsMin =
              new Date(ItemDate) < new Date(options?.minEndDate);
          }

          disabled =
            isEndDateInPast ||
            isEndDateExceedsMaxRange ||
            isStartDateExceedsMax ||
            isStartDateExceedsMin ||
            isEndDateExceedsMax ||
            isEndDateExceedsMin;

          // Check if item is in range
          const inRange = isInRange(ItemDate);
          const isStart = isRangeStart(ItemDate);
          const isEnd = isRangeEnd(ItemDate);

          const isBothStartAndEnd = isStart && isEnd;

          return (
            <ViewItem
              t={t}
              key={FormattedItemDate}
              item={visible_item}
              onClick={(e) => handleMonthClick(e, item)}
              disabled={disabled}
              isSelected={!disabled ? isSelected : undefined}
              viewType={viewType}
              theme={theme}
              inRange={inRange}
              isRangeStart={isStart}
              isRangeEnd={isEnd}
              isBothStartAndEnd={isBothStartAndEnd}
              isSamePeriod={isSamePeriod}
              type={type}
              data-year={FormattedYear}
            />
          );
        })}
        {viewType === "Daily" ? (
          <ExtraDaysView
            t={t}
            options={nextMonthDays}
            viewType={viewType}
            theme={theme}
          />
        ) : null}
      </Box>
    </Box>
  );
};

const ViewItem = ({
  item,
  disabled,
  onClick,
  viewType,
  theme,
  isSelected,
  notAllowed,
  inRange,
  isRangeStart,
  isRangeEnd,
  isBothStartAndEnd,
  isSamePeriod,
  type,
  t,
  ...props
}) => {
  let width = "2.1rem";

  if (viewType === "Monthly") {
    width = "4.1rem";
  }
  if (viewType === "Weekly") {
    width = "9rem";
  }
  if (viewType === "Quarterly") {
    width = "9rem";
  }
  if (viewType === "Annually") {
    width = "4rem";
  }

  // Determine border radius based on range position
  let borderRadius = "4px";

  // Generate border style based on item position in range
  let borderStyle = `1px solid ${
    isSelected
      ? theme.palette.color.primary[600]
      : theme.palette.color.slate[notAllowed ? 100 : 300]
  }`;

  return (
    <Box
      sx={{
        position: "relative",
        width: viewType === "Daily" ? "2rem" : "auto",
        textAlign: "center",
        margin: "0.15rem",
      }}
      {...props}
    >
      <Button
        disabled={disabled}
        onClick={onClick}
        sx={{
          width: width,
          height: viewType === "Daily" ? width : "fit-content",
          color: isSelected
            ? theme.palette.color.primary[700]
            : theme.palette.color.slate[notAllowed ? 900 : 700],
          backgroundColor: theme.palette.color.slate[50],
          border: borderStyle,
          fontSize: "0.7rem",
          fontWeight: isSelected ? 700 : 600,
          lineHeight: "normal",
          padding: viewType === "Daily" ? "0.25rem" : "0.5rem 1rem",
          textTransform: "initial",
          minWidth: viewType === "Daily" ? "2rem" : "auto",
          borderRadius,
          position: "relative",
          transition: "all 0.2s ease",
          animation: isSelected ? `${pulseAnimation} 0.5s ease` : "none",
          zIndex: isSelected ? 2 : 1,

          "&:hover": {
            backgroundColor: isSelected
              ? theme.palette.color.primary?.[50]
              : theme.palette.color.slate[100],
            transform: "scale(1.05)",
            zIndex: 5,
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: viewType === "Daily" ? "0" : undefined,
        }}
      >
        {t(item)}
      </Button>
    </Box>
  );
};

const DaysHeader = ({ theme, t }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        gap: "0.5rem",
        transition: "height 0.3s ease",
        flexWrap: "wrap",
        alignItems: "center",
        flexDirection: "row",
        mb: "0.5rem",
      }}
    >
      {daysHeaderOptions?.map((day) => (
        <Typography
          key={day}
          sx={{
            width: "2.3rem",
            fontWeight: 700,
            textAlign: "center",
            fontSize: "0.8rem",
            color: theme.palette.color.slate[800],
          }}
        >
          {t(day)}
        </Typography>
      ))}
    </Stack>
  );
};

const YearHeader = ({ selectedYear, onClickYear, clickLeft, clickRight }) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBlock: "1rem",
          position: "relative",
        }}
      >
        <WestIcon
          onClick={clickLeft}
          sx={{
            color: "#64748B",
            cursor: "pointer",
            mr: "3rem",
            transition: "all 0.3s ease",
            "&:hover": {
              color: "#334155",
              transform: "scale(1.2) translateX(-2px)",
            },
          }}
        />
        <Box
          onClick={onClickYear}
          sx={{
            fontSize: "1rem",
            fontWeight: 600,
            lineHeight: "normal",
            fontFamily: "Inter",
            color: "#334155",
            cursor: "pointer",
            transition: "all 0.3s ease",
            padding: "0.25rem 0.75rem",
            borderRadius: "4px",
            position: "relative",
            "&:hover": {
              color: "#1E293B",
              backgroundColor: "rgba(226, 232, 240, 0.5)",
              transform: "scale(1.1)",
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-4px",
                left: "50%",
                transform: "translateX(-50%)",
                width: "20px",
                height: "2px",
                backgroundColor: "#334155",
                animation: `${fadeInAnimation} 0.3s ease`,
              },
            },
          }}
        >
          {selectedYear}
        </Box>
        <EastIcon
          onClick={clickRight}
          sx={{
            color: "#64748B",
            cursor: "pointer",
            ml: "3rem",
            transition: "all 0.3s ease",
            "&:hover": {
              color: "#334155",
              transform: "scale(1.2) translateX(2px)",
            },
          }}
        />
      </div>
    </div>
  );
};

const ExtraDaysView = ({ options, viewType, theme, t }) => {
  return (
    <>
      {options?.map((item) => (
        <ViewItem
          t={t}
          key={item}
          item={item}
          disabled={true}
          notAllowed
          viewType={viewType}
          theme={theme}
        />
      ))}
    </>
  );
};
