import { createSlice } from "@reduxjs/toolkit";
import { deepMerge } from "@/Helper/functions";
import format from "date-fns/format";
import _ from "underscore";

import initialData from "../../Helper/data";
import { resetAllState } from "./reset";

const { cardActionList } = initialData;
const initialState = {
  dataSetData: {},
  datasetAccountList: [],
  dataSetList: [],
  chartData: [],
  columnOrder: [],
  todayLiq: 0,
  accountBalanceUpdateDate: null,
  chartLineKeys: [],
  cardActionList: [...cardActionList],
  gropedTransactionData: [],
  highlightedScenarios: [],
  highlightedCostUnit: [],
  transactionData: [],
  updateChart: false,
  isKanbanView: true,
  searchText: "",
  planning_type: "monthly",
  isBoardLoading: true,
  planningChartVatInfo: null,
};

const boardSlice = createSlice({
  name: "boardSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    toggleView(state, action) {
      state.isKanbanView = action?.payload || !state.isKanbanView;
    },
    setSearchText(state, action) {
      state.searchText = action?.payload;
    },

    setDataSetData(state, action) {
      if (action.payload?.partial) {
        state.dataSetData = deepMerge(
          state.dataSetData,
          action.payload?.partial
        );
      } else {
        state.dataSetData = action.payload;
      }
    },
    setDataSetAccountList(state, action) {
      state.datasetAccountList = action.payload;
    },
    setHighlightedScenarios(state, action) {
      state.highlightedScenarios = action.payload;
    },
    setUpdateChart(state) {
      state.updateChart = !state.updateChart;
    },

    setDataSetList(state, action) {
      let array = [...action.payload];
      array?.sort((a, b) => (a.position > b.position ? 1 : -1));
      state.dataSetList = array;
    },
    setCardActionList(state, action) {
      state.cardActionList = action.payload;
    },
    setAccountBalanceUpdateDate(state, action) {
      state.accountBalanceUpdateDate = action.payload;
    },
    setColumnOrder(state, action) {
      state.columnOrder = action.payload;
    },
    setMonthlyChartData(state, action) {
      state.chartData = action.payload;
    },
    setChartLineKeys(state, action) {
      state.chartLineKeys = action.payload;
    },
    setTransactionData(state, action) {
      let data1 = action.payload?.filter((o1) => o1.gross_value >= 0);
      let data2 = action.payload?.filter((o1) => o1.gross_value < 0);
      let inflow = _.groupBy([...data1], ({ due_date }) =>
        format(new Date(due_date), "yyyy-MM")
      );
      let outflow = _.groupBy([...data2], ({ due_date }) =>
        format(new Date(due_date), "yyyy-MM")
      );
      state.transactionData = action.payload;
      state.gropedTransactionData = { inflow, outflow };
    },

    setTodayLiq(state, action) {
      state.todayLiq = action.payload;
    },
    setHighlightedCostUnit(state, action) {
      state.highlightedCostUnit = action.payload;
    },

    setBoardLoading(state, action) {
      state.isBoardLoading = action.payload;
    },
    setPlanningChartVatInfo(state, action) {
      state.planningChartVatInfo = action.payload;
    },
  },
});
export const {
  toggleView,
  setDataSetData,
  setTransactionData,
  setTransactionDataDummy,
  setDataSetList,
  setColumnOrder,
  setMonthlyChartData,
  setTodayLiq,
  setChartLineKeys,
  setCardActionList,
  setDataSetAccountList,
  setAccountBalanceUpdateDate,
  setKanbanTransactionsInflow,
  setUpdateChart,
  setSearchText,
  setHighlightedScenarios,
  setHighlightedCostUnit,
  setBoardLoading,
  setPlanningChartVatInfo,
} = boardSlice.actions;
export default boardSlice;
