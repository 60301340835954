import {
  circularProgressClasses,
  CircularProgress,
  ListItemText,
  TextField,
  useTheme,
  Divider,
  Tooltip,
  Button,
  Stack,
  Chip,
  Box,
} from "@mui/material";
import {
  forwardRef,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { TbEdit, TbPlugConnected, TbPlugConnectedX } from "react-icons/tb";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { PiArrowsClockwiseBold, PiGearSix } from "react-icons/pi";
import { MdBusiness, MdOutlineEdit } from "react-icons/md";
import BusinessIcon from "@mui/icons-material/Business";
import { useDispatch, useSelector } from "react-redux";
import { FaPlugCirclePlus } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IoMdAlert } from "react-icons/io";
import { GrUpdate } from "react-icons/gr";
import React from "react";

import {
  setDataSetAccountList,
  setDataSetData,
  setDataSetList,
} from "../../../store/slices/board";
import {
  getIntegrationCard,
  getFinApiQueryKey,
  truncate,
} from "../../../Helper/data";
import { setDataSource, setJoyRidePayload } from "../../../store/slices/global";
import StatusView from "../../../Pages/Settings/DataSource/StatusView";
import useDebounce from "../../../hooks/3-useDebounce/useDebounce";
import DSImage from "../../../Pages/Settings/DataSource/DSImage";
import { setPopupStatus3 } from "../../../store/slices/datasets";
import { setRefreshData } from "../../../store/slices/appmain";
import { GlobalContext } from "../../../GlobalContextWrapper";
import { setStepIndex } from "../../../store/slices/tour";
import TailwindButton from "../../Overlay/TailwindButton";
import useStatusHook from "../../../hooks/useStatusHook";
import { startTour } from "../../../store/slices/tour";
import Translate from "../../../hooks/HOC/Translate";
import EndPoints from "../../../APICall/EndPoints";
import DataSetDropDown from "./DataSetDropDown";
import { queryClient } from "../../../App";
import CustomPopover from "../../PopOver";
import APICall from "../../../APICall";
import Icon from "../../Icon";

const DSSelectorView = forwardRef(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const IntegrationsUpdatedData = useRef({});
  const globalContext = useContext(GlobalContext);
  const liveSearchRef = globalContext?.liveSearchRef;

  const isDSChanged = useRef(false);
  const popupRef = useRef(null);
  let abortController = new AbortController();
  let aborted = abortController.signal.aborted;

  //redux
  const joyRidePayload = useSelector(
    (state) => state.globalSlice.joyRidePayload
  );
  const accounts = useSelector((state) => state.globalSlice.accounts);
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const consentAlert = useSelector((state) => state.globalSlice.consentAlert);
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const title = useSelector((state) => state.boardSlice?.dataSetData?.title);
  const refreshData = useSelector((state) => state.appSlice?.refreshData);
  const accountByDS = useSelector((state) => state.globalSlice.accountByDS);

  const isFirstFetchDs = useSelector(
    (state) => state.globalSlice.isFirstFetchDs
  );
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );
  const isConsentAlert = consentAlert !== 0;

  const dataSetData = {
    uuid: uuid,
    title: title,
  };

  //state
  const [showAlert, setShowAlert] = useState(false);
  const [showAccountHideAlert, setShowAccountHideAlert] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //api
  const updateAccountLinkedToDataSet = async (
    dataset_id,
    integration_id,
    obj
  ) => {
    await APICall(
      "patch",
      EndPoints.datasets + `${dataset_id}/data_sources/${integration_id}/`,
      obj
    ).then((response) => {
      if (response.status === 200 && response.data && aborted === false) {
        // if (IntegrationsUpdatedData?.current?.[integration_id]) {
        //   IntegrationsUpdatedData.current[integration_id] = null;
        // }
      }
    });
  };

  //lifecycle method
  useEffect(() => {
    return () => {
      setShowAlert(false);
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (joyRidePayload?.next?.key === "joyride_planview_step_2") {
      liveSearchRef?.current?.handleOpen();
      setTimeout(() => {
        dispatch(setStepIndex(joyRidePayload?.next?.payload?.index));
        dispatch(startTour());
        dispatch(setJoyRidePayload(null));
      }, 500);
    }
    if (joyRidePayload?.next?.key === "joyride_planview_step_3") {
      liveSearchRef?.current?.handleClose();
      setTimeout(() => {
        dispatch(setStepIndex(joyRidePayload?.next?.payload?.index));
        dispatch(startTour());
        dispatch(setJoyRidePayload(null));
      }, 500);
    }
    if (joyRidePayload?.next?.key === "joyride_planview_step_4") {
      onClickPopOver();
      setTimeout(() => {
        dispatch(setStepIndex(joyRidePayload?.next?.payload?.index));
        dispatch(startTour());
        dispatch(setJoyRidePayload(null));
      }, 500);
    }
    if (joyRidePayload?.next?.key === "joyride_planview_step_6") {
      onClosePopOver();
      setTimeout(() => {
        dispatch(setStepIndex(joyRidePayload?.next?.payload?.index));
        dispatch(startTour());
        dispatch(setJoyRidePayload(null));
      }, 500);
    }

    if (joyRidePayload?.prev?.key === "joyride_planview_step_5") {
      onClickPopOver();
      setTimeout(() => {
        dispatch(setStepIndex(joyRidePayload?.prev?.payload?.index));
        dispatch(startTour());
        dispatch(setJoyRidePayload(null));
      }, 500);
    }
    if (joyRidePayload?.prev?.key === "joyride_planview_step_3") {
      onClosePopOver();
      setTimeout(() => {
        dispatch(setStepIndex(joyRidePayload?.prev?.payload?.index));
        dispatch(startTour());
        dispatch(setJoyRidePayload(null));
      }, 500);
    }
    if (joyRidePayload?.prev?.key === "joyride_planview_step_2") {
      liveSearchRef?.current?.handleOpen();
      setTimeout(() => {
        dispatch(setStepIndex(joyRidePayload?.prev?.payload?.index));
        dispatch(startTour());
        dispatch(setJoyRidePayload(null));
      }, 500);
    }
    if (joyRidePayload?.prev?.key === "joyride_planview_step_1") {
      liveSearchRef?.current?.handleClose();
      setTimeout(() => {
        dispatch(setStepIndex(joyRidePayload?.prev?.payload?.index));
        dispatch(startTour());
        dispatch(setJoyRidePayload(null));
      }, 500);
    }
    if (joyRidePayload?.key === "open_integration_overlay") {
      onClickPopOver();
      dispatch(setJoyRidePayload(null));
    }
  }, [dispatch, joyRidePayload, liveSearchRef]);

  useDebounce(
    () => {
      if (dataSetData?.uuid && dataSource) {
        getDSdatasetsConsents(dataSource);
      }
    },
    1000,
    [dataSource, accounts, dataSetData?.uuid],
    true
  );

  useDebounce(
    () => {
      if (dataSetData?.uuid && datasetAccountList?.length > 0) {
        const accountNotFound = datasetAccountList?.find(
          (o1) =>
            (o1.type === 1 && !o1.internal_dataset && !o1.account) ||
            (o1.type === 19 && o1.is_connected && !o1.last_sync_date)
        );
        const isNotActiveAccountFound = datasetAccountList?.find(
          (o1) => !o1?.active
        );
        if (isNotActiveAccountFound) {
          setShowAccountHideAlert(true);
        } else {
          setShowAccountHideAlert(false);
        }
        if (
          Boolean(accountNotFound) &&
          isFirstFetchDs === 0 &&
          window?.global?.isFirstFetchUuid?.length === 0
        ) {
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
      }
    },
    500,
    [dataSetData?.uuid, datasetAccountList]
  );

  //functions
  const onClickPopOver = () => {
    setAnchorEl(popupRef.current);
  };

  const onClosePopOver = () => {
    setAnchorEl(null);
    if (isDSChanged.current) {
      if (Object.keys(IntegrationsUpdatedData?.current)?.length > 0) {
        Object.keys(IntegrationsUpdatedData?.current).forEach(async (key) => {
          const data = IntegrationsUpdatedData?.current?.[key];

          if (data) {
            await updateAccountLinkedToDataSet(
              data?.dataset,
              data?.data_source,
              data?.payload
            );
          }
        });
      }
      setTimeout(() => {
        let options = {
          predicate: (query) =>
            ["transactions", "List", "invoices"].includes(query.queryKey[0]) &&
            query.queryKey[1].dataset === dataSetData?.uuid,
          type: "active",
        };
        queryClient.invalidateQueries(options, { cancelRefetch: true });
        dispatch(setRefreshData(!refreshData));
      }, 500);
      isDSChanged.current = false;
    }
  };

  const getDSdatasetsConsents = async (dataSourceArray) => {
    let connectedData = [];
    dataSourceArray.forEach((element) => {
      const isDataSetAdded = element?.datasets?.filter(
        (o1) => o1.dataset === dataSetData?.uuid
      );
      if (isDataSetAdded?.length > 0) {
        const account = accountByDS?.[element?.uuid]?.[0];
        const card = getIntegrationCard({ ds: element });

        const obj = {
          ...element,
          active: isDataSetAdded?.[0]?.active,
          card: card,
          account: account,
          position: element?.internal_dataset ? 0 : element.type,
        };
        connectedData.push(obj);
      }
    });
    connectedData?.sort((a, b) => (a.position > b.position ? 1 : -1));
    IntegrationsUpdatedData.current = {};
    dispatch(setDataSetAccountList(connectedData));
  };

  const tooltip =
    isFirstFetchDs !== 0 &&
    datasetAccountList.find((o1) => window?.global?.isFirstFetchUuid?.includes(o1.uuid))
      ? t("integrations_fetching")
      : "";

  const ChipView = forwardRef((props, _ref) => {
    return (
      <Tooltip title={tooltip}>
        <Chip
          ref={_ref}
          className="joyride_planview_step_3"
          label={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {truncate(dataSetData?.title, 13)}
              <Box
                sx={{
                  borderRadius: theme.borderRadius.main,
                  backgroundColor: theme.palette.color.slate[300],
                  py: "0.35rem",
                  px: "0.5rem",
                  ml: "0.5rem",
                }}
              >
                {datasetAccountList?.length
                  ? datasetAccountList?.length - 1
                  : 0}
              </Box>
            </Box>
          }
          onDelete={() => {}}
          deleteIcon={
            <ArrowDropDownIcon
              sx={{
                color: `${theme.palette.color.slate[700]} !important`,
                mr: "0px !important",
                transform: `rotate(${anchorEl ? 180 : 0}deg)`,
                transition: "all 0.3s ease-in-out",
              }}
            />
          }
          icon={
            <Box
              sx={{
                display: "flex",
              }}
            >
              {isFirstFetchDs !== 0 &&
              datasetAccountList.find((o1) =>
                window?.global?.isFirstFetchUuid?.includes(o1.uuid)
              ) ? (
                <CircularProgress
                  size={18}
                  sx={{
                    p: "0px !important",
                    mr: "0.25rem",
                    color: `${theme.palette.color.slate[700]} !important`,
                  }}
                />
              ) : showAlert || isConsentAlert ? (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ gap: "0.5rem" }}
                >
                  {showAccountHideAlert ? (
                    <Tooltip
                      title={t("integration_overlay_hidden_integration_alert")}
                      style={{
                        height: "1.2rem",
                      }}
                    >
                      <VisibilityOffOutlinedIcon
                        sx={{
                          color: theme.palette.color.slate[600],
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  ) : null}
                  <Icon
                    tooltip={
                      showAlert
                        ? "Integration is not connected."
                        : "integration_overlay_consent_alert"
                    }
                    icon={<IoMdAlert />}
                    fontSize={{ xs: "1.2rem", md: "1.4rem" }}
                    color={
                      showAlert
                        ? theme.palette.color.red[500]
                        : theme.palette.color.blue[500]
                    }
                  ></Icon>
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ gap: "0.5rem" }}
                >
                  {showAccountHideAlert ? (
                    <Tooltip
                      title={t("integration_overlay_hidden_integration_alert")}
                      style={{
                        height: "1.2rem",
                      }}
                    >
                      <VisibilityOffOutlinedIcon
                        sx={{
                          color: theme.palette.color.slate[600],
                          fontSize: "1.2rem",
                        }}
                      />
                    </Tooltip>
                  ) : null}
                  <BusinessIcon
                    sx={{
                      color: theme.palette.color.slate[700],
                      fontSize: "1.5rem",
                    }}
                  />
                </Stack>
              )}
            </Box>
          }
          sx={{
            color: theme.palette.color.slate[700],
            backgroundColor: theme.palette.color.slate[200],
            borderRadius: theme.borderRadius.main,
            ml: "0.5rem",
            height: "2.5rem",
            p: { xs: "0.25rem", md: "0.5rem" },
            pl: { xs: "0.5rem", md: "0.75rem" },
            cursor: "pointer",
            "& span": {
              fontSize: { xs: "0.7rem", md: "0.875rem" },
              lineHeight: { xs: "0.7rem", md: "0.875rem" },
              fontWeight: 600,
            },

            "& .MuiChip-icon": {
              ml: "0.1rem",
              mb: "0.1rem",
              mr: 0,
            },
            "&:hover span": {
              color: `${theme.palette.primary.main} !important`,
            },
          }}
          {...props}
        />
      </Tooltip>
    );
  });

  return (
    <CustomPopover
      type="anchor"
      anchorEl={anchorEl}
      ref={popupRef}
      onClose={onClosePopOver}
      onClick={onClickPopOver}
      BaseButton={<ChipView />}
    >
      <OverlayView
        isDSChanged={isDSChanged}
        IntegrationsUpdatedData={IntegrationsUpdatedData}
        isOpen={!!anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </CustomPopover>
  );
});
export default DSSelectorView;

const OverlayView = ({
  isDSChanged,
  IntegrationsUpdatedData,
  setAnchorEl,
  isOpen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const dsRef = globalContext?.dsRef;
  let abortController = new AbortController();
  let aborted = abortController.signal.aborted;

  //redux
  const dataSource = useSelector((state) => state.globalSlice.dataSource);
  const uuid = useSelector((state) => state.boardSlice?.dataSetData?.uuid);
  const title = useSelector((state) => state.boardSlice?.dataSetData?.title);
  const datasetAccountList = useSelector(
    (state) => state.boardSlice.datasetAccountList
  );

  const dataSetData = {
    uuid: uuid,
    title: title,
  };

  //api
  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data && aborted === false) {
        let data = response.data.results;
        dispatch(setDataSource(data));
      }
    });
  };

  //functions
  const onChangeSelectedAccount = (option) => {
    const updatedData = [
      ...datasetAccountList?.map((item) => {
        if (item.uuid === option.uuid) {
          const dataSourceId = option.datasets.find(
            (o1) => o1.dataset === dataSetData?.uuid
          );
          const obj = { ...item, active: !item?.active };

          IntegrationsUpdatedData.current[dataSourceId?.uuid] = {
            dataset: dataSetData?.uuid,
            data_source: dataSourceId?.uuid,
            payload: { active: !item?.active },
          };

          return obj;
        }
        return item;
      }),
    ];
    dispatch(setDataSetAccountList(updatedData));
    isDSChanged.current = true;
  };

  const onManage = () => {
    navigate(`/settings/Integrations`);
    setAnchorEl(null);
  };

  const onClickAssign = () => {
    dsRef?.current?.openIntegrationOverlay("assign");
  };

  const onClickAdd = () => {
    dsRef?.current?.openIntegrationOverlay("add");
  };

  const onClickErrorLogs = (ds, error) => {
    dsRef?.current?.onClickErrorLogs(ds, error);
  };

  const onClickEditAccount = (item) => {
    dsRef?.current?.onClickEditIntegration(item);
  };

  const isAvailableDs = useMemo(() => {
    return dataSource?.find(
      (o1) =>
        !o1.internal_dataset &&
        o1.state === 1 &&
        !o1.datasets?.find((o2) => o2.dataset === dataSetData?.uuid)
    );
  }, [dataSetData?.uuid, dataSource]);

  useEffect(() => {
    if (isOpen) {
      IntegrationsUpdatedData.current = {};
    }
  }, [isOpen]);

  return (
    <Box
      className={"joyride_planview_step_4"}
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem",
        paddingTop: "1rem",
        height: "fit-content",
        width: "73rem",
        minHeight: "3.125rem",
        borderRadius: theme.borderRadius.borderRadiusXL,
        backgroundColor: theme.palette.color.white,
        outline: `2px solid ${theme.palette.color.grey[300]}`,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
      >
        <DataSetView />
        <SyncAllButton dsRef={dsRef} />
      </Stack>
      {datasetAccountList?.map((item) => {
        return (
          <div key={item?.uuid}>
            <Box
              sx={{
                px: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                py: 0,
                height: "3rem",
                width: "100%",
                flexGrow: "unset",
                cursor: "auto",
                "&: hover": {
                  "& #action-fetch-new-button": {
                    backgroundColor: theme.palette.color.sky[50],
                    color: theme.palette.color.sky[600],
                    "& svg": {
                      color: theme.palette.color.sky[600],
                    },
                  },
                },
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <DSImage
                  ds_uuid={item?.uuid}
                  integration_title={item?.note}
                  sx={{
                    width: "4rem",
                    height: "2rem",
                  }}
                />
                <TitleView item={item} />
              </div>

              <Stack
                flexDirection={"row-reverse"}
                alignItems={"center"}
                sx={{
                  position: "absolute",
                  right: "1rem",
                  gap: "0.4rem",
                  backgroundColor: theme.palette.color.white,
                }}
              >
                <RemoveIcon item={item} isDSChanged={isDSChanged} />
                <EditView item={item} onClick={onClickEditAccount} />
                <Tooltip
                  placement="top"
                  title={
                    item?.active
                      ? t("Disable Integration")
                      : t("Enable Integration")
                  }
                >
                  <Button
                    size="small"
                    onClick={() => onChangeSelectedAccount(item)}
                    sx={{
                      display: "flex",
                      minWidth: "auto",
                      textTransform: "none",
                      fontSize: "0.7rem",
                      height: "1.85rem",
                      fontWeight: 500,
                      "& svg": {
                        color: item?.active
                          ? theme.palette.color.slate[300]
                          : theme.palette.color.slate[600],
                        fontSize: "1.2rem !important",
                      },
                      backgroundColor: theme.palette.color.slate[50],
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: item?.active
                          ? theme.palette.color.green[100]
                          : theme.palette.color.slate[100],
                        "& svg": {
                          color: item?.active
                            ? theme.palette.color.green[600]
                            : theme.palette.color.slate[600],
                        },
                      },
                    }}
                  >
                    {item?.active ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </Button>
                </Tooltip>

                {!item.internal_dataset ? (
                  <StatusView
                    uuid={item?.uuid}
                    type="alert"
                    refetch
                    showBalance
                    onClickErrorLogs={onClickErrorLogs}
                  />
                ) : (
                  <div
                    style={{
                      width: "8.2rem",
                    }}
                  ></div>
                )}

                <FetchNewButton
                  getDataSource={getDataSource}
                  DS={item}
                  dsRef={dsRef}
                />
              </Stack>
            </Box>
            <Divider
              variant="middle"
              flexItem
              sx={{
                mx: "2rem",
                borderColor: theme.palette.color.slate[100],
              }}
            />
          </div>
        );
      })}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.75rem",
          mt: "1rem",
          cursor: "pointer",
          "&:hover": {
            color: theme.palette.primary.main,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <TailwindButton
            icon={
              <Icon
                icon={<FaPlugCirclePlus />}
                fontSize={"1rem"}
                color="inherit"
                style={{ marginRight: "0.5rem" }}
              ></Icon>
            }
            onClick={onClickAdd}
            className={"joyride_planview_step_5"}
            text={t("Add new Integration")}
            tooltip={t("Add new Integration Tooltip")}
            backgroundColor={theme.palette.primary.main}
            sx={{
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
            }}
          />
          {isAvailableDs ? (
            <TailwindButton
              variant="outlined"
              icon={
                <Icon
                  icon={<TbPlugConnected />}
                  fontSize={"1rem"}
                  color="inherit"
                  style={{ marginRight: "0.5rem" }}
                ></Icon>
              }
              text={t("Assign available Integration")}
              tooltip={t("Assign available Integration Tooltip")}
              onClick={onClickAssign}
              color={theme.palette.primary.main}
              sx={{
                fontSize: "0.8rem",
                lineHeight: "0.8rem",
              }}
            />
          ) : null}

          <Tooltip title={t("Manage Integrations of")}>
            <Box
              sx={{
                ml: "0.5rem",
                p: { xs: "0.25rem", small: "0.5rem" },
                display: "inline-flex",
                alignItem: "center",
                JustifyContent: "center",
                color: theme.palette.primary.main,
                "&:hover": {
                  borderRadius: 10,
                  opacity: 1,
                  backgroundColor: theme.palette.primary[50],
                },
              }}
            >
              <Icon
                onClick={onManage}
                icon={<PiGearSix />}
                fontSize={{ xs: "1.5rem", small: "1.3rem" }}
                color={theme.palette.primary.main}
                style={{ cursor: "pointer" }}
              ></Icon>
            </Box>
          </Tooltip>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <DataSetDropDown />
          <OrgSettingsView />
        </div>
      </Box>
    </Box>
  );
};

const constructTooltip = ({ tooltip, level, name }) => {
  if (level === 1) {
    return tooltip + `${name}`;
  } else {
    return tooltip + ` / ${name}`;
  }
};

const TitleView = ({ item }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isFirstFetchDs = useSelector(
    (state) => state.globalSlice.isFirstFetchDs
  );

  const isBankFetching =
    item.type === 12 &&
    !item.last_sync_date &&
    isFirstFetchDs !== 0 &&
    window?.global?.isFirstFetchUuid?.includes(item.uuid);

  let tooltip = "";
  if (item.internal_dataset) {
    tooltip = t("Internal data source tooltip");
  } else if (item?.account?.bank_details?.title) {
    let level = 1;
    if (item?.account?.bank_details?.title) {
      tooltip = constructTooltip({
        tooltip,
        level,
        name: item?.account?.bank_details?.title,
      });
      level++;
    }

    if (item?.account?.name) {
      tooltip = constructTooltip({
        tooltip,
        level,
        name: item?.account?.name,
      });
      level++;
    }

    if (item?.account?.iban) {
      tooltip = constructTooltip({
        tooltip,
        level,
        name: item?.account?.iban,
      });
      level++;
    }
  } else {
    tooltip = item?.title;
  }

  return (
    <Tooltip title={tooltip} followCursor>
      <ListItemText
        primary={
          isBankFetching
            ? t("bank details fetching")
            : item.internal_dataset
              ? t("Internal data source")
              : item?.title
        }
        secondary={
          !isBankFetching && !item.internal_dataset && item?.account?.name
            ? `${truncate(item?.account?.name, item?.account?.iban ? 15 : 15 + 22)}${item?.account?.iban ? ` / ${item?.account?.iban}` : ""}`
            : ""
        }
        sx={{
          flex: "unset",
          mx: "1rem",
          width: "35rem",
          cursor: "default",

          "& .MuiListItemText-primary ": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            color: theme.palette.primary[700],
            fontWeight: 500,
            fontSize: "1rem",
          },

          "& .MuiListItemText-secondary": {
            color: theme.palette.color.slate[400],
            fontSize: "0.65rem",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          },
        }}
      />
    </Tooltip>
  );
};

const EditView = ({ item, onClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (item?.internal_dataset) {
    return (
      <div
        style={{
          width: "2rem",
        }}
      ></div>
    );
  }
  return (
    <div
      style={{
        width: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Tooltip placement="top" title={t("Edit Integration")}>
        <Button
          size="small"
          onClick={() => onClick(item)}
          sx={{
            display: "flex",
            minWidth: "auto",
            textTransform: "none",
            fontSize: "0.7rem",
            height: "1.85rem",
            fontWeight: 500,
            backgroundColor: theme.palette.color.slate[50],
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
              color: theme.palette.color.slate[300],
              fontSize: "1.2rem !important",
            },
            "&:hover": {
              backgroundColor: theme.palette.color.sky[100],
              "& svg": {
                color: theme.palette.color.sky[600],
              },
            },
          }}
        >
          <TbEdit />
        </Button>
      </Tooltip>
    </div>
  );
};

const RemoveIcon = ({ item, isDSChanged }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const param = useRef(null);

  const dataset = useSelector((state) => state.boardSlice?.dataSetData?.uuid);

  const [loading, setLoading] = useState(false);

  const removeDataSourceToDataSet = async (dataset_id, item, isForce) => {
    setLoading(true);
    const obj = item?.datasets?.find((o1) => o1.dataset === dataset_id);
    let url = `${dataset_id}/data_sources/${obj?.uuid}/`;
    if (isForce) {
      url = url + "?force=true/";
    }
    await APICall("delete", EndPoints.datasets + url, null, {
      doNotCatchErrorResponse: true,
    })
      .then((response) => {
        if (response.status === 204) {
          getDataSource();
          isDSChanged.current = true;
        }
        if (response.status === 400 && response?.data?.[0]) {
          param.current = {
            dataset_id,
            item,
          };
          dispatch(
            setPopupStatus3({
              open: true,
              overlay_type: "delete",
              onConfirm: onAdd,
              onClose: onCloseCallback,
              payload: {
                title: t("Attention"),
                message: (
                  <Translate
                    i18nkey={`ds_remove_attention_ds_have_transaction_with_org_category`}
                  />
                ),
                confirmText: t("Ok"),
                hideCancel: true,
              },
            })
          );
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getDataSource = async () => {
    await APICall("get", EndPoints.integrations).then((response) => {
      if (response.status === 200 && response.data) {
        let data = response.data.results;
        dispatch(setDataSource(data));
        dispatch(setPopupStatus3(null));
        setLoading(false);
      }
    });
  };

  const onCloseCallback = () => {
    param.current = null;
    setLoading(false);
  };

  const onAdd = () => {
    removeDataSourceToDataSet(
      param.current?.dataset_id,
      param.current?.item,
      true
    );
  };

  if (item?.internal_dataset) {
    return (
      <div
        style={{
          width: "2rem",
        }}
      ></div>
    );
  }
  return (
    <div
      style={{
        width: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading ? (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress
            variant="determinate"
            sx={{
              color: theme.palette.color.slate[200],
            }}
            size={22}
            thickness={4}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: theme.palette.primary.main,
              animationDuration: "550ms",
              position: "absolute",
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={22}
            thickness={4}
          />
        </div>
      ) : (
        <Tooltip placement="top" title={t("Remove Integration")}>
          <Button
            size="small"
            onClick={() => removeDataSourceToDataSet(dataset, item)}
            sx={{
              display: "flex",
              minWidth: "auto",
              textTransform: "none",
              fontSize: "0.7rem",
              height: "1.85rem",
              fontWeight: 500,
              backgroundColor: theme.palette.color.slate[50],
              alignItems: "center",
              justifyContent: "center",
              "& svg": {
                color: theme.palette.color.slate[300],
                fontSize: "1.2rem !important",
              },
              "&:hover": {
                backgroundColor: theme.palette.color.red[100],
                "& svg": {
                  color: theme.palette.color.red[600],
                },
              },
            }}
          >
            <TbPlugConnectedX />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

const FetchNewButton = ({ DS, dsRef }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const FinApiQueryKey = getFinApiQueryKey({ data_source: DS?.uuid });

  const IntegrationsQueryKey = [
    "integrations",
    {
      data_source: DS?.uuid,
      apiType: "data_source_external_connections",
    },
  ];
  const isFetchAll = [
    "Google Sheets API",
    "Google Sheets API 2",
    "Excel import",
  ]?.includes(DS?.note);

  const data_source_by_uuid_finapi_connections = useStatusHook(null, {
    queryKey: FinApiQueryKey,
  });
  const data_source_by_uuid_external_connections = useStatusHook(null, {
    queryKey: IntegrationsQueryKey,
  });

  const isValidDs = () => {
    if (DS.type === 10) {
      return false;
    }

    if (
      DS.type === 12 &&
      data_source_by_uuid_finapi_connections?.data !== "COMPLETED" &&
      data_source_by_uuid_finapi_connections?.data !== "UPDATED"
    ) {
      return false;
    }
    if (
      DS.type === 19 &&
      data_source_by_uuid_external_connections?.data !== "UPDATED"
    ) {
      return false;
    }
    return true;
  };

  const fetchNewTransaction = () => {
    dsRef?.current?.fetchNewTransaction(DS);
  };

  const fetchAllTransaction = () => {
    dsRef?.current?.fetchAllTransaction(DS);
  };
  // if (DS?.type === 12) {
  //   console.log(
  //     "finapi_connections:",
  //     DS?.title,
  //     data_source_by_uuid_finapi_connections?.data,
  //     DS,
  //     isValidDs()
  //   );
  // }

  if (!DS || !DS?.last_sync_date || DS?.type === 1 || DS?.type === 2) {
    return null;
  }
  if (!isValidDs()) {
    return null;
  }
  return (
    <Button
      id="action-fetch-new-button"
      size="small"
      onClick={isFetchAll ? fetchAllTransaction : fetchNewTransaction}
      sx={{
        display: "flex",
        minWidth: "auto",
        textTransform: "none",
        fontSize: "0.7rem",
        height: "1.85rem",
        fontWeight: 500,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.color.slate[50],
        color: theme.palette.color.slate[300],
        px: "0.5rem",
        "& svg": {
          color: theme.palette.color.slate[300],
          fontSize: "1.2rem !important",
          mr: "0.5rem",
        },
        "&:hover": {
          backgroundColor: theme.palette.color.sky[100],
        },
      }}
    >
      <PiArrowsClockwiseBold />
      {t("Check_for_new_transactions")}
    </Button>
  );
};

const OrgSettingsView = () => {
  const theme = useTheme();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClickDataSetSetting = () => {
    navigate(`/settings/organizations`);
  };

  return (
    <Tooltip title={t("org_settings_tooltip")}>
      <Box
        className="dashboard_overview_8_step"
        sx={{
          ml: "0.5rem",
          p: { xs: "0.25rem", small: "0.5rem" },
          display: "inline-flex",
          alignItem: "center",
          JustifyContent: "center",
          "&:hover": {
            borderRadius: 10,
            opacity: 1,
            backgroundColor: theme.palette.color.slate[200],
          },
        }}
      >
        <Icon
          onClick={onClickDataSetSetting}
          icon={<PiGearSix />}
          fontSize={{ xs: "1.5rem", small: "1.3rem" }}
          color={theme.palette.color.slate[800]}
          style={{ cursor: "pointer" }}
        ></Icon>
      </Box>
    </Tooltip>
  );
};

const DataSetView = () => {
  const dispatch = useDispatch();
  const isDataUpdated = useRef(false);
  const theme = useTheme();

  //redux state
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const dataSetList = useSelector((state) => state.boardSlice?.dataSetList);
  const profile = useSelector((state) => state.settingsSlice.profile);

  //api
  const updateDataSetByID = async (id, obj) => {
    await APICall("patch", EndPoints.datasets + `${id}/`, obj).then(
      (response) => {
        if (response.status === 200 && response.data) {
        }
      }
    );
  };

  //function
  const handleEditChange = (e) => {
    const { value } = e.target;
    isDataUpdated.current = true;
    if (!value && value?.trim() === "") {
    } else {
      dispatch(setDataSetData({ partial: { title: value } }));
    }
  };

  const handleEditBlurChange = (e) => {
    if (isDataUpdated.current) {
      let { value } = e.target;
      let obj = {
        title: value,
      };
      let data = [...dataSetList];
      let index = dataSetList?.findIndex((o1) => o1.uuid === dataSetData?.uuid);
      if (index > -1) {
        data[index] = { ...data[index], ...obj };
      }
      dispatch(setDataSetList(data));
      updateDataSetByID(dataSetData?.uuid, obj);
    }
  };

  return (
    <Box
      sx={{
        pl: "1.5rem",
        mb: "0.5rem",
        display: "flex",
        width: "fit-content",
        flexDirection: "row",
        alignItems: "center",
        position: "relative",
        "&:hover": {
          "& #dataset_title_edit": {
            display: "block !important",
          },
        },
      }}
    >
      <span
        style={{
          width: "3rem",
          // height: "3rem",
        }}
      >
        {profile?.company_logo ? (
          <img
            src={profile?.company_logo}
            alt="logo"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 12,
              objectFit: "cover",
            }}
          />
        ) : (
          <Icon
            icon={<MdBusiness />}
            fontSize={"2.2rem"}
            color="inherit"
            style={{
              marginTop: "0.25rem",
            }}
          ></Icon>
        )}
      </span>

      <TextField
        id="outlined-size-small"
        value={dataSetData?.title}
        onChange={handleEditChange}
        onBlur={handleEditBlurChange}
        size="small"
        sx={{
          "& svg": {
            borderRadius: theme.borderRadius.main,
            color: theme.palette.color.slate[200],
            position: "absolute",
            right: "0.5rem",
            fontSize: "1.3rem",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "0",
            borderRadius: theme.borderRadius.main,
          },
          "&:hover": {
            "& svg": {
              color: theme.palette.color.slate[700],
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px",
              borderRadius: theme.borderRadius.main,
              borderColor: theme.palette.color.slate[700],
            },
          },

          "& input": {
            borderRadius: theme.palette.color.slate[700],
            color: theme.palette.color.slate[700],
            fontSize: "1.3rem",
            fontFamily: theme.typography.fontFamily,
            fontWeight: 600,
            pl: "0.5rem",
            py: "0.25rem",
            pr: "1.75rem",
            textOverflow: "ellipsis",
          },
          ml: "1rem",
        }}
      />
      <span
        id="dataset_title_edit"
        style={{
          position: "absolute",
          right: "1%",
          fontSize: "1.3rem",
          lineHeight: "1.3rem",
          display: "none",
        }}
      >
        <MdOutlineEdit />
      </span>
    </Box>
  );
};

const SyncAllButton = ({ dsRef }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const syncAllIntegrations = () => {
    dsRef?.current?.syncAllIntegrations();
  };

  return (
    <Button
      size="small"
      onClick={syncAllIntegrations}
      sx={{
        display: "flex",
        minWidth: "auto",
        textTransform: "none",
        fontSize: "0.7rem",
        height: "1.85rem",
        fontWeight: 500,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.color.slate[50],
        color: theme.palette.color.slate[300],
        px: "0.5rem",
        mr: "1rem",
        "& svg": {
          color: theme.palette.color.slate[300],
          fontSize: "0.8rem !important",
          mr: "0.5rem",
        },
        "&:hover": {
          "& svg": {
            color: theme.palette.primary.main,
          },
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary[100],
        },
      }}
    >
      <GrUpdate />
      {t("sync_all_integrations")}
    </Button>
  );
};
