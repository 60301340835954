import React from "react";
import { Box, TextField, useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { forwardRef, useState } from "react";

import { Constant } from "../../../../Helper";

const SearchFilterView = forwardRef(
  (
    {
      color = "slate",
      onClickClear,
      onFocusInput,
      onBlurInput,
      onChange,
      value,
      active,
      inputRef,
      width = "9rem",
      sx,
    },
    _ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();

    //state
    const [activeFilter, setActiveFilter] = useState(false);

    const isActive = active || activeFilter;
    //functions
    const onFocus = () => {
      if (onFocusInput) onFocusInput();
      setActiveFilter(true);
    };

    const onBlur = () => {
      if (onBlurInput) onBlurInput();
      setActiveFilter(false);
    };

    return (
      <Box
        onClick={(e) => e.stopPropagation()} //important to prevent event propagation of onblur
        display="inline-flex"
        alignItems="center"
        justifyContent={"flex-start"}
        sx={{
          borderRadius: theme.borderRadius.borderRadiusXL,
          backgroundColor: isActive ? theme.palette.color.white : "transparent",
          border: `1px ${isActive ? "solid" : "dashed"} ${theme.palette.color[color][400]}`,
          height: Constant.FILTER_HEIGHT,
          ml: "0.5rem",
          "& .MuiOutlinedInput-notchedOutline": {
            color: theme.palette.color[color][isActive ? 500 : 400],
            border: "none",
          },
          ...sx,
        }}
      >
        <SearchIcon
          sx={{
            color: theme.palette.color[color][isActive ? 500 : 400],
            mt: "1.5px",
            ml: "0.5rem",
            fontSize: "1.3rem",
          }}
        />
        <TextField
          inputRef={inputRef}
          id="outlined-size-small"
          size="small"
          name="name"
          autoComplete="off"
          value={value}
          onChange={onChange}
          placeholder={t("Search")}
          onFocus={onFocus}
          onBlur={onBlur}
          sx={{
            width,
            fontWeight: theme.typography.fontWeightMedium,
            margin: "0px 0.5rem",
            ".MuiInputBase-input": {
              fontSize: "0.9rem",
              py: "0.3rem",
              px: "0.1rem",
              color: theme.palette.color[color][500],
              fontWeight: theme.typography.fontWeightMedium,
            },
          }}
        />

        {onClickClear && active ? (
          <ClearIcon
            onClick={onClickClear}
            sx={{
              mr: "0.5rem",
              fontSize: "1.1rem",
              cursor: "pointer",
            }}
          />
        ) : null}
      </Box>
    );
  }
);

export default SearchFilterView;
