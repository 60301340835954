import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { useMemo, useEffect } from "react";
import jwt_decode from "jwt-decode";

import ThemeSpinLoader from "../components/ThemeSpinLoader";
import authSlice from "../store/slices/auth";

/**
 * ProtectedRoute component - Guards routes that require authentication
 * 
 * Features:
 * - Checks for valid tokens
 * - Automatically redirects to login when tokens expire
 * - Shows loading state during authentication check
 * - Handles token expiration
 */
function ProtectedRoute({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isChecking, setIsChecking] = React.useState(true);

  // Decode JWT token to get expiration time
  const { exp } = useMemo(() => {
    if (auth?.refreshToken) {
      try {
        return jwt_decode(auth?.refreshToken);
      } catch (error) {
        console.error("Error decoding token:", error);
        return { exp: 0 };
      }
    } else {
      return { exp: 0 };
    }
  }, [auth?.refreshToken]);

  // Check token validity on mount and path change
  useEffect(() => {
    const checkTokenValidity = () => {
      setIsChecking(true);
      const logoutTime = exp * 1000;
      
      if (!exp || Date.now() >= logoutTime) {
        dispatch(authSlice.actions.logout());
        navigate("/login", { 
          replace: true,
          state: { from: location.pathname }  // Remember where user was trying to go
        });
      }
      
      setIsChecking(false);
    };
    
    checkTokenValidity();
    
    // Setup a timer to check token validity periodically
    const tokenCheckInterval = setInterval(() => {
      const logoutTime = exp * 1000;
      if (!exp || Date.now() >= logoutTime) {
        clearInterval(tokenCheckInterval);
        dispatch(authSlice.actions.logout());
        navigate("/login", { replace: true });
      }
    }, 60000); // Check every minute
    
    return () => clearInterval(tokenCheckInterval);
  }, [dispatch, exp, location.pathname, navigate]);

  // Show loading state while checking authentication
  if (isChecking) {
    return <ThemeSpinLoader loading={true} showTips={true} isFallback={true}/>;
  }

  // Allow access if authenticated
  if (auth?.account && auth?.refreshToken && auth?.token) {
    return children;
  } 
  
  // Redirect to login otherwise
  return (
    <Navigate 
      to="/login" 
      replace={true}
      state={{ from: location.pathname }} 
    />
  );
}

export default ProtectedRoute;
