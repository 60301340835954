import {
  createRoutesFromChildren,
  useNavigationType,
  BrowserRouter,
  useLocation,
  matchRoutes,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, useLayoutEffect, useEffect } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { Grow } from "@mui/material";
import { format } from "date-fns";
import "./styles.css";
import "./fonts.css";

import ThemeSpinLoader from "./components/ThemeSpinLoader";
import GlobalContextWrapper from "./GlobalContextWrapper";
import store, { persistor } from "./store";
import packageJson from "../package.json";
import ErrorPage from "./Pages/ErrorPage";
import Tour from "./components/Tour";
import { Constant } from "./Helper";
import AppRouter from "./routes";
import theme from "./theme";

// Prevent multiple Sentry instances by checking if it's already initialized
if (!window.__SENTRY_REPLAY_INTEGRATION__) {
  window.__SENTRY_REPLAY_INTEGRATION__ = true;

  Sentry.init({
    release: "finban@" + packageJson?.version,
    dsn: import.meta.env.VITE_SENTRY_DNS,
    environment: import.meta.env.VITE_MODE,
    debugIds: true,
    validate: true, // Ensure files are uploaded correctly
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        blockAllMedia: false,
      }),
    ],
    // beforeSend no longer needs to set the flag since we do it before init
    beforeSend(event) {
      // Ignore Service Worker registration errors from any domain
      const blockedError = [
        "Failed to fetch dynamically imported module",
        "Failed to register a ServiceWorker",
      ];
      if (event.exception && event.exception.values) {
        const errorMessage = event.exception.values[0].value;

        // Check if the error is related to Service Worker registration failure
        if (blockedError.some((error) => errorMessage?.includes(error))) {
          if (!window?.location?.origin?.includes(".finban.io/")) {
            return null; // Ignore errors from other domains
          }
        }
      }

      // Continue sending other events
      return event;
    },
    normalizeDepth: 7,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.7,
    // autoSessionTracking: false,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // "localhost",
      /^https:\/\/test\.finban\.io\/api/,
      /^https:\/\/staging\.finban\.io\/api/,
      /^https:\/\/app\.finban\.io\/api/,
    ],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error

    replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    enabled: import.meta.env.VITE_MODE !== "development",
  });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      cacheTime: (1000 * 60 * 60 * 1) / 48, // 0.5 hour
      refetchOnWindowFocus: false, // default: true
      refetchOnMount: false, // default: true
      // notifyOnChangeProps: [
      //   "isFetching",
      //   "isLoading",
      //   "isSuccess",
      //   "data",
      //   "error",
      // ],
      // staleTime: 60000,
      // refetchOnReconnect: false, // default: true
      // logger: {
      //   log: (...args) => {
      //     // Log debugging information
      //   },
      //   warn: (...args) => {
      //     // Log warning
      //   },
      //   error: (...args) => {
      //     // Log error
      //   },
      // },
    },
  },
});

export default function App() {
  useLayoutEffect(() => {
    if (import.meta.env.VITE_PLAUSIBLE_DATA_DOMAIN) {
      const script = document.createElement("script");
      script.src = import.meta.env.VITE_PLAUSIBLE_URL;
      script.defer = true;
      script.type = "text/javascript";
      script.setAttribute(
        "data-domain",
        import.meta.env.VITE_PLAUSIBLE_DATA_DOMAIN
      );
      document.body.append(script);
    }
  }, []);

  const onError = (error) => {
    if (Constant?.isTest) {
      console.log("Error  ==> ", error);
    }
    if (!Constant?.isDev) {
      const user = localStorage.getItem("LoggedInUser");

      Sentry.setExtra("onError  ==> ", {
        message: error?.message,
        response: error?.response,
        status: error?.status || error?.response?.status,
        time: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        user,
      });
      Sentry.captureException(error);
    }
    if (error?.message?.includes("Maximum call stack size exceeded")) {
      clearAllState();
      if (!window?.location?.pathname?.includes("/login")) {
        window.location.href = "/login";
      }
    }
  };

  const clearAllState = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("last_visited_dataset");
    localStorage.removeItem("LoggedInUser");
    localStorage.removeItem("persist:root");
    queryClient.removeQueries();
  };

  return (
    <span>
      <GoogleAnalytics />
      <ClarityAnalytics />
      <Sentry.ErrorBoundary
        FallbackComponent={ErrorPage}
        onError={onError}
        FallbackRender={null}
      >
        <ThemeProvider theme={theme}>
          <Suspense
            fallback={
              <ThemeSpinLoader
                loading={true}
                showTips={true}
                isFallback={true}
              />
            }
          >
            <SnackbarProvider
              maxSnack={3}
              variant="success"
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              TransitionComponent={Grow}
            >
              <Provider store={store}>
                <PersistGate persistor={persistor} loading={null}>
                  <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                      <GlobalContextWrapper>
                        <Tour />
                        <AppRouter />
                      </GlobalContextWrapper>
                    </BrowserRouter>
                  </QueryClientProvider>
                </PersistGate>
              </Provider>
            </SnackbarProvider>
          </Suspense>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </span>
  );
}

const GoogleAnalytics = () => {
  const GA_ID = import.meta.env.VITE_GTAG_ID;

  useEffect(() => {
    const GTM_ID = "GTM-PPVDD3T"; // Your GTM ID

    if (GTM_ID && Constant.isProd) {
      // Google Tag Manager Script
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l !== "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", GTM_ID);
    }

    if (GA_ID && Constant.isProd) {
      // Load GA4 script dynamically
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
      document.head.appendChild(script);

      // Initialize gtag
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;

      gtag("js", new Date());
      gtag("config", GA_ID);
    }
  }, [GA_ID]);

  if (!Constant.isProd) {
    return null;
  }
  return (
    <noscript>
      {/* Google Tag Manager (noscript) */}
      <iframe
        title="gtag"
        src="https://www.googletagmanager.com/ns.html?id=GTM-PPVDD3T"
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
  );
};

const ClarityAnalytics = () => {
  function setCookie(name, value, days) {
    const expires = new Date(
      Date.now() + days * 24 * 60 * 60 * 1000
    ).toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

  function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
  }

  function getOrCreateSessionId() {
    let sessionId = getCookie("clarity_session_id");
    if (sessionId) {
      if (!sessionStorage.getItem("clarity_session_data")) {
        const sessionStartTime = Date.now();
        sessionStorage.setItem(
          "clarity_session_data",
          JSON.stringify({ sessionId, sessionStartTime })
        );
      }
      return sessionId;
    }

    sessionId = "session-" + Math.random().toString(36).substring(2, 11);
    const sessionStartTime = Date.now();
    sessionStorage.setItem(
      "clarity_session_data",
      JSON.stringify({ sessionId, sessionStartTime })
    );
    setCookie("clarity_session_id", sessionId, 7);
    return sessionId;
  }

  function hasSessionExpired(sessionStartTime) {
    const sessionTimeout = 30 * 60 * 1000; // 30 minutes
    return Date.now() - sessionStartTime > sessionTimeout;
  }

  function manageSession() {
    const userAgent = navigator.userAgent;
    if (/bot|crawler|spider|robot|crawling/i.test(userAgent)) {
      // Skip session creation for bots
      return null;
    }

    let sessionData = sessionStorage.getItem("clarity_session_data");
    if (sessionData) {
      sessionData = JSON.parse(sessionData);
      if (hasSessionExpired(sessionData.sessionStartTime)) {
        sessionStorage.removeItem("clarity_session_data");
        deleteCookie("clarity_session_id");
      } else {
        return sessionData.sessionId;
      }
    }
    return getOrCreateSessionId();
  }

  let sessionId = null;
  let clarityId = null;

  try {
    sessionId = import.meta.env.VITE_CLARITY_ID ? manageSession() : null;

    if (import.meta.env.VITE_CLARITY_ID) {
      clarityId = (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.type = "text/javascript";
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
        c[a]("set", "session_id", sessionId);
      })(
        window,
        document,
        "clarity",
        "script",
        import.meta.env.VITE_CLARITY_ID
      );
    }
  } catch (error) {
    // Handle the error, but don't report it to Sentry
  }

  return (
    <>
      <script>{clarityId}</script>
    </>
  );
};
