import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { forwardRef } from "react";
import React from "react";

import ToolTipWrapper from "../../TooltipWrapper";
import { Constant } from "../../../Helper";

const FilterBaseView = forwardRef(
  (
    {
      tooltip,
      color = "slate",
      icon,
      heading,
      allValue,
      value,
      Placeholder = "Select option",
      isActive,
      onClick,
      onClear,
      notEditable,
      maxWidth = "7rem",
      tooltipSx,
      baseSx,
    },
    _ref
  ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
      <ToolTipWrapper
        title={allValue || t(tooltip)}
        isOverlay={!!allValue}
        placement="top"
        tooltipSx={tooltipSx}
      >
        <Box
          ref={_ref}
          sx={{
            color: theme.palette.color[color][600],
            border: `1px solid ${theme.palette.color[color][isActive ? 400 : 200]}`,
            fontWeight: theme.typography.fontWeightMediumBold,
            backgroundColor: theme.palette.color[color][100],
            borderRadius: theme.borderRadius.borderRadiusXL,
            fontSize: "0.9rem",
            lineHeight: "0.9rem",
            // pl: "0.5rem",
            height: Constant.FILTER_HEIGHT,
            display: "flex",
            alignItems: "center",
            "& svg": {
              color: theme.palette.color[color][600],
            },
            ...baseSx,
          }}
        >
          <span
            style={{
              gap: "0.25rem",
              display: "inline-flex",
              alignItems: "center",
              borderTopLeftRadius: theme.borderRadius.borderRadiusXL,
              borderBottomLeftRadius: theme.borderRadius.borderRadiusXL,
              backgroundColor: "transparent",
              height: `calc(${Constant.FILTER_HEIGHT} - 2px)`,
              paddingInline: "0.5rem",
              borderRadius: "inherit",
            }}
          >
            {icon}
            {heading ? t(heading) : ""}
          </span>

          <Divider
            orientation="vertical"
            flexItem
            sx={{
              borderColor: theme.palette.color[color][isActive ? 400 : 200],
            }}
          />
          <Stack
            className="filter-base-view-placeholder"
            onClick={onClick}
            sx={{
              display: "flex",
              px: "0.5rem",
              alignItems: "center",
              flexDirection: "row",
              cursor: "pointer",
              color: theme.palette.color[color][!value ? 400 : 500],
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: "0.9rem",
              lineHeight: "normal",
              height: "100%",
              maxWidth: maxWidth,
              borderRadius: "inherit",
              "&:hover": {
                backgroundColor: theme.palette.color[color][200],
              },
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                height: "100%",
                borderRadius: "inherit",
              }}
            >
              {value || t(Placeholder)}
            </span>
          </Stack>
          {onClear ? (
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: theme.palette.color[color][isActive ? 400 : 200],
              }}
            />
          ) : null}
          {onClear ? (
            <Box
              onClick={notEditable ? undefined : onClear}
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                cursor: "pointer",
                borderTopRightRadius: theme.borderRadius.borderRadiusXL,
                borderBottomRightRadius: theme.borderRadius.borderRadiusXL,
                "&:hover": {
                  backgroundColor: theme.palette.color[color][200],
                },
              }}
            >
              <ClearIcon
                sx={{
                  mx: "0.25rem",
                  color: theme.palette.color[color][!value ? 400 : 500],
                  fontSize: "1.1rem",
                  opacity: notEditable ? 0.5 : 1,
                }}
              />
            </Box>
          ) : null}
        </Box>
      </ToolTipWrapper>
    );
  }
);

export default FilterBaseView;
