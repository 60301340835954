import { startOfYear, endOfYear, format } from "date-fns";
import { useIsFetching } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import React from "react";

import {
  getPlanningChartQueryKey,
  getReportPagesQueryKey,
  getContactQueryKey,
  pastTableQueryKey,
  getStaffQueryKey,
  tableQueryKey,
} from "../Helper/queryKeys";
import { getConnectedScenariosUuid } from "../Helper/functions";
import { queryClient } from "../App";

// Hook
function useStatusHook(key, payload) {
  const appliedFilterlist = useSelector(
    (state) => state.globalSlice.appliedFilterlist
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const startDate = useSelector((state) => state.reportSlice?.startDate);
  const endDate = useSelector((state) => state.reportSlice?.endDate);
 
  const highlightedScenarios = useSelector(
    (state) => state.boardSlice?.highlightedScenarios
  );
  const scenarioById = useSelector((state) => state.globalSlice.scenarioById);

  const selected_scenario_uuid = useMemo(() => {
    if (highlightedScenarios?.[0] && key === "table_data") {
      const _scenario = scenarioById?.[highlightedScenarios?.[0]]?.[0];
      return getConnectedScenariosUuid({ scenarioById, _scenario });
    } else {
      return [];
    }
  }, [highlightedScenarios, key, scenarioById]);

  let queryKey = [];
  if (key === "normal_monthly_transactions") {
    queryKey = [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        start_date: dataSetData?.start_date,
        apiType: "normal_monthly_transactions",
        type: payload?.type,
        state: payload?.state,
        scenario: payload?.scenario,
        category: appliedFilterlist?.kanban?.selectedCategory,
        cost_unit: appliedFilterlist?.kanban?.selectedCostUnits,
        groupType: appliedFilterlist?.kanban?.groupType || "scenarios",
      },
    ];
  }
  //past data
  if (key === "past_table_data") {
    queryKey = pastTableQueryKey({
      planning_type: dataSetData?.planning_type,
      from_date: dataSetData?.start_date,
      dataset: dataSetData?.uuid,
    });
  }
  if (key === "table_data") {
    queryKey = tableQueryKey({
      planning_type: dataSetData?.planning_type,
      multiScenarioIds: selected_scenario_uuid,
      from_date: dataSetData?.start_date,
      to_date: dataSetData?.end_date,
      dataset: dataSetData?.uuid,
    });
  }
  if (key === `transaction_${dataSetData?.planning_type}_chart`) {
    queryKey = getPlanningChartQueryKey({
      planning_type: dataSetData?.planning_type,
      default_vat_pay_months: 1,
      from_date: dataSetData?.start_date,
      to_date: dataSetData?.end_date,
      dataset: dataSetData?.uuid,
    });
  }
  if (key === "ReportPages") {
    queryKey = getReportPagesQueryKey({ dataset: dataSetData?.uuid });
  }
  if (key === "period_data") {
    const period_start_date = startDate
      ? format(new Date(startDate), "yyyy-MM-dd")
      : format(startOfYear(new Date()), "yyyy-MM-dd");
    const period_end_date = startDate
      ? format(new Date(endDate), "yyyy-MM-dd")
      : format(endOfYear(new Date()), "yyyy-MM-dd");
    queryKey = [
      "transactions",
      {
        dataset: dataSetData?.uuid,
        apiType: "period_data",
        from_payment_date: period_start_date,
        to_payment_date: period_end_date,
      },
    ];
  }
  if (key === "staff") {
    queryKey = getStaffQueryKey();
  }
  if (key === "contacts") {
    queryKey = getContactQueryKey({ dataset: dataSetData?.uuid });
  }
  if (!key) {
    queryKey = payload?.queryKey;
  }

  const isFetching = useIsFetching({
    queryKey: queryKey,
  });
  const data = queryClient.getQueryData(queryKey);

  return { isFetching: Boolean(isFetching), data };
}

export default useStatusHook;
