import React from "react";
import { Tab, Tabs, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

import { truncate } from "../Helper/data";

const StyledTabs = styled((props) => <Tabs {...props} />)(({ theme }) => {
  return {
    minHeight: "2.5rem",
    "& .MuiTab-iconWrapper": {
      marginBottom: "0 !important",
      marginRight: "0.25rem !important",
    },
    "& .MuiTab-root": {
      display: "flex",
      minWidth: "6rem",
      paddingInline: 0,
      marginRight: "1.5rem",
      alignItems: "center",
      flexDirection: "row",
      minHeight: "1.8rem",
      fontSize: "0.95rem",
      color: theme.palette.primary.dark,
      fontWeight: theme.typography.fontWeightMediumBold,
      textTransform: "initial",
    },
    "& [aria-selected=false]": {
      opacity: 1,
      color: theme.palette.color.slate[500],
    },
    "& [aria-selected=true]": {
      color: theme.palette.primary.dark,
      "& svg": {
        color: `${theme.palette.primary.dark} !important`,
      },
    },

    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary.dark,
    },
  };
});
const ThemeTabs = ({
  tab,
  setTab,
  truncateText,
  truncateTextCount = 12,
  options = [],
  hide = [],
  doNotTranslate = false,
  ...props
}) => {
  const { t } = useTranslation();
  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  if (!options?.length) return null;

  return (
    <StyledTabs
      value={tab}
      onChange={handleChange}
      textColor="inherit"
      indicatorColor="secondary"
      aria-label="secondary tabs example"
      {...props}
    >
      {options?.map((option) => {
        if (hide?.includes(option?.value)) {
          return null;
        }

        return (
          <Tab
            {...option}
            key={option?.value || option?.uuid}
            value={option?.value || option?.uuid}
            label={
              truncateText
                ? truncate(
                    doNotTranslate
                      ? option?.label || option?.title
                      : t(option?.label || option?.title),
                    truncateTextCount
                  )
                : doNotTranslate
                  ? option?.label || option?.title
                  : t(option?.label || option?.title)
            }
          />
        );
      })}
    </StyledTabs>
  );
};

export default ThemeTabs;
