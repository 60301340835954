import React from "react";
import { useState } from "react";
import useDebounce from "./3-useDebounce/useDebounce";

export function useDeferredTimeout({ value, timeoutMs = 500 }) {
  const [_value, setValue] = useState(value);
  useDebounce(
    () => {
      setValue(value);
    },
    timeoutMs,
    [value]
  );
  return _value;
}
