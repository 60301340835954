import {
  FormControlLabel,
  Typography,
  Checkbox,
  useTheme,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { enqueueSnackbar } from "notistack";
import React from "react";

import { setPopupStatus3 } from "../../store/slices/datasets";
import TailwindButton from "../Overlay/TailwindButton";
import KeyboardSortCutKey from "../KeyboardSortCutKey";
import CustomModal from "./CustomModal";

export default function ConfirmationModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  //redux
  // const action_confirm = useSelector(
  //   (state) => state.globalSlice.appliedFilterlist?.kanban[popupStatus3?.from]
  // );
  // const [rememberChecked, setRememberChecked] = React.useState(
  //   popupStatus3?.from ? action_confirm : popupStatus3?.rememberChecked
  // );
  const popupStatus3 = useSelector((state) => state.datasetSlice?.popupStatus3);

  //state
  const [isConfirmed, setIsConfirmed] = React.useState(false);

  //functions
  const onClose = () => {
    dispatch(setPopupStatus3(null));
    if (popupStatus3?.onClose) popupStatus3?.onClose();
  };

  const onCloseViaBackdrop = () => {
    if (!popupStatus3?.disableBackdropClick) onClose();
  };

  const onCloseViaButton = () => {
    onClose();
  };

  // const handleRememberChange = (event) => {
  //   setRememberChecked(event.target.checked);
  //   popupStatus3?.onCheckMarkChange(event.target.checked);
  //   if (popupStatus3?.from) {
  //     dispatch(
  //       updateFilters({ parent_key:"kanban", obj:{[popupStatus3?.from]: event.target.checked} })
  //     );
  //   }
  // };

  const handleCheckChange = (event) => {
    setIsConfirmed(event.target.checked);
  };

  const giveConfirmCheckWarning = () => {
    enqueueSnackbar(t("confirm_overlay_please_confirm_action"), {
      variant: "info",
      autoHideDuration: 3000,
    });
  };

  let color = "slate";
  let keyStyle = {};
  if (popupStatus3?.overlay_type === "info") {
    color = "blue";
  }
  if (popupStatus3?.overlay_type === "warn") {
    color = "amber";
  }
  if (popupStatus3?.overlay_type === "delete") {
    keyStyle = {
      borderColor: theme.palette.color.red[700],
      backgroundColor: theme.palette.color.red[100],
      // opacity: popupStatus3?.showConfirmCheckBox && !isConfirmed ? 0.15 : 1,
      color: theme.palette.color.red[500],
      fontSize: "1.1rem",
    };
    color = "red";
  }
  if (popupStatus3?.overlay_type === "success") {
    color = "green";
  }

  return (
    <CustomModal
      hideAction
      open={!!popupStatus3?.open}
      onClose={onCloseViaBackdrop}
      width="100%"
      sx={{
        zIndex: 1320,
      }}
      PaperPropsSx={{
        minWidth: "45rem",
        maxWidth: "50rem",
        padding: "0.25rem",
      }}
    >
      <div
        style={{
          position: "relative",
          padding: "1.5rem",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography
          variant="h5"
          fontWeight={"fontWeightBold"}
          color="color.mainTitle"
        >
          {popupStatus3?.payload?.title}
        </Typography>
        <Box mt="1.5rem" mb="1.5rem">
          <Typography
            variant="body1"
            sx={{
              backgroundColor: theme.palette.color?.[color]?.[50],
              color: theme.palette.color?.[color]?.[500],
              p: "1rem",
              borderRadius: theme.borderRadius.main,
            }}
          >
            {popupStatus3?.payload?.message}
          </Typography>
        </Box>
        {popupStatus3?.showConfirmCheckBox ? (
          <FormControlLabel
            sx={{
              mb: "0.5rem",
              ml: "-9px",
              color: theme.palette.color?.description,
              alignItems: "start",
              "& .MuiCheckbox-root": {
                color: theme.palette.color.description,
                py: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              },
            }}
            control={
              <Checkbox
                variant="outlined"
                value="remember"
                color="primary"
                checked={isConfirmed || false}
                onChange={handleCheckChange}
              />
            }
            label={t(popupStatus3?.confirmCheckBoxLabel)}
          />
        ) : null}
        <Box
          sx={{
            marginTop: "1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            position: "relative",
          }}
        >
          {popupStatus3?.payload?.linkText ? (
            <Typography
              variant="caption"
              fontWeight={"fontWeightMedium"}
              component={"a"}
              color="color.slate.600"
              href={popupStatus3?.payload?.link}
              target={"_blank"}
              sx={{
                position: "absolute",
                left: 0,
                textDecoration: "underline",
                "&:hover": {
                  color: theme.palette.color.slate[700],
                },
              }}
            >
              {popupStatus3?.payload?.linkText}
            </Typography>
          ) : null}
          <div style={{ display: "flex", alignItems: "center" }}>
            {!popupStatus3?.payload?.hideCancel && (
              <TailwindButton
                type="cancel"
                text={popupStatus3?.payload?.cancelText || t("Cancel")}
                onClick={onCloseViaButton}
                icon={
                  <KeyboardSortCutKey
                    keys={["ESC"]}
                    theme={theme}
                    action={onCloseViaButton}
                    style={{
                      marginLeft: "0.5rem",
                    }}
                  />
                }
                sx={{
                  ml: 0,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row-reverse",
                }}
              />
            )}
            <TailwindButton
              text={
                popupStatus3?.loading
                  ? undefined
                  : popupStatus3?.payload?.confirmText || t("Confirm")
              }
              loading={popupStatus3?.loading}
              // disable={popupStatus3?.showConfirmCheckBox && !isConfirmed}
              icon={
                popupStatus3?.loading ? undefined : (
                  <KeyboardSortCutKey
                    keys={["Enter"]}
                    theme={theme}
                    action={
                      popupStatus3?.showConfirmCheckBox && !isConfirmed
                        ? () => giveConfirmCheckWarning()
                        : popupStatus3?.onConfirm
                          ? () =>
                              popupStatus3?.onConfirm({
                                payload: popupStatus3?.payload,
                              })
                          : undefined
                    }
                    keyStyle={keyStyle}
                    style={{
                      marginLeft: "0.5rem",
                    }}
                  />
                )
              }
              onClick={
                popupStatus3?.showConfirmCheckBox && !isConfirmed
                  ? () => giveConfirmCheckWarning()
                  : popupStatus3?.onConfirm
                    ? () =>
                        popupStatus3?.onConfirm({
                          payload: popupStatus3?.payload,
                        })
                    : undefined
              }
              color={theme.palette.color?.white}
              backgroundColor={theme.palette.color?.[color]?.[600]}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                py: popupStatus3?.loading ? "1.2rem" : "0.75rem",
                "& .MuiLoadingButton-loadingIndicator": {
                  color: theme.palette.color?.[color]?.[500],
                },
                // "&.MuiLoadingButton-root.Mui-disabled": {
                //   backgroundColor: `${theme.palette.color?.[color]?.[200]} !important`,
                //   color: `${theme.palette.color.white} !important`,
                //   fontWeight: theme.typography.fontWeightMediumBold,
                // },
              }}
            />
          </div>

          {/* for kanban did used  */}
          {/* {popupStatus3?.showCheckBox ? (
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                fontSize: "0.9rem",
              },
            }}
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={rememberChecked || false}
                onChange={handleRememberChange}
              />
            }
            label={t("don't show this checkbox again")}
          />
        ) : null} */}
        </Box>
      </div>
    </CustomModal>
  );
}
