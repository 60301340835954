import {
  Drawer as MuiDrawer,
  IconButton,
  useTheme,
  Divider,
  styled,
  Stack,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect } from "react";
import React from "react";

import { setMainDrawer } from "../../store/slices/settings";
import TabView from "./DatasetHeader/TabView";
import { Constant } from "../../Helper";

const openedMixin = (theme) => ({
  width: Constant.DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: Constant.DRAWER_WIDTH_CLOSED,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: Constant.DRAWER_WIDTH,
  height: "100%",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiPaper-root": {
    borderRadius: 0,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MainDrawerView = ({ showHeader, isStartPage }) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  //redux
  const isMainDrawerOpen = useSelector(
    (state) => state.settingsSlice?.isMainDrawerOpen
  );

  //functions
  const handleDrawerClose = () => {
    dispatch(setMainDrawer(false));
  };

  const handleDrawerOpen = () => {
    dispatch(setMainDrawer(true));
  };

  useEffect(() => {
    if (isMainDrawerOpen && isStartPage) {
      handleDrawerClose();
    }
  }, [isMainDrawerOpen, isStartPage]);

  if (!showHeader || isStartPage) {
    return null;
  }

  return (
    <Drawer
      variant="permanent"
      open={isMainDrawerOpen}
      sx={{
        display: { xs: "flex", s2133: "none" },
        height: `calc(100% - ${Constant.BANNER_HEIGHT})`,
        backgroundColor: `${theme.palette.color.slate[100]} !important`,
        zIndex: 999,
        "& .MuiDrawer-paper": {
          backgroundColor: `${theme.palette.color.slate[100]} !important`,
        },
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={isMainDrawerOpen ? "flex-end" : "flex-start"}
        alignItems={"center"}
        sx={{
          mt: Constant.BANNER_HEIGHT,
          height: Constant.HEADER_HEIGHT,
        }}
      >
        <IconButton
          size="large"
          aria-controls="menu-appbar"
          disableFocusRipple
          onClick={isMainDrawerOpen ? handleDrawerClose : handleDrawerOpen}
          sx={{
            p: "8px",
            ml: !isMainDrawerOpen ? "0.25rem" : "0",
            height: "fit-content",
          }}
        >
          {!isMainDrawerOpen ? (
            <MenuIcon
              sx={{
                color: theme.palette.primary.main,
              }}
            />
          ) : (
            <ChevronLeftIcon sx={{ color: theme.palette.primary.dark }} />
          )}
        </IconButton>
      </Stack>
      <Divider />
      <TabView isMainDrawerOpen={isMainDrawerOpen} />
    </Drawer>
  );
};

export default MainDrawerView;
