import React from "react";
import { Box, ClickAwayListener, useTheme } from "@mui/material";

const ActionViewWrapper = ({
  sx,
  onClickAway,
  children,
  width,
  height = "2.2rem",
  iconMR = 0,
  wrapperBackgroundColor,
  hoverBtnBackgroundColor,
  hoverBtnIconColor,
  btnBackgroundColor = "#fff",
  ...rest
}) => {
  const theme = useTheme();
  return (
    <ClickAwayListener
      onClickAway={onClickAway ? onClickAway : () => undefined}
    >
      <Box
        size="small"
        {...rest}
        sx={{
          right: "1rem",
          display: "flex",
          alignSelf: "flex-end",
          justifyContent: "space-between",
          maxWidth: "fit-content",
          borderRadius: "50px",
          cursor: "pointer",
          backgroundColor:
            wrapperBackgroundColor || theme.palette.color.slate[200],
          "& .actionBtn": {
            backgroundColor: btnBackgroundColor,
            borderRadius: 50,
            fontWeight: 600,
            fontSize: "0.8rem",
            minWidth: !width ? "auto" : "2.2rem",
            textTransform: "initial",
            width: width || "2.2rem",
            height: height,
            marginInline: "0.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            marginBlock: "0.37rem",
            "&: hover": {
              backgroundColor:
                hoverBtnBackgroundColor || theme.palette.color.slate[50],
              "& .actionBtnIcon, svg": {
                color: `${hoverBtnIconColor || theme.palette.primary.main} !important`,
              },
            },
            "& .actionBtnIcon": {
              color: theme.palette.primary.main,
              fontSize: "1.25rem",
              margin: 0,
              marginRight: iconMR,
            },
          },
          ...sx,
        }}
      >
        {children}
      </Box>
    </ClickAwayListener>
  );
};

export default ActionViewWrapper;
