import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Route, Routes, useLocation } from "react-router-dom";
import React, { useState, Suspense } from "react";
import { Box, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import MainDrawerView from "../components/Header/MainDrawerView";
import initialData, { getTailwindColor } from "../Helper/data";
import ThemeSpinLoader from "../components/ThemeSpinLoader";
import LazyRouteWrapper from "./LazyRouteWrapper";
import Banner from "../components/Banner/Banner";
import ProtectedRoute from "./ProtectedRoute";
import Header from "../components/Header";
import GlobalViews from "./GlobalViews";
import { Constant } from "../Helper";

// Lazy load components for better code splitting
const SubscriptionPaymentSuccessful = React.lazy(() =>
  import("../Pages/Settings/Subscription/SubscriptionPaymentSuccessful")
);
const RecurringView = React.lazy(() =>
  import("../Pages/Datasets/Employees/Component/RecurringView")
);
const AccountActivateScreen = React.lazy(() =>
  import("../Pages/Auth/AccountActivateScreen")
);
const PasswordResetScreen = React.lazy(() =>
  import("../Pages/Auth/PasswordResetScreen")
);
const PassResetFormScreen = React.lazy(() =>
  import("../Pages/Auth/PassResetFormScreen")
);
const DataSetList = React.lazy(() =>
  import("../Pages/Settings/DataSetList/index")
);
const Categories = React.lazy(() =>
  import("../Pages/Settings/Categories/index")
);
const DataSource = React.lazy(() =>
  import("../Pages/Settings/DataSource/index")
);
const Transactions = React.lazy(() => import("../Pages/Datasets/Transactions"));
const RegisterScreen = React.lazy(() => import("../Pages/Auth/RegisterScreen"));
const Subscription = React.lazy(() => import("../Pages/Settings/Subscription"));
const Clients = React.lazy(() => import("../Pages/Datasets/Employees/Clients"));
const Reports = React.lazy(() => import("../Pages/Datasets/Reports/index"));
const General = React.lazy(() => import("../Pages/Settings/General/index"));
const Profile = React.lazy(() => import("../Pages/Settings/Profile/index"));
const LoginScreen = React.lazy(() => import("../Pages/Auth/LoginScreen"));
const Employees = React.lazy(() => import("../Pages/Datasets/Employees"));
const Loan = React.lazy(() => import("../Pages/Datasets/Employees/Loan"));
const Security = React.lazy(() => import("../Pages/Settings/Security"));
const Planning = React.lazy(() => import("../Pages/Datasets/Planning"));
const NotFoundPage = React.lazy(() => import("../Pages/NotFoundPage"));
const Settings = React.lazy(() => import("../Pages/Settings/index"));
const Vats = React.lazy(() => import("../Pages/Settings/Vat/index"));
const Dataset = React.lazy(() => import("../Pages/Datasets/index"));
const Rules = React.lazy(() => import("../Pages/Settings/Rules"));
const Users = React.lazy(() => import("../Pages/Settings/Users"));
const ErrorPage = React.lazy(() => import("../Pages/ErrorPage"));
const Start = React.lazy(() => import("../Pages/Start"));

// Route loading fallback component
const RouteLoadingFallback = () => (
  <ThemeSpinLoader loading={true} showTips={true} isFallback={true} />
);

const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

// Helper function to render a lazy-loaded component with proper error handling
const renderLazy = (Component, props = {}) => (
  <LazyRouteWrapper component={Component} {...props} />
);

// Helper function to render multiple routes with the same element
const renderMultiRoutes = ({ element: Element, paths, ...rest }) =>
  paths.map((path) => (
    <Route key={path} path={path} {...rest} element={Element} />
  ));

export default function AppRouter() {
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const isMainDrawerOpen = useSelector(
    (state) => state.settingsSlice?.isMainDrawerOpen
  );
  const beta_enabled = useSelector(
    (state) => state.settingsSlice?.profile?.beta_enabled
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isPageNotFound = useSelector(
    (state) => state.commonSlice.isPageNotFound
  );
  const profile = useSelector((state) => state.settingsSlice?.profile);
  const isDevMode = useSelector((state) => state.commonSlice?.isDevMode);

  const isFinbanAccount =
    profile?.email?.includes("@finban.io") ||
    profile?.email?.includes("@e-laborat.de");

  const [showBanner, setShowBanner] = useState(false);

  const allowedRoutes =
    auth?.token &&
    auth?.refreshToken &&
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/register") &&
    !location.pathname.includes("/registration/") &&
    !location.pathname.includes("/auth/");
  const showHeader = isAllHeaderApiFetched && allowedRoutes;
  const isStartPage =
    location?.pathname?.includes("/dashboard") || location?.pathname === "/";

  // Implementation of scroll restoration
  React.useEffect(() => {
    // Scroll to top when navigating to a new route
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Box
      component="div"
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        "& .MuiSkeleton-root": {
          backgroundColor: getTailwindColor("grey", 100),
        },
      }}
    >
      {isDevMode && (
        <ReactQueryDevtools
          initialIsOpen={false}
          position={"left-bottom"}
          buttonPosition={"left-bottom"}
        />
      )}

      {/* <RouterProvider
       router={router}
       fallbackElement={<h1>..Loading</h1>}
       /> */}
      {allowedRoutes ? <GlobalViews /> : null}
      {allowedRoutes ? (
        <Banner showBanner={showBanner} setShowBanner={setShowBanner} />
      ) : null}

      <Stack
        direction={"row"}
        sx={{
          width: "100%",
          height:
            showHeader && allowedRoutes
              ? `calc(100% - ${Constant.BANNER_HEIGHT})`
              : "100%",
          overflow: "hidden",
        }}
      >
        <MainDrawerView showHeader={showHeader} isStartPage={isStartPage} />
        <Box
          sx={{
            width: {
              xs:
                showHeader && !isStartPage
                  ? `calc(100% - ${
                      isMainDrawerOpen && showHeader && !isStartPage
                        ? Constant.DRAWER_WIDTH
                        : Constant.DRAWER_WIDTH_CLOSED
                    })`
                  : "100%",
              s2133: "100%",
            },
            height: "100%",
            position: "relative",
          }}
        >
          {showHeader && !isPageNotFound ? <Header /> : null}
          <div
            style={{
              width: "100%",
              height: `calc(100% - ${
                allowedRoutes ? Constant.HEADER_HEIGHT : "0px"
              })`,
            }}
          >
            <Suspense fallback={<RouteLoadingFallback />}>
              <SentryRoutes>
                {/* Public Routes */}
                <Route path="/login" element={renderLazy(LoginScreen)} />
                <Route path="/register" element={renderLazy(RegisterScreen)} />
                <Route
                  path="/register/activate/"
                  element={renderLazy(AccountActivateScreen)}
                />
                <Route
                  path="/auth/password_reset/"
                  element={renderLazy(PasswordResetScreen)}
                />
                <Route path="/error" element={renderLazy(ErrorPage)} />
                <Route
                  path="/register/password_reset/"
                  element={renderLazy(PassResetFormScreen)}
                />

                {!isAllHeaderApiFetched ? (
                  !auth.token ? (
                    <Route path="*" element={renderLazy(NotFoundPage)} />
                  ) : null
                ) : (
                  <React.Fragment>
                    {/* Dashboard Routes */}
                    {renderMultiRoutes({
                      paths: ["/", "/dashboard"],
                      element: (
                        <ProtectedRoute>{renderLazy(Start)}</ProtectedRoute>
                      ),
                    })}
                    {/* Dataset Routes */}
                    {/* `/${initialData?.path?.organization}/:id/list/suggestion`,
                        `/${initialData?.path?.organization}/:id/list/categorized`,
                        `/${initialData?.path?.organization}/:id/list/uncategorized`,
                        `/${initialData?.path?.organization}/:id/list/reconcile`,
                        `/${initialData?.path?.organization}/:id/list/issues`, */}
                    <Route
                      path={`/${initialData?.path?.organization}/:id`}
                      element={
                        <ProtectedRoute>{renderLazy(Dataset)}</ProtectedRoute>
                      }
                    />
                    <Route
                      path={`/${initialData?.path?.organization}/:id/table`}
                      element={
                        <ProtectedRoute>{renderLazy(Dataset)}</ProtectedRoute>
                      }
                    >
                      <Route
                        path=""
                        element={
                          <ProtectedRoute>
                            {renderLazy(Planning)}
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    <Route>
                      {renderMultiRoutes({
                        paths: [
                          `/${initialData?.path?.organization}/:id/list/bulk`,
                          `/${initialData?.path?.organization}/:id/list/bank`,
                          `/${initialData?.path?.organization}/:id/list/open`,
                          `/${initialData?.path?.organization}/:id/list/budget`,
                          `/${initialData?.path?.organization}/:id/list/reconcile`,
                          `/${initialData?.path?.organization}/:id/list/late`,
                          `/${initialData?.path?.organization}/:id/list/reconcile`,
                          `/${initialData?.path?.organization}/:id/list/all`,
                        ],
                        element: (
                          <ProtectedRoute>
                            {renderLazy(Transactions)}
                          </ProtectedRoute>
                        ),
                      })}
                    </Route>
                    <Route
                      path={`/${initialData?.path?.organization}/:id/employees`}
                      element={
                        <ProtectedRoute>{renderLazy(Employees)}</ProtectedRoute>
                      }
                    />
                    {isFinbanAccount && beta_enabled ? (
                      <Route
                        path={`/${initialData?.path?.organization}/:id/loans`}
                        element={
                          <ProtectedRoute>{renderLazy(Loan)}</ProtectedRoute>
                        }
                      />
                    ) : null}

                    {isFinbanAccount && beta_enabled ? (
                      <Route>
                        {renderMultiRoutes({
                          paths: [
                            `/${initialData?.path?.organization}/:id/customers/customers-list`,
                            `/${initialData?.path?.organization}/:id/customers/customers-portfolio-analysis`,
                            `/${initialData?.path?.organization}/:id/customers/top-customers`,
                            `/${initialData?.path?.organization}/:id/customers/merge-customers`,
                          ],
                          element: (
                            <ProtectedRoute>
                              {renderLazy(Clients)}
                            </ProtectedRoute>
                          ),
                        })}
                      </Route>
                    ) : null}

                    <Route>
                      {renderMultiRoutes({
                        paths: [
                          `/${initialData?.path?.organization}/:id/clients/recurring-subscriptions`,
                        ],
                        element: (
                          <ProtectedRoute>
                            {renderLazy(RecurringView)}
                          </ProtectedRoute>
                        ),
                      })}
                    </Route>
                    <Route>
                      {renderMultiRoutes({
                        paths: [
                          `/${initialData?.path?.organization}/:id/reports/overview`,
                        ],
                        element: (
                          <ProtectedRoute>{renderLazy(Reports)}</ProtectedRoute>
                        ),
                      })}
                    </Route>

                    <Route
                      path={`/${initialData?.path?.organization}/:id/categories`}
                      element={
                        <ProtectedRoute>
                          {renderLazy(Categories, { isMainPage: true })}
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/settings"
                      element={
                        <ProtectedRoute>{renderLazy(Settings)}</ProtectedRoute>
                      }
                    >
                      <Route
                        path="/settings/security"
                        element={
                          <ProtectedRoute>
                            {renderLazy(Security)}
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/settings/personal"
                        element={
                          <ProtectedRoute>{renderLazy(Profile)}</ProtectedRoute>
                        }
                      />
                      <Route
                        path="/settings/users"
                        element={
                          <ProtectedRoute>{renderLazy(Users)}</ProtectedRoute>
                        }
                      />
                      <Route
                        path="/settings/general"
                        element={
                          <ProtectedRoute>{renderLazy(General)}</ProtectedRoute>
                        }
                      />

                      <Route
                        path={`/settings/${initialData?.path?.organizations}`}
                        element={
                          <ProtectedRoute>
                            {renderLazy(DataSetList)}
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="/settings/vats"
                        element={
                          <ProtectedRoute>{renderLazy(Vats)}</ProtectedRoute>
                        }
                      />
                      <Route
                        path="/settings/rules"
                        element={
                          <ProtectedRoute>{renderLazy(Rules)}</ProtectedRoute>
                        }
                      />
                      <Route
                        path="/settings/Integrations"
                        element={
                          <ProtectedRoute>
                            {renderLazy(DataSource, { type: "general" })}
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/settings/subscription"
                        element={
                          <ProtectedRoute>
                            {renderLazy(Subscription)}
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/settings/subscription/successful-payment/"
                        element={
                          <ProtectedRoute>
                            {renderLazy(SubscriptionPaymentSuccessful)}
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="*"
                      element={renderLazy(NotFoundPage, { isLogin: true })}
                    />
                  </React.Fragment>
                )}
              </SentryRoutes>
            </Suspense>
          </div>
        </Box>
      </Stack>
    </Box>
  );
}
