import React, { useState } from "react";

import useUpdateEffect from "../../../hooks/4-useUpdateEffect/useUpdateEffect";
import CustomPopoverScratch from "../../PopOver/CustomPopover";
import DropdownOverlay from "./DropdownOverlay";
import DropdownBase from "./DropdownBase";

const CustomDropdown = ({
  width = "12rem",
  color = "slate",
  disabled,
  placeholder,
  placeholderIcon,
  create_new_text,
  display_key,
  searchType,
  end_point,
  hideRadioButtons,
  defaultOptions,
  menuOptions,
  payload,
  value,
  onChange,
  onClickMenuItem,
  primaryItemView,
  secondaryItemView,
  customOpenAddForm,
  query_options_payload = {},
  dropdownBaseSX,
  titleCount,
  key1,
  key2,
  button,
}) => {
  const [selectedItem, setSelectedItem] = useState(value);

  useUpdateEffect(() => setSelectedItem(value), [value]);

  return (
    <CustomPopoverScratch
      disabled={disabled}
      button={
        button ? (
          React.cloneElement(button, { selectedItem, value, disabled })
        ) : (
          <DropdownBase
            disabled={disabled}
            width={width}
            color={color}
            placeholder={placeholder}
            placeholderIcon={placeholderIcon}
            display_key={display_key}
            selectedItem={selectedItem}
            sx={dropdownBaseSX}
          />
        )
      }
    >
      <DropdownOverlay
        color={color}
        defaultOptions={defaultOptions}
        menuOptions={menuOptions}
        selectedItem={selectedItem}
        searchType={searchType}
        display_key={display_key}
        end_point={end_point}
        hideRadioButtons={hideRadioButtons}
        setSelectedItem={setSelectedItem}
        onChange={onChange}
        payload={payload}
        create_new_text={create_new_text}
        onClickMenuItem={onClickMenuItem}
        primaryItemView={primaryItemView}
        secondaryItemView={secondaryItemView}
        customOpenAddForm={customOpenAddForm}
        query_options_payload={query_options_payload}
        titleCount={titleCount}
        key1={key1}
        key2={key2}
      />
    </CustomPopoverScratch>
  );
};

export default CustomDropdown;
