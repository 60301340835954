import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import i18next from "i18next";
import React from "react";

// import en from "./Helper/translations/en/translations.json";
// import de from "./Helper/translations/de/translations.json";

// const options = {
//   order: ["navigator"],
//   lookupQuerystring: "lng",
// };

// i18next
//   .use(
//     new Backend(null, {
//       loadPath: "/translations/{{lng}}/{{ns}}.json",
//     })
//   )
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     detection: options,
//     supportedLngs: ["en", "de"],

//     // we init with resources
//     fallbackLng: "en",
//     resources: {
//       en: { translations: en },
//       de: { translations: de },
//     },
//     debug: true,

//     // have a common namespace used around the full app
//     ns: ["translations"],
//     defaultNS: "translations",

//     keySeparator: false, // we use content as keys

//     interpolation: {
//       escapeValue: false,
//     },
//   });

// i18next.fallbacks = true;

// i18next.translations = {
//   en,
//   de,
//   // fr,
//   // nl,
// };

const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${import.meta.env.VITE_I18N_API_KEY}`;
i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "de",
    supportedLngs: ["en", "de"],
    ns: ["default"],
    defaultNS: "default",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: loadPath,
    },
  });

export default i18next;

//backup until 9 march 2025
