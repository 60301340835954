import { Tooltip, useTheme } from "@mui/material";
import React from "react";

const ToolTipWrapper = ({
  children,
  title,
  slotProps,
  restProps,
  tooltipSx,
  isOverlay,
}) => {
  const theme = useTheme();
  return (
    <Tooltip
      placement="top"
      title={
        isOverlay ? (
          <div
            style={{
              padding: "0.3rem",
              boxShadow: theme.boxShadow,
              backgroundColor: theme.palette.color.overlayBorderShade,
              borderRadius: theme.borderRadius.borderRadiusXL,
              border: "none",
              outline: "none",
            }}
          >
            <div
              style={{
                outline: `2px solid ${theme.palette.color.grey[300]}`,
                borderRadius: theme.borderRadius.borderRadiusXL,
                backgroundColor: theme.palette.color.white,
              }}
            >
              {title}
            </div>
          </div>
        ) : (
          title
        )
      }
      // leaveDelay={200000}
      slotProps={{
        tooltip: {
          sx: {
            borderRadius: isOverlay
              ? theme.borderRadius.borderRadiusXL2
              : theme.borderRadius.main,
            padding: isOverlay ? "0rem" : ".25rem",
            border: "none",
            outline: "none",
            backgroundColor: isOverlay
              ? "transparent"
              : theme.palette.color.white,
            backdropFilter: "blur(2px)",
            ...tooltipSx,
          },
        },
        ...slotProps,
      }}
      {...restProps}
    >
      {children}
    </Tooltip>
  );
};

export default ToolTipWrapper;
