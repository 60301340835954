import { createSlice } from "@reduxjs/toolkit";

import { resetAllState } from "./reset";

const initialState = {
  transactionsOverlayStatus: {},
  contactOverlayStatus: {},
  popupStatus: { open: false, anchorEl: null },
  popupStatus2: { open: false, anchorEl: null },
  popupStatus3: { open: false, anchorEl: null },
  popupStatus4: { open: false, anchorEl: null },
  popupStatus5: { open: false, anchorEl: null },
};

const datasetSlice = createSlice({
  name: "datasetSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setTransactionsOverlayStatus(state, action) {
      state.transactionsOverlayStatus = action.payload;
    },
    setContactOverlayStatus(state, action) {
      state.contactOverlayStatus = action.payload;
    },
    setPopupStatus(state, action) {
      state.popupStatus = action.payload;
    },
    setPopupStatus2(state, action) {
      state.popupStatus2 = action.payload;
    },
    setPopupStatus3(state, action) {
      if (action.payload?.key) {
        state.popupStatus3[action.payload?.key] = action.payload?.value;
      } else {
        state.popupStatus3 = action.payload;
      }
    },
    setPopupStatus5(state, action) {
      if (action.payload?.key) {
        state.popupStatus5[action.payload?.key] = action.payload?.value;
      } else {
        state.popupStatus5 = action.payload;
      }
    },
    setPopupStatus4(state, action) {
      state.popupStatus4 = action.payload;
    },
  },
});
export const {
  setTransactionsOverlayStatus,
  setPopupStatus,
  setPopupStatus2,
  setPopupStatus3,
  setPopupStatus4,
  setPopupStatus5,
  setContactOverlayStatus,
} = datasetSlice.actions;
export default datasetSlice;
