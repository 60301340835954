import { Box, Button, Typography, Paper } from "@mui/material";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ReactJoyride from "react-joyride";
import React from "react";

import { setStepIndex, stopTour } from "../../store/slices/tour";
import { setJoyRidePayload } from "../../store/slices/global";
import Translate from "../../hooks/HOC/Translate";
import AsyncImage from "../AsyncImage";
import { tourStep } from "./TourStep";
import theme from "../../theme";

const Tour = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //redux
  const tourState = useSelector((state) => state.tourSlice);
  const steps = tourStep?.[tourState?.name] ?? [];

  //functions
  const handleJoyrideCallback = async (data) => {
    const { action, type, status, index } = data;
    // data?.step?.target--->means curret active target

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const { name } = tourState;
      const isNext = type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT;
      const isPrev = type === EVENTS.STEP_AFTER && action === ACTIONS.PREV;
      let nextKeys = [];
      let prevKeys = [];
      let joyRidePayload = {
        name,
        current: {
          key: data?.step?.target,
          payload: {
            index: index,
          },
        },
      };
      if (isNext) {
        joyRidePayload.next = {
          key: steps?.[index + 1]?.content,
          payload: {
            index: index + 1,
          },
        };
      }
      if (isPrev) {
        joyRidePayload.prev = {
          key: steps?.[index - 1]?.content,
          payload: {
            index: index - 1,
          },
        };
      }
      if (name === "joyride_planview") {
        nextKeys = [
          "joyride_planview_step_2",
          "joyride_planview_step_3",
          "joyride_planview_step_4",
          "joyride_planview_step_6",
        ];
        prevKeys = [
          "joyride_planview_step_5",
          "joyride_planview_step_3",
          "joyride_planview_step_2",
          "joyride_planview_step_1",
        ];
      }
      // console.log("JoyrideCallback", joyRidePayload, data);

      if (
        nextKeys?.includes(joyRidePayload?.next?.key) ||
        prevKeys?.includes(joyRidePayload?.prev?.key)
      ) {
        dispatch(stopTour());
      }
      dispatch(setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1)));
      dispatch(setJoyRidePayload(joyRidePayload));
    } else if (
      action === ACTIONS.CLOSE ||
      status === STATUS.SKIPPED ||
      status === STATUS.FINISHED
    ) {
      dispatch(stopTour());
    }
  };

  return (
    <ReactJoyride
      key={tourState.key}
      {...{
        run: tourState.run,
        stepIndex: tourState.stepIndex,
        steps: tourState.steps,
        loading: tourState.loading,
        name: tourState.name,
        tourStatus: tourState.tourStatus,
      }}
      continuous={true}
      scrollToFirstStep={true}
      showProgress={true}
      showSkipButton={true}
      callback={handleJoyrideCallback}
      tooltipComponent={CustomTooltip}
      styles={{
        options: {
          primaryColor: theme.palette.primary.main,
          zIndex: 9999,
        },
      }}
      locale={{
        last: t("Close"),
        back: t("Back"),
        skip: t("Skip"),
        next: t("Next"),
      }}
    />
  );
};
export default Tour;

function CustomTooltip(props) {
  console.log("🚀 props:", props);
  const { t } = useTranslation();
  const {
    backProps,
    closeProps,
    continuous,
    index,
    size,
    primaryProps,
    step,
    tooltipProps,
  } = props;

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        minWidth: 360,
        maxWidth: 420,
        position: "relative",
        borderRadius: theme.borderRadius.main,
        background: theme.palette.color.white,
        backdropFilter: "blur(10px)",
        boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
        animation: "slideIn 0.3s ease-out",
        "@keyframes slideIn": {
          "0%": {
            opacity: 0,
            transform: "translateY(10px)",
          },
          "100%": {
            opacity: 1,
            transform: "translateY(0)",
          },
        },
        ...tooltipProps.style,
      }}
      {...tooltipProps}
    >
      <Button
        {...closeProps}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          minWidth: "auto",
          p: 1.5,
          color: "text.secondary",
          transition: "all 0.2s ease",
          "&:hover": {
            color: "text.primary",
            bgcolor: "action.hover",
            transform: "rotate(90deg)",
          },
        }}
      >
        <CloseIcon fontSize="small" />
      </Button>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 4,
          pr: 4,
          minHeight: 80,
          height: "fit-content",
        }}
      >
        <Box
          sx={{
            position: "relative",
            animation: "bounce 2s infinite",
            backgroundColor: "transparent",
            "@keyframes bounce": {
              "0%, 100%": {
                transform: "translateY(0)",
              },
              "50%": {
                transform: "translateY(-5px)",
              },
            },
          }}
        >
          <AsyncImage
            imageKey="onboarding_avatar"
            alt="Finbot"
            style={{
              width: "5.5rem",
              marginTop: "0.5rem",
              objectFit: "cover",
              flexShrink: 0,
              borderRadius: "50%",
            }}
          />
        </Box>

        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            lineHeight: 1.6,
            flex: 1,
            minWidth: 0,
            mt: 8,
            animation: "fadeIn 0.5s ease-out",
            "@keyframes fadeIn": {
              "0%": {
                opacity: 0,
                transform: "translateX(-10px)",
              },
              "100%": {
                opacity: 1,
                transform: "translateX(0)",
              },
            },
          }}
        >
          {step?.content ? <Translate i18nkey={step?.content} /> : ""}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: index > 0 ? "space-between" : "flex-end",
          alignItems: "center",
          mt: 4,
          pt: 3,
          borderTop: 1,
          borderColor: "divider",
          animation: "slideUp 0.3s ease-out",
          "@keyframes slideUp": {
            "0%": {
              opacity: 0,
              transform: "translateY(10px)",
            },
            "100%": {
              opacity: 1,
              transform: "translateY(0)",
            },
          },
        }}
      >
        {index > 0 && (
          <Button
            {...backProps}
            variant="outlined"
            size="small"
            sx={{
              px: 3,
              py: 1.5,
              minWidth: 80,
              transition: "all 0.2s ease",
              "&:hover": {
                transform: "translateX(-2px)",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            {backProps.title}
          </Button>
        )}
        {continuous && (
          <Button
            {...primaryProps}
            variant="contained"
            size="small"
            color="primary"
            sx={{
              px: 3,
              py: 1.5,
              minWidth: 80,
              transition: "all 0.2s ease",
              "&:hover": {
                transform: "translateX(2px)",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            {t("joyride_action_next_step", {
              currentStep: index + 1,
              totalStep: size,
            })}
          </Button>
        )}
      </Box>
    </Paper>
  );
}

// if (name === "list") {
//   if (
//     type === EVENTS.STEP_AFTER &&
//     action === ACTIONS.PREV &&
//     data?.step?.target === ".list_6_step" &&
//     index === 5
//   ) {
//     Emitter.emit("list_1_step");
//   }
//   if (
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.NEXT &&
//       data?.step?.target === ".list_5_step" &&
//       index === 4) ||
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.PREV &&
//       data?.step?.target === ".list_7_step" &&
//       index === 6)
//   ) {
//     Emitter.emit("list_5_step");
//   }
//   if (
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.NEXT &&
//       data?.step?.target === ".list_6_step" &&
//       index === 5) ||
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.PREV &&
//       data?.step?.target === ".list_8_step" &&
//       index === 7)
//   ) {
//     Emitter.emit("list_6_step");
//   }
//   if (
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.NEXT &&
//       data?.step?.target === ".list_7_step" &&
//       index === 6) ||
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.PREV &&
//       data?.step?.target === ".list_10_step" &&
//       index === 9)
//   ) {
//     Emitter.emit("list_7_step");
//   }
//   if (
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.NEXT &&
//       data?.step?.target === ".list_9_step" &&
//       index === 8) ||
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.PREV &&
//       data?.step?.target === ".list_11_step" &&
//       index === 10)
//   ) {
//     Emitter.emit("list_9_step");
//   }
//   if (
//     type === EVENTS.STEP_AFTER &&
//     action === ACTIONS.NEXT &&
//     data?.step?.target === ".list_10_step" &&
//     index === 9
//   ) {
//     Emitter.emit("list_10_step");
//   }
// }

// if (name === "dashboard_overview") {
//   if (
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.NEXT &&
//       data?.step?.target === ".dashboard_overview_1_step" &&
//       index === 1) ||
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.PREV &&
//       data?.step?.target === ".dashboard_overview_3_step" &&
//       index === 3)
//   ) {
//     Emitter.emit("dashboard_overview_1_step", 2);
//   }
//   if (
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.NEXT &&
//       data?.step?.target === ".dashboard_overview_2_1_step" &&
//       index === 2) ||
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.PREV &&
//       data?.step?.target === ".dashboard_overview_3_step_after" &&
//       index === 4)
//   ) {
//     Emitter.emit("dashboard_overview_2_1_step", 6);
//   }
//   if (
//     type === EVENTS.STEP_AFTER &&
//     action === ACTIONS.NEXT &&
//     data?.step?.target === ".dashboard_overview_3_step" &&
//     index === 3
//   ) {
//     Emitter.emit("dashboard_overview_3_step_after", 7);
//   }
//   if (
//     type === EVENTS.STEP_AFTER &&
//     action === ACTIONS.PREV &&
//     data?.step?.target === ".dashboard_overview_2_1_step" &&
//     index === 2
//   ) {
//     Emitter.emit("dashboard_overview_0_step", 0);
//   }
// }

// if (name === "kanban") {
//   if (
//     ((type === EVENTS.STEP_AFTER || type === "error:target_not_found") &&
//       action === ACTIONS.NEXT &&
//       data?.step?.target === ".kanban_1_step" &&
//       index === 0) ||
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.PREV &&
//       data?.step?.target === ".kanban_2_step" &&
//       index === 2)
//   ) {
//     Emitter.emit("Event_Open_Transaction_Overlay");
//   }
//   if (
//     type === EVENTS.STEP_AFTER &&
//     (action === ACTIONS.NEXT || action === ACTIONS.PREV) &&
//     data?.step?.target === ".kanban_3_step_before" &&
//     index === 1
//   ) {
//     Emitter.emit("Event_Close_Transaction_Overlay");
//   }
//   if (
//     type === EVENTS.STEP_AFTER &&
//     action === ACTIONS.NEXT &&
//     data?.step?.target === ".kanban_2_step_single" &&
//     index === 3
//   ) {
//     Emitter.emit("Event_Open_Transaction_Overlay");
//   }
//   if (
//     type === EVENTS.STEP_AFTER &&
//     action === ACTIONS.PREV &&
//     data?.step?.target === ".kanban_5_step" &&
//     index === 11
//   ) {
//     Emitter.emit("Event_Close_Grouped_Options");
//   }
//   if (
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.NEXT &&
//       data?.step?.target === ".kanban_5_step" &&
//       index === 11) ||
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.PREV &&
//       data?.step?.target === ".kanban_7_step" &&
//       index === 15)
//   ) {
//     Emitter.emit(
//       "Event_Open_Grouped_Options",
//       action === ACTIONS.PREV ? 14 : 12
//     );
//   }

//   if (
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.NEXT &&
//       data?.step?.target === ".kanban_7_step" &&
//       index === 15) ||
//     (type === EVENTS.STEP_AFTER &&
//       action === ACTIONS.PREV &&
//       data?.step?.target === ".kanban_11_step" &&
//       index === 17)
//   ) {
//     Emitter.emit("Event_Open_Chart_Options");
//   }

//   if (
//     type === EVENTS.STEP_AFTER &&
//     action === ACTIONS.NEXT &&
//     data?.step?.target === ".kanban_8_step" &&
//     index === 16
//   ) {
//     Emitter.emit("Event_Open_Help_Overlay");
//   }
// }
