import React, { useRef, useState, useContext, useMemo } from "react";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { TbUsersPlus } from "react-icons/tb";
import { useSnackbar } from "notistack";
import { format } from "date-fns";

import {
  getAllTransactionsByParams,
  convertToValidManualState,
  batchCloneRecurringRules,
  deleteBatchTransactions,
  addBatchTransactions,
  formatDateToLocal,
  isBookedStates,
} from "../../Helper/data";
import { setRecurring_rules } from "../../store/slices/global";
import { setPopupStatus3 } from "../../store/slices/datasets";
import useSubscriptions from "../../hooks/useSubscriptions";
import { GlobalContext } from "../../GlobalContextWrapper";
import ActionViewWrapper from "../ActionViewWrapper.jsx";
import { setLoading } from "../../store/slices/appmain";
import TailwindButton from "../Overlay/TailwindButton";
import ReconcileOverlay from "./ReconcileOverlay";
import Translate from "../../hooks/HOC/Translate";
import EndPoints from "../../APICall/EndPoints";
import ActionButton from "../ActionButton";
import SplitModal from "./SplitModal";
import { Color } from "../../Helper";
import APICall from "../../APICall";
import store from "../../store";

// const menuOptions = [
//   {
//     value: 1,
//     label: "transaction-edit-form-past-button",
//     tooltip: "edit_form_update_past_button_tooltip",
//   },
// ];
const ActionButtonView = ({
  cardItem,
  tab,
  disabled,
  isTransactionFormUpdated,
  recurring_Obj,
  onCloseTransactionEdit,
  onSaveRecurrence,
  onClose,
  onCancel,
  updateFlag,
  deleteCardByIdApi,
  internalDsRef,
  showSaved,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubscriptionValid] = useSubscriptions();
  const globalContext = useContext(GlobalContext);

  const seqFunctionRef = globalContext?.seqFunctionRef;

  let splitModalType = useRef("add");
  let deleteType = useRef("single");
  let localData = useRef({ response: [], payload: [] });

  //redux state
  const transactionsOverlayStatus = useSelector(
    (state) => state.datasetSlice?.transactionsOverlayStatus
  );
  const selectionCategoriesByID = useSelector(
    (state) => state.categorySlice.selectionCategoriesByID
  );
  const dataSetData = useSelector((state) => state.boardSlice?.dataSetData);
  const profile = useSelector((state) => state.settingsSlice?.profile);

  const isFinbanAccount =
    profile?.email?.includes("@finban.io") ||
    profile?.email?.includes("@e-laborat.de");

  const modalType = transactionsOverlayStatus?.payload?.modalType;

  //state
  const [open, setOpen] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [isSplitOpen, setIsSplitOpen] = useState(false);

  const isFirstRecurrence = useMemo(() => {
    if (recurring_Obj?.start_date && cardItem?.due_date && cardItem?.uuid) {
      return (
        format(new Date(recurring_Obj?.start_date), "yyyy-MM") ===
        format(new Date(cardItem?.due_date), "yyyy-MM")
      );
    } else {
      return false;
    }
  }, [cardItem?.uuid, cardItem?.due_date, recurring_Obj]);

  const isReconcileAllow =
    isFinbanAccount &&
    ((cardItem?.recurring_rule && isFirstRecurrence) ||
      !cardItem?.recurring_rule) &&
    (cardItem?.state?.includes("Invoice") ||
      cardItem?.state === "Open" ||
      isBookedStates({ state: cardItem?.state }));
  //api
  const addCard = async (obj, type = "Add") => {
    await APICall("post", EndPoints.transactions, obj, {
      doNotCatchErrorResponse: true,
    })
      .then((response) => {
        if (response.status === 201 && response.data) {
          if (type === "clone") {
            enqueueSnackbar(t("Card Cloned Successfully"));
          } else {
            enqueueSnackbar(t("Card Added Successfully"));
          }
          onClose(true);
        }
        if (response.status === 400 && response.data) {
          //keep this handling
          const hasAnyErrorKey =
            response?.data && Object.keys(response?.data)?.length > 0
              ? Object.values(response?.data)?.[0]?.[0]
              : null;
          if (hasAnyErrorKey) {
            enqueueSnackbar(hasAnyErrorKey, {
              variant: "error",
              preventDuplicate: true,
            });
          }
        }
      })

      .finally(() => {
        setLoadingAdd(false);
      });
  };

  const deleteBatch = async (data) => {
    let uuids = data?.map((o1) => o1.uuid);
    dispatch(setLoading(true));
    const isDeleted = await deleteBatchTransactions(uuids);
    if (isDeleted) {
      dispatch(setLoading(false));
      enqueueSnackbar(t("Cards Deleted Successfully"));
      onClose(true);
    }
  };

  const addBatch = async (array) => {
    dispatch(setLoading(true));
    await addBatchTransactions(array);
    const results = await getAllTransactionsByParams({
      endpoint: "recurring_rules",
      dataset: dataSetData?.uuid,
    });
    dispatch(setRecurring_rules(results || []));
    dispatch(setLoading(false));
    enqueueSnackbar(t("Cards Added Successfully"));
    onClose(true);
  };

  //functions
  const add_transaction = () => {
    let dueDate = null;
    let invoiceDate = null;
    // if (
    //   tab === "single" &&
    //   (!cardItem?.gross_value ||
    //     cardItem?.gross_value?.trim() === "" ||
    //     cardItem?.gross_value?.trim() === "-" ||
    //     Number(cardItem?.gross_value) === -0 ||
    //     Number(cardItem?.gross_value) === 0)
    // ) {
    //   enqueueSnackbar(t("please add an amount"), {
    //     variant: "warning",
    //     autoHideDuration: 5000,
    //     preventDuplicate: true,
    //   });
    //   return;
    // }

    setLoadingAdd(true);
    if (cardItem?.due_date && cardItem?.due_date !== "Invalid date") {
      dueDate = format(new Date(cardItem?.due_date), "yyyy-MM-dd");
    }
    if (cardItem?.invoice_date && cardItem?.invoice_date !== "Invalid date") {
      invoiceDate = format(new Date(cardItem?.invoice_date), "yyyy-MM-dd");
    }
    let title = "";
    if (cardItem?.title) {
      title = cardItem?.title;
    } else {
      title = `${t(cardItem?.state)}`;
      if (cardItem?.recurring_rule) {
        title = title + ` Sequence`;
      } else {
        title = title + ` Transaction`;
      }
      if (cardItem?.category) {
        const category = selectionCategoriesByID?.[cardItem?.category]?.[0];
        title =
          title +
          ` For ${category?.immutable ? t(category?.title) : category?.title}`;
      }
      title = title + ` in ${formatDateToLocal(new Date(), "MMMM")}`;
    }

    let obj = {
      title: title,
      currency: dataSetData?.currency,
      state: cardItem.state || "Open",
      scenario: cardItem.scenario || "Base",
      note: cardItem.note || "",
      due_date: dueDate || format(new Date(), "yyyy-MM-dd"),
      invoice_date: invoiceDate || null,
      gross_value: localData.current?.gross_value
        ? String(localData.current?.gross_value)
        : String(cardItem?.gross_value),
      tax: cardItem.tax || null,
      category: cardItem.category || null,
      source: cardItem.source || 1,
      cost_unit: cardItem.cost_unit || null,
      data_source: cardItem?.data_source,
      contact: cardItem?.contact,
      transaction_system: cardItem.transaction_system,
      recurring_rule: cardItem.recurring_rule || null,
      //income_expense_type it is read only value , it will not make any change in backend
      income_expense_type: cardItem.income_expense_type || null,
      position: 3001,
    };
    internalDsRef?.current?.enableInternalDS();
    addCard(obj);
  };

  const onClickCardClone = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      let obj = {
        title: `Copy of ${cardItem?.title}`,
        dataset: cardItem?.dataset,
        scenario: cardItem.scenario || "Base",
        note: cardItem?.note,
        due_date: cardItem?.due_date,
        invoice_date: cardItem?.invoice_date,
        contact: cardItem?.contact,
        tax: cardItem?.tax,
        category: cardItem?.category,
        gross_value: cardItem?.gross_value,
        cost_unit: cardItem?.cost_unit,
        position: cardItem?.position,
        recurring_rule: cardItem?.recurring_rule,
        source: 1,
        state: convertToValidManualState(cardItem?.state),
        data_source:
          cardItem?.source === 1
            ? cardItem?.data_source
            : dataSetData?.internal_data_source,
      };
      internalDsRef?.current?.enableInternalDS();
      addCard(obj, "clone");
    }
  };

  const onClickStaffClone = async () => {
    if (isSubscriptionValid({ showMessage: true, type: "employee" })) {
      seqFunctionRef?.current?.cloneSequencesWithStaff({
        item: {
          name: recurring_Obj?.name,
          recurringList: [recurring_Obj],
        },
      });
    }
  };

  const onClickCardDelete = () => {
    deleteType.current = "single";
    dispatch(
      setPopupStatus3({
        id: "simple-popper",
        open: true,
        overlay_type: "delete",
        onConfirm: onOkDelete,
        onClose: onCloseDelete,
        payload: {
          title: t("Delete"),
          message: t("Are you sure, you want to delete this transaction?"),
          type: deleteType.current,
        },
      })
    );
  };

  const onDeleteRecurrence = () => {
    deleteType.current = "recurring";
    dispatch(
      setPopupStatus3({
        id: "simple-popper",
        open: true,
        overlay_type: "delete",
        onConfirm: onOkDelete,
        onClose: onCloseDelete,
        payload: {
          title: t("Delete"),
          message: (
            <Translate i18nkey={`delete_recurring_transaction_warning`} />
          ),
          type: deleteType.current,
        },
      })
    );
  };

  const onClickStaffDelete = async () => {
    seqFunctionRef?.current?.deleteSequencesWithStaff({
      item: {
        contact: recurring_Obj?.contact,
        recurringList: [recurring_Obj],
      },
    });
  };

  const onClickRecurClone = async () => {
    if (isSubscriptionValid({ showMessage: true })) {
      dispatch(setLoading(true));
      let recurObj = {
        uuid: recurring_Obj?.uuid,
        name:
          recurring_Obj?.recurring_type === "employee"
            ? recurring_Obj?.name
            : `Copy of ${recurring_Obj?.name}`,
      };
      const newClonedRules = await batchCloneRecurringRules([recurObj]);

      let oldRecurData = await getAllTransactionsByParams({
        dataset: dataSetData?.uuid,
        recurring_rule: [cardItem?.recurring_rule],
        is_reconciled: false,
      });
      let array = [];
      newClonedRules?.forEach((rule) => {
        oldRecurData?.forEach((element) => {
          const obj = {
            title: `Copy of ${element?.title}`,
            state: convertToValidManualState(element?.state),
            scenario: element?.scenario,
            note: element?.note,
            due_date: element?.due_date,
            invoice_date: element?.invoice_date,
            tax: element?.tax,
            category: element?.category,
            gross_value: element?.gross_value,
            data_source: element?.data_source,
            contact: element?.contact,
            cost_unit: element?.cost_unit,
            source: 1,
            position: element?.position,
            currency: element?.currency,
            recurring_rule: rule?.uuid,
          };
          array.push(obj);
        });
      });

      setTimeout(() => {
        addBatch(array);
      }, 1000);
    }
  };

  const onClickCardReconcile = () => {
    if (isSubscriptionValid({ showMessage: true })) {
      if (cardItem?.reconciled) {
        const obj = {
          reconciled: null,
        };
        updateFlag(obj);
      } else {
        setOpen(true);
      }
    }
  };

  const onCloseDelete = () => {
    dispatch(setPopupStatus3(null));
  };

  const onOkDelete = async () => {
    onCloseDelete();
    if (deleteType.current === "single") {
      deleteCardByIdApi(cardItem.uuid);
    }
    if (deleteType.current === "recurring") {
      dispatch(setLoading(true));
      let oldRecurData = await getAllTransactionsByParams({
        dataset: dataSetData?.uuid,
        recurring_rule: [cardItem?.recurring_rule],
        is_reconciled: false,
      });
      const recurring_rules = store.getState().globalSlice?.recurring_rules;
      const data = recurring_rules?.filter(
        (o1) => o1?.uuid !== cardItem?.recurring_rule
      );
      dispatch(setRecurring_rules(data));
      deleteBatch(oldRecurData);
    }
  };

  const onClickSplit = () => {
    splitModalType.current =
      cardItem?.children?.length > 0 || cardItem.parent ? "edit" : "add";
    setIsSplitOpen(true);
  };

  const handleCloseSplit = (type) => {
    setIsSplitOpen(false);
    if (!type) onClose(true);
  };

  const onAdd = () => {
    if (tab === "single") {
      add_transaction();
    } else {
      onSaveRecurrence({ doClose: true });
    }
  };

  const btnBackgroundColor = Color.tailwind.grey[200];
  return (
    <Box
      sx={{
        width: "45rem",
        py: "1.5rem",
        justifyContent: "space-between",
        backgroundColor: Color.FooterBG,
        position: "fixed",
        bottom: 0,
        right: "0",
        zIndex: 6,
      }}
    >
      {open && (
        <ReconcileOverlay
          setOpen={setOpen}
          open={open}
          itemToBeReconcile={cardItem}
          isTransactionFormUpdated={isTransactionFormUpdated}
        />
      )}
      {isSplitOpen && (
        <SplitModal
          handleClose={handleCloseSplit}
          open={isSplitOpen}
          itemToBeSplit={cardItem}
          modalType={splitModalType.current}
        />
      )}
      {modalType === "edit" ? (
        <ActionViewWrapper
          height="2.6rem"
          iconMR={"0.5rem"}
          width="fit-content"
          wrapperBackgroundColor={"transparent !important"}
          btnBackgroundColor={btnBackgroundColor}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            maxWidth: "97%",
            mb:
              modalType === "edit" && cardItem?.recurring_rule && showSaved
                ? "2rem"
                : "4rem",
            " .actionBtn": {
              paddingRight: "1.5rem",
              borderRadius: theme.borderRadius.main,
            },
          }}
        >
          <Stack sx={{ gap: "0.5rem", ml: "1.25rem" }}>
            <Stack direction="row" alignItems={"center"} sx={{ gap: "0.5rem" }}>
              <TailwindButton
                disable={!showSaved}
                text={
                  showSaved
                    ? t("transaction-edit-form-saved-button")
                    : t("Update")
                }
                sx={{ fontSize: "0.8rem", ml: 0 }}
                onClick={showSaved ? onCloseTransactionEdit : undefined}
              />

              <TailwindButton
                type="cancel"
                text={t("Cancel")}
                sx={{
                  fontSize: "0.8rem",
                  ml: 0,
                  backgroundColor: theme.palette.color.slate[200],
                  "&:hover": {
                    backgroundColor: theme.palette.color.slate[300],
                  },
                }}
                onClick={onCancel}
              />
            </Stack>

            {modalType === "edit" && cardItem?.recurring_rule && showSaved ? (
              <TailwindButton
                variant="outlined"
                text={t("transaction-edit-form-past-button")}
                color={theme.palette.primary.main}
                sx={{
                  fontSize: "0.8rem",
                  ml: 0,
                }}
                onClick={() => onCloseTransactionEdit({ isPastIncluded: true })}
              />
            ) : null}
            {/* {modalType === "edit" && cardItem?.recurring_rule && showSaved ? (
              <span>
                <MenuView
                  anchorEl={anchorElMenu}
                  open={Boolean(anchorElMenu)}
                  options={menuOptions}
                  handleClose={handleClose}
                  onClickItem={onClickItem}
                />
                <IconButton
                  aria-label="more"
                  id="long-button"
                  onClick={onClickMenu}
                  sx={{ background: "transparent !important", mr: 1 }}
                >
                  <MoreVertIcon
                    sx={{
                      color: "color.slate.600",
                    }}
                  />
                </IconButton>
              </span>
            ) : null} */}
          </Stack>

          {cardItem?.recurring_rule ? (
            <Box
              size="small"
              sx={{
                display: "flex",
                maxWidth: "fit-content",
                flexWrap: "wrap",
                gap: "0.5rem",
                borderRadius: "20px",
                ml: "2rem",
              }}
            >
              {isReconcileAllow && (
                <ActionButton
                  onClick={onClickCardReconcile}
                  isHighlighted={cardItem?.reconciled}
                  label={
                    cardItem?.reconciled ? t("Reconciled") : t("Reconcile")
                  }
                  icon={<CompareArrowsIcon />}
                />
              )}
              {isBookedStates({ state: cardItem?.state }) &&
              cardItem?.recurring_rule ? null : (
                <ActionButton
                  onClick={onClickRecurClone}
                  label={
                    tab === "employee"
                      ? "staff_duplicate_sequence"
                      : "duplicate_sequence"
                  }
                  icon={<ContentCopyIcon />}
                  tooltip={
                    tab === "employee"
                      ? "staff_duplicate_sequence_tooltip"
                      : "duplicate_sequence_tooltip"
                  }
                />
              )}
              {!isBookedStates({ state: cardItem?.state }) && (
                <ActionButton
                  onClick={onDeleteRecurrence}
                  label={
                    tab === "employee"
                      ? "staff_delete_sequence"
                      : "delete_sequence"
                  }
                  icon={<DeleteSweepIcon />}
                  tooltip={
                    tab === "employee"
                      ? "staff_delete_sequence_tooltip"
                      : "delete_sequence_tooltip"
                  }
                />
              )}

              {tab === "employee" && recurring_Obj?.name ? (
                <ActionButton
                  onClick={onClickStaffClone}
                  label={"staff_duplicate_sequence_with_staff"}
                  tooltip={"staff_duplicate_sequence_with_staff_tooltip"}
                  icon={<TbUsersPlus />}
                />
              ) : null}
              {tab === "employee" &&
              recurring_Obj?.name &&
              recurring_Obj?.contact ? (
                <ActionButton
                  onClick={onClickStaffDelete}
                  label={"staff_delete_sequence_with_staff"}
                  icon={<DeleteSweepIcon />}
                  tooltip={"staff_delete_sequence_with_staff_tooltip"}
                />
              ) : null}
            </Box>
          ) : (
            <Box
              size="small"
              sx={{
                display: "flex",
                maxWidth: "fit-content",
                borderRadius: "20px",
                flexWrap: "wrap",
                gap: "0.5rem",
                ml: "2rem",
              }}
            >
              {cardItem?.source === 1 || cardItem.source === 2 ? (
                <ActionButton
                  onClick={onClickSplit}
                  isHighlighted={cardItem?.parent}
                  label={cardItem?.parent ? "Merge" : "Split"}
                  icon={<CallSplitIcon />}
                />
              ) : null}

              {isReconcileAllow && (
                <ActionButton
                  onClick={onClickCardReconcile}
                  isHighlighted={cardItem?.reconciled}
                  label={
                    cardItem?.reconciled ? t("Reconciled") : t("Reconcile")
                  }
                  icon={<CompareArrowsIcon />}
                />
              )}

              <ActionButton
                onClick={onClickCardClone}
                label={"Duplicate Planning"}
                icon={<ContentCopyIcon />}
              />

              {!disabled && (
                <ActionButton
                  onClick={onClickCardDelete}
                  label={"Delete Planning"}
                  icon={<DeleteIcon />}
                />
              )}
            </Box>
          )}
        </ActionViewWrapper>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          backgroundColor={"inherit"}
        >
          <TailwindButton
            loading={loadingAdd}
            text={t("Add")}
            sx={{ fontSize: "1rem" }}
            onClick={onAdd}
          />
          <TailwindButton
            type="cancel"
            text={t("Cancel")}
            sx={{ fontSize: "1rem" }}
            onClick={onCloseTransactionEdit}
          />
        </Box>
      )}
    </Box>
  );
};

export default ActionButtonView;
