import { Button, Paper, Typography, useTheme } from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { differenceInDays } from "date-fns";
import { useSelector } from "react-redux";
import React from "react";

import { GlobalContext } from "../../GlobalContextWrapper";
import { Color, Constant, Fonts } from "../../Helper";
import { isPlanExpired } from "../../Helper/data";

const Banner = ({ setShowBanner, showBanner }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const globalContext = useContext(GlobalContext);
  const calendlyRef = globalContext?.calendlyRef;

  //redux
  const refreshBalance = useSelector((state) => state.appSlice?.refreshBalance); //keep this for rerender
  const isBannerVisible = useSelector(
    (state) => state.appSlice?.isBannerVisible
  );
  const subscription = useSelector(
    (state) => state.settingsSlice?.subscription
  );
  const isAllHeaderApiFetched = useSelector(
    (state) => state.commonSlice.isAllHeaderApiFetched
  );
  const isPageNotFound = useSelector(
    (state) => state.commonSlice.isPageNotFound
  );

  let day = differenceInDays(
    new Date(subscription?.expiry_date ?? new Date()),
    new Date()
  );
  const isMaintenance = t("maintenance_banner_message") !== "hide";

  useEffect(() => {
    if (
      ((isMaintenance ||
        (subscription?.name === "Trial" && isAllHeaderApiFetched)) &&
        !isPageNotFound) ||
      isBannerVisible
    ) {
      Constant.BANNER_HEIGHT = "2.5rem";
      setShowBanner(true);
    } else {
      Constant.BANNER_HEIGHT = "0rem";
      setShowBanner(false);
    }
  }, [
    subscription?.name,
    isAllHeaderApiFetched,
    isPageNotFound,
    t,
    refreshBalance,
    setShowBanner,
    isMaintenance,
    isBannerVisible,
  ]);

  const onClickLink = () => {
    navigate("/settings/subscription");
  };

  const getText = () => {
    let text = "";

    if (isPlanExpired(subscription)) {
      text = t("Your subscription plan  Expired - Please upgrade you plan.", {
        subscription: subscription?.name,
      });
      return text;
    }
    if (subscription?.name === "Trial") {
      if (day >= 0 && day <= 2) {
        text = t(
          "Your subscription plan will Expire, Book now for a 25% discount.",
          {
            subscription: subscription?.name,
            day: day + 1,
            day_plural: day > 0 ? t("day_s") : t("day"),
          }
        );
        return text;
      }
      text = t("You can still test finban, Book now for a 25% discount.", {
        subscription: subscription?.name,
        day: day + 1,
        day_plural: day > 0 ? t("day_s") : t("day"),
      });
    } else {
      if ((day >= 0 && day <= 2) || isBannerVisible) {
        text = t("Your subscription plan will Expired in day", {
          subscription: subscription?.name,
          day: day + 1,
          day_plural: day > 0 ? t("day_s") : t("day"),
        });
        return text;
      }
    }
    return text;
  };

  const onClickPlanning = () => {
    navigate("/settings/subscription");
  };

  const onClickCalendly = () => {
    calendlyRef?.current?.handleOpen();
  };

  if (!showBanner) {
    return null;
  }

  return (
    <Paper
      sx={{
        width: "100%",
        fontSize: "1rem",
        paddingBlock: "0.1rem",
        height: Constant.BANNER_HEIGHT,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: isMaintenance
          ? theme.palette.color.red[500]
          : theme?.palette?.primary.dark,
        borderRadius: 0,
        gap: "1rem",
        zIndex: 1000,
      }}
      square
      elevation={0}
    >
      <Typography
        sx={{
          background: "inherit",
          color: Color?.white,
          fontFamily: Fonts?.Text,
          fontSize: { xs: "0.8rem", small: "1rem" },
          fontWeight: 500,
          cursor: "pointer",
          height: Constant.BANNER_HEIGHT,
          display: "flex",
          alignItems: "center",
        }}
        onClick={onClickLink}
      >
        {isMaintenance ? `${t("maintenance_banner_message")}` : getText()}
      </Typography>
      {isMaintenance ? null : (
        <Button
          onClick={onClickPlanning}
          color="primary"
          variant="text"
          disableRipple
          size="large"
          sx={{
            py: "0.1rem",
            px: "0.75rem",
            textTransform: "capitalize",
            fontWeight: theme.typography.fontWeightMediumBold,
            color: theme.palette.primary.main,
            boxShadow: theme.boxShadow,
            backgroundColor: theme.palette.color.white,
            "&:hover": {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.color.white,
            },
          }}
        >
          {t("banner_button_text_01")}
        </Button>
      )}
      {isMaintenance ? null : (
        <Button
          onClick={onClickCalendly}
          startIcon={<SupportAgentIcon />}
          color="primary"
          variant="contained"
          disableRipple
          size="large"
          sx={{
            py: "0.1rem",
            px: "0.75rem",
            textTransform: "capitalize",
            fontWeight: theme.typography.fontWeightMediumBold,
            color: theme.palette.color.white,
            boxShadow: theme.boxShadow,
            backgroundColor: "transparent",
            border: `1px solid ${theme.palette.color.white}`,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          {t("banner_button_text_02")}
        </Button>
      )}
    </Paper>
  );
};
export default Banner;
