import ReactDOM from "react-dom/client";
import React from "react";
import "./styles.css";
import "./fonts.css";

import packageJson from "../package.json";
import { color } from "./theme";
import App from "./App";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);
Error.stackTraceLimit = import.meta.env.VITE_MODE === "development" ? 10 : 30;

const reloadPage = async () => {
  // clear cache
  window.location.replace(`${window.location.href} `);
};

const ErrorHandler = {
  config: {
    vitePreloadError: {
      enabled: true,
      maxNotifications: 3,
      notificationTimeout: 5 * 60 * 1000, // 5 minutes
    },
    moduleImportError: {
      enabled: true,
      maxNotifications: 3,
      notificationTimeout: 5 * 60 * 1000, // 5 minutes
    },
  },

  createNotification(options = {}) {
    const existingNotification = document.getElementById("app-notification");
    if (existingNotification) {
      existingNotification.remove();
    }

    const defaultOptions = {
      message: "Notification",
      type: "error",
      actionText: "Reload",
      onAction: () => reloadPage(),
      duration: null, // Persistent by default
    };

    const settings = { ...defaultOptions, ...options };

    const notification = document.createElement("div");
    notification.id = "app-notification";

    const typeStyles = {
      theme: {
        backgroundColor: color.primary.main,
        color: "white",
      },
      success: {
        backgroundColor: "#22c55e",
        color: "white",
      },
      info: {
        backgroundColor: "#4a90e2",
        color: "white",
      },
      warning: {
        backgroundColor: "#ff9800",
        color: "white",
      },
      error: {
        backgroundColor: "#f44336",
        color: "white",
      },
    };

    const style = typeStyles[settings.type] || typeStyles.error;

    Object.assign(notification.style, {
      position: "fixed",
      top: "2%",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: style.backgroundColor,
      color: style.color,
      paddingBlock: "0.75rem",
      paddingInline: "1rem",
      borderRadius: "5px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      zIndex: "9999",
      display: "flex",
      alignItems: "center",
      gap: "10px",
      maxWidth: "fit-content",
      wordBreak: "break-word",
      fontFamily: "Inter",
    });

    const text = document.createElement("span");
    text.textContent = settings.message;

    if (settings.onAction) {
      const button = document.createElement("button");
      button.textContent = settings.actionText;
      button.style.padding = "8px 12px";
      button.style.border = "none";
      button.style.borderRadius = "4px";
      button.style.backgroundColor = "white";
      button.style.color = style.backgroundColor;
      button.style.cursor = "pointer";
      button.style.fontWeight = "bold";
      button.style.fontFamily = "Inter";
      button.onclick = () => reloadPage();

      notification.appendChild(text);
      notification.appendChild(button);
    } else {
      notification.appendChild(text);
    }
    document.body.appendChild(notification);

    if (settings.duration) {
      setTimeout(() => {
        notification.remove();
      }, settings.duration);
    }
  },

  trackErrorNotification(errorType) {
    if (!this.config[errorType].enabled) return false;

    const notificationKey = `${errorType}Count`;
    const timestampKey = `${errorType}Timestamp`;

    const currentTime = Date.now();
    const storedCount = parseInt(
      sessionStorage.getItem(notificationKey) || "0"
    );
    const lastTimestamp = parseInt(sessionStorage.getItem(timestampKey) || "0");

    // Reset count if outside the time window
    if (
      currentTime - lastTimestamp >
      this.config[errorType].notificationTimeout
    ) {
      sessionStorage.removeItem(notificationKey);
      sessionStorage.removeItem(timestampKey);
    }

    // Check if we've exceeded max notifications
    if (storedCount >= this.config[errorType].maxNotifications) {
      console.warn(`Max ${errorType} notifications reached`);
      return false;
    }

    // Update notification tracking
    sessionStorage.setItem(notificationKey, storedCount + 1);
    sessionStorage.setItem(timestampKey, currentTime.toString());

    return true;
  },

  handleModuleImportError() {
    if (!this.trackErrorNotification("moduleImportError")) return;
    this.createNotification({
      message: "A new version of the application is available!",
      type: "theme",
      actionText: "Reload Now",
      onAction: () => reloadPage(),
    });
  },
};

const Errors = [
  "TypeError: error loading dynamically imported module",
  "Failed to fetch dynamically imported module",
];

window.addEventListener("vite:preloadError", (event) => {
  if (Errors?.some((error) => event?.payload?.message?.includes(error))) {
    //new version is available
    ErrorHandler.handleModuleImportError(event);
  }
});
window.addEventListener("error", (event) => {
  if (Errors?.some((error) => event?.message?.includes(error))) {
    //new version is available
    ErrorHandler.handleModuleImportError(event);
  }
});

// ErrorHandler.handleModuleImportError();
console.log("verison", `finban@${packageJson.version}`);
root.render(<App />);
