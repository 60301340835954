import { createSlice } from "@reduxjs/toolkit";

import { resetAllState } from "./reset";

const initialState = {
  stageText: false,
  testLoading: false,
  isFirstLoading: true,
  isBannerVisible: false,
  refreshData: null,
  refreshBalance: null,
  isMonthChartLoading: false,
  isMonthChartFetching: false,
  isTableLoading: false,
  loading: false,
  refreshColumnData: null,
  isKanbanUpdated: false,
};

const appSlice = createSlice({
  name: "appSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAllState, () => initialState);
  },
  reducers: {
    setIsKanbanUpdated: (state, action) => {
      state.isKanbanUpdated = action.payload;
    },
    setRefreshColumnData: (state, action) => {
      state.refreshColumnData = action.payload;
    },
    setIsTableLoading: (state, action) => {
      state.isTableLoading = action.payload;
    },
    setRefreshData: (state, action) => {
      state.refreshData = action.payload;
    },
    setIsMonthChartFetching: (state, action) => {
      state.isMonthChartFetching = action.payload;
    },
    setIsMonthChartLoading: (state, action) => {
      state.isMonthChartLoading = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setStageLoadingText: (state, action) => {
      state.stageText = action.payload;
    },
    setTestLoading: (state, action) => {
      state.testLoading = action.payload || !state.testLoading;
    },
    setRefreshBalance: (state, action) => {
      state.refreshBalance = action.payload;
    },
    setFirstLoading: (state, action) => {
      state.isFirstLoading = action.payload;
    },
    toggleShowBanner: (state) => {
      state.isBannerVisible = !state.isBannerVisible;
    },
  },
});
export const {
  setIsKanbanUpdated,
  setIsMonthChartLoading,
  setLoading,
  setStageLoadingText,
  setTestLoading,
  setRefreshData,
  setRefreshColumnData,
  toggleRefreshDataInflow,
  toggleRefreshDataOutflow,
  setIsTableLoading,
  setRefreshBalance,
  setFirstLoading,
  toggleShowBanner,
  setIsMonthChartFetching,
} = appSlice.actions;
export default appSlice;
